import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminSiteDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { AdminDetailsPanelTitle } from '../../../4-features/admin/admin-details-panel-title/admin-details-panel-title';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { ModifySiteDrawer } from '../modify-site-drawer/modify-site-drawer';

interface SiteDetailsAdminPanelProps {
  // This panel will only be shown if the site details are successfully loaded
  siteDetails: NonNullable<GetAdminSiteDetailsQuery['siteByPK']>;
}

export const SiteDetailsAdminPanel = ({ siteDetails }: SiteDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const [openModifySite, setOpenModifySite] = useState<boolean>(false);
  const { formatWithDefaultTimezone } = useFormatTimezone();

  const { result: userCanEditSite, loading: loadingUserCanEditSite } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: {
      type: 'Site',
      customerId: siteDetails.customer.companyId
    },
    skip: false
  });

  return (
    <aside className="site-details-admin-panel" data-testid="site-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('siteAdminDetailsPage.panel.type')}
        objectName={siteDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies/${siteDetails.customer.companyId}`}
        editButtonProps={{
          onClick: () => setOpenModifySite(true),
          disabled: loadingUserCanEditSite || !userCanEditSite
        }}
      />
      <div className="site-details-admin-panel__contents">
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.customer')}
          data-testid="site-details-admin-panel-customer"
          value={siteDetails.customer.company.name}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.address')}
          data-testid="site-details-admin-panel-address"
          value={getNonNullishDisplayValue(siteDetails.location.formattedAddress)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.latitude')}
          data-testid="site-details-admin-panel-latitude"
          value={siteDetails.location.latitude}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.longitude')}
          data-testid="site-details-admin-panel-longitude"
          value={siteDetails.location.longitude}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.timezone')}
          data-testid="site-details-admin-panel-timezone"
          value={siteDetails.timezoneName}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.description')}
          data-testid="site-details-admin-panel-description"
          value={getNonNullishDisplayValue(siteDetails.description, true)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.createdAt')}
          data-testid="site-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(siteDetails.createdAt)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.updatedAt')}
          data-testid="site-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(siteDetails.updatedAt)}
        />
      </div>
      <ModifySiteDrawer
        open={openModifySite}
        createdAt={formatWithDefaultTimezone(siteDetails.createdAt)}
        customerId={siteDetails.customer.companyId}
        customerName={siteDetails.customer.company.name}
        setOpenModifySite={setOpenModifySite}
        defaultValues={{
          id: siteDetails.id,
          name: siteDetails.name,
          description: siteDetails.description,
          timezone: siteDetails.timezoneName,
          location: {
            latitude: siteDetails.location.latitude,
            longitude: siteDetails.location.longitude,
            formattedAddress: siteDetails.location.formattedAddress
          }
        }}
      />
    </aside>
  );
};
