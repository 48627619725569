import { DateTime } from 'luxon';

import { OperationPeriod } from '../../types/operation-annotation';

export const calculateTimeRangeFromPeriod = (
  period: OperationPeriod,
  timezone: string
): DateTime<boolean>[] | null[] => {
  const timeNow = DateTime.now().setZone(timezone);
  switch (period) {
    case OperationPeriod.Last7Days:
      return [timeNow.minus({ day: 7 }).startOf('day'), timeNow.endOf('day')];
    case OperationPeriod.Last30Days:
      return [timeNow.minus({ day: 30 }).startOf('day'), timeNow.endOf('day')];
    case OperationPeriod.Last90Days:
      return [timeNow.minus({ day: 90 }).startOf('day'), timeNow.endOf('day')];
    case OperationPeriod.FromStart:
    default:
      return [null, null];
  }
};
