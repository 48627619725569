import { JSX } from 'react';

import {
  GetAllDeviceOperationsPerformanceQuery,
  GetAllDeviceOperationsPerformanceQueryVariables
} from '../../../../__generated__/graphql';
import { calculateSuccessPercentage } from '../../../../utilities/calculate-success-percentage/calculate-success-percentage';
import { execIfBothDefined } from '../../../../utilities/exec-if-both-defined/exec-if-both-defined';
import { PerformanceMetricsRender } from '../../../4-features/performance-metrics-render/performance-metrics-render';

interface OperationPerformanceMetricsProps {
  performanceQueryVariables: GetAllDeviceOperationsPerformanceQueryVariables;
  loadingPerformance: boolean;
  errorPerformance: boolean;
  data?: GetAllDeviceOperationsPerformanceQuery;
}

export const OperationPerformanceMetrics = ({
  loadingPerformance,
  errorPerformance,
  data
}: OperationPerformanceMetricsProps): JSX.Element => {
  const acdCycleOperationsFullyCompleted = data?.totalACDCycleOperationsFullyCompleted.aggregate?.count || 0;
  const acdCycleOperationsUnexpectedBehavior = data?.totalACDCycleOperationsUnexpectedBehavior.aggregate?.count || 0;
  const acdCycleOperationsPartiallyCompleted = data?.totalACDCycleOperationsPartiallyCompleted.aggregate?.count || 0;

  const acdCycleOperationsUnexpectedBehaviorAndPartiallyCompleted = execIfBothDefined(
    acdCycleOperationsUnexpectedBehavior,
    acdCycleOperationsPartiallyCompleted,
    (val1, val2) => val1 + val2
  );

  const acdCycleOperationsPartiallyCompletedAndFullyCompleted = execIfBothDefined(
    acdCycleOperationsPartiallyCompleted,
    acdCycleOperationsFullyCompleted,
    (val1, val2) => val1 + val2
  );

  const acdCycleOperationsFullyCompleteRate = calculateSuccessPercentage(
    acdCycleOperationsUnexpectedBehaviorAndPartiallyCompleted,
    acdCycleOperationsFullyCompleted
  );

  const acdCycleOperationsOperationalRate = calculateSuccessPercentage(
    acdCycleOperationsUnexpectedBehavior,
    acdCycleOperationsPartiallyCompletedAndFullyCompleted
  );

  const acdCycleOperationsCount = execIfBothDefined(
    acdCycleOperationsFullyCompleted,
    acdCycleOperationsUnexpectedBehaviorAndPartiallyCompleted,
    (val1, val2) => val1 + val2
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsFullyCompletedRate={acdCycleOperationsFullyCompleteRate}
      acdCycleOperationsOperationalRate={acdCycleOperationsOperationalRate}
      acdCycleOperationsCount={acdCycleOperationsCount}
      loadingTotalAcdCyclesOperationResult={loadingPerformance}
      errorTotalAcdCyclesOperationResult={errorPerformance}
      isRawPerformance={true}
    />
  );
};
