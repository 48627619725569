import { JSX, MouseEventHandler } from 'react';

import { LongText } from '../../../../5-elements/long-text/long-text';

interface DeviceNotesProps {
  actionLinkText: string;
  remarks: string;
  handleActionLinkClick: MouseEventHandler<HTMLButtonElement>;
}

export const DeviceNotes = ({ actionLinkText, remarks, handleActionLinkClick }: DeviceNotesProps): JSX.Element => {
  return (
    <div className="device-notes" data-testid="device-notes">
      <LongText text={remarks} actionLinkText={actionLinkText} handleActionLinkClick={handleActionLinkClick} />
    </div>
  );
};
