import { first } from 'lodash';

import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types/flatten';
import { calculateDuration } from '../../../../utilities/calculate-duration/calculate-duration';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { generateOperationResultLabel } from '../../../../utilities/generate-operation-result-label/generate-operation-result-label';
import { getAnnotationStatus } from '../../../../utilities/get-annotation-status/get-annotation-status';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';
import { DataGridOperationResultCellProps } from '../../../4-features/operations/data-grid-operation-result-cell/data-grid-operation-result-cell';

export type DeviceLatestOperationRow = Flatten<ReturnType<typeof generateDeviceLatestOperationRows>>;

export const generateDeviceLatestOperationRows = (
  operations: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceOperations'],
  deviceId: string,
  serialNumber?: string | null,
  userTimezone?: string
) => {
  return operations.map((operation) => {
    const annotationStatus = getAnnotationStatus(
      operation.deviceOperationResult?.id,
      operation.deviceOperationAnnotatedResult?.id
    );
    return {
      id: operation.id,
      deviceId: deviceId,
      serialNumber: { serialNumber, sequence: operation.sequence },
      result: {
        annotationStatus: annotationStatus,
        annotatedResultName: operation.deviceOperationAnnotatedResult?.name,
        originalResultName: operation.deviceOperationResult?.name,
        annotatedTimestamp: first(operation.deviceOperationAnnotationAudits)?.annotatedAt,
        finalResultName: generateOperationResultLabel(operation),
        success: operation.deviceOperationFinalResult?.isSuccessful,
        resultClass: operation.deviceOperationFinalResult?.resultClass,
        hasEndedAt: Boolean(operation.endAt),
        hasOperationResult: Boolean(operation.deviceOperationResult)
      } as DataGridOperationResultCellProps,
      dateTime: formatTimestamp(operation.startAt, userTimezone),
      duration: calculateDuration(operation.startAt, operation.endAt),
      operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(operation.operationalLifeCycle)
    };
  });
};
