import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { differenceWith, includes, isEmpty, isEqual, sortBy, uniqBy } from 'lodash';
import { Dispatch, JSX, SetStateAction, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  userToDeviceGroupLinkTableHeaderCells,
  userToDeviceGroupLinkTableRowCells
} from './display-table-configuration';
import {
  ManageUserToDeviceGroupLinkRequest,
  manageUserToDeviceGroupLinkSchema
} from './validation-schema/manage-user-to-device-group-link-schema';
import { GetAdminDeviceGroupDetailsQuery, UserDeviceGroupRole } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_USER_TO_DEVICE_GROUP_LINK } from '../../../../services/mutations/admin/companies/create-user-to-device-group-link';
import { MUTATION_UNLINK_USER_FROM_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/unlink-user-from-device-group';
import { QUERY_GET_ADMIN_DEVICE_GROUP_USERS } from '../../../../services/queries/admin/users/get-admin-device-group-users';
import { CompanyType } from '../../../../types/company-type';
import { DeviceGroupUserValidityDuration } from '../../../../types/device-group-user-validity-duration';
import { mapDeviceGroupUserValidityDuration } from '../../../../utilities/map-display-labels/map-device-group-user-validity-duration';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { mapValidityDurationToString } from '../../../../utilities/map-validity-duration-to-string/map-validity-duration-to-string';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { DisplayTable } from '../../../5-elements/display-table/display-table';
import { Loading } from '../../../5-elements/loading/loading';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { UserTimezoneContext } from '../../../contexts/user-timezone-context';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { ErrorPage } from '../../error-page/error-page';

interface ManageUserToDeviceGroupLinkDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenManageUserToDeviceGroupLinkDrawer: Dispatch<SetStateAction<boolean>>;
  refetchDeviceGroups: () => Promise<ApolloQueryResult<GetAdminDeviceGroupDetailsQuery>>;
  defaultValues: ManageUserToDeviceGroupLinkRequest;
  customerId: string;
  deviceGroupName: string;
  serviceProviderId?: string;
}

export const ManageUserToDeviceGroupLinkDrawer = ({
  setOpenManageUserToDeviceGroupLinkDrawer,
  customerId,
  deviceGroupName,
  serviceProviderId,
  defaultValues,
  refetchDeviceGroups,
  ...props
}: ManageUserToDeviceGroupLinkDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedDeviceGroupRole, setSelectedDeviceGroupRole] = useState<UserDeviceGroupRole>(
    UserDeviceGroupRole.DeviceObserver
  );
  const [selectedUserValidity, setSelectedUserValidity] = useState<DeviceGroupUserValidityDuration>(
    DeviceGroupUserValidityDuration.SevenDays
  );
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isDirty }
  } = useForm<ManageUserToDeviceGroupLinkRequest>({
    resolver: zodResolver(manageUserToDeviceGroupLinkSchema),
    defaultValues
  });
  const {
    loading: loadingUsers,
    data: dataUsers,
    error: errorUsers
  } = useQuery(QUERY_GET_ADMIN_DEVICE_GROUP_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      filters: { companyId: { _in: serviceProviderId ? [customerId, serviceProviderId] : [customerId] } },
      deviceGroupId: defaultValues.deviceGroupId
    },
    skip: props.open === false
  });
  const [createLink, { loading: loadingCreateLink }] = useMutation(MUTATION_CREATE_USER_TO_DEVICE_GROUP_LINK, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [unlink, { loading: loadingUnlink }] = useMutation(MUTATION_UNLINK_USER_FROM_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });

  const getUserDisplayName = (firstName: string, lastName: string, companyType: CompanyType): string => {
    switch (companyType) {
      case CompanyType.ServiceProvider:
        return t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userNameServiceProvider', {
          firstName,
          lastName
        });
      default:
        return `${firstName} ${lastName}`;
    }
  };

  const usersResponse = dataUsers?.users || [];
  const userOptions: RSSelectItemProps[] = usersResponse
    .map((user) => ({
      displayName: getUserDisplayName(user.firstName, user.lastName, user.company.companyType as CompanyType),
      menuItemProps: { value: user.id }
    }))
    .filter(
      (item) =>
        !includes(
          watch('users').map((item) => item.userId),
          item.menuItemProps.value
        )
    );
  const deviceGroupRoleValues = Object.values(UserDeviceGroupRole);
  const deviceGroupRoleSelectOptions: RSSelectItemProps[] = deviceGroupRoleValues.map((item) => ({
    displayName: mapUserDeviceGroupRole(item),
    menuItemProps: { value: item }
  }));
  const userDeviceGroupValidityValues = Object.values(DeviceGroupUserValidityDuration);
  const userDeviceGroupValiditySelectOptions: RSSelectItemProps[] = userDeviceGroupValidityValues.map((item) => ({
    displayName: mapDeviceGroupUserValidityDuration(item),
    menuItemProps: { value: item }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenManageUserToDeviceGroupLinkDrawer(false);
  };

  const handleCancel = (): void => {
    // For other forms we compare the equality in the value of two objects; however, because the difficulty in the
    // comparison of `validUntil` (selection is a date range but request needs a DateTime string), we decided to detect
    // the change of the form, instead of the value comparison.
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenManageUserToDeviceGroupLinkDrawer(false);
    }
  };

  const onSubmit: SubmitHandler<ManageUserToDeviceGroupLinkRequest> = async (data): Promise<void> => {
    // For other forms we compare the equality in the value of two objects; however, because the difficulty in the
    // comparison of `validUntil` (selection is a date range but request needs a DateTime string), we decided to detect
    // the change of the form, instead of the value comparison.
    if (!isDirty) {
      setOpenManageUserToDeviceGroupLinkDrawer(false);
      setShowConfirmationModal(false);
      return;
    }

    const usersAdded = differenceWith(data.users, defaultValues.users, isEqual);
    const usersRemoved = differenceWith(defaultValues.users, data.users, isEqual);
    const failedAddRequests: string[] = [];
    const failedRemoveRequests: string[] = [];

    if (usersAdded.length > 0 || usersRemoved.length > 0) {
      // NOTE the spreading dots `...` used in the promises to get them running in parallel.
      // we want to continue with executing the queries as many as possible. Even if one fails, the other can continue
      // running
      await Promise.allSettled([
        ...usersAdded.map(async (userAdded) => {
          await createLink({
            variables: {
              deviceGroupId: data.deviceGroupId,
              userId: userAdded.userId,
              userRole: userAdded.userRole,
              validUntil: userAdded.validUntil
            },
            onCompleted: () => {
              const addedUser = dataUsers?.users.find((user) => user.id === userAdded.userId);
              const addedUserName = getUserDisplayName(
                addedUser!.firstName,
                addedUser!.lastName,
                addedUser!.company.name as CompanyType
              );
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userLinked', { userName: addedUserName }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const linkFailedUser = dataUsers?.users.find((item) => item.id === userAdded.userId);
              const linkFailedUserName = getUserDisplayName(
                linkFailedUser!.firstName,
                linkFailedUser!.lastName,
                linkFailedUser!.company.name as CompanyType
              );
              failedAddRequests.push(userAdded.userId);
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userLinkFailed', {
                  userName: linkFailedUserName
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        }),
        ...usersRemoved.map(async (userRemoved) => {
          await unlink({
            variables: { deviceGroupId: data.deviceGroupId, userId: userRemoved.userId },
            onCompleted: () => {
              const removedUser = dataUsers?.users.find((item) => item.id === userRemoved.userId);
              const removedUserName = getUserDisplayName(
                removedUser!.firstName,
                removedUser!.lastName,
                removedUser!.company.name as CompanyType
              );
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userUnlinked', {
                  userName: removedUserName
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const removeFailedUser = dataUsers?.users.find((item) => item.id === userRemoved.userId);
              const removeFailedUserName = getUserDisplayName(
                removeFailedUser!.firstName,
                removeFailedUser!.lastName,
                removeFailedUser!.company.name as CompanyType
              );
              failedRemoveRequests.push(userRemoved.userId);
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userUnlinkFailed', {
                  userName: removeFailedUserName
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        })
      ]).finally(() => {
        // 1. Users who were added but failed shall be excluded from the list
        // 2. Users who were removed but failed shall be kept in the list
        const usersListExcludeAddFailed = data.users.filter(
          (requestUser) => !includes(failedAddRequests, requestUser.userId)
        );
        const usersListRemoveFailed = defaultValues.users.filter((defaultUser) =>
          includes(failedRemoveRequests, defaultUser.userId)
        );
        reset({ ...data, users: [...usersListExcludeAddFailed, ...usersListRemoveFailed] });
        refetchDeviceGroups();
        setShowConfirmationModal(false);
        setOpenManageUserToDeviceGroupLinkDrawer(false);
      });
    }
  };

  // Purpose: Re-set the selected validity and device group role when the drawer is closed.
  useEffect(() => {
    if (!props.open) {
      setSelectedUserValidity(DeviceGroupUserValidityDuration.SevenDays);
      setSelectedDeviceGroupRole(UserDeviceGroupRole.DeviceObserver);
    }
  }, [props.open]);

  if (errorUsers) {
    return (
      <RSDrawer {...props} className="manage-user-to-device-group-link-drawer">
        <ModalDrawerHeader
          title={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.title', { deviceGroupName })}
        />
        <div className="manage-user-to-device-group-link-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorUsers.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingUsers) {
    return (
      <RSDrawer {...props} className="manage-user-to-device-group-link-drawer">
        <ModalDrawerHeader
          title={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.title', { deviceGroupName })}
        />
        <div className="manage-user-to-device-group-link-drawer__form">
          <div className="manage-user-to-device-group-link-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="manage-user-to-device-group-link-drawer">
      <ModalDrawerHeader
        title={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.title', { deviceGroupName })}
      />
      {dataUsers && (
        <>
          <form
            className="manage-user-to-device-group-link-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="manage-user-to-device-group-link-drawer-form"
          >
            <div className="manage-user-to-device-group-link-drawer__form-contents">
              <div className="manage-user-to-device-group-link-drawer__link-select">
                <h3>{t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.linkUser')}</h3>
                <div className="manage-user-to-device-group-link-drawer__link-input">
                  <RSSelect
                    inputLabel={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.name')}
                    inputLabelTooltip={t('tooltips.deviceGroup.userName')}
                    formControlProps={{ className: 'manage-user-to-device-group-link-drawer__name-select' }}
                    menuItems={userOptions}
                    onChange={(event) => setSelectedUserId(event.target.value as string)}
                    value={selectedUserId}
                    disabled={isEmpty(userOptions)}
                    data-testid="manage-user-to-device-group-link-drawer-name-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <RSSelect
                    inputLabel={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.deviceGroupRole')}
                    inputLabelTooltip={t('tooltips.deviceGroup.deviceGroupRole')}
                    menuItems={deviceGroupRoleSelectOptions}
                    formControlProps={{
                      className: 'manage-user-to-device-group-link-drawer__device-group-role-select'
                    }}
                    onChange={(event) => setSelectedDeviceGroupRole(event.target.value as UserDeviceGroupRole)}
                    value={selectedDeviceGroupRole}
                    data-testid="manage-user-to-device-group-link-drawer-device-group-role-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <RSSelect
                    inputLabel={t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.validFor')}
                    inputLabelTooltip={t('tooltips.deviceGroup.validFor')}
                    menuItems={userDeviceGroupValiditySelectOptions}
                    formControlProps={{
                      className: 'manage-user-to-device-group-link-drawer__valid-for-select'
                    }}
                    onChange={(event) => setSelectedUserValidity(event.target.value as DeviceGroupUserValidityDuration)}
                    value={selectedUserValidity}
                    data-testid="manage-user-to-device-group-link-drawer-valid-for-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <div className="manage-user-to-device-group-link-drawer__link-button-wrapper">
                    <RSButton
                      color="success"
                      variant="contained"
                      onClick={() => {
                        if (selectedUserId !== '') {
                          setValue(
                            'users',
                            uniqBy(
                              [
                                ...getValues('users'),
                                {
                                  userId: selectedUserId,
                                  userRole: selectedDeviceGroupRole,
                                  validUntil: mapValidityDurationToString(selectedUserValidity, userTimezone)
                                }
                              ],
                              (item) => item.userId
                            ),
                            { shouldDirty: true }
                          );
                          setSelectedUserId('');
                        }
                      }}
                      disabled={selectedUserId === ''}
                      data-testid="manage-user-to-device-group-link-link-button"
                    >
                      {t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.link')}
                    </RSButton>
                  </div>
                </div>
              </div>
              <div className="manage-user-to-device-group-link-drawer__user-overview">
                <h3>{t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.userOverview')}</h3>
                <DisplayTable
                  className="manage-user-to-device-group-link-drawer__user-overview-table"
                  columnHeader={{ headerCells: userToDeviceGroupLinkTableHeaderCells }}
                  data-testid="manage-user-to-device-group-link-drawer-user-overview-table"
                  rows={sortBy(
                    watch('users').map((user) => {
                      const matchedDataUser = dataUsers.users.find((dataUser) => dataUser.id === user.userId);
                      const userDisplayName = getUserDisplayName(
                        matchedDataUser!.firstName,
                        matchedDataUser!.lastName,
                        matchedDataUser!.company.companyType as CompanyType
                      );
                      return {
                        key: user.userId,
                        cells: userToDeviceGroupLinkTableRowCells(
                          user.userId,
                          userDisplayName,
                          matchedDataUser!.company.name,
                          user.userRole,
                          () =>
                            setValue(
                              'users',
                              getValues('users').filter((item) => item.userId !== user.userId),
                              { shouldDirty: true }
                            ),
                          // Have to parse the function in this way to make sure the number of hooks keep the same
                          // during different rendering
                          formatWithDefaultTimezone,
                          user.validUntil
                        )
                      };
                    }),
                    // The first item in the cell is the name of the user, and we sort by it
                    (row) => row.cells[0].children
                  )}
                />
              </div>
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loadingCreateLink || loadingUnlink}
              isCancelDisabled={loadingCreateLink || loadingUnlink}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loadingCreateLink || loadingUnlink}
            disableCancelButton={loadingCreateLink || loadingUnlink}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
        </>
      )}
    </RSDrawer>
  );
};
