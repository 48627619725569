import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { RSButton, RSButtonProps } from '../../../5-elements/rs-button/rs-button';

interface FilterPanelButtonsGroupProps {
  handleClearAll: MouseEventHandler<HTMLButtonElement>;
  handleApply: MouseEventHandler<HTMLButtonElement>;
  isApplyDisabled: boolean;
  variant?: 'default' | 'admin';
}

export const FilterPanelButtonsGroup = (props: FilterPanelButtonsGroupProps) => {
  const { t } = useTranslation();
  const buttonColor: RSButtonProps['color'] = props.variant === 'admin' ? 'success' : 'primary';

  return (
    <div className="filter-panel__buttons-group" data-testid="filter-panel-buttons-group">
      <RSButton data-testid="filter-clear-all" onClick={props.handleClearAll} color={buttonColor}>
        {t('filterPanelButtonsGroup.clearAll')}
      </RSButton>
      <RSButton
        data-testid="filter-apply"
        variant="contained"
        disabled={props.isApplyDisabled}
        onClick={props.handleApply}
        color={buttonColor}
      >
        {t('filterPanelButtonsGroup.apply')}
      </RSButton>
    </div>
  );
};
