import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_CURRENT_PERFORMANCE_AGGREGATE = gql(`
  query getDeviceCurrentPerformanceAggregate($filters: DeviceBoolExp) {
    deviceCurrentPerformanceAggregate(where: { device: $filters }) {
      aggregate {
        sum {
          totalLastPeriod
          totalPrevPeriod
          fullyCompletedPrevPeriod
          partiallyCompletedPrevPeriod
          unexpectedBehaviorPrevPeriod
          fullyCompletedLastPeriod
          partiallyCompletedLastPeriod
          unexpectedBehaviorLastPeriod
        }
      }
    }
  }
`);
