import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_HISTORICAL_PERFORMANCE = gql(`
  query getDeviceHistoricalPerformance($deviceId: uuid!, $from: date!, $to: date!) {
    deviceHistoricalPerformance(where:  { deviceId: { _eq: $deviceId}, date: {_gte: $from, _lt:  $to}}, orderBy: {date: ASC},) {
      fullyCompletedCount7Days
      fullyCompletedPerformance7DaysPct
      operationalPerformance7DaysPct
      partiallyCompletedCount7Days
      totalCount7Days
      unexpectedBehaviorCount7Days
      date
    }
  }
`);
