import { z } from 'zod';

import { UserDeviceGroupUserRole } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';
import { CompanyType } from '../../../../../types/company-type';

export const addCompanySchema = z.object({
  companyType: z.nativeEnum(CompanyType, { message: i18n.t('forms.inputValidation.emptyInput') }),
  name: z
    .string()
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  maxDeviceGroupRole: z.nativeEnum(UserDeviceGroupUserRole),
  allowImport: z.boolean().nullish(),
  documentCenterUrl: z.string().url().nullish()
});

export type AddCompanyRequest = z.infer<typeof addCompanySchema>;
