import { JSX } from 'react';
import { LinkProps } from 'react-router-dom';

import { RSLinkTimezone } from '../../../4-features/rs-link-timezone/rs-link-timezone';

interface DataGridLinkProps extends LinkProps {
  to: string;
}

export const DataGridLink = ({ children, ...props }: DataGridLinkProps): JSX.Element => {
  return (
    <RSLinkTimezone
      {...props}
      className="data-grid-link"
      data-testid="data-grid-link"
      onClick={(event) => event.stopPropagation()}
    >
      {children}
    </RSLinkTimezone>
  );
};
