import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICE_GROUP_USERS = gql(`
  query getAdminDeviceGroupUsers($filters: UserBoolExp, $deviceGroupId: uuid!) {
    users(orderBy: [{ firstName: ASC }, { lastName: ASC }], where: $filters) {
      id
      firstName
      lastName
      company {
        id
        companyType
        name
      }
      userDeviceGroups(where: { deviceGroupId: { _eq: $deviceGroupId } }) {
        id
        userRole
        validUntil
      }
    }
  }
`);
