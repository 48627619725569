import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_DEVICE_TO_DEVICE_GROUP_LINK = gql(`
  mutation createDeviceToDeviceGroupLink($deviceGroupId: String!, $deviceId: String!) {
    createDeviceToDeviceGroupLink(deviceGroupId: $deviceGroupId, deviceId: $deviceId) {
      deviceGroupId
      deviceId
    }
  }
`);
