import { isEmpty, uniq } from 'lodash';

import { DeviceBoolExp } from '../../../../__generated__/graphql';
import { mapDeviceDeactivated } from '../../../../utilities/map-device-deactivated/map-device-deactivated';
import { mapOnlineStatusEnumToValue } from '../../../../utilities/map-online-status/map-online-status';
import { DevicesOverviewSearchParameters } from '../devices-overview-states-schema/devices-overview-states-schema';

export const deviceFilterFields = [
  'serialNumber',
  'deviceState',
  'connectivityState',
  'operationalLifeCycle',
  'connectorType',
  'customer',
  'rocOS',
  'site',
  'program',
  'deactivated'
] as const;

type DevicesFilterField = (typeof deviceFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<DevicesOverviewSearchParameters, DevicesFilterField>
): DeviceBoolExp => {
  const filterQuery: DeviceBoolExp = { _and: [] };
  if (filterParameters.serialNumber) {
    filterQuery._and!.push({ serialNumber: { _in: uniq(filterParameters.serialNumber) } });
  }
  if (filterParameters.operationalLifeCycle) {
    filterQuery._and!.push({ operationalLifeCycle: { _in: uniq(filterParameters.operationalLifeCycle) } });
  }
  if (filterParameters.deviceState) {
    filterQuery._and!.push({ deviceLastState: { deviceStatus: { _in: uniq(filterParameters.deviceState) } } });
  }
  if (filterParameters.connectivityState) {
    const connectivityStateValueList = filterParameters.connectivityState.map((item) =>
      mapOnlineStatusEnumToValue(item)
    );
    filterQuery._and!.push({
      deviceMeasurementValues: {
        deviceMeasurementProperty: { name: { _eq: 'is_online' } },
        endAt: { _isNull: true },
        value: { _in: uniq(connectivityStateValueList) }
      }
    });
  }
  if (filterParameters.connectorType) {
    filterQuery._and!.push({ connectorHolderType: { connectorType: { _in: uniq(filterParameters.connectorType) } } });
  }
  if (filterParameters.customer) {
    filterQuery._and!.push({ site: { customer: { company: { name: { _in: uniq(filterParameters.customer) } } } } });
  }
  if (filterParameters.rocOS) {
    filterQuery._and!.push({
      deviceSoftwareConfigurationActive: { rocosComposedVersion: { _in: uniq(filterParameters.rocOS) } }
    });
  }
  if (filterParameters.site) {
    filterQuery._and!.push({ site: { id: { _in: uniq(filterParameters.site) } } });
  }
  if (filterParameters.program) {
    filterQuery._and!.push({ program: { name: { _in: uniq(filterParameters.program) } } });
  }
  if (filterParameters.deactivated) {
    const uniqueActiveFilterValue = uniq(filterParameters.deactivated);
    if (uniqueActiveFilterValue.length === 1) {
      filterQuery._and!.push({ deactivated: { _eq: mapDeviceDeactivated(uniqueActiveFilterValue[0]) } });
    }
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};
