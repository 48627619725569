import { JSX } from 'react';

import CheckIcon from '../../../../assets/icons/check-mark.svg?react';
import CrossIcon from '../../../../assets/icons/cross.svg?react';
import InProgressIcon from '../../../../assets/icons/in-progress.svg?react';
import { DeviceOperationResultClass } from '../../../../types/device-operation-result-class';
import { DataTestId } from '../../../../types/rs-input';
import { RSChip, RSChipProps } from '../../../5-elements/rs-chip/rs-chip';
import { RSTooltip, RSTooltipProps } from '../../../5-elements/rs-tooltip/rs-tooltip';

export interface OperationResultChipProps {
  resultClass?: DeviceOperationResultClass;
  hasEndedAt: boolean;
  title: RSTooltipProps['title'];
  label: RSChipProps['label'];
  success?: boolean;
}

interface OperationResultChipBaseProps extends DataTestId {
  title: RSTooltipProps['title'];
  label: RSChipProps['label'];
  icon: RSChipProps['icon'];
  color: RSChipProps['color'];
}

const OperationResultChipBase = ({
  title,
  label,
  icon,
  color,
  'data-testid': dataTestId
}: OperationResultChipBaseProps) => {
  return (
    <RSTooltip title={title} className="operation-result-chip__tooltip">
      <div>
        <RSChip
          className="operation-result-chip"
          label={label}
          icon={icon}
          color={color}
          data-testid={dataTestId ? `operation-result-chip-${dataTestId}` : 'operation-result-chip'}
        />
      </div>
    </RSTooltip>
  );
};

export const OperationResultChip = ({
  title,
  label,
  hasEndedAt,
  resultClass,
  success
}: OperationResultChipProps): JSX.Element => {
  if (hasEndedAt && resultClass === DeviceOperationResultClass.OperationFullyCompleted) {
    return (
      <OperationResultChipBase title={title} label={label} icon={<CheckIcon />} color="success" data-testid="success" />
    );
  }

  if (hasEndedAt && resultClass === DeviceOperationResultClass.OperationUnexpectedBehavior) {
    return (
      <OperationResultChipBase title={title} label={label} icon={<CrossIcon />} color="error" data-testid="error" />
    );
  }

  if (hasEndedAt && resultClass === DeviceOperationResultClass.OperationPartiallyCompleted) {
    return (
      <OperationResultChipBase title={title} label={label} icon={<CheckIcon />} color="warning" data-testid="warning" />
    );
  }
  // fall back scenario - we somehow have an ended operation without a result class
  if ((hasEndedAt && resultClass === undefined) || resultClass === null) {
    if (success === true) {
      return (
        <OperationResultChipBase
          title={title}
          label={label}
          icon={<CheckIcon />}
          color="success"
          data-testid="success"
        />
      );
    } else {
      // we can not handle endDate without success....
      // assume operation is failed...
      return (
        <OperationResultChipBase title={title} label={label} icon={<CrossIcon />} color="error" data-testid="error" />
      );
    }
  }
  return (
    <OperationResultChipBase
      title={title}
      label={label}
      icon={<InProgressIcon />}
      color="default"
      data-testid="default"
    />
  );
};
