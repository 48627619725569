import { MouseEventHandler } from 'react';

import UnlinkIcon from '../../../../assets/icons/unlink.svg?react';
import i18n from '../../../../i18n';
import { DisplayTableCell, DisplayTableColumnHeaderCell } from '../../../5-elements/display-table/display-table';

export const deviceToDeviceGroupLinkTableHeaderCells: DisplayTableColumnHeaderCell[] = [
  {
    children: i18n.t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.serialNumber'),
    key: 'serial-number',
    className: 'manage-device-to-device-group-link-drawer__serial-number-column'
  },
  {
    children: i18n.t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceType'),
    key: 'device-type',
    className: 'manage-device-to-device-group-link-drawer__device-type-column'
  },
  {
    children: i18n.t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.connectorHolder'),
    key: 'connector-holder',
    className: 'manage-device-to-device-group-link-drawer__connector-holder-column'
  },
  {
    children: '',
    key: 'unlink',
    className: 'manage-device-to-device-group-link-drawer__unlink-column'
  }
];

export const deviceToDeviceGroupLinkTableRowCells = (
  deviceId: string,
  deviceModelNumber: string,
  handleUnlink: MouseEventHandler<HTMLSpanElement>,
  serialNumber?: string | null,
  connectorHolder?: string | null
): DisplayTableCell[] => {
  return [
    { children: serialNumber, key: serialNumber || 'unknown' },
    { children: deviceModelNumber, key: `${deviceModelNumber}-${deviceId}` },
    { children: connectorHolder, key: `${connectorHolder || 'unknown'}-${deviceId}` },
    {
      // Cannot use <button> as it behaves differently in a form
      children: (
        <span
          className="manage-device-to-device-group-link-drawer__unlink-icon-wrapper"
          title={i18n.t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.unlinkTitle')}
          onClick={handleUnlink}
          data-testid={`manage-device-to-device-group-link-drawer-unlink-device-${deviceId}`}
        >
          <UnlinkIcon />
        </span>
      ),
      key: `unlink-${deviceId}`
    }
  ];
};
