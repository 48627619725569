import { useQuery } from '@apollo/client';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { deviceDeviceGroupDataGridColumns } from './data-grid-configurations/device-device-groups-data-grid-columns';
import { DeviceDeviceGroupRow, generateDeviceGroupRows } from './data-grid-configurations/generate-device-group-rows';
import { DeviceDetailsAdminPanel } from './device-details-admin-panel/device-details-admin-panel';
import { ManageDeviceDeviceGroupDrawer } from './manage-device-device-groups-drawer/manage-device-device-groups-drawer';
import { appConfig } from '../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { QUERY_GET_ADMIN_DEVICE_DETAILS } from '../../../services/queries/admin/devices/get-admin-device-details';
import { getNonNullishDisplayValue } from '../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { AdminDetailsMainSectionWrapper } from '../../2-templates/main-sections/admin-details-main-section-wrapper/admin-details-main-section-wrapper';
import { AdminDetailsDataGrid } from '../../4-features/admin/admin-details-data-grid/admin-details-data-grid';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const DeviceDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [openManageDeviceDeviceGroupsDrawer, setOpenManageDeviceDeviceGroupsDrawer] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_DEVICE_DETAILS, {
    variables: { deviceId: deviceId! }
  });
  // Create, Update and Delete share the same permission check condition, so we use "update"
  const { result: canUserManageDeviceGroupDeviceLinks, loading: loadingCanUserManageDeviceGroupDeviceLinks } =
    useAuthCheckerWithSubjectInfo({
      action: 'UPDATE',
      subjectInfo: { type: 'DeviceGroupDeviceLinks', customerId: data?.deviceByPK?.site.customer.companyId || '' },
      skip: false
    });

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DeviceDeviceGroupRow>): void => {
    navigate(`${appConfig.basePath}/admin/device-groups/${params.id}`);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const deviceDetails = data?.deviceByPK;
  if (!deviceDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<DeviceDetailsAdminPanel deviceDetails={deviceDetails} />}>
      <div className="device-details-admin-page" data-testid="device-details-admin-page">
        <div className="device-details-admin-page__device-groups" data-testid="device-details-admin-page-device-groups">
          <div className="device-details-admin-page__title-bar">
            <h2>{t('deviceAdminDetailsPage.deviceGroupsDataGrid.title')}</h2>
            <RSButton
              disabled={loadingCanUserManageDeviceGroupDeviceLinks || !canUserManageDeviceGroupDeviceLinks}
              color="success"
              variant="contained"
              data-testid="device-details-admin-page-manage-device-groups"
              onClick={() => setOpenManageDeviceDeviceGroupsDrawer(true)}
            >
              {t('deviceAdminDetailsPage.deviceGroupsDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceDeviceGroupDataGridColumns}
              loading={loading}
              rows={generateDeviceGroupRows(deviceDetails.deviceGroupDevices, userTimezone)}
              onRowClick={handleRowClick}
              data-testid="device-details-admin-page-device-groups-data-grid"
            />
          </div>
        </div>
      </div>
      <ManageDeviceDeviceGroupDrawer
        open={openManageDeviceDeviceGroupsDrawer}
        customerId={deviceDetails.site.customer.companyId}
        setOpenManageDeviceDeviceGroupsDrawer={setOpenManageDeviceDeviceGroupsDrawer}
        serialNumber={getNonNullishDisplayValue(deviceDetails.serialNumber)}
        refetchDeviceDetails={refetch}
        defaultValues={{
          deviceId: deviceDetails.id,
          deviceGroupIds: deviceDetails.deviceGroupDevices.map((deviceGroupDevice) => deviceGroupDevice.deviceGroup.id)
        }}
      />
    </AdminDetailsMainSectionWrapper>
  );
};
