import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddDeviceGroupRequest, addDeviceGroupSchema } from './validation-schema/add-device-group-schema';
import { CreateDeviceGroupMutationVariables, GetAdminCompanyDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/create-device-group';
import { QUERY_GET_COMPANY_NAMES } from '../../../../services/queries/admin/commons/get-company-names';
import { CompanyType } from '../../../../types/company-type';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { Loading } from '../../../5-elements/loading/loading';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { RSTextInput } from '../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../error-page/error-page';

interface AddDeviceGroupDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenAddDeviceGroup: Dispatch<SetStateAction<boolean>>;
  refetchCompany: () => Promise<ApolloQueryResult<GetAdminCompanyDetailsQuery>>;
  customerId: string;
  customerName: string;
}

export const AddDeviceGroupDrawer = ({
  setOpenAddDeviceGroup,
  refetchCompany,
  customerId,
  customerName,
  ...props
}: AddDeviceGroupDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingServiceProviderNames,
    data: dataServiceProviderNames,
    error: errorServiceProviderNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, {
    fetchPolicy: 'network-only',
    skip: !props.open,
    variables: { filters: { companyType: { _eq: CompanyType.ServiceProvider } } }
  });
  const [createDeviceGroup, { loading: loadingCreateDeviceGroup }] = useMutation(MUTATION_CREATE_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('companyAdminDetailsPage.addDeviceGroupDrawer.createDeviceGroupFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors, dirtyFields }
  } = useForm<AddDeviceGroupRequest>({
    resolver: zodResolver(addDeviceGroupSchema),
    defaultValues: { customerId: customerId, name: '', serviceProviderId: '' }
  });

  const customerIdSelectOption: RSSelectItemProps[] = [
    { displayName: customerName, menuItemProps: { value: customerId } }
  ];
  const serviceProviderResponse = dataServiceProviderNames?.companies || [];
  const serviceProviderOptions: RSSelectItemProps[] = serviceProviderResponse.map((serviceProvider) => ({
    displayName: serviceProvider.name,
    menuItemProps: { value: serviceProvider.id }
  }));
  const serviceProviderOptionsWithPlaceholder: RSSelectItemProps[] = [
    {
      displayName: t('companyAdminDetailsPage.addDeviceGroupDrawer.selectServiceProviderPlaceholder'),
      menuItemProps: { value: '' }
    },
    ...serviceProviderOptions
  ];

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenAddDeviceGroup(false);
  };

  const handleCancel = (): void => {
    if (!isEmpty(dirtyFields)) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenAddDeviceGroup(false);
    }
  };

  const onSubmit: SubmitHandler<AddDeviceGroupRequest> = (data): void => {
    if (isEmpty(dirtyFields)) {
      setOpenAddDeviceGroup(false);
      setShowConfirmationModal(false);
      return;
    }

    const dataToSubmit: CreateDeviceGroupMutationVariables = {
      ...data,
      serviceProviderId: data.serviceProviderId === '' ? undefined : data.serviceProviderId
    };

    createDeviceGroup({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(
          t('companyAdminDetailsPage.addDeviceGroupDrawer.createDeviceGroupSuccess'),
          undefined,
          undefined,
          'success'
        );
        reset();
        refetchCompany();
        setOpenAddDeviceGroup(false);
        setShowConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/device-groups/${responseData.createDeviceGroup.deviceGroup!.id}`);
      }
    });
  };

  if (errorServiceProviderNames) {
    return (
      <RSDrawer {...props} className="add-device-group-drawer">
        <ModalDrawerHeader title={t('companyAdminDetailsPage.addDeviceGroupDrawer.title')} />
        <div className="add-device-group-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorServiceProviderNames.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('companyAdminDetailsPage.addDeviceGroupDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingServiceProviderNames || !dataServiceProviderNames) {
    return (
      <RSDrawer {...props} className="add-device-group-drawer">
        <ModalDrawerHeader title={t('companyAdminDetailsPage.addDeviceGroupDrawer.title')} />
        <div className="add-device-group-drawer__form">
          <div className="add-device-group-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('companyAdminDetailsPage.addDeviceGroupDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="add-device-group-drawer">
      <ModalDrawerHeader title={t('companyAdminDetailsPage.addDeviceGroupDrawer.title')} />
      <form
        className="add-device-group-drawer__form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="add-device-group-drawer-form"
      >
        <div className="add-device-group-drawer__input-fields">
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addDeviceGroupDrawer.name')}
            required={true}
            data-testid="add-device-group-drawer-name-input"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <Controller
            name="customerId"
            control={control}
            render={({ field: { onBlur, value } }) => (
              <RSSelect
                className="add-device-group-drawer__customer-id-select"
                inputLabel={t('companyAdminDetailsPage.addDeviceGroupDrawer.customer')}
                required={true}
                menuItems={customerIdSelectOption}
                helperText={errors.customerId?.message}
                error={Boolean(errors.customerId)}
                onBlur={onBlur}
                disabled={true}
                data-testid="add-device-group-drawer-customer-id-select"
                value={value}
                color="success"
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          {/*
            This is an optional field, we need to make it to an uncontrolled component to allow turing an empty string
            into `undefined` whilst displaying the placeholder
          */}
          <RSSelect
            className="add-device-group-drawer__service-provider-id-select"
            inputLabel={t('companyAdminDetailsPage.addDeviceGroupDrawer.serviceProvider')}
            inputLabelTooltip={t('tooltips.deviceGroup.serviceProvider')}
            displayEmpty={true}
            menuItems={serviceProviderOptionsWithPlaceholder}
            helperText={errors.serviceProviderId?.message}
            error={Boolean(errors.serviceProviderId)}
            data-testid="add-device-group-drawer-service-provider-select"
            defaultValue=""
            color="success"
            {...RS_SELECT_ADMIN_MENU_PROPS}
            {...register('serviceProviderId', { setValueAs: (value) => (value === '' ? null : value) })}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addDeviceGroupDrawer.description')}
            data-testid="add-device-group-drawer-description-input"
            multiline={true}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description', { setValueAs: (value) => (value === '' ? undefined : value) })}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingCreateDeviceGroup}
          isCancelDisabled={loadingCreateDeviceGroup}
          colorVariant="success"
          saveButtonText={t('companyAdminDetailsPage.addDeviceGroupDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loadingCreateDeviceGroup}
        disableCancelButton={loadingCreateDeviceGroup}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};
