import { useApolloClient } from '@apollo/client';
import { Backdrop, InputLabel } from '@mui/material';
import { ChangeEvent, JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from '../../5-elements/loading/loading';
import { RSSwitch } from '../../5-elements/rs-switch/rs-switch';
import { AutoRefreshContext } from '../../contexts/auto-refresh-context';

export const AutoRefreshSwitch = (): JSX.Element => {
  const { t } = useTranslation();
  const { autoRefresh, setAutoRefresh } = useContext(AutoRefreshContext);
  const { resetStore } = useApolloClient();
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSetAutoRefresh = (event: ChangeEvent<HTMLInputElement>): void => {
    setAutoRefresh(event.target.checked);
    if (event.target.checked) {
      setShowOverlay(true);
      resetStore();
      setTimeout(() => {
        setShowOverlay(false);
      }, 500);
    }
  };

  return (
    <div className="auto-refresh-switch" data-testid="auto-refresh-switch">
      <Backdrop className="auto-refresh-switch__loading-overlay" open={showOverlay}>
        <Loading />
      </Backdrop>
      <InputLabel
        shrink={true}
        className="auto-refresh-switch__input-label"
        data-testid="auto-refresh-switch-input-label"
      >
        {t('autoRefreshSwitch.label')}
      </InputLabel>
      <div className="auto-refresh-switch__toggle">
        <RSSwitch
          data-testid="auto-refresh-switch-button"
          falseValueLabel={t('autoRefreshSwitch.off')}
          trueValueLabel={t('autoRefreshSwitch.on')}
          checked={autoRefresh}
          onChange={(event) => handleSetAutoRefresh(event)}
        />
      </div>
    </div>
  );
};
