import { useTranslation } from 'react-i18next';

import { CustomerCountBlocks, CustomerCountBlocksProps } from './customer-count-blocks/customer-count-blocks';
import { CustomerExternalLinks } from './customer-external-links/customer-external-links';
import { CustomerProgramsTable } from './customer-programs-table/customer-programs-table';
import { GetCustomerDetailsQuery } from '../../../../__generated__/graphql';
import ArrowLeftIcon from '../../../../assets/icons/arrow-left.svg?react';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { InternalLink } from '../../../5-elements/internal-link/internal-link';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';

interface CustomerDetailsPageProps {
  title: string;
  counts: Omit<CustomerCountBlocksProps, 'customerName'>;
  createdAt: string;
  updatedAt: string;
  programs: NonNullable<GetCustomerDetailsQuery['customerByPK']>['programs'];
  documentCenterUrl?: string | null;
  customerName: string;
  showBackToCustomerOverview?: boolean;
}

export const CustomerDetailsSidePanel = ({
  title,
  counts,
  createdAt,
  updatedAt,
  programs,
  documentCenterUrl,
  customerName,
  showBackToCustomerOverview
}: CustomerDetailsPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();

  return (
    <section className="customer-details-side-panel" data-testid="customer-details-side-panel">
      <div className="customer-details-side-panel__title">
        {showBackToCustomerOverview && (
          <InternalLink
            to="/portal/customers"
            icon={<ArrowLeftIcon />}
            text={t('customerDetailsPage.sidePanel.backLinkText')}
          />
        )}
        <h1>{title}</h1>
      </div>
      <div className="customer-details-side-panel__customer-information">
        <div className="customer-details-side-panel__customer-statistics">
          <CustomerCountBlocks
            devices={counts.devices}
            sites={counts.sites}
            programs={counts.programs}
            customerName={customerName}
          />
          <div className="customer-details-side-panel__dates" data-testid="customer-details-side-panel-dates">
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.createdAt')}
              subValue={formatWithDefaultTimezone(createdAt)}
            />
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.updatedAt')}
              subValue={formatWithDefaultTimezone(updatedAt)}
            />
          </div>
        </div>
        <CustomerProgramsTable programs={programs} />
        <CustomerExternalLinks documentCenterUrl={documentCenterUrl} customerName={customerName} />
      </div>
    </section>
  );
};
