import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MainNavMenuButton, MainNavMenuButtonProps } from './main-nav-menu-button';
import AcdCycleIcon from '../../../assets/icons/acd-cycle.svg?react';
import CustomerIcon from '../../../assets/icons/customer.svg?react';
import GearIcon from '../../../assets/icons/gear.svg?react';
import RocIcon from '../../../assets/icons/roc.svg?react';
import { UserCompanyTypeContext } from '../../contexts/user-company-type-context';

export const OperationMainNavMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const { showServicePortal, loading: loadingUser, error: errorUser } = useContext(UserCompanyTypeContext);
  if (errorUser || loadingUser) {
    return <nav className="operation-main-nav-menu" data-testid="operation-main-nav-menu" />;
  }

  const customersButtonKey = showServicePortal ? 'mainNavMenu.customers' : 'mainNavMenu.home';

  const mainNavMenuConfig: MainNavMenuButtonProps[] = [
    { label: t(customersButtonKey), icon: <CustomerIcon />, to: '/portal/customers' },
    { label: t('mainNavMenu.devices'), icon: <RocIcon />, to: '/portal/devices' },
    { label: t('mainNavMenu.operations'), icon: <AcdCycleIcon />, to: '/portal/operations' }
  ];

  return (
    <nav className="operation-main-nav-menu" data-testid="operation-main-nav-menu">
      <div className="operation-main-nav-menu__pages">
        {mainNavMenuConfig.map((menuItem) => (
          <MainNavMenuButton key={menuItem.label} {...menuItem} />
        ))}
      </div>

      <div className="operation-main-nav-menu__admin">
        {/*
            To avoid the blinking effect introduced by react-router redirect, we redirect the user to the "landing page"
            of the admin interface, instead of `/portal/admin`. In the button component, the "active" class has been
            adjusted for the admin case.
          */}
        <MainNavMenuButton label={t('mainNavMenu.admin')} icon={<GearIcon />} to="/portal/admin/companies" />
      </div>
    </nav>
  );
};
