import { Tooltip, TooltipProps } from '@mui/material';
import classNames from 'classnames';
import React, { JSX } from 'react';

export type RSTooltipProps = TooltipProps & {
  useHtml?: boolean;
};

export const RSTooltip = (props: RSTooltipProps): JSX.Element => {
  const tooltipClassName = classNames('rs-tooltip', props.className);

  const { useHtml, ...restProps } = props;
  const title = useHtml ? (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: props.title ?? '' }}></div>
    </React.Fragment>
  ) : (
    props.title
  );

  return (
    <Tooltip
      {...restProps}
      title={title}
      classes={{ popper: 'rs-tooltip__popper', tooltip: tooltipClassName }}
      slotProps={{
        // Distance between the tooltip and the wrapped component: 5px
        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -9] } }] }
      }}
    >
      {props.children}
    </Tooltip>
  );
};
