import { CircularProgress } from '@mui/material';
import { JSX, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import BlobUploadIcon from '../../../../assets/icons/blob-upload.svg?react';
import { ObjectUploadStatus } from '../../../../types/object-upload-status';
import { OnlineStatus } from '../../../../types/online-status';
import {
  ObjectUploadStatusMessage,
  mapObjectUploadStatusDescription
} from '../../../../utilities/map-object-upload-status/map-object-upload-status';
import { RSButton } from '../../../5-elements/rs-button/rs-button';

interface OperationImageFetchSkippedProps {
  /* Whether there is an error when requesting the image URL */
  hasErrorImageUrl: boolean;
  /* The device online status */
  deviceOnlineStatus: OnlineStatus;
  /* The image upload status */
  imageStatus: ObjectUploadStatus;
  /* Whether the portal is requesting the image */
  requestingImageStatus: boolean;
  /* The method to handle image request from the remote server */
  handleRequestImage: MouseEventHandler<HTMLButtonElement>;
}

export const OperationImageFetchSkipped = ({
  hasErrorImageUrl,
  deviceOnlineStatus,
  imageStatus,
  requestingImageStatus,
  handleRequestImage
}: OperationImageFetchSkippedProps): JSX.Element => {
  const { t } = useTranslation();
  const generateImageFetchStatusMessage = (
    hasErrorImageUrl: boolean,
    imageStatus: ObjectUploadStatus,
    deviceOnlineStatus: OnlineStatus
  ): ObjectUploadStatusMessage => {
    if (hasErrorImageUrl) {
      return {
        title: t(`operationsPage.operationDetails.imageModal.titleNotCollected`),
        main: t('operationsPage.operationDetails.imageModal.urlRequestError'),
        detail: null
      };
    }
    return mapObjectUploadStatusDescription(imageStatus, deviceOnlineStatus, 'image');
  };

  const uploadStatusMessages = generateImageFetchStatusMessage(hasErrorImageUrl, imageStatus, deviceOnlineStatus);
  return (
    <div className="operation-image-fetch-skipped" data-testid="operation-image-fetch-skipped">
      <div className="operation-image-fetch-skipped__icon-container">
        <BlobUploadIcon />
      </div>
      <h3 className="operation-image-fetch-skipped__title">{uploadStatusMessages.title}</h3>
      <span className="operation-image-fetch-skipped__status" data-testid="operation-image-fetch-skipped-status">
        {uploadStatusMessages.main}
      </span>
      <span
        data-testid="operation-image-fetch-skipped-status-detail"
        className="operation-image-fetch-skipped__status-detail"
      >
        {uploadStatusMessages.detail}
      </span>

      <RSButton
        variant="contained"
        disabled={
          requestingImageStatus ||
          imageStatus === ObjectUploadStatus.UploadFailed ||
          imageStatus === ObjectUploadStatus.UploadFailedNotFound
        }
        onClick={handleRequestImage}
        data-testid="operation-image-request-button"
      >
        {requestingImageStatus ? (
          <span className="operation-image-fetch-skipped__button-requesting">
            <CircularProgress size={16} />
            {t('operationsPage.operationDetails.imageModal.requesting')}
          </span>
        ) : (
          t('operationsPage.operationDetails.imageModal.requestImage')
        )}
      </RSButton>
    </div>
  );
};
