import { ACDCycleOperationResultSuccessRates } from '../../types/acdcycle-operation-result-success-rates';
import { calculateACDCycleOperationsResultSuccessRates } from '../calculate-acdcycle-operation-result-success-rates/calculate-acdcycle-operation-result-success-rates';

export const getPerformanceMetricsValues = (
  fullyCompletedLastPeriod: number | null | undefined,
  partiallyCompletedLastPeriod: number | null | undefined,
  unexpectedBehaviorLastPeriod: number | null | undefined,
  fullyCompletedPrevPeriod: number | null | undefined,
  partiallyCompletedPrevPeriod: number | null | undefined,
  unexpectedBehaviorPrevPeriod: number | null | undefined
): ACDCycleOperationResultSuccessRates => {
  return calculateACDCycleOperationsResultSuccessRates(
    fullyCompletedLastPeriod,
    partiallyCompletedLastPeriod,
    unexpectedBehaviorLastPeriod,
    fullyCompletedPrevPeriod,
    partiallyCompletedPrevPeriod,
    unexpectedBehaviorPrevPeriod
  );
};
