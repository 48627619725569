import { MouseEventHandler } from 'react';

import UnlinkIcon from '../../../../assets/icons/unlink.svg?react';
import i18n from '../../../../i18n';
import { DisplayTableCell, DisplayTableColumnHeaderCell } from '../../../5-elements/display-table/display-table';

export const deviceDeviceGroupsTableHeaderCells: DisplayTableColumnHeaderCell[] = [
  {
    children: 'Name',
    key: 'device-group-name',
    className: 'manage-device-device-groups-drawer__device-group-name-column'
  },
  {
    children: '',
    key: 'unlink',
    className: 'manage-device-device-groups-drawer__unlink-column'
  }
];

export const deviceDeviceGroupsTableRowCells = (
  deviceGroupId: string,
  handleUnlink: MouseEventHandler<HTMLSpanElement>,
  deviceGroupName: string
): DisplayTableCell[] => {
  return [
    { children: deviceGroupName, key: deviceGroupId || 'unknown' },
    {
      // Cannot use <button> as it behaves differently in a form
      children: (
        <span
          className="manage-device-device-groups-drawer__unlink-icon-wrapper"
          title={i18n.t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.unlink')}
          onClick={handleUnlink}
          data-testid={`manage-device-device-groups-drawer-unlink-device-group-${deviceGroupId}`}
        >
          <UnlinkIcon />
        </span>
      ),
      key: `unlink-${deviceGroupId}`
    }
  ];
};
