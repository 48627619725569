import { DeviceStatusIconVariant } from '../../types/device-state';

export const mapDeviceStateToStatusIcon = (
  deviceState?: string | null,
  isOffline?: boolean
): DeviceStatusIconVariant => {
  if (isOffline) {
    return 'offline';
  }

  switch (deviceState) {
    case 'Operational':
      return 'normal';
    case 'Maintenance':
    case 'Recoverable':
      return 'attention-required';
    case 'Irrecoverable':
      return 'critical';
    default:
      return 'no-monitoring-active';
  }
};
