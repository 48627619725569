import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import SupportIcon from '../../../assets/icons/support.svg?react';
import { SUPPORT_LINK } from '../../../constants/constants';

export const SupportButton = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="support-button" data-testid="support-button">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={SUPPORT_LINK}
        data-testid="support-link"
        className="support-button__link"
      >
        <SupportIcon className="support-button__icon" />
        {t('supportButton.title')}
      </a>
    </div>
  );
};
