import { FormControl, FormHelperText, FormLabel, Switch, SwitchProps } from '@mui/material';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { RSFormLabelProps, RSHelperTextProps } from '../../../types/rs-input';
import { InfoIconTooltip } from '../../4-features/info-icon-tooltip/info-icon-tooltip';

export interface RSSwitchProps extends SwitchProps {
  /**
   * The label of the switch.
   */
  formLabel?: ReactNode;
  /**
   * An optional tooltip for the form label
   */
  formLabelTooltip?: ReactNode;
  /**
   * The properties for the switch label.
   */
  formLabelProps?: RSFormLabelProps;
  /**
   * The helper text of the switch.
   */
  formHelperText?: ReactNode;
  /**
   * The properties for the helper text of the switch.
   */
  formHelperTextProps?: RSHelperTextProps;
  /**
   * The label corresponds to the "false" value.
   */
  falseValueLabel: string;
  /**
   * The label corresponds to the "true" value.
   */
  trueValueLabel: string;
}

/**
 * This component renders a RSSwitch component. It is based on the MUI Switch component.
 * The API documentation: https://mui.com/material-ui/api/switch/
 * NOTE: You usually put the switch in a MUI `<FormGroup />` component, and provide an `aria-label` for accessibility
 *   purpose.
 * Usage example:
 * ```
 * <FormGroup>
 *   <RSSwitch
       falseValueLabel="No"
       trueValueLabel="Yes"
       formLabel="Yes or no?"
       inputProps={{ 'aria-label': 'example switch' }}
       formHelperText="Hello!"
     />
     ...
     </FormGroup>
 * ```
 * Only commonly used properties are listed below.
 *
 * @param {ReactNode} [formLabel] The label of the switch.
 * @param {RSFormLabelProps} [formLabelProps] The properties for the switch label.
 * @param {ReactNode} [formHelperText] The helper text of the switch.
 * @param {RSHelperTextProps} [formHelperTextProps] The properties for the helper text of the switch.
 * @param {string} falseValueLabel The label which corresponds to the `false` value.
 * @param {string} trueValueLabel The label which corresponds to the `true` value.
 * @returns {React.JSX.Element} A React element that renders a FormControl component, which wraps an `<RSSwitch />`.
 */
export const RSSwitch = forwardRef(
  (
    {
      formLabel,
      formLabelTooltip,
      formLabelProps,
      formHelperText,
      formHelperTextProps,
      falseValueLabel,
      trueValueLabel,
      ...props
    }: RSSwitchProps,
    ref
  ) => {
    const { t } = useTranslation();
    const getLabelClassNames = (value: 'true' | 'false', disabled?: boolean) => {
      return classNames('rs-switch__context-label', {
        'rs-switch__context-label--true': value === 'true',
        'rs-switch__context-label--false': value === 'false',
        'rs-switch__context-label--disabled': disabled
      });
    };
    return (
      <FormControl data-testid="rs-switch-form-control">
        {formLabel && (
          <FormLabel {...formLabelProps} className={classNames('rs-switch__label', formLabelProps?.className)}>
            {formLabel} {props.required && t('input.required')}
            {formLabelTooltip && (
              <>
                &nbsp;
                <InfoIconTooltip infoIconTooltip={formLabelTooltip} />
              </>
            )}
          </FormLabel>
        )}
        <div className="rs-switch__contents">
          <span className={getLabelClassNames('false', props.disabled)} data-testid="rs-switch-falsy-value-label">
            {falseValueLabel}
          </span>
          <Switch {...props} inputRef={ref} className={classNames('rs-switch', props.className)} />
          <span className={getLabelClassNames('true', props.disabled)} data-testid="rs-switch-truthy-value-label">
            {trueValueLabel}
          </span>
        </div>
        {formHelperText && (
          <FormHelperText
            {...formHelperTextProps}
            className={classNames('rs-switch__helper-text', formHelperTextProps?.className)}
          >
            {formHelperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
RSSwitch.displayName = 'RSSwitch';
