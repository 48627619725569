import { DateTime } from 'luxon';

import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { browserTimezone } from '../../../../utilities/get-browser-timezone/get-browser-timezone';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';

export const formatOperationTime = (timestamp?: string | null, userTimezone?: string) => {
  if (!timestamp) {
    return getNonNullishDisplayValue(undefined);
  }
  return formatTimestamp(timestamp, userTimezone, undefined, {
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    second: '2-digit'
  });
};

/*
  The start date can be different from the end date.
  If they are the same, return only one date in the string.
  If they are different, return `dateA - dateB`.
*/
export const formatOperationDate = (startAt: string, endAt?: string | null, userTimezone?: string): string => {
  const parsedStartAt = DateTime.fromISO(startAt).setZone(userTimezone || browserTimezone);
  if (!parsedStartAt.isValid) {
    return getNonNullishDisplayValue(undefined) as string;
  }
  const formattedStartAt = parsedStartAt.toISODate();
  if (!endAt) {
    return formattedStartAt;
  }
  const parsedEndAt = DateTime.fromISO(endAt);
  if (!parsedEndAt.isValid) {
    return formattedStartAt;
  }
  const formattedEndAt = parsedEndAt.toISODate();
  if (formattedStartAt === formattedEndAt) {
    return formattedStartAt;
  }
  return `${formattedStartAt} - ${formattedEndAt}`;
};
