import { JSX, PropsWithChildren } from 'react';

import { PortalHeader } from '../../3-sections/portal-header/portal-header';
import { PortalMain } from '../../3-sections/portal-main/portal-main';
import { PortalGrid } from '../portal-grid/portal-grid';

type HeaderOnlyWrapperProps = PropsWithChildren;

export const HeaderOnlyWrapper = (props: HeaderOnlyWrapperProps): JSX.Element => {
  return (
    <PortalGrid>
      <PortalHeader />
      <PortalMain fullWidth={true}>{props.children}</PortalMain>
    </PortalGrid>
  );
};
