import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, isEqual, omit, pick } from 'lodash';
import qs from 'qs';
import { JSX, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  GetConnectorTypesQuery,
  GetCustomerNamesQuery,
  GetProgramNamesQuery,
  GetRocOsVersionsQuery,
  GetSerialNumbersQuery,
  GetSiteNamesQuery,
  OperationalLifeCycle
} from '../../../../__generated__/graphql';
import { DeviceDeactivated } from '../../../../types/device-deactivated';
import { DeviceState } from '../../../../types/device-state';
import { OnlineStatus } from '../../../../types/online-status';
import { RSAutocompleteValue } from '../../../../types/rs-autocomplete';
import { filterValidUrlFields } from '../../../../utilities/filter-valid-url-fields/filter-valid-url-fields';
import {
  OperationalLifeCycleType,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';
import { mapOnlineStatusDisplayLabel } from '../../../../utilities/map-online-status/map-online-status';
import { FilterPanelButtonsGroup } from '../../../4-features/filter-panel/filter-panel-buttons-group/filter-panel-buttons-group';
import { RSAutocomplete } from '../../../5-elements/rs-autocomplete/rs-autocomplete';
import { RSAutocompleteDefaultMenuOption } from '../../../5-elements/rs-autocomplete/rs-autocomplete-default-menu-option/rs-autocomplete-default-menu-option';
import {
  DevicesOverviewSearchParameters,
  devicesOverviewStatesSchema
} from '../devices-overview-states-schema/devices-overview-states-schema';
import { deviceFilterFields } from '../generate-queries/generate-filter-query';

interface DevicesFilterPanelProps {
  serialNumber?: GetSerialNumbersQuery;
  rocOSVersions?: GetRocOsVersionsQuery;
  connectorTypes?: GetConnectorTypesQuery;
  customerNames?: GetCustomerNamesQuery;
  siteNames?: GetSiteNamesQuery;
  programNames?: GetProgramNamesQuery;
  defaultValues: DevicesOverviewSearchParameters;
  showCustomer?: boolean;
}

export const DevicesFilterPanel = ({
  serialNumber,
  rocOSVersions,
  connectorTypes,
  customerNames,
  siteNames,
  programNames,
  defaultValues,
  showCustomer
}: DevicesFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues
  } = useForm<DevicesOverviewSearchParameters>({
    resolver: zodResolver(devicesOverviewStatesSchema),
    defaultValues
  });
  const serialNumberOptions = (serialNumber?.devices.map((device) => device.serialNumber) as string[]) || [];
  const rocOSVersionOptions =
    rocOSVersions?.activeDeviceSoftwareConfigurations.map((config) => config.rocosComposedVersion) || [];
  const connectivityStateOptions = Object.values(OnlineStatus);
  const deviceStateOptions = Object.values(DeviceState);
  const operationalLifeCycleOptions = Object.values(OperationalLifeCycle);
  const connectorTypeOptions =
    connectorTypes?.connectorHolderTypes.map((connectorHolderType) => connectorHolderType.connectorType) || [];
  const customerNameOptions = customerNames?.customers.map((customer) => customer.company.name) || [];
  const siteOptions = siteNames?.sites.map((site) => site.id) || [];
  const siteToSiteLabelMap =
    siteNames?.sites.reduce(
      (acc, site) => {
        acc[site.id] = `${site.customer.company.name} - ${site.name}`;
        return acc;
      },
      {} as Record<string, string>
    ) || {};

  const siteDisplayName = (id: string): string => {
    return siteToSiteLabelMap[id];
  };

  const siteNameMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={siteDisplayName(option as string)} />;
  };
  const siteNameTagDisplayOptions = (option: RSAutocompleteValue) => siteDisplayName(option as string);
  const programNameOptions = programNames?.programs.map((program) => program.name) || [];
  const activeOptions = Object.values(DeviceDeactivated);

  const connectivityStateMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapOnlineStatusDisplayLabel(option as OnlineStatus)} />;
  };
  const connectivityStateTagDisplayOptions = (option: RSAutocompleteValue) =>
    mapOnlineStatusDisplayLabel(option as OnlineStatus);
  const operationalLifeCycleMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return (
      <RSAutocompleteDefaultMenuOption
        option={mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType)!}
      />
    );
  };
  const operationalLifeCycleTagDisplayOptions = (option: RSAutocompleteValue) =>
    mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType);

  const onSubmit: SubmitHandler<DevicesOverviewSearchParameters> = (data): void => {
    const newSearchParams = { ...qs.parse(searchParams.toString()), ...data, page: '1' };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<DevicesOverviewSearchParameters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter = omit(searchParamsObject, deviceFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({
      serialNumber: [],
      deviceState: [],
      connectivityState: [],
      site: [],
      operationalLifeCycle: [],
      rocOS: [],
      connectorType: [],
      customer: [],
      program: [],
      deactivated: []
    });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());

    const validUrlFields = filterValidUrlFields<DevicesOverviewSearchParameters>(
      searchParameters,
      devicesOverviewStatesSchema
    );

    const filterParameters = pick(validUrlFields, deviceFilterFields);
    const resetObject: DevicesOverviewSearchParameters = {
      serialNumber: filterParameters.serialNumber || [],
      deviceState: filterParameters.deviceState || [],
      connectivityState: filterParameters.connectivityState || [],
      site: filterParameters.site || [],
      operationalLifeCycle: filterParameters.operationalLifeCycle || [],
      rocOS: filterParameters.rocOS || [],
      connectorType: filterParameters.connectorType || [],
      customer: filterParameters.customer || [],
      program: filterParameters.program || [],
      deactivated: filterParameters.deactivated || []
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  return (
    <aside className="devices-filter-panel" data-testid="devices-filter-panel">
      <form className="devices-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="devices-filter-panel__filters">
          <Controller
            name="serialNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.serialNumber')}
                  data-testid="devices-serial-number-autocomplete"
                  options={serialNumberOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(serialNumberOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="deviceState"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.deviceState')}
                  data-testid="devices-device-state-autocomplete"
                  options={deviceStateOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(deviceStateOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="connectivityState"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.connectivityState')}
                  data-testid="devices-connectivity-state-autocomplete"
                  options={connectivityStateOptions}
                  customMenuOption={connectivityStateMenuDisplayOptions}
                  customTagOption={connectivityStateTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(connectivityStateOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="operationalLifeCycle"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.operationalLifeCycle')}
                  data-testid="devices-operational-life-cycle-autocomplete"
                  options={operationalLifeCycleOptions}
                  customMenuOption={operationalLifeCycleMenuDisplayOptions}
                  customTagOption={operationalLifeCycleTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(operationalLifeCycleOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="rocOS"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.rocOS')}
                  data-testid="devices-roc-os-autocomplete"
                  options={rocOSVersionOptions as string[]}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(rocOSVersionOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="connectorType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.connectorType')}
                  data-testid="devices-connector-type-autocomplete"
                  options={connectorTypeOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(connectorTypeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          {showCustomer && (
            <Controller
              name="customer"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <RSAutocomplete
                    inputLabel={t('devicesPage.tableColumns.customer')}
                    data-testid="devices-customer-autocomplete"
                    options={customerNameOptions}
                    onChange={(_event, values) => onChange(values)}
                    value={filter(value, (valueItem) => includes(customerNameOptions, valueItem))}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          )}
          <Controller
            name="site"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.site')}
                  data-testid="devices-site-autocomplete"
                  options={siteOptions}
                  customMenuOption={siteNameMenuDisplayOptions}
                  customTagOption={siteNameTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(siteOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.program')}
                  data-testid="devices-program-autocomplete"
                  options={programNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(programNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="deactivated"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('devicesPage.tableColumns.deactivated')}
                  data-testid="devices-deactivated-autocomplete"
                  options={activeOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(activeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
        />
      </form>
    </aside>
  );
};
