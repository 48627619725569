import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { t } from 'i18next';
import { head, isEmpty } from 'lodash';
import { JSX, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { CustomerSitesList } from './customer-sites-list/customer-sites-list';
import { DEFAULT_REFRESH_INTERVAL } from '../../../constants/constants';
import { QUERY_GET_USER_AUTH_INFO } from '../../../services/queries/authz/get-user-auth-info';
import { QUERY_GET_CUSTOMER_DETAILS } from '../../../services/queries/customers/get-customer-details';
import { getPerformanceMetricsValues } from '../../../utilities/get-performance-metrics-values/get-performance-metrics-values';
import { DetailsMainSectionWrapper } from '../../2-templates/main-sections/details-main-section-wrapper/details-main-section-wrapper';
import { PerformanceMetricsRender } from '../../4-features/performance-metrics-render/performance-metrics-render';
import { AutoRefreshContext } from '../../contexts/auto-refresh-context';
import { CustomerDetailsSidePanel } from '../customer-details-page/customer-details-side-panel/customer-details-side-panel';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const CustomerDetailsPage = (): JSX.Element => {
  const { customerId } = useParams();
  const { user } = useAuth0();
  const { autoRefresh } = useContext(AutoRefreshContext);
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, { variables: { userAuthId: user?.sub || '' }, skip: !user?.sub });
  const queryVariables = { companyId: customerId! };
  const { loading, data, error, startPolling, stopPolling } = useQuery(QUERY_GET_CUSTOMER_DETAILS, {
    variables: queryVariables
  });

  if (autoRefresh) {
    startPolling(DEFAULT_REFRESH_INTERVAL);
  } else {
    stopPolling();
  }

  const groupedError = error || errorUser;

  if (groupedError) {
    const isUUIDError =
      groupedError.name === 'ApolloError' && groupedError.message.includes('invalid input syntax for type uuid');
    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const isLoading = loadingUser || !dataUser || loading;

  if (isLoading) {
    return <LoadingPage />;
  }

  const customer = data?.customerByPK;

  if (isEmpty(customer)) {
    return <PageNotFoundErrorPage />;
  }

  // Only show "Back to customer" if: either the user is a super user, or the company type is NOT customer
  const showBackToCustomerOverview = head(dataUser!.users)!.isSuperUser || !head(dataUser!.users)!.company.customer;

  const customerPerformance = customer.customerCurrentPerformance;
  const performanceValues = getPerformanceMetricsValues(
    customerPerformance?.fullyCompletedLastPeriod,
    customerPerformance?.partiallyCompletedLastPeriod,
    customerPerformance?.unexpectedBehaviorLastPeriod,
    customerPerformance?.fullyCompletedPrevPeriod,
    customerPerformance?.partiallyCompletedPrevPeriod,
    customerPerformance?.unexpectedBehaviorPrevPeriod
  );

  return (
    <DetailsMainSectionWrapper
      panel={
        <CustomerDetailsSidePanel
          title={customer.company.name}
          counts={{
            devices: customer.devicesAggregate.aggregate?.count || 0,
            sites: customer.sitesAggregate.aggregate?.count || 0,
            programs: customer.programs.length
          }}
          documentCenterUrl={customer.documentCenterUrl}
          customerName={customer.company.name}
          createdAt={customer.company.createdAt}
          updatedAt={customer.company.updatedAt}
          programs={customer.programs}
          showBackToCustomerOverview={showBackToCustomerOverview}
        />
      }
    >
      <section className="customer-details-page" data-testid="customer-details-page">
        <div className="customer-details-page__overview">
          <h2>{t('customerDetailsPage.sites.title')}</h2>
          <PerformanceMetricsRender
            acdCycleOperationsFullyCompletedRate={performanceValues.acdCycleOperationsFullyCompletedRate}
            acdCycleOperationsFullyCompletedRateChange={performanceValues.acdCycleOperationsFullyCompletedRateChange}
            acdCycleOperationsOperationalRate={performanceValues.acdCycleOperationsOperationalRate}
            acdCycleOperationsOperationalRateChange={performanceValues.acdCycleOperationsOperationalRateChange}
            acdCycleOperationsCount={performanceValues.acdCycleOperationsCount}
            acdCycleOperationsCountChange={performanceValues.acdCycleOperationsCountChange}
            loadingTotalAcdCyclesOperationResult={isLoading}
            errorTotalAcdCyclesOperationResult={Boolean(error)}
          />
        </div>
        <div className="customer-details-page__sites">
          <CustomerSitesList sites={customer.sites} loading={isLoading} hasError={Boolean(error)} />
        </div>
      </section>
    </DetailsMainSectionWrapper>
  );
};
