import { GetAdminDevicesQuery } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { FlattenFunction } from '../../../../types/flatten';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';

export const generateDevicesAdminRows = (devices?: GetAdminDevicesQuery['devices'], userTimezone?: string) => {
  if (!devices) {
    return [];
  }

  return devices.map((device) => {
    return {
      id: device.id,
      serialNumber: device.serialNumber,
      company: device.site.customer.company.name,
      site: device.site.name,
      program: device.program?.name || i18n.t('noData'),
      deviceGroups: device.deviceGroupDevicesAggregate.aggregate?.count || 0,
      rocType: device.deviceType.modelNumber,
      connectorHolderType: device.connectorHolderType?.code || i18n.t('noData'),
      updatedAt: formatTimestamp(device.updatedAt, userTimezone),
      createdAt: formatTimestamp(device.createdAt, userTimezone)
    };
  });
};

export type DevicesAdminRow = FlattenFunction<typeof generateDevicesAdminRows>;
