import { useAuth0 } from '@auth0/auth0-react';
import copy from 'copy-text-to-clipboard';
import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RSButton } from '../../5-elements/rs-button/rs-button';
import { useEnqueueSnackbar } from '../../hooks/use-enqueue-snackbar';

export const TokenPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const myToken = await getAccessTokenSilently();
        setToken(myToken);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Error occurred when fetching the token: ${JSON.stringify(error)}`);
      }
    };
    fetchToken();
  }, []);

  const handleCopyToken = () => {
    if (token) {
      copy(token);
      sendMessageToSnackbar(t('tokenPage.tokenCopied'), t('tokenPage.tokenCopiedDetail'), undefined, 'success');
    } else {
      // eslint-disable-next-line no-console
      console.warn('Token is not available');
    }
  };

  return (
    <div className="token-page">
      <h1>{t('tokenPage.title')}</h1>
      <div>
        <p className="token-page__explanation">{t('tokenPage.explanation')}</p>
        <div>
          <RSButton variant="contained" color="primary" data-testid="token-page-copy-button" onClick={handleCopyToken}>
            {t('tokenPage.copyTokenButton')}
          </RSButton>
        </div>
      </div>
    </div>
  );
};
