import { GridColDef } from '@mui/x-data-grid';

import { CompanySiteRow } from './generate-site-rows';
import { appConfig } from '../../../../configs/configs';
import i18n from '../../../../i18n';
import { DataGridLink } from '../../../5-elements/data-grid/data-grid-link/data-grid-link';

export const companySitesDataGridColumns: GridColDef<CompanySiteRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: true,
    flex: 2,
    minWidth: 200,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('companyAdminDetailsPage.sitesDataGrid.name'),
    renderCell: ({ id, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/admin/sites/${id}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'timezone',
    hideable: false,
    sortable: true,
    flex: 1.6,
    minWidth: 160,
    headerName: i18n.t('companyAdminDetailsPage.sitesDataGrid.timezone')
  },
  {
    field: 'address',
    hideable: false,
    sortable: true,
    flex: 3,
    minWidth: 300,
    headerName: i18n.t('companyAdminDetailsPage.sitesDataGrid.address')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('companyAdminDetailsPage.sitesDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('companyAdminDetailsPage.sitesDataGrid.updatedAt')
  }
];
