import { isFinite, isNumber, round } from 'lodash';
import { DateTimeFormatOptions } from 'luxon';

import i18n from '../../../../../i18n';
import { getNonNullishDisplayValue } from '../../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';

export const contextualizeConnectivityStatusDisplayValue = (value?: number | null): string => {
  switch (value) {
    case 0:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.offline');
    case 1:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.heartbeatOnly');
    case 2:
      return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.online');
    default:
      return i18n.t('deviceDetailsPage.deviceHealth.unknown');
  }
};

export const contextualizeZOffsetDisplayValue = (value?: number | null): string | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  // recalculate m to mm
  return (value * 1000).toFixed(0);
};

export const contextualizeSafetyTriggeredDisplayValue = (value?: number | null): string => {
  switch (value) {
    case 0:
      return i18n.t('deviceDetailsPage.deviceHealth.safetyTriggered.notTriggered');
    case 1:
      return i18n.t('deviceDetailsPage.deviceHealth.safetyTriggered.triggered');
    default:
      return i18n.t('deviceDetailsPage.deviceHealth.unknown');
  }
};

export const calculateUsagePercentage = (usage?: number | null, total?: number | null): number => {
  if (isNumber(usage) && isNumber(total)) {
    const calculatedValue = (usage / total) * 100;
    return isNaN(calculatedValue) || !isFinite(calculatedValue) ? 0 : round(calculatedValue, 0);
  }
  return 0;
};

export const generateDisplayTimestamp = (
  time: string | null | undefined,
  formatter: (timestamp: string, locale?: string, localeStringSettings?: DateTimeFormatOptions) => string
): string => {
  return i18n.t('deviceDetailsPage.deviceHealth.timestamp', {
    timestamp: `${time ? formatter(time) : getNonNullishDisplayValue(time)}`
  });
};
