import { gql } from '../../../../__generated__/gql';

export const MUTATION_UNLINK_USER_FROM_DEVICE_GROUP = gql(`
  mutation unlinkUserFromDeviceGroup($deviceGroupId: String!, $userId: String!) {
    removeUserFromDeviceGroupLink(deviceGroupId: $deviceGroupId, userId: $userId) {
      deviceGroupId
      userId
    }
  }
`);
