import i18n from '../../i18n';
import { DeviceGroupUserValidityDuration } from '../../types/device-group-user-validity-duration';

export const mapDeviceGroupUserValidityDuration = (validityDuration: DeviceGroupUserValidityDuration): string => {
  switch (validityDuration) {
    case DeviceGroupUserValidityDuration.NoEndDate:
      return i18n.t('deviceGroupUserValidityDuration.noEndDate');
    case DeviceGroupUserValidityDuration.OneDay:
      return i18n.t('deviceGroupUserValidityDuration.oneDay');
    case DeviceGroupUserValidityDuration.SevenDays:
      return i18n.t('deviceGroupUserValidityDuration.sevenDays');
    case DeviceGroupUserValidityDuration.ThirtyDays:
      return i18n.t('deviceGroupUserValidityDuration.thirtyDays');
    default:
      throw Error('The duration is invalid');
  }
};
