import { gql } from '../../../__generated__/gql';

export const QUERY_GET_SITE_NAMES = gql(`
  query getSiteNames($filters: SiteBoolExp) {
    sites(orderBy: { name: ASC }, where: $filters) {
      id
      name
      customer{
        companyId
        company {
          name
          id
        }
      }
    }
  }
`);
