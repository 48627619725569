import { GridColDef } from '@mui/x-data-grid';

import { CompaniesAdminRow } from './generate-companies-admin-rows';
import { appConfig } from '../../../../configs/configs';
import i18n from '../../../../i18n';
import { CompanyType } from '../../../../types/company-type';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { BooleanCheckmarkCross } from '../../../5-elements/boolean-checkmark-cross/boolean-checkmark-cross';
import { DataGridHeaderCell } from '../../../5-elements/data-grid/data-grid-header-cell/data-grid-header-cell';
import { DataGridLink } from '../../../5-elements/data-grid/data-grid-link/data-grid-link';

export const companiesAdminDataGridColumns: GridColDef<CompaniesAdminRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: false,
    flex: 2,
    minWidth: 200,
    headerName: i18n.t('companiesAdminPage.tableColumns.name'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />,
    renderCell: ({ id, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/admin/companies/${id}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'type',
    hideable: false,
    sortable: false,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('companiesAdminPage.tableColumns.type'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />,
    renderCell: ({ value }) => mapCompanyType(value as CompanyType)
  },
  {
    field: 'sites',
    hideable: false,
    sortable: false,
    flex: 0.6,
    minWidth: 60,
    headerName: i18n.t('companiesAdminPage.tableColumns.sites'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'devices',
    hideable: false,
    sortable: false,
    flex: 0.6,
    minWidth: 60,
    headerName: i18n.t('companiesAdminPage.tableColumns.devices'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'users',
    hideable: false,
    sortable: false,
    flex: 0.6,
    minWidth: 60,
    headerName: i18n.t('companiesAdminPage.tableColumns.users'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'allowImport',
    hideable: false,
    sortable: false,
    flex: 1.3,
    minWidth: 130,
    headerName: i18n.t('companiesAdminPage.tableColumns.allowImport'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />,
    renderCell: ({ value }) => {
      const allowImportValue = value as boolean;
      return (
        <BooleanCheckmarkCross extraClassNames={['companies-admin-page__allow-import']} value={allowImportValue} />
      );
    }
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: false,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('companiesAdminPage.tableColumns.createdAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: false,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('companiesAdminPage.tableColumns.updatedAt'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} sortable />
  }
];
