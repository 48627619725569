import { JSX, PropsWithChildren } from 'react';

import { AuthenticatedOnlyWrapper } from '../authenticated-only-wrapper/authenticated-only-wrapper';
import { HeaderNavigationWrapper } from '../header-navigation-wrapper/header-navigation-wrapper';
import { HeaderOnlyWrapper } from '../header-only-wrapper/header-only-wrapper';

interface PageWrapperProps extends PropsWithChildren {
  loginRequired: boolean;
  hasNavigation: boolean;
  loginRedirectUri: string;
}

export const PageWrapper = (props: PageWrapperProps): JSX.Element => {
  if (props.loginRequired) {
    if (props.hasNavigation) {
      return (
        <AuthenticatedOnlyWrapper loginRedirectUri={props.loginRedirectUri}>
          <HeaderNavigationWrapper>{props.children}</HeaderNavigationWrapper>
        </AuthenticatedOnlyWrapper>
      );
    }

    return (
      <AuthenticatedOnlyWrapper loginRedirectUri={props.loginRedirectUri}>
        <HeaderOnlyWrapper>{props.children}</HeaderOnlyWrapper>
      </AuthenticatedOnlyWrapper>
    );
  }

  if (props.hasNavigation) {
    return <HeaderNavigationWrapper>{props.children}</HeaderNavigationWrapper>;
  }

  return <HeaderOnlyWrapper>{props.children}</HeaderOnlyWrapper>;
};
