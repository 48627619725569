import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { ACDCycleOperationResultSuccessRates } from '../../../types/acdcycle-operation-result-success-rates';
import { RSTooltip } from '../../5-elements/rs-tooltip/rs-tooltip';
import { PerformanceMetricBlock } from '../performance-metric-block/performance-metric-block';

interface PerformanceMetricsRenderProps extends ACDCycleOperationResultSuccessRates {
  loadingTotalAcdCyclesOperationResult: boolean;
  errorTotalAcdCyclesOperationResult: boolean;
  isRawPerformance?: boolean;
}

export const PerformanceMetricsRender = ({
  acdCycleOperationsFullyCompletedRate,
  acdCycleOperationsFullyCompletedRateChange,
  acdCycleOperationsOperationalRate,
  acdCycleOperationsOperationalRateChange,
  acdCycleOperationsCount,
  acdCycleOperationsCountChange,
  loadingTotalAcdCyclesOperationResult,
  errorTotalAcdCyclesOperationResult,
  isRawPerformance
}: PerformanceMetricsRenderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="performance-metrics-render" data-testid="performance-metrics-render">
      <div className="performance-metrics-render__presentation" data-testid="performance-metrics-presentation">
        <RSTooltip
          title={
            isRawPerformance
              ? t('performanceMetricsBlocks.tooltips.rawOperationalPerformance')
              : t('performanceMetricsBlocks.tooltips.operationalPerformance')
          }
        >
          <div
            className="performance-metrics-render__presentation-block"
            data-testid="performance-metrics-presentation-block"
          >
            <PerformanceMetricBlock
              metric={{ value: acdCycleOperationsOperationalRate, displayUnit: t('units.percentage') }}
              metricChange={
                acdCycleOperationsOperationalRateChange === undefined
                  ? undefined
                  : {
                      value: acdCycleOperationsOperationalRateChange,
                      displayUnit: t('units.percentage')
                    }
              }
              loading={loadingTotalAcdCyclesOperationResult}
              hasError={errorTotalAcdCyclesOperationResult}
              label={
                isRawPerformance
                  ? t('performanceMetricsBlocks.labels.rawOperationalPerformance')
                  : t('performanceMetricsBlocks.labels.operationalPerformance')
              }
              data-testid="performance-metrics-render-operation-operational-performance"
            />
          </div>
        </RSTooltip>
        <RSTooltip
          title={
            isRawPerformance
              ? t('performanceMetricsBlocks.tooltips.rawFullyCompletedPerformance')
              : t('performanceMetricsBlocks.tooltips.fullyCompletedPerformance')
          }
        >
          <div
            className="performance-metrics-render__presentation-block"
            data-testid="performance-metrics-presentation-block"
          >
            <PerformanceMetricBlock
              metric={{ value: acdCycleOperationsFullyCompletedRate, displayUnit: t('units.percentage') }}
              metricChange={
                acdCycleOperationsFullyCompletedRateChange === undefined
                  ? undefined
                  : {
                      value: acdCycleOperationsFullyCompletedRateChange,
                      displayUnit: t('units.percentage')
                    }
              }
              loading={loadingTotalAcdCyclesOperationResult}
              hasError={errorTotalAcdCyclesOperationResult}
              label={
                isRawPerformance
                  ? t('performanceMetricsBlocks.labels.rawFullyCompletedPerformance')
                  : t('performanceMetricsBlocks.labels.fullyCompletedPerformance')
              }
              data-testid="performance-metrics-render-operation-fully-completed-performance"
            />
          </div>
        </RSTooltip>
        <RSTooltip
          title={
            isRawPerformance
              ? t('performanceMetricsBlocks.tooltips.rawTotalCount')
              : t('performanceMetricsBlocks.tooltips.totalCount')
          }
        >
          <div
            className="performance-metrics-render__presentation-block"
            data-testid="performance-metrics-presentation-block"
          >
            <PerformanceMetricBlock
              metric={{ value: acdCycleOperationsCount, decimalPlaces: 0 }}
              metricChange={
                acdCycleOperationsCountChange === undefined
                  ? undefined
                  : { value: acdCycleOperationsCountChange, displayUnit: t('units.percentage') }
              }
              loading={loadingTotalAcdCyclesOperationResult}
              hasError={errorTotalAcdCyclesOperationResult}
              label={t('performanceMetricsBlocks.labels.acdCycles')}
              data-testid="performance-metrics-render-acd-cycles-performance"
            />
          </div>
        </RSTooltip>
      </div>
    </div>
  );
};
