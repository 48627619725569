import { DataGrid, DataGridProps, GridRowSpacing, GridRowSpacingParams } from '@mui/x-data-grid';
import { JSX, useCallback } from 'react';

import ArrowDownIcon from '../../../../assets/icons/arrow-down.svg?react';
import ArrowUpIcon from '../../../../assets/icons/arrow-up.svg?react';
import ArrowsUpAndDownIcon from '../../../../assets/icons/arrows-up-and-down.svg?react';

export const AdminDetailsDataGrid = (props: DataGridProps): JSX.Element => {
  const CustomColumnHeaderSortAscendingIcon = (): JSX.Element => {
    return (
      <div className="admin-details-data-grid__column-header-sort-icon-wrapper">
        <ArrowUpIcon className="admin-details-data-grid__column-header-sort-icon" />
      </div>
    );
  };

  const CustomColumnHeaderSortDescendingIcon = (): JSX.Element => {
    return (
      <div className="admin-details-data-grid__column-header-sort-icon-wrapper">
        <ArrowDownIcon className="admin-details-data-grid__column-header-sort-icon" />
      </div>
    );
  };

  const CustomColumnHeaderSortUnsortedIcon = (): JSX.Element => {
    return (
      <div className="admin-details-data-grid__column-header-sort-icon-wrapper">
        <ArrowsUpAndDownIcon className="admin-details-data-grid__column-header-sort-icon" />
      </div>
    );
  };

  // https://mui.com/x/react-data-grid/row-height/#row-spacing
  const getRowSpacing = useCallback((params: GridRowSpacingParams): GridRowSpacing => {
    return { top: params.isFirstVisible ? 0 : 4, bottom: params.isLastVisible ? 0 : 4 };
  }, []);

  return (
    <DataGrid
      className="admin-details-data-grid"
      {...props}
      disableColumnMenu={true}
      hideFooter={true}
      disableColumnResize={true}
      disableColumnSelector={true}
      disableColumnFilter={true}
      disableDensitySelector={true}
      disableMultipleRowSelection={true}
      disableRowSelectionOnClick={true}
      rowHeight={56}
      getRowSpacing={getRowSpacing}
      slots={{
        columnSortedAscendingIcon: CustomColumnHeaderSortAscendingIcon,
        columnSortedDescendingIcon: CustomColumnHeaderSortDescendingIcon,
        columnUnsortedIcon: CustomColumnHeaderSortUnsortedIcon
      }}
    />
  );
};
