import { JSX, PropsWithChildren } from 'react';

import { DataTestId } from '../../../../types/rs-input';

interface AdminDetailsMainSectionWrapper extends PropsWithChildren, DataTestId {
  panel: JSX.Element;
}

export const AdminDetailsMainSectionWrapper = ({
  panel,
  children,
  'data-testid': dataTestId
}: AdminDetailsMainSectionWrapper): JSX.Element => {
  return (
    <section className="admin-details-main-section-wrapper" data-testid={dataTestId}>
      <div className="admin-details-main-section-wrapper__contents">
        <div className="admin-details-main-section-wrapper__panel">{panel}</div>
        <div className="admin-details-main-section-wrapper__main-content">{children}</div>
      </div>
    </section>
  );
};
