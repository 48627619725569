import { gql } from '../../../../__generated__/gql';

export const MUTATION_MODIFY_SERVICE_PROVIDER = gql(`
  mutation modifyServiceProvider($id: String!, $name: String!, $maxDeviceGroupRole: UserDeviceGroupUserRole!) {
    modifyServiceProvider(id: $id, name: $name, maxDeviceGroupRole: $maxDeviceGroupRole) {
      id
      serviceProvider {
        companyId
        updatedAt
        company {
          name
          id
          maxDeviceGroupRole
        }
      }
    }
  }
`);
