import { useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Paper, alpha } from '@mui/material';
import { LineChart, ScatterChart } from '@mui/x-charts';
import { DateTime } from 'luxon';
import { JSX, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { AggregationType } from '../../../__generated__/graphql';
import { QUERY_GET_SERIAL_NUMBERS } from '../../../services/queries/commons/get-serial-numbers';
import { QUERY_GET_DEVICE_HISTORICAL_PERFORMANCE } from '../../../services/queries/devices/get-device-historical-performance';
import { QUERY_GET_DEVICE_MEASUREMENT_VALUES } from '../../../services/queries/devices/get-device-measurements';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { RSSelect, RSSelectItemProps } from '../../5-elements/rs-select/rs-select';
import { RSTextInput } from '../../5-elements/rs-text-input/rs-text-input';

/**
 * This page is here for testing purposes only. It is not used in the application.
 * The reason to leave it in, is that it will be a good reference for future chart implementations
 */

const isDateValid = (date: string | null | undefined): boolean => {
  if (!date) {
    return false;
  }
  try {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  } catch {
    return false;
  }
};

export const ChartTestPage = (): JSX.Element => {
  const [lineChartInterpolationMethod, setLineChartInterpolationMethod] = useState<'monotoneX' | 'stepAfter'>(
    'stepAfter'
  );
  const {
    //  loading: loadingSerialNumbers,
    data: dataSerialNumbers
    //  error: errorSerialNumbers
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, { fetchPolicy: 'network-only' });

  const serialNumberOptions: RSSelectItemProps[] =
    dataSerialNumbers?.devices.map((item) => ({
      displayName: item.serialNumber,
      menuItemProps: { value: item.id }
    })) || [];

  const formSchema = z.object({
    serialNumber: z.string().optional(),
    fromDate: z.string().datetime({ offset: true }).nullish(),
    toDate: z.string().datetime({ offset: true }).nullish(),
    aggregationType: z.string().optional()
  });
  type FormType = z.infer<typeof formSchema>;

  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useForm<FormType>({
    resolver: zodResolver(formSchema)
  });

  const deviceId = watch('serialNumber');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const aggregationType = watch('aggregationType');

  const mapAggregationType = (aggregationType: string | null | undefined): AggregationType | null => {
    switch (aggregationType) {
      case 'AGGREGATION_1DAY':
        return AggregationType.Aggregation_1Day;
      case 'AGGREGATION_1HOUR':
        return AggregationType.Aggregation_1Hour;
      case 'AGGREGATION_NONE':
        return AggregationType.AggregationNone;
      default:
        return null;
    }
  };

  const today = DateTime.now().toFormat('yyyy-MM-dd');
  const lastDate = new Date(isDateValid(toDate) ? toDate! : today);
  const firstDate = new Date(isDateValid(fromDate) ? fromDate! : '2024-01-01');
  const endDateStr = lastDate.toISOString();
  const firstDatStr = firstDate.toISOString();
  const mappedAggregationType = mapAggregationType(aggregationType);
  const { data: memoryData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'memory_usage',
      ...(mappedAggregationType && { aggregationType: mappedAggregationType })
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const memXLabels = memoryData?.requestAggregatedMeasurements?.data?.map((dat) => new Date(dat?.dateTime || ''));
  const memAvgData = memoryData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.avgValue || 0);
  const memMaxData = memoryData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.maxValue || 0);
  const memMinData = memoryData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.minValue || 0);
  const memAggregationType = memoryData?.requestAggregatedMeasurements?.aggregationType;

  const { data: storageData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'storage_usage',
      ...(mappedAggregationType && { aggregationType: mappedAggregationType })
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const storageXLabels = storageData?.requestAggregatedMeasurements?.data?.map((dat) => new Date(dat?.dateTime || ''));
  const storageAvgData = storageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.avgValue || 0);
  const storageMaxData = storageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.maxValue || 0);
  const storageMinData = storageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.minValue || 0);
  const storageAggregationType = storageData?.requestAggregatedMeasurements?.aggregationType;

  const { data: cpuUsageData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'cpu_usage',
      ...(mappedAggregationType && { aggregationType: mappedAggregationType })
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const cpuUsageXLabels = cpuUsageData?.requestAggregatedMeasurements?.data?.map(
    (dat) => new Date(dat?.dateTime || '')
  );
  const cpuUsageAvgData = cpuUsageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.avgValue || 0);
  const cpuUsageMaxData = cpuUsageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.maxValue || 0);
  const cpuUsageMinData = cpuUsageData?.requestAggregatedMeasurements?.data?.map((dat) => dat?.minValue || 0);
  const cpuUsageAggregationType = cpuUsageData?.requestAggregatedMeasurements?.aggregationType;

  const { data: isOnlineData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'is_online',
      aggregationType: AggregationType.AggregationNone
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const isOnlineXLabels = isOnlineData?.requestAggregatedMeasurements?.data?.map(
    (dat) => new Date(dat?.dateTime || '')
  );
  const onlineData = isOnlineData?.requestAggregatedMeasurements?.data;
  const isOnlineUnknownData = onlineData?.map((dat) => (dat?.avgValue === null ? 1 : 0));
  const isOnlineOfflineData = onlineData?.map((dat) => (dat?.avgValue === 0 ? 1 : 0));
  const isOnlineHeartBeatData = onlineData?.map((dat) => (dat?.avgValue === 1 ? 1 : 0));
  const isOnlineOnlineData = onlineData?.map((dat) => (dat?.avgValue === 2 ? 1 : 0));
  const isOnlineAggregationType = isOnlineData?.requestAggregatedMeasurements?.aggregationType;

  const { data: safetyTriggerData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'safety_triggered',
      aggregationType: AggregationType.AggregationNone
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const safetyTriggerXLabels = safetyTriggerData?.requestAggregatedMeasurements?.data?.map(
    (dat) => new Date(dat?.dateTime || '')
  );

  const triggerData = safetyTriggerData?.requestAggregatedMeasurements?.data;
  const safetyTriggerTriggered = triggerData?.map((dat) => (dat?.avgValue === 1 ? 1 : 0));
  const safetyTriggerNotTriggered = triggerData?.map((dat) => (dat?.avgValue === 0 ? 1 : 0));
  const safetyTriggerUnknown = triggerData?.map((dat) => (dat?.avgValue === null ? 1 : 0));
  const safetyTriggerAggregationType = safetyTriggerData?.requestAggregatedMeasurements?.aggregationType;

  const { data: zOffsetData } = useQuery(QUERY_GET_DEVICE_MEASUREMENT_VALUES, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr,
      name: 'z_offset',
      ...(mappedAggregationType && { aggregationType: mappedAggregationType })
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  // to do on aggregation type none: change label and do not include min/max
  // add the x-label (date) to tooltip
  const zOffsetXLabels = zOffsetData?.requestAggregatedMeasurements?.data?.map((dat) => new Date(dat?.dateTime || ''));
  const offsetData = zOffsetData?.requestAggregatedMeasurements?.data;
  let count = 0;
  const zOffsetMinData = offsetData
    ?.filter((dat) => dat?.minValue !== null)
    ?.map((dat) => ({
      x: new Date(dat?.dateTime || new Date()).getTime(),
      y: (dat?.minValue || 0) * 1000,
      id: count++ + ''
    }));
  const zOffsetAvgData = offsetData
    ?.filter((dat) => dat?.avgValue !== null)
    ?.map((dat) => ({
      x: new Date(dat?.dateTime || new Date()).getTime(),
      y: (dat?.avgValue || 0) * 1000,
      id: count++ + ''
    }));
  const zOffsetMaxData = offsetData
    ?.filter((dat) => dat?.maxValue !== null)
    ?.map((dat) => ({
      x: new Date(dat?.dateTime || new Date()).getTime(),
      y: (dat?.maxValue || 0) * 1000,
      id: count++ + ''
    }));
  const zOffsetAggregationType = zOffsetData?.requestAggregatedMeasurements?.aggregationType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomItemTooltipContent = (props: { itemData: any; series: any }) => {
    const { itemData, series } = props;

    const date = DateTime.fromMillis(series.data[itemData.dataIndex].x).toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS
    );
    return (
      <Paper sx={{ padding: 3 }}>
        <p>{series.label}</p>
        <p>{date}</p>
        <p>{series.data[itemData.dataIndex].y.toFixed(2)} mm</p>
      </Paper>
    );
  };

  const { data: historicalPerformanceData } = useQuery(QUERY_GET_DEVICE_HISTORICAL_PERFORMANCE, {
    variables: {
      deviceId: deviceId!,
      from: firstDatStr,
      to: endDateStr
    },
    skip: !deviceId,
    onError: (error) => {
      // enqueueSnackbarMessage.error(t('errors.mutation.request-upload-url'), error.name, error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  const completedPerfSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.fullyCompletedPerformance7DaysPct ?? null
  );
  const operationalPerfSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.operationalPerformance7DaysPct ?? null
  );
  const totalCountSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.totalCount7Days ?? null
  );
  const fullyCompletedCountSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.fullyCompletedCount7Days ?? null
  );
  const partiallyCompletedCountSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.partiallyCompletedCount7Days ?? null
  );
  const unexpectedBehaviorCountSeries = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => dat?.unexpectedBehaviorCount7Days ?? null
  );
  const performanceXLabels = historicalPerformanceData?.deviceHistoricalPerformance?.map(
    (dat) => new Date(dat?.date || '')
  );

  const subDays = (date: Date, days: number): Date => {
    return DateTime.fromJSDate(date).minus({ days }).toJSDate();
  };

  const addDays = (date: Date, days: number): Date => {
    return DateTime.fromJSDate(date).plus({ days }).toJSDate();
  };

  // make sure graph start at expected date
  if (performanceXLabels && new Date(performanceXLabels[0]).getTime() != new Date(firstDatStr).getTime()) {
    performanceXLabels?.unshift(subDays(performanceXLabels[0], 1));
    completedPerfSeries?.unshift(0);
    operationalPerfSeries?.unshift(0);
    totalCountSeries?.unshift(0);
    fullyCompletedCountSeries?.unshift(0);
    partiallyCompletedCountSeries?.unshift(0);
    unexpectedBehaviorCountSeries?.unshift(0);

    performanceXLabels?.unshift(new Date(firstDatStr));
    completedPerfSeries?.unshift(0);
    operationalPerfSeries?.unshift(0);
    totalCountSeries?.unshift(0);
    fullyCompletedCountSeries?.unshift(0);
    partiallyCompletedCountSeries?.unshift(0);
    unexpectedBehaviorCountSeries?.unshift(0);
  }

  // make sure graph start at expected date
  if (
    performanceXLabels &&
    new Date(performanceXLabels[performanceXLabels.length - 1]).getTime() != subDays(new Date(endDateStr), 1).getTime()
  ) {
    performanceXLabels?.push(addDays(performanceXLabels[performanceXLabels.length - 1], 1));
    completedPerfSeries?.push(0);
    operationalPerfSeries?.push(0);
    totalCountSeries?.push(0);
    fullyCompletedCountSeries?.push(0);
    partiallyCompletedCountSeries?.push(0);
    unexpectedBehaviorCountSeries?.push(0);

    performanceXLabels?.push(new Date(endDateStr));
    completedPerfSeries?.push(0);
    operationalPerfSeries?.push(0);
    totalCountSeries?.push(0);
    fullyCompletedCountSeries?.push(0);
    partiallyCompletedCountSeries?.push(0);
    unexpectedBehaviorCountSeries?.push(0);
  }

  return (
    <div className="chart-test-page">
      <h1>Charts Demo 🤖</h1>
      <div className="chart-test-page__charts">
        <h2 className="chart-test-page__division-title">Charts test</h2>
        <div className="chart-test-page__bar-chart">
          <Controller
            name="serialNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSSelect
                  inputLabel="Serial number"
                  required={true}
                  menuItems={serialNumberOptions}
                  error={Boolean(errors.serialNumber)}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ''}
                  color="success"
                />
              );
            }}
          />
          <Controller
            name="aggregationType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSSelect
                  inputLabel="Aggregation type"
                  required={true}
                  menuItems={[
                    {
                      displayName: 'AUTO',
                      menuItemProps: { value: '' }
                    },
                    {
                      displayName: 'Aggregation 1 day',
                      menuItemProps: { value: 'AGGREGATION_1DAY' }
                    },
                    {
                      displayName: 'Aggregation 1 hour',
                      menuItemProps: { value: 'AGGREGATION_1HOUR' }
                    },
                    {
                      displayName: 'No Aggregation',
                      menuItemProps: { value: 'AGGREGATION_NONE' }
                    }
                  ]}
                  error={Boolean(errors.serialNumber)}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ''}
                  color="success"
                />
              );
            }}
          />
          <RSTextInput inputLabel="From date" {...register('fromDate')} />
          <RSTextInput inputLabel="To date" {...register('toDate')} />
          {memAvgData && memXLabels && (
            <>
              <h1>Memory</h1>
              <b>{memAggregationType}</b>
              <LineChart
                width={1000}
                height={400}
                series={[
                  {
                    data: memMaxData,
                    label: 'max mem usage',
                    area: false,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#fca247'
                  },
                  {
                    data: memMinData,
                    label: 'min mem usage',
                    area: false,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#f7dbbe'
                  },
                  {
                    data: memAvgData,
                    label: 'avg mem usage',
                    area: false,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#0066ff'
                  }
                ]}
                yAxis={[{ min: 0, max: 8000 }]}
                xAxis={[
                  {
                    min: memXLabels[0],
                    max: memXLabels[memXLabels.length - 1],
                    scaleType: 'time',
                    data: memXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
              />
            </>
          )}
          {storageAvgData && storageXLabels && (
            <>
              <h1>Storage</h1>
              <b>{storageAggregationType}</b>
              <LineChart
                width={1000}
                height={400}
                series={[
                  {
                    data: storageMaxData,
                    label: 'max storage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#66ccff'
                  },
                  {
                    data: storageAvgData,
                    label: 'avg storage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#0066ff'
                  },
                  {
                    data: storageMinData,
                    label: 'min storage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#0000cc'
                  }
                ]}
                yAxis={[{ min: 0, max: 121856 }]}
                xAxis={[
                  {
                    min: storageXLabels[0],
                    max: storageXLabels[storageXLabels.length - 1],
                    scaleType: 'time',
                    data: storageXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
              />
            </>
          )}
          {cpuUsageAvgData && cpuUsageXLabels && (
            <>
              <h1>CPU Usage</h1>
              <b>{cpuUsageAggregationType}</b>
              <LineChart
                width={1000}
                height={400}
                series={[
                  {
                    data: cpuUsageMaxData,
                    label: 'max cpuUsage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#66ccff'
                  },
                  {
                    data: cpuUsageAvgData,
                    label: 'avg cpuUsage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#0066ff'
                  },
                  {
                    data: cpuUsageMinData,
                    label: 'min cpuUsage usage',
                    area: true,
                    showMark: false,
                    curve: lineChartInterpolationMethod,
                    color: '#0000cc'
                  }
                ]}
                yAxis={[{ min: 0, max: 100 }]}
                xAxis={[
                  {
                    min: cpuUsageXLabels[0],
                    max: cpuUsageXLabels[cpuUsageXLabels.length - 1],
                    scaleType: 'time',
                    data: cpuUsageXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
              />
            </>
          )}
          <br />
          <div className="chart-test-page__line-chart">
            <RSButton
              variant="contained"
              onClick={() =>
                setLineChartInterpolationMethod((prevValue) => (prevValue === 'stepAfter' ? 'monotoneX' : 'stepAfter'))
              }
            >{`Line chart: Switch to ${lineChartInterpolationMethod === 'stepAfter' ? 'monotoneX' : 'stepAfter'}`}</RSButton>
          </div>
          {isOnlineOfflineData && isOnlineXLabels && (
            <>
              <h1>Is Online</h1>
              <b>{isOnlineAggregationType}</b>
              <LineChart
                width={1000}
                height={200}
                series={[
                  {
                    data: isOnlineHeartBeatData,
                    label: 'heartbeat',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#EEB270'
                  },
                  {
                    data: isOnlineOfflineData,
                    label: 'offline',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#EE857D'
                  },
                  {
                    data: isOnlineOnlineData,
                    label: 'isOnline',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#85C68A'
                  },
                  {
                    data: isOnlineUnknownData,
                    label: 'unknown',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#bbbbbb'
                  }
                ]}
                yAxis={[{ min: 0 }]}
                xAxis={[
                  {
                    min: isOnlineXLabels[0],
                    max: isOnlineXLabels[isOnlineXLabels.length - 1],
                    scaleType: 'time',
                    data: isOnlineXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
              />
            </>
          )}
          {safetyTriggerTriggered && safetyTriggerXLabels && (
            <>
              <h1>Safety Triggered</h1>
              <b>{safetyTriggerAggregationType}</b>
              <LineChart
                width={1000}
                height={200}
                series={[
                  {
                    data: safetyTriggerNotTriggered,
                    label: 'not triggered',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#85C68A'
                  },
                  {
                    data: safetyTriggerTriggered,
                    label: 'triggered',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#EE857D'
                  },
                  {
                    data: safetyTriggerUnknown,
                    label: 'unknown',
                    area: true,
                    showMark: false,
                    curve: 'stepAfter',
                    color: '#bbbbbb'
                  }
                ]}
                yAxis={[{ min: 0 }]}
                xAxis={[
                  {
                    min: safetyTriggerXLabels[0],
                    max: safetyTriggerXLabels[safetyTriggerXLabels.length - 1],
                    scaleType: 'time',
                    data: safetyTriggerXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
              />
            </>
          )}
          {zOffsetAvgData && zOffsetXLabels && (
            <>
              <h1>Z Offset</h1>
              <b>{zOffsetAggregationType}</b>
              <ScatterChart
                width={1000}
                height={400}
                xAxis={[
                  {
                    min: zOffsetXLabels[0],
                    max: zOffsetXLabels[zOffsetXLabels.length - 1],
                    scaleType: 'time',
                    data: zOffsetXLabels,
                    valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                  }
                ]}
                series={[
                  { label: 'Min z', data: zOffsetMinData },
                  { label: 'Avg z', data: zOffsetAvgData },
                  { label: 'Max z', data: zOffsetMaxData }
                ]}
                tooltip={{
                  itemContent: CustomItemTooltipContent
                }}
              />
            </>
          )}
          {completedPerfSeries && performanceXLabels && (
            <LineChart
              width={1000}
              height={400}
              series={[
                {
                  data: completedPerfSeries,
                  label: 'completed performance',
                  id: 'completedPerformance',
                  yAxisId: 'leftAxisId',
                  area: true,
                  showMark: true,
                  color: '#6666aa'
                },
                {
                  data: operationalPerfSeries,
                  label: 'operational performance',
                  id: 'operationalPerformance',
                  yAxisId: 'leftAxisId',
                  area: true,
                  showMark: true,
                  color: '#004488'
                },
                {
                  data: totalCountSeries,
                  label: 'total count',
                  id: 'totalCount',
                  area: false,
                  showMark: true,
                  color: 'black',
                  yAxisId: 'rightAxisId'
                },
                {
                  data: fullyCompletedCountSeries,
                  label: 'fully completed count',
                  id: 'fullyCompleted',
                  area: false,
                  showMark: true,
                  color: 'green',
                  yAxisId: 'rightAxisId'
                },
                {
                  data: partiallyCompletedCountSeries,
                  label: 'partially count',
                  id: 'partiallyCompleted',
                  area: false,
                  showMark: true,
                  color: 'orange',
                  yAxisId: 'rightAxisId'
                },
                {
                  data: unexpectedBehaviorCountSeries,
                  label: 'unexpected behavior count',
                  id: 'unexpectedBehavior',
                  area: false,
                  showMark: true,
                  color: '#ff4400',
                  yAxisId: 'rightAxisId'
                }
              ]}
              yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
              xAxis={[
                {
                  min: performanceXLabels[0],
                  max: performanceXLabels[performanceXLabels.length - 1],
                  scaleType: 'time',
                  data: performanceXLabels,
                  valueFormatter: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
                }
              ]}
              rightAxis="rightAxisId"
              sx={{
                '& .MuiAreaElement-series-completedPerformance': {
                  fill: "url('#myGradient1')",
                  strokeWidth: 2,
                  opacity: 0.8
                },
                '& .MuiAreaElement-series-operationalPerformance': {
                  fill: "url('#myGradient2')",
                  strokeWidth: 2,
                  opacity: 0.8
                },
                '.MuiLineElement-series-unexpectedBehavior': {
                  strokeDasharray: '5 5'
                },
                '.MuiLineElement-series-partiallyCompleted': {
                  strokeDasharray: '5 5'
                },
                '.MuiLineElement-series-totalCount': {
                  strokeDasharray: '5 5'
                },
                '.MuiLineElement-series-fullyCompleted': {
                  strokeDasharray: '5 5'
                }
              }}
            >
              <defs>
                <linearGradient id="myGradient1" gradientTransform="rotate(90)">
                  <stop offset="10%" stopColor={alpha('#000088', 0.4)} />
                  <stop offset="90%" stopColor={alpha('#ffffff', 0.4)} />
                </linearGradient>
                <linearGradient id="myGradient2" gradientTransform="rotate(90)">
                  <stop offset="10%" stopColor={alpha('#004488', 0.4)} />
                  <stop offset="90%" stopColor={alpha('#ffffff', 0.4)} />
                </linearGradient>
              </defs>
            </LineChart>
          )}
        </div>
      </div>
    </div>
  );
};
