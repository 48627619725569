import { DateTime, DateTimeFormatOptions } from 'luxon';

import i18n from '../../i18n';
import { getBrowserLocale } from '../get-browser-locale/get-browser-locale';
import { browserTimezone } from '../get-browser-timezone/get-browser-timezone';

export const validateTimezone = (timezone?: string): boolean => {
  if (!timezone) {
    return false;
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });
    return true;
  } catch {
    return false;
  }
};

export const formatTimestamp = (
  timestamp: string,
  timezone?: string,
  locale?: string,
  localeStringSettings?: DateTimeFormatOptions
): string => {
  const defaultLocaleStringSettings: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    second: '2-digit'
  };
  const parsedTimeObject = DateTime.fromISO(timestamp);

  if (!parsedTimeObject.isValid) {
    return i18n.t('timestamp.invalid');
  }

  const formattedDateTime = parsedTimeObject
    .setZone((validateTimezone(timezone) && timezone) || browserTimezone || 'UTC')
    .setLocale(locale || getBrowserLocale() || 'en-GB')
    .toLocaleString(localeStringSettings || defaultLocaleStringSettings);

  return formattedDateTime;
};
