import { isEmpty, uniq } from 'lodash';

import { CustomerBoolExp } from '../../../../__generated__/graphql';
import { CustomersOverviewSearchParameters } from '../customers-overview-states-schema/customers-overview-states-schema';

export const customersFilterFields = ['name'] as const;
type CustomersFilterField = (typeof customersFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<CustomersOverviewSearchParameters, CustomersFilterField>
): CustomerBoolExp => {
  const filterQuery: CustomerBoolExp = { _and: [] };
  if (filterParameters.name) {
    filterQuery._and!.push({ company: { name: { _in: uniq(filterParameters.name) } } });
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};
