import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  RadioGroup,
  RadioGroupProps
} from '@mui/material';
import classNames from 'classnames';
import { JSX, ReactNode } from 'react';

import { InfoIconTooltip } from '../../4-features/info-icon-tooltip/info-icon-tooltip';

interface RSRadioGroupProps extends RadioGroupProps {
  /**
   * The label of the radio group.
   */
  formLabel?: ReactNode;
  /**
   * An optional tooltip for the form label
   */
  formLabelTooltip?: ReactNode;
  /**
   * The properties for the radio group label.
   */
  formLabelProps?: FormLabelProps;
  /**
   * The helper text of the radio group.
   */
  formHelperText?: ReactNode;
  /**
   * The properties for the helper text of the radio group.
   */
  formHelperTextProps?: FormHelperTextProps;
}

/**
 * This component renders a RSRadioGroup component. It is based on the MUI RadioGroup component.
 * The API documentation: https://mui.com/material-ui/api/radio-group/
 * NOTE: You usually combine <RSRadioGroup /> with options provided by `<RSRadio />`.
 * Usage example:
 * ```
 * <RSRadioGroup
     formLabel="A group of cats"
     formLabelProps={{ id: 'radio-group-for-cats' }}
     aria-labelledby="radio-group-for-cats"
     name="group-of-cats"
     defaultValue="smirking"
     formHelperText="Please choose your favourite cat"
   >
     <RSRadio label="😼 Smirking cat (default value)" value="smirking" data-testid="smirking-cat" />
     <RSRadio label="😾 Pouting cat" value="pouting" data-testid="pouting-cat" />
     <RSRadio label="🙀 Weary cat" value="weary" data-testid="weary-kat" />
     <RSRadio label="🐶 Hond (disabled)" value="hond" disabled={true} />
   </RSRadioGroup>
 * ```
 * Only commonly used properties are listed below.
 *
 * @param {ReactNode} children The content of the component.
 * @param {ReactNode} [formLabel] The label of the radio group.
 * @param {FormLabelProps} [formLabelProps] The properties for the radio group label.
 * @param {ReactNode} [formHelperText] The helper text of the radio group.
 * @param {FormHelperTextProps} [formHelperTextProps] The properties for the helper text of the radio group.
 * @returns {React.JSX.Element} A React element that renders a FormControlLabel component, which wraps a `<Radio />`
 *   control.
 */
export const RSRadioGroup = ({
  children,
  formLabel,
  formLabelTooltip,
  formLabelProps,
  formHelperText,
  formHelperTextProps,
  ...props
}: RSRadioGroupProps): JSX.Element => {
  return (
    <FormControl className="rs-radio-group__form-control">
      {formLabel && (
        <FormLabel
          {...formLabelProps}
          className={classNames('rs-radio-group__label', formLabelProps?.className)}
          data-testid="rs-radio-group-label"
        >
          {formLabel}
          {formLabelTooltip && (
            <>
              &nbsp;
              <InfoIconTooltip infoIconTooltip={formLabelTooltip} />
            </>
          )}
        </FormLabel>
      )}
      <RadioGroup {...props} className={classNames('rs-radio-group', props.className)}>
        {children}
      </RadioGroup>
      {formHelperText && (
        <FormHelperText
          {...formHelperTextProps}
          className={classNames('rs-radio-group__helper-text', formHelperTextProps?.className)}
          data-testid="rs-radio-group-helper-text"
        >
          {formHelperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
