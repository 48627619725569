import { isEmpty } from 'lodash';

import { GetAdminCompanyDetailsQuery, SelectionType } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { Flatten } from '../../../../types/flatten';
import { contextualizeBoolean } from '../../../../utilities/contextualize-boolean/contextualize-boolean';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';

type CompanyAlertConfigurations = NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>['rocAlertConfigurations'];

const calculateDevicesCount = (total: number, aggregateCount: number, selectionType: SelectionType): number => {
  switch (selectionType) {
    case SelectionType.All:
      return total;
    case SelectionType.Specific:
      return aggregateCount;
    case SelectionType.AllExcept:
      return total - aggregateCount;
    default:
      return 0;
  }
};

export const generateAlertConfigurationRows = (
  alertConfigurations: CompanyAlertConfigurations,
  totalDevicesCount: number,
  userTimezone?: string
) => {
  if (isEmpty(alertConfigurations)) {
    return [];
  }

  return alertConfigurations.map((alert) => ({
    id: alert.id,
    name: alert.name,
    status: contextualizeBoolean(
      i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.alertEnabled'),
      i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.alertDisabled'),
      alert.enabled
    ),
    devicesCount: calculateDevicesCount(
      totalDevicesCount,
      alert.rocAlertDevicesAggregate.aggregate?.count || 0,
      alert.deviceSelectionType as SelectionType
    ),
    createdAt: formatTimestamp(alert.createdAt, userTimezone),
    updatedAt: formatTimestamp(alert.updatedAt, userTimezone)
  }));
};

export type CompanyAlertConfigurationRow = Flatten<ReturnType<typeof generateAlertConfigurationRows>>;
