import { useTranslation } from 'react-i18next';

import { getEnvironmentType } from '../../../configs/configs';

export const PortalTypeIndicator = (): JSX.Element => {
  const { t } = useTranslation();

  const environmentType = getEnvironmentType();
  switch (environmentType) {
    case 'prod':
      return <></>;
    default:
      return (
        <div data-testid="portal-header-portal-type" className="portal-header__portal_type">
          {t(`header.portalType.${environmentType}`)}
        </div>
      );
  }
};
