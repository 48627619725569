import { gql } from '../../../__generated__/gql';

export const QUERY_GET_USER_AUTH_INFO = gql(`
  query getUserAuthInfo($userAuthId: String!) {
    users(where: { authUid: { _eq: $userAuthId } }) {
      id
      companyId
      email
      authUid
      userPermissionLinks {
        id
        userPermission
      }
      company {
        id
        customer {
          companyId
          allowImport
        }
        serviceProvider {
          companyId
        }
      }
      isSuperUser
      userDeviceGroups {
        deviceGroup {
          id
          customerId
          serviceProviderId
        }
        id
        userRole
      }
    }
  }
`);
