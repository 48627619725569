import { DeviceOrderBy, OrderBy } from '../../../../__generated__/graphql';

export enum DevicesOverviewSortOptions {
  SerialNumberAsc = 'serialNumber',
  SerialNumberDesc = '-serialNumber',
  OperationalLifeCycleAsc = 'operationalLifeCycle',
  OperationalLifeCycleDesc = '-operationalLifeCycle',
  RocOSAsc = 'rocOS',
  RocOSDesc = '-rocOS',
  ConnectorTypeAsc = 'connectorType',
  ConnectorTypeDesc = '-connectorType',
  CustomerAsc = 'customer',
  CustomerDesc = '-customer',
  SiteAsc = 'site',
  SiteDesc = '-site',
  ProgramAsc = 'program',
  ProgramDesc = '-program',
  OperationalPerformanceAsc = 'operationalPerformance',
  OperationalPerformanceDesc = '-operationalPerformance',
  BasePerformanceAsc = 'basePerformance',
  BasePerformanceDesc = '-basePerformance'
}

export const generateSortQuery = (sortOption?: DevicesOverviewSortOptions): DeviceOrderBy[] => {
  switch (sortOption) {
    case DevicesOverviewSortOptions.SerialNumberAsc:
      return [{ serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.SerialNumberDesc:
      return [{ serialNumber: OrderBy.Desc }];
    case DevicesOverviewSortOptions.OperationalLifeCycleAsc:
      return [{ operationalLifeCycle: OrderBy.Asc }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.OperationalLifeCycleDesc:
      return [{ operationalLifeCycle: OrderBy.Desc }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.RocOSAsc:
      return [
        { deviceSoftwareConfigurationActive: { rocosComposedVersion: OrderBy.Asc } },
        { serialNumber: OrderBy.Asc }
      ];
    case DevicesOverviewSortOptions.RocOSDesc:
      return [
        { deviceSoftwareConfigurationActive: { rocosComposedVersion: OrderBy.Desc } },
        { serialNumber: OrderBy.Asc }
      ];
    case DevicesOverviewSortOptions.ConnectorTypeAsc:
      return [{ connectorHolderType: { connectorType: OrderBy.Asc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.ConnectorTypeDesc:
      return [{ connectorHolderType: { connectorType: OrderBy.Desc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.CustomerAsc:
      return [{ site: { customer: { company: { name: OrderBy.Asc } } } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.CustomerDesc:
      return [{ site: { customer: { company: { name: OrderBy.Desc } } } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.SiteAsc:
      return [{ site: { name: OrderBy.Asc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.SiteDesc:
      return [{ site: { name: OrderBy.Desc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.ProgramAsc:
      return [{ program: { name: OrderBy.Asc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.ProgramDesc:
      return [{ program: { name: OrderBy.Desc } }, { serialNumber: OrderBy.Asc }];
    case DevicesOverviewSortOptions.OperationalPerformanceAsc:
      return [
        { deviceCurrentPerformance: { operationalPerformanceLastPeriodPct: OrderBy.AscNullsFirst } },
        { serialNumber: OrderBy.Asc }
      ];
    case DevicesOverviewSortOptions.OperationalPerformanceDesc:
      return [
        { deviceCurrentPerformance: { operationalPerformanceLastPeriodPct: OrderBy.DescNullsLast } },
        { serialNumber: OrderBy.Asc }
      ];
    case DevicesOverviewSortOptions.BasePerformanceAsc:
      return [
        { deviceCurrentPerformance: { performanceLastPeriodPct: OrderBy.AscNullsFirst } },
        { serialNumber: OrderBy.Asc }
      ];
    case DevicesOverviewSortOptions.BasePerformanceDesc:
      return [
        { deviceCurrentPerformance: { performanceLastPeriodPct: OrderBy.DescNullsLast } },
        { serialNumber: OrderBy.Asc }
      ];
    default:
      return [{ serialNumber: OrderBy.Asc }];
  }
};
