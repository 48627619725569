import { ApolloError } from '@apollo/client';
import { createContext } from 'react';

export interface UserCompanyTypeContextInterface {
  showServicePortal?: boolean;
  companyId?: string;
  error?: ApolloError | Error;
  loading?: boolean;
}

export const UserCompanyTypeContext = createContext<UserCompanyTypeContextInterface>({
  showServicePortal: undefined,
  companyId: undefined,
  error: undefined,
  loading: undefined
});
