import { useQuery } from '@apollo/client';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { deviceGroupDevicesDataGridColumns } from './data-grid-configurations/device-group-devices-data-grid-columns';
import { deviceGroupUsersDataGridColumns } from './data-grid-configurations/device-group-users-data-grid-column';
import { DeviceGroupDeviceRow, generateDeviceRows } from './data-grid-configurations/generate-device-rows';
import { DeviceGroupUserRow, generateUserRows } from './data-grid-configurations/generate-user-rows';
import { DeviceGroupDetailsAdminPanel } from './device-group-details-admin-panel/device-group-details-admin-panel';
import { ManageDeviceToDeviceGroupLinkDrawer } from './manage-device-to-device-group-link-drawer/manage-device-to-device-group-link-drawer';
import { ManageUserToDeviceGroupLinkDrawer } from './manage-user-to-device-group-link-drawer/manage-user-to-device-group-link-drawer';
import { UserDeviceGroupRole } from '../../../__generated__/graphql';
import { appConfig } from '../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { QUERY_GET_ADMIN_DEVICE_GROUP_DETAILS } from '../../../services/queries/admin/companies/get-admin-device-group-details';
import { AdminDetailsMainSectionWrapper } from '../../2-templates/main-sections/admin-details-main-section-wrapper/admin-details-main-section-wrapper';
import { AdminDetailsDataGrid } from '../../4-features/admin/admin-details-data-grid/admin-details-data-grid';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const DeviceGroupDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceGroupId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [openManageDeviceToDeviceGroupLinkDrawer, setOpenManageDeviceToDeviceGroupLinkDrawer] =
    useState<boolean>(false);
  const [openManageUserToDeviceGroupLinkDrawer, setOpenManageUserToDeviceGroupLinkDrawer] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_DEVICE_GROUP_DETAILS, {
    variables: { deviceGroupId: deviceGroupId! },
    fetchPolicy: 'network-only'
  });
  // Create, Update and Delete share the same permission check condition, so we use "update"
  const { result: canUserManageDeviceGroupDeviceLinks, loading: loadingCanUserManageDeviceGroupDeviceLinks } =
    useAuthCheckerWithSubjectInfo({
      action: 'UPDATE',
      subjectInfo: {
        type: 'DeviceGroupDeviceLinks',
        customerId: data?.deviceGroupByPK?.customer.companyId || '',
        serviceProviderId: data?.deviceGroupByPK?.serviceProvider?.companyId,
        deviceGroupId: data?.deviceGroupByPK?.id
      },
      skip: false
    });
  // Create, Update and Delete share the same permission check condition, so we use "update"
  const { result: canUserManageDeviceGroupUserLinks, loading: loadingCanUserManageDeviceGroupUserLinks } =
    useAuthCheckerWithSubjectInfo({
      action: 'UPDATE',
      subjectInfo: {
        type: 'DeviceGroupUserLinks',
        customerId: data?.deviceGroupByPK?.customer.companyId || '',
        serviceProviderId: data?.deviceGroupByPK?.serviceProvider?.companyId,
        deviceGroupId: data?.deviceGroupByPK?.id
      },
      skip: false
    });

  const handleDeviceRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DeviceGroupDeviceRow>): void => {
    navigate(`${appConfig.basePath}/admin/devices/${params.id}`);
  };

  const handleUserRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DeviceGroupUserRow>): void => {
    navigate(`${appConfig.basePath}/admin/users/${params.id}`);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const deviceGroupDetails = data?.deviceGroupByPK;
  if (!deviceGroupDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<DeviceGroupDetailsAdminPanel deviceGroupDetails={deviceGroupDetails} />}>
      <div className="device-group-details-admin-page" data-testid="device-group-details-admin-page">
        <div className="device-group-details-admin-page__devices" data-testid="device-group-details-admin-page-devices">
          <div className="device-group-details-admin-page__title-bar">
            <h2>{t('deviceGroupDetailsPage.deviceDataGrid.title')}</h2>
            <RSButton
              color="success"
              disabled={loadingCanUserManageDeviceGroupDeviceLinks || !canUserManageDeviceGroupDeviceLinks}
              onClick={() => setOpenManageDeviceToDeviceGroupLinkDrawer(true)}
              variant="contained"
              data-testid="device-group-details-admin-page-manage-devices"
            >
              {t('deviceGroupDetailsPage.deviceDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-group-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceGroupDevicesDataGridColumns}
              loading={loading}
              rows={generateDeviceRows(deviceGroupDetails.deviceGroupDevices, userTimezone)}
              onRowClick={handleDeviceRowClick}
              data-testid="device-group-details-admin-page-devices-data-grid"
            />
          </div>
        </div>
        <div className="device-group-details-admin-page__users" data-testid="device-group-details-admin-page-users">
          <div className="device-group-details-admin-page__title-bar">
            <h2>{t('deviceGroupDetailsPage.userDataGrid.title')}</h2>
            <RSButton
              color="success"
              disabled={loadingCanUserManageDeviceGroupUserLinks || !canUserManageDeviceGroupUserLinks}
              onClick={() => setOpenManageUserToDeviceGroupLinkDrawer(true)}
              variant="contained"
              data-testid="device-group-details-admin-page-manage-users"
            >
              {t('deviceGroupDetailsPage.userDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-group-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceGroupUsersDataGridColumns}
              loading={loading}
              rows={generateUserRows(deviceGroupDetails.userDeviceGroups, userTimezone)}
              onRowClick={handleUserRowClick}
              data-testid="device-group-details-admin-page-users-data-grid"
            />
          </div>
        </div>
      </div>
      <ManageDeviceToDeviceGroupLinkDrawer
        open={openManageDeviceToDeviceGroupLinkDrawer}
        setOpenManageDeviceToDeviceGroupLinkDrawer={setOpenManageDeviceToDeviceGroupLinkDrawer}
        defaultValues={{
          deviceGroupId: deviceGroupDetails.id,
          deviceIds: deviceGroupDetails.deviceGroupDevices.map((device) => device.deviceId) || []
        }}
        customerId={deviceGroupDetails.customer.companyId}
        deviceGroupName={deviceGroupDetails.name}
        refetchDeviceGroups={refetch}
      />
      <ManageUserToDeviceGroupLinkDrawer
        open={openManageUserToDeviceGroupLinkDrawer}
        setOpenManageUserToDeviceGroupLinkDrawer={setOpenManageUserToDeviceGroupLinkDrawer}
        customerId={deviceGroupDetails.customer.companyId}
        deviceGroupName={deviceGroupDetails.name}
        serviceProviderId={deviceGroupDetails.serviceProvider?.companyId}
        defaultValues={{
          deviceGroupId: deviceGroupDetails.id,
          users: deviceGroupDetails.userDeviceGroups.map((user) => ({
            userId: user.user.id,
            userRole: user.userRole as UserDeviceGroupRole,
            validUntil: user.validUntil
          }))
        }}
        refetchDeviceGroups={refetch}
      />
    </AdminDetailsMainSectionWrapper>
  );
};
