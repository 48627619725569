import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATION_RESULTS = gql(`
  query getOperationResults {
    deviceOperationResults(orderBy: [{ isSuccessful: DESC }, {resultClass: ASC} { name: DESC }], where: { allowForAnnotation: { _eq: true } }) {
      id
      code
      name
      isSuccessful
      resultClass
    }
  }
`);
