import { z } from 'zod';

import { UserDeviceGroupUserRole } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';

export const modifyCompanySchema = z.object({
  id: z.string().uuid(),
  name: z
    .string()
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  maxDeviceGroupRole: z.nativeEnum(UserDeviceGroupUserRole),
  allowImport: z.boolean().nullish(),
  documentCenterUrl: z.string().url().nullish(),
  rocsysApiIpAcceptlist: z.string().nullish()
});

export type ModifyCompanyRequest = z.infer<typeof modifyCompanySchema>;
