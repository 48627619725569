import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ROC_ALERT_CHANNELS = gql(`
  query getRocAlertChannels {
    rocAlertChannel(orderBy: { code: ASC }) {
      id
      code
      rocAlertChannelParameters(orderBy: { name: ASC }) {
        id
        label
        name
        order
        type
      }
    }
  }
`);
