import { JSX, ReactNode } from 'react';

import {
  OperationResultChip,
  OperationResultChipProps
} from '../../../4-features/operations/operation-result-chip/operation-result-chip';

interface SubOperationHeaderProps {
  title: string;
  operationResult: OperationResultChipProps;
  actions?: ReactNode;
}

export const SubOperationHeader = (props: SubOperationHeaderProps): JSX.Element => {
  return (
    <div className="sub-operation-header" data-testid="sub-operation-header">
      <div className="sub-operation-header__title-result">
        <h3 className="sub-operation-header__title">{props.title}</h3>
        <OperationResultChip {...props.operationResult} />
      </div>
      {props.actions && <div className="sub-operation-header__actions">{props.actions}</div>}
    </div>
  );
};
