import qs from 'qs';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { CountBlock } from '../../../../5-elements/count-block/count-block';
import { CountBlockWithInternalLink } from '../../../../5-elements/count-block-with-internal-link/count-block-with-internal-link';

export interface CustomerCountBlocksProps {
  devices: number;
  sites: number;
  programs: number;
  customerName: string;
}

export const CustomerCountBlocks = ({
  customerName,
  devices,
  sites,
  programs
}: CustomerCountBlocksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section className="customer-count-blocks" data-testid="customer-count-blocks">
      <CountBlockWithInternalLink
        title={t('customerDetailsPage.sidePanel.countBlocks.devices')}
        count={devices}
        to={`/portal/devices?${qs.stringify({ customer: [customerName] }, { arrayFormat: 'brackets' })}`}
        data-testid="customer-count-devices"
      />
      <CountBlock
        title={t('customerDetailsPage.sidePanel.countBlocks.sites')}
        count={sites}
        data-testid="customer-count-sites"
      />
      <CountBlock
        title={t('customerDetailsPage.sidePanel.countBlocks.programs')}
        count={programs}
        data-testid="customer-count-programs"
      />
    </section>
  );
};
