import { z } from 'zod';

import { UserDeviceGroupRole } from '../../../../../__generated__/graphql';

export const manageUserToDeviceGroupLinkSchema = z.object({
  deviceGroupId: z.string().uuid(),
  users: z
    .object({
      userId: z.string().uuid(),
      userRole: z.nativeEnum(UserDeviceGroupRole),
      validUntil: z.string().datetime({ offset: true }).nullish()
    })
    .array()
});

export type ManageUserToDeviceGroupLinkRequest = z.infer<typeof manageUserToDeviceGroupLinkSchema>;
