import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICE_DEVICE_GROUPS = gql(`
  query getAdminDeviceDeviceGroups($customerId: uuid!) {
    deviceGroups(where: { customerId: { _eq: $customerId } }) {
      id
      name
    }
  }
`);
