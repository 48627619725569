import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICE_GROUP_DEVICES = gql(`
  query getAdminDeviceGroupDevices($filters: DeviceBoolExp) {
    devices(orderBy: { serialNumber: ASC }, where: $filters) {
      id
      serialNumber
      deviceType {
        id
        modelNumber
      }
      connectorHolderType {
        id
        name
      }
    }
  }
`);
