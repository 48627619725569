import { t } from 'i18next';
import { JSX, PropsWithChildren } from 'react';

import { ErrorPage } from '../../1-pages/error-page/error-page';
import { LoadingPage } from '../../1-pages/loading-page/loading-page';
import { HeaderOnlyWrapper } from '../../2-templates/header-only-wrapper/header-only-wrapper';

interface AuthenticationLoadingErrorContainerProps extends PropsWithChildren {
  isLoading: boolean;
  error?: Error;
}

export const AuthenticationLoadingErrorContainer = ({
  isLoading,
  error,
  children
}: AuthenticationLoadingErrorContainerProps): JSX.Element => {
  // With <PageWrapper />, the header will only be shown if the user has been successfully authenticated, as the header
  // is a child of the <AuthenticatedOnlyWrapper>.
  // When loading or error occurs, the child (which includes the header) will not get rendered. Therefore the header
  // wrapper needs to be used explicitly.
  if (isLoading) {
    return (
      <HeaderOnlyWrapper>
        <LoadingPage />
      </HeaderOnlyWrapper>
    );
  }
  if (error) {
    return (
      <HeaderOnlyWrapper>
        <ErrorPage
          title={t('errorPage.errorOccurred')}
          titleEmphasized={t('errorPage.error')}
          message={error.message}
        />
      </HeaderOnlyWrapper>
    );
  }
  return <>{children}</>;
};
