import { useApolloClient } from '@apollo/client';
import { Backdrop, InputLabel } from '@mui/material';
import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReloadIcon from '../../../assets/icons/reload.svg?react';
import { Loading } from '../../5-elements/loading/loading';
import { RSButton } from '../../5-elements/rs-button/rs-button';

export const ReloadData = (): JSX.Element => {
  const { t } = useTranslation();
  const { resetStore } = useApolloClient();
  const [showOverlay, setShowOverlay] = useState(false);

  const handleReload = (): void => {
    setShowOverlay(true);
    resetStore();
    setTimeout(() => {
      setShowOverlay(false);
    }, 500);
  };

  return (
    <div className="reload-data" data-testid="reload-data" onClick={handleReload}>
      <Backdrop className="reload-data__loading-overlay" open={showOverlay}>
        <Loading />
      </Backdrop>
      <InputLabel shrink={true} className="reload-data__input-label" data-testid="reload-data-input-label">
        {t('reloadData.label')}
      </InputLabel>
      <RSButton extraClassNames={['reload-data__button']} data-testid="reload-data-button">
        <div className="reload-data__icon-wrapper">
          <ReloadIcon />
        </div>
      </RSButton>
    </div>
  );
};
