import { DateTimeFormatOptions } from 'luxon';
import { MouseEventHandler } from 'react';

import { UserDeviceGroupRole } from '../../../../__generated__/graphql';
import UnlinkIcon from '../../../../assets/icons/unlink.svg?react';
import i18n from '../../../../i18n';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { DisplayTableCell, DisplayTableColumnHeaderCell } from '../../../5-elements/display-table/display-table';

export const userToDeviceGroupLinkTableHeaderCells: DisplayTableColumnHeaderCell[] = [
  {
    children: i18n.t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.name'),
    key: 'user-name',
    className: 'manage-user-to-device-group-link-drawer__name-column'
  },
  {
    children: i18n.t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.company'),
    key: 'company-name',
    className: 'manage-user-to-device-group-link-drawer__company-column'
  },
  {
    children: i18n.t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.deviceGroupRole'),
    key: 'user-device-group-role',
    className: 'manage-user-to-device-group-link-drawer__device-group-role-column'
  },
  {
    children: i18n.t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.validUntil'),
    key: 'valid-until',
    className: 'manage-user-to-device-group-link-drawer__valid-until-column'
  },
  {
    children: '',
    key: 'unlink',
    className: 'manage-user-to-device-group-link-drawer__unlink-column'
  }
];

export const userToDeviceGroupLinkTableRowCells = (
  userId: string,
  userName: string,
  companyName: string,
  deviceGroupRole: UserDeviceGroupRole,
  handleUnlink: MouseEventHandler<HTMLSpanElement>,
  formatWithDefaultTimezone: (
    timestamp: string,
    locale?: string,
    localeStringSettings?: DateTimeFormatOptions
  ) => string,
  validUntil?: string | null
): DisplayTableCell[] => {
  return [
    { children: userName, key: userName, className: 'manage-user-to-device-group-link-drawer__name-column' },
    {
      children: companyName,
      key: `${companyName}-${userId}`,
      className: 'manage-user-to-device-group-link-drawer__company-column'
    },
    {
      children: mapUserDeviceGroupRole(deviceGroupRole),
      key: `${deviceGroupRole}-${userId}`,
      className: 'manage-user-to-device-group-link-drawer__device-group-role-column'
    },
    {
      children: validUntil ? formatWithDefaultTimezone(validUntil) : getNonNullishDisplayValue(validUntil),
      key: `valid-until-${userId}`,
      className: 'manage-user-to-device-group-link-drawer__valid-until-column'
    },
    {
      // Cannot use <button> as it behaves differently in a form
      children: (
        <span
          className="manage-user-to-device-group-link-drawer__unlink-icon-wrapper"
          title={i18n.t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.unlink')}
          onClick={handleUnlink}
          data-testid={`manage-user-to-device-group-link-drawer-unlink-user-${userId}`}
        >
          <UnlinkIcon />
        </span>
      ),
      key: `unlink-${userId}`,
      className: 'manage-user-to-device-group-link-drawer__unlink-column'
    }
  ];
};
