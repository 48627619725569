import { first } from 'lodash';

import { GetOperationsQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types/flatten';
import { calculateDuration } from '../../../../utilities/calculate-duration/calculate-duration';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { generateOperationResultLabel } from '../../../../utilities/generate-operation-result-label/generate-operation-result-label';
import { getAnnotationStatus } from '../../../../utilities/get-annotation-status/get-annotation-status';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';
import { DataGridOperationResultCellProps } from '../../../4-features/operations/data-grid-operation-result-cell/data-grid-operation-result-cell';

export type OperationRow = FlattenFunction<typeof generateOperationRows>;

export const generateOperationRows = (
  deviceOperations?: GetOperationsQuery['deviceOperations'] | null,
  userTimezone?: string
) => {
  if (!deviceOperations) {
    return [];
  }

  return deviceOperations.map((deviceOperation) => {
    const annotationStatus = getAnnotationStatus(
      deviceOperation.deviceOperationResult?.id,
      deviceOperation.deviceOperationAnnotatedResult?.id
    );
    return {
      id: deviceOperation.id,
      serialNumber: {
        serialNumber: deviceOperation.device.serialNumber,
        sequence: deviceOperation.sequence
      },
      result: {
        annotationStatus: annotationStatus,
        finalResultName: generateOperationResultLabel(deviceOperation),
        annotatedResultName: deviceOperation.deviceOperationAnnotatedResult?.name,
        originalResultName: deviceOperation.deviceOperationResult?.name,
        annotatedTimestamp: first(deviceOperation.deviceOperationAnnotationAudits)?.annotatedAt,
        success: deviceOperation.deviceOperationFinalResult?.isSuccessful,
        hasEndedAt: Boolean(deviceOperation.endAt),
        hasOperationResult: Boolean(deviceOperation.deviceOperationResult),
        resultClass: deviceOperation.deviceOperationFinalResult?.resultClass
      } as DataGridOperationResultCellProps,
      dateTime: formatTimestamp(deviceOperation.startAt, userTimezone),
      duration: calculateDuration(deviceOperation.startAt, deviceOperation.endAt),
      operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(deviceOperation.operationalLifeCycle),
      customer: deviceOperation.device.site.customer.company.name,
      site: deviceOperation.device.site.name,
      program: deviceOperation.device.program?.name
    };
  });
};
