import { z } from 'zod';

import { UserDeviceGroupRole } from '../../../../../__generated__/graphql';

export const manageUserDeviceGroupsSchema = z.object({
  userId: z.string().uuid(),
  deviceGroups: z
    .object({
      deviceGroupId: z.string().uuid(),
      userRole: z.nativeEnum(UserDeviceGroupRole),
      validUntil: z.string().datetime({ offset: true }).nullish()
    })
    .array()
});

export type ManageUserDeviceGroupsRequest = z.infer<typeof manageUserDeviceGroupsSchema>;
