import { JSX, PropsWithChildren, ReactNode } from 'react';

import { DataTestId } from '../../../../types/rs-input';

interface AdminOverviewMainSectionWrapperProps extends PropsWithChildren, DataTestId {
  title: ReactNode;
  filter: JSX.Element;
}

export const AdminOverviewMainSectionWrapper = ({
  'data-testid': dataTestId,
  ...props
}: AdminOverviewMainSectionWrapperProps): JSX.Element => {
  return (
    <section className="admin-overview-main-section-wrapper" data-testid={dataTestId}>
      <div className="admin-overview-main-section-wrapper__title">{props.title}</div>
      <div className="admin-overview-main-section-wrapper__contents">
        <div className="admin-overview-main-section-wrapper__filter">{props.filter}</div>
        <div className="admin-overview-main-section-wrapper__main-content">{props.children}</div>
      </div>
    </section>
  );
};
