import { FormControl, FormHelperText, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { RSHelperTextProps, RSInputLabelProps } from '../../../types/rs-input';
import { InfoIconTooltip } from '../../4-features/info-icon-tooltip/info-icon-tooltip';

interface RSTextInputProps extends OutlinedInputProps {
  inputLabel?: ReactNode;
  inputLabelTooltip?: ReactNode;
  inputLabelProps?: RSInputLabelProps;
  helperText?: ReactNode;
  helperTextProps?: RSHelperTextProps;
}

/**
 * This component renders a RSTextInput component. It is based on the MUI Input and TextInput components.
 * The API is the same as the MUI Input component: https://mui.com/material-ui/api/input/
 * Only commonly used properties are listed below.
 *
 * @param {string} [className] Optional class name for the input field.
 * @param {boolean} [required] Whether the input field is required.
 * @param {ReactNode} [inputLabel] The input label for the input field.
 * @param {ReactNode} [helperText] The helper text for the input field.
 * @param {boolean} [disabled] Whether the input field is disabled.
 * @param {boolean} [error] Whether the input field has error.
 * @returns {React.JSX.Element} A React element that renders a RSTextInput component.
 */
export const RSTextInput = forwardRef(
  (
    { inputLabel, inputLabelProps, inputLabelTooltip, helperText, helperTextProps, ...props }: RSTextInputProps,
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <FormControl className="rs-text-input__form-control" data-testid="rs-text-input-form-control">
        {inputLabel && (
          <InputLabel
            {...inputLabelProps}
            shrink={true}
            variant="outlined"
            className={classNames('rs-text-input__input-label', inputLabelProps?.className)}
            error={props.error}
          >
            {inputLabel} {props.required && t('input.required')}
            {inputLabelTooltip && (
              <>
                &nbsp;
                <InfoIconTooltip infoIconTooltip={inputLabelTooltip} />
              </>
            )}
          </InputLabel>
        )}
        <OutlinedInput
          {...props}
          className={classNames('rs-text-input__input', props.className)}
          inputRef={ref}
          autoComplete="off"
        />
        {helperText && (
          <FormHelperText
            {...helperTextProps}
            className={classNames('rs-text-input__helper-text', helperTextProps?.className)}
            error={props.error}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
RSTextInput.displayName = 'RSTextInput';
