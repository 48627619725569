import { useQuery } from '@apollo/client';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { UserDeviceGroupRow, generateDeviceGroupRows } from './data-grid-configurations/generate-device-group-rows';
import { userDeviceGroupDataGridColumns } from './data-grid-configurations/user-device-groups-data-grid-columns';
import { ManageUserDeviceGroupsDrawer } from './manage-user-device-groups-drawer/manage-user-device-groups-drawer';
import { UserDetailsAdminPanel } from './user-details-admin-panel/user-details-admin-panel';
import { UserDeviceGroupRole } from '../../../__generated__/graphql';
import { appConfig } from '../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { QUERY_GET_ADMIN_USER_DETAILS } from '../../../services/queries/admin/users/get-admin-user-details';
import { CompanyType } from '../../../types/company-type';
import { AdminDetailsMainSectionWrapper } from '../../2-templates/main-sections/admin-details-main-section-wrapper/admin-details-main-section-wrapper';
import { AdminDetailsDataGrid } from '../../4-features/admin/admin-details-data-grid/admin-details-data-grid';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const UserDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [openManageUserDeviceGroupsDrawer, setOpenManageUserDeviceGroupsDrawer] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_USER_DETAILS, { variables: { userId: userId! } });
  const { result: canUserManageDeviceGroupUserLinks, loading: loadingCanUserManageDeviceGroupUserLinks } =
    useAuthCheckerWithSubjectInfo({
      action: 'UPDATE',
      subjectInfo: {
        // We don't provide the device group ID here:
        // 1. The device group ID does not affect the permission for "UPDATE".
        // 2. We don't know which device group(s) the user wants to configure
        type: 'DeviceGroupUserLinks',
        customerId: (data?.userByPK?.company.companyType === CompanyType.Customer && data.userByPK.company.id) || '',
        serviceProviderId:
          (data?.userByPK?.company.companyType === CompanyType.ServiceProvider && data.userByPK.company.id) || undefined
      },
      skip: !data || loading || Boolean(error)
    });

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<UserDeviceGroupRow>): void => {
    navigate(`${appConfig.basePath}/admin/device-groups/${params.id}`);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const userDetails = data?.userByPK;
  if (!userDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<UserDetailsAdminPanel userDetails={userDetails} />}>
      <div className="user-details-admin-page" data-testid="user-details-admin-page">
        <div className="user-details-admin-page__device-groups" data-testid="user-details-admin-page-device-groups">
          <div className="user-details-admin-page__title-bar">
            <h2>{t('userAdminDetailsPage.deviceGroupsDataGrid.title')}</h2>
            <RSButton
              disabled={loadingCanUserManageDeviceGroupUserLinks || !canUserManageDeviceGroupUserLinks}
              onClick={() => setOpenManageUserDeviceGroupsDrawer(true)}
              color="success"
              variant="contained"
              data-testid="user-details-admin-page-manage-device-groups"
            >
              {t('userAdminDetailsPage.deviceGroupsDataGrid.manage')}
            </RSButton>
          </div>
          <div className="user-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={userDeviceGroupDataGridColumns}
              loading={loading}
              rows={generateDeviceGroupRows(userDetails.userDeviceGroups, userTimezone)}
              onRowClick={handleRowClick}
              data-testid="user-details-admin-page-device-groups-data-grid"
            />
          </div>
        </div>
      </div>
      <ManageUserDeviceGroupsDrawer
        open={openManageUserDeviceGroupsDrawer}
        refetchUserDetails={refetch}
        setOpenManageUserDeviceGroupsDrawer={setOpenManageUserDeviceGroupsDrawer}
        userName={`${userDetails.firstName} ${userDetails.lastName}`}
        customerId={(userDetails.company.companyType === CompanyType.Customer && userDetails.company.id) || undefined}
        serviceProviderId={
          (userDetails.company.companyType === CompanyType.ServiceProvider && userDetails.company.id) || undefined
        }
        defaultValues={{
          userId: userDetails.id,
          deviceGroups: userDetails.userDeviceGroups.map((userDeviceGroup) => ({
            deviceGroupId: userDeviceGroup.deviceGroup.id,
            userRole: userDeviceGroup.userRole as UserDeviceGroupRole,
            validUntil: userDeviceGroup.validUntil
          }))
        }}
      />
    </AdminDetailsMainSectionWrapper>
  );
};
