import { useQuery } from '@apollo/client';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { SiteDeviceRow, generateDeviceRows } from './data-grid-configurations/generate-device-rows';
import { siteDevicesDataGridColumns } from './data-grid-configurations/site-devices-data-grid-columns';
import { SiteDetailsAdminPanel } from './site-details-admin-panel/site-details-admin-panel';
import { appConfig } from '../../../configs/configs';
import { QUERY_GET_ADMIN_SITE_DETAILS } from '../../../services/queries/admin/companies/get-admin-site-details';
import { AdminDetailsMainSectionWrapper } from '../../2-templates/main-sections/admin-details-main-section-wrapper/admin-details-main-section-wrapper';
import { AdminDetailsDataGrid } from '../../4-features/admin/admin-details-data-grid/admin-details-data-grid';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const SiteDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_SITE_DETAILS, { variables: { siteId: siteId! } });

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<SiteDeviceRow>): void => {
    navigate(`${appConfig.basePath}/admin/devices/${params.id}`);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const siteDetails = data?.siteByPK;
  if (!siteDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<SiteDetailsAdminPanel siteDetails={siteDetails} />}>
      <div className="site-details-admin-page" data-testid="site-details-admin-page">
        <div className="site-details-admin-page__devices" data-testid="site-details-admin-page-devices">
          <div className="site-details-admin-page__title-bar">
            <h2>{t('siteAdminDetailsPage.deviceDataGrid.title')}</h2>
          </div>
          <div className="site-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={siteDevicesDataGridColumns}
              loading={loading}
              rows={generateDeviceRows(siteDetails.devices, userTimezone)}
              onRowClick={handleRowClick}
              data-testid="site-details-admin-page-devices-data-grid"
            />
          </div>
        </div>
      </div>
    </AdminDetailsMainSectionWrapper>
  );
};
