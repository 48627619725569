import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import TrashBinIcon from '../../../assets/icons/trash-bin.svg?react';
import { RSButton, RSButtonProps } from '../../5-elements/rs-button/rs-button';

interface DrawerButtonsGroupProps {
  handleCancel?: MouseEventHandler<HTMLButtonElement>;
  handleSave?: MouseEventHandler<HTMLButtonElement>;
  handleDelete?: MouseEventHandler<HTMLButtonElement>;
  isSaveDisabled?: boolean;
  isCancelDisabled?: boolean;
  isDeleteDisabled?: boolean;
  colorVariant?: RSButtonProps['color'];
  cancelButtonText?: string;
  saveButtonText?: string;
  deleteButtonText?: string;
}

export const DrawerButtonsGroup = (props: DrawerButtonsGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className="drawer-buttons-group" data-testid="drawer-buttons-group">
      <RSButton
        data-testid="drawer-buttons-group-cancel-button"
        onClick={props.handleCancel}
        disabled={props.isCancelDisabled}
        color={props.colorVariant || 'primary'}
      >
        {props.cancelButtonText || t('drawerEditButtonsGroup.cancel')}
      </RSButton>
      <div className="drawer-buttons-group__actions">
        {props.handleDelete && (
          <RSButton
            data-testid="drawer-buttons-group-delete-button"
            extraClassNames={['drawer-buttons-group__delete-button']}
            onClick={props.handleDelete}
            disabled={props.isDeleteDisabled}
            color="error"
          >
            <div className="drawer-buttons-group__delete-icon-wrapper">
              <TrashBinIcon />
            </div>
            {props.deleteButtonText || t('drawerEditButtonsGroup.delete')}
          </RSButton>
        )}
        <RSButton
          data-testid="drawer-buttons-group-save-button"
          variant="contained"
          disabled={props.isSaveDisabled}
          onClick={props.handleSave}
          color={props.colorVariant || 'primary'}
        >
          {props.saveButtonText || t('drawerEditButtonsGroup.save')}
        </RSButton>
      </div>
    </div>
  );
};
