import { z } from 'zod';

import i18n from '../../../../../i18n';

export const modifyDeviceGroupSchema = z.object({
  id: z.string().uuid(),
  name: z
    .string()
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  // Optional field
  serviceProviderId: z.preprocess((arg) => (arg === '' ? undefined : arg), z.string().uuid().optional()),
  description: z.string().nullish()
});

export type ModifyDeviceGroupRequest = z.infer<typeof modifyDeviceGroupSchema>;
