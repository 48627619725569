import { ApolloQueryResult, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddCompanyRequest, addCompanySchema } from './validation-schema/add-company-schema';
import {
  GetAdminCompaniesQuery,
  GetCompanyNamesQuery,
  UserDeviceGroupUserRole
} from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_CUSTOMER } from '../../../../services/mutations/admin/companies/create-customer';
import { MUTATION_CREATE_SERVICE_PROVIDER } from '../../../../services/mutations/admin/companies/create-service-provider';
import { CompanyType } from '../../../../types/company-type';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { RSSwitch } from '../../../5-elements/rs-switch/rs-switch';
import { RSTextInput } from '../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';

interface AddCompanyDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenAddCompany: Dispatch<SetStateAction<boolean>>;
  refetchCompanies: () => Promise<ApolloQueryResult<GetAdminCompaniesQuery>>;
  refetchCompanyNames: () => Promise<ApolloQueryResult<GetCompanyNamesQuery>>;
}

export const AddCompanyDrawer = ({
  setOpenAddCompany,
  refetchCompanies,
  refetchCompanyNames,
  ...props
}: AddCompanyDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [createCustomer, { loading: loadingCreateCustomer }] = useMutation(MUTATION_CREATE_CUSTOMER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('companiesAdminPage.addCompanyDrawer.createCustomerFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const [createServiceProvider, { loading: loadingCreateServiceProvider }] = useMutation(
    MUTATION_CREATE_SERVICE_PROVIDER,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) =>
        sendMessageToSnackbar(
          t('companiesAdminPage.addCompanyDrawer.createServiceProviderFailed'),
          undefined,
          error.message || error.name,
          'error'
        )
    }
  );
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty }
  } = useForm<AddCompanyRequest>({
    resolver: zodResolver(addCompanySchema),
    defaultValues: { allowImport: false, maxDeviceGroupRole: UserDeviceGroupUserRole.DeviceObserver, name: '' }
  });

  const companyTypeValues = Object.values(CompanyType);
  const companyTypeSelectOptions: RSSelectItemProps[] = companyTypeValues.map((item) => ({
    displayName: mapCompanyType(item),
    menuItemProps: { value: item }
  }));

  const maxDeviceGroupRoleValues = Object.values(UserDeviceGroupUserRole);
  const maxDeviceGroupRoleSelectOptions: RSSelectItemProps[] = maxDeviceGroupRoleValues.map((item) => ({
    displayName: mapUserDeviceGroupRole(item),
    menuItemProps: { value: item }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenAddCompany(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenAddCompany(false);
    }
  };

  const onSubmit: SubmitHandler<AddCompanyRequest> = (data): void => {
    if (!isDirty) {
      setOpenAddCompany(false);
      setShowConfirmationModal(false);
      return;
    }

    if (data.companyType === CompanyType.ServiceProvider) {
      createServiceProvider({
        variables: { name: data.name, maxDeviceGroupRole: data.maxDeviceGroupRole },
        onCompleted: (responseData) => {
          sendMessageToSnackbar(
            t('companiesAdminPage.addCompanyDrawer.createServiceProviderSuccess'),
            undefined,
            undefined,
            'success'
          );
          setOpenAddCompany(false);
          setShowConfirmationModal(false);
          reset(data);
          refetchCompanies();
          refetchCompanyNames();
          navigate(`${appConfig.basePath}/admin/companies/${responseData.createServiceProvider.id}`);
        }
      });
    }

    if (data.companyType === CompanyType.Customer) {
      createCustomer({
        variables: {
          name: data.name,
          maxDeviceGroupRole: data.maxDeviceGroupRole,
          allowImport: Boolean(data.allowImport),
          documentCenterUrl: data.documentCenterUrl
        },
        onCompleted: (responseData) => {
          sendMessageToSnackbar(
            t('companiesAdminPage.addCompanyDrawer.createCustomerSuccess'),
            undefined,
            undefined,
            'success'
          );
          setOpenAddCompany(false);
          setShowConfirmationModal(false);
          reset(data);
          refetchCompanies();
          refetchCompanyNames();
          navigate(`${appConfig.basePath}/admin/companies/${responseData.createCustomer.id}`);
        }
      });
    }
  };

  return (
    <RSDrawer {...props} className="add-company-drawer">
      <ModalDrawerHeader title={t('companiesAdminPage.addCompanyDrawer.title')} />
      <form
        className="add-company-drawer__form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="add-company-drawer-form"
      >
        <div className="add-company-drawer__input-fields">
          <Controller
            name="companyType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="add-company-drawer__company-type-select"
                inputLabel={t('companiesAdminPage.addCompanyDrawer.companyType')}
                inputLabelTooltip={t('tooltips.company.companyType')}
                required={true}
                menuItems={companyTypeSelectOptions}
                helperText={errors.companyType?.message}
                error={Boolean(errors.companyType)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="add-company-drawer-company-type-select"
                value={value || ''}
                color="success"
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <RSTextInput
            inputLabel={t('companiesAdminPage.addCompanyDrawer.name')}
            required={true}
            data-testid="add-company-drawer-name-input"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <Controller
            name="maxDeviceGroupRole"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="add-company-drawer__max-device-group-role-select"
                inputLabel={t('companiesAdminPage.addCompanyDrawer.maxDeviceGroupRole')}
                inputLabelTooltip={t('tooltips.company.maxDeviceGroupRole')}
                required={true}
                menuItems={maxDeviceGroupRoleSelectOptions}
                helperText={errors.maxDeviceGroupRole?.message}
                error={Boolean(errors.maxDeviceGroupRole)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="add-company-drawer-max-device-group-role-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          {watch('companyType') === CompanyType.Customer && (
            <Controller
              control={control}
              name="allowImport"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('companiesAdminPage.addCompanyDrawer.allowImport')}
                  formLabelTooltip={t('tooltips.company.allowImport')}
                  data-testid="add-company-drawer-allow-import-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  falseValueLabel={t('companiesAdminPage.addCompanyDrawer.allowImportDisabled')}
                  trueValueLabel={t('companiesAdminPage.addCompanyDrawer.allowImportEnabled')}
                />
              )}
            />
          )}
          {watch('companyType') === CompanyType.Customer && (
            <RSTextInput
              inputLabel={t('companiesAdminPage.addCompanyDrawer.documentCenterUrl')}
              inputLabelTooltip={t('tooltips.company.documentCenterUrl')}
              data-testid="add-company-drawer-document-center-input"
              helperText={errors.documentCenterUrl?.message}
              error={Boolean(errors.documentCenterUrl)}
              {...register('documentCenterUrl', { setValueAs: (value) => (value === '' ? undefined : value) })}
            />
          )}
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingCreateCustomer || loadingCreateServiceProvider}
          isCancelDisabled={loadingCreateCustomer || loadingCreateServiceProvider}
          colorVariant="success"
          saveButtonText={t('companiesAdminPage.addCompanyDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loadingCreateCustomer || loadingCreateServiceProvider}
        disableCancelButton={loadingCreateCustomer || loadingCreateServiceProvider}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};
