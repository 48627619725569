import { JSX } from 'react';

import { AnnotationStatusInterface } from '../../../../types/operation-annotation';
import { generateOperationTimestampTooltipTitle } from '../../../../utilities/generate-operation-timestamp-tooltip-title/generate-operation-timestamp-tooltip-title';
import { RSTooltip } from '../../../5-elements/rs-tooltip/rs-tooltip';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { OperationAnnotationIcon } from '../operation-annotation-icon/operation-annotation-icon';
import { OperationResultChip, OperationResultChipProps } from '../operation-result-chip/operation-result-chip';
import { OperationResultTooltipTitle } from '../operation-result-tooltip-title/operation-result-tooltip-title';

export interface DataGridOperationResultCellProps
  extends Pick<OperationResultChipProps, 'hasEndedAt' | 'resultClass'>,
    AnnotationStatusInterface {
  finalResultName: string;
  annotatedResultName?: string;
  originalResultName?: string;
  annotatedTimestamp?: string;
  success?: boolean;
}

export const DataGridOperationResultCell = ({
  annotationStatus,
  annotatedResultName,
  originalResultName,
  finalResultName,
  annotatedTimestamp,
  hasEndedAt,
  resultClass,
  success
}: DataGridOperationResultCellProps): JSX.Element => {
  const { formatWithDefaultTimezone } = useFormatTimezone();
  return (
    <div className="data-grid-operation-result-cell" data-testid="data-grid-operation-result-cell">
      <RSTooltip
        title={generateOperationTimestampTooltipTitle(
          annotationStatus,
          annotatedTimestamp && formatWithDefaultTimezone(annotatedTimestamp)
        )}
      >
        <div className="data-grid-operation-result-cell__operation-annotation-icon-container">
          <OperationAnnotationIcon annotationStatus={annotationStatus} />
        </div>
      </RSTooltip>
      <OperationResultChip
        title={
          <OperationResultTooltipTitle
            annotationStatus={annotationStatus}
            originalResult={originalResultName}
            annotatedResult={annotatedResultName}
            success={success}
            hasEndedAt={hasEndedAt}
          />
        }
        label={finalResultName}
        hasEndedAt={hasEndedAt}
        resultClass={resultClass}
        success={success}
      />
    </div>
  );
};
