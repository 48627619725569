import { toNumber } from 'lodash';
import { z } from 'zod';

import { CompanyType } from '../../../../types/company-type';
import { DeviceAllowImport } from '../../../../types/device-allow-import';
import {
  PreprocessArgument,
  preprocessArrayStringInput
} from '../../../../utilities/preprocess-array-string-input/preprocess-array-string-input';
import { CompaniesAdminSortOptions } from '../generate-queries/generate-sort-query';

export const companiesAdminStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(CompaniesAdminSortOptions).optional(),
  // filtering
  name: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  type: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(CompanyType).array().optional()
  ),
  allowImport: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(DeviceAllowImport).array().optional()
  )
});

export type CompaniesAdminSearchParameters = z.infer<typeof companiesAdminStatesSchema>;
