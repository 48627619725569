import { gql } from '../../../../__generated__/gql';

export const MUTATION_MODIFY_DEVICE_GROUP = gql(`
  mutation modifyDeviceGroup($id: String!, $name: String, $serviceProviderId: uuid, $description: String) {
    modifyDeviceGroup(id: $id, name: $name, serviceProviderId: $serviceProviderId, description: $description) {
      id
      deviceGroup {
        id
        name
        description
        updatedAt
        serviceProvider {
          companyId
          company {
            id
            name
          }
        }
      }
    }
  }
`);
