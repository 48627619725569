import { JSX } from 'react';

import { DataTestId } from '../../../types/rs-input';

interface PerformanceMetricBlockPresentationProps extends DataTestId {
  label: string;
  values: JSX.Element;
}

export const PerformanceMetricBlockPresentation = ({
  label,
  values,
  'data-testid': dataTestId
}: PerformanceMetricBlockPresentationProps): JSX.Element => {
  return (
    <div
      className="performance-metric-block-presentation"
      data-testid={dataTestId || 'performance-metric-block-presentation'}
    >
      <h4 className="performance-metric-block-presentation__label">{label}</h4>
      {values}
    </div>
  );
};
