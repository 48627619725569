import { GridColDef } from '@mui/x-data-grid';

import { UserDeviceGroupRow } from './generate-device-group-rows';
import { appConfig } from '../../../../configs/configs';
import i18n from '../../../../i18n';
import { DataGridLink } from '../../../5-elements/data-grid/data-grid-link/data-grid-link';

export const userDeviceGroupDataGridColumns: GridColDef<UserDeviceGroupRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: true,
    flex: 2.4,
    minWidth: 240,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.name'),
    renderCell: ({ id, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/admin/device-groups/${id}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'customer',
    hideable: false,
    sortable: true,
    flex: 2.2,
    minWidth: 220,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.customer')
  },
  {
    field: 'role',
    hideable: false,
    sortable: true,
    flex: 1.7,
    minWidth: 170,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.role')
  },
  {
    field: 'validUntil',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.validUntil')
  },
  {
    field: 'devicesCount',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.devicesCount')
  },
  {
    field: 'usersCount',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.usersCount')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('userAdminDetailsPage.deviceGroupsDataGrid.updatedAt')
  }
];
