import { isNil, toString } from 'lodash';

import i18n from '../../../../../i18n';
import { contextualizeBoolean } from '../../../../../utilities/contextualize-boolean/contextualize-boolean';

export const getImageCollectionStatus = (value?: boolean | null): string =>
  contextualizeBoolean(
    i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.onFailedAcdCycles'),
    i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.off'),
    value
  );

export const getLogCollectionStatus = (
  autoLogCollectionEnabled?: boolean | null,
  autoLogCollectionOnSuccessEnabled?: boolean | null
): string => {
  if (autoLogCollectionEnabled) {
    if (autoLogCollectionOnSuccessEnabled) {
      return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.all');
    }
    return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.onFailedAcdCycles');
  }
  return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.off');
};

export const getNotificationStatus = (
  notificationsEnabled?: boolean | null,
  notificationsOnFailedCycleEnabled?: boolean | null
): string => {
  if (notificationsEnabled) {
    if (notificationsOnFailedCycleEnabled) {
      return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.statesAndCycles');
    }
    return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.states');
  }
  return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.off');
};

export const getFeatureEnabledStatus = (value?: boolean | null): string =>
  contextualizeBoolean(
    i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.enabled'),
    i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.disabled'),
    value
  );

// The server prevents accepting retentionDays = 0, therefore we do not handle `value=0` case here.
export const getRetentionDays = (value?: number | null): string => {
  if (isNil(value)) {
    return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.disabled');
  }

  if (value === 1) {
    return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.day', {
      value: toString(1)
    });
  }

  return i18n.t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.displayValues.days', {
    value: toString(value)
  });
};
