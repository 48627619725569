import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { differenceWith, includes, isEmpty, isEqual, sortBy, uniqBy } from 'lodash';
import { Dispatch, JSX, SetStateAction, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { userDeviceGroupsTableHeaderCells, userDeviceGroupsTableRowCells } from './display-table-configuration';
import {
  ManageUserDeviceGroupsRequest,
  manageUserDeviceGroupsSchema
} from './validation-schema/manage-user-device-groups-schema';
import {
  GetAdminUserDetailsQuery,
  GetAdminUserDeviceGroupsQueryVariables,
  UserDeviceGroupRole
} from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_USER_TO_DEVICE_GROUP_LINK } from '../../../../services/mutations/admin/companies/create-user-to-device-group-link';
import { MUTATION_UNLINK_USER_FROM_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/unlink-user-from-device-group';
import { QUERY_GET_ADMIN_USER_DEVICE_GROUPS } from '../../../../services/queries/admin/users/get-admin-user-device-groups';
import { DeviceGroupUserValidityDuration } from '../../../../types/device-group-user-validity-duration';
import { mapDeviceGroupUserValidityDuration } from '../../../../utilities/map-display-labels/map-device-group-user-validity-duration';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { mapValidityDurationToString } from '../../../../utilities/map-validity-duration-to-string/map-validity-duration-to-string';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { DisplayTable } from '../../../5-elements/display-table/display-table';
import { Loading } from '../../../5-elements/loading/loading';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { UserTimezoneContext } from '../../../contexts/user-timezone-context';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { ErrorPage } from '../../error-page/error-page';

interface ManageUserDeviceGroupsDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenManageUserDeviceGroupsDrawer: Dispatch<SetStateAction<boolean>>;
  defaultValues: ManageUserDeviceGroupsRequest;
  refetchUserDetails: () => Promise<ApolloQueryResult<GetAdminUserDetailsQuery>>;
  customerId?: string;
  serviceProviderId?: string;
  userName: string;
}

export const ManageUserDeviceGroupsDrawer = ({
  setOpenManageUserDeviceGroupsDrawer,
  defaultValues,
  customerId,
  serviceProviderId,
  refetchUserDetails,
  userName,
  ...props
}: ManageUserDeviceGroupsDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [selectedDeviceGroupId, setSelectedDeviceGroupId] = useState<string>('');
  const [selectedDeviceGroupRole, setSelectedDeviceGroupRole] = useState<UserDeviceGroupRole>(
    UserDeviceGroupRole.DeviceObserver
  );
  const [selectedUserValidity, setSelectedUserValidity] = useState<DeviceGroupUserValidityDuration>(
    DeviceGroupUserValidityDuration.SevenDays
  );
  const userDeviceGroupsFilters: GetAdminUserDeviceGroupsQueryVariables['filters'] = customerId
    ? { customerId: { _eq: customerId } }
    : { serviceProviderId: { _eq: serviceProviderId } };
  const {
    loading: loadingDeviceGroups,
    data: dataDeviceGroups,
    error: errorDeviceGroups
  } = useQuery(QUERY_GET_ADMIN_USER_DEVICE_GROUPS, {
    fetchPolicy: 'network-only',
    variables: { filters: userDeviceGroupsFilters },
    skip: props.open === false
  });
  const [createLink, { loading: loadingCreateLink }] = useMutation(MUTATION_CREATE_USER_TO_DEVICE_GROUP_LINK, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [unlink, { loading: loadingUnlink }] = useMutation(MUTATION_UNLINK_USER_FROM_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isDirty }
  } = useForm<ManageUserDeviceGroupsRequest>({
    resolver: zodResolver(manageUserDeviceGroupsSchema),
    defaultValues
  });

  const deviceGroupsResponse = dataDeviceGroups?.deviceGroups || [];
  const deviceGroupOptions: RSSelectItemProps[] = deviceGroupsResponse
    .map((deviceGroup) => ({
      displayName: `${deviceGroup.name} - ${deviceGroup.customer.company.name}`,
      menuItemProps: { value: deviceGroup.id }
    }))
    .filter(
      (item) =>
        !includes(
          watch('deviceGroups').map((item) => item.deviceGroupId),
          item.menuItemProps.value
        )
    );
  const deviceGroupRoleValues = Object.values(UserDeviceGroupRole);
  const deviceGroupRoleSelectOptions: RSSelectItemProps[] = deviceGroupRoleValues.map((item) => ({
    displayName: mapUserDeviceGroupRole(item),
    menuItemProps: { value: item }
  }));
  const userDeviceGroupValidityValues = Object.values(DeviceGroupUserValidityDuration);
  const userDeviceGroupValiditySelectOptions: RSSelectItemProps[] = userDeviceGroupValidityValues.map((item) => ({
    displayName: mapDeviceGroupUserValidityDuration(item),
    menuItemProps: { value: item }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenManageUserDeviceGroupsDrawer(false);
  };

  const handleCancel = (): void => {
    // For other forms we compare the equality in the value of two objects; however, because the difficulty in the
    // comparison of `validUntil` (selection is a date range but request needs a DateTime string), we decided to detect
    // the change of the form, instead of the value comparison.
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenManageUserDeviceGroupsDrawer(false);
    }
  };

  const onSubmit: SubmitHandler<ManageUserDeviceGroupsRequest> = async (data): Promise<void> => {
    // For other forms we compare the equality in the value of two objects; however, because the difficulty in the
    // comparison of `validUntil` (selection is a date range but request needs a DateTime string), we decided to detect
    // the change of the form, instead of the value comparison.
    if (!isDirty) {
      setOpenManageUserDeviceGroupsDrawer(false);
      setShowConfirmationModal(false);
      return;
    }

    const deviceGroupsAdded = differenceWith(data.deviceGroups, defaultValues.deviceGroups, isEqual);
    const deviceGroupsRemoved = differenceWith(defaultValues.deviceGroups, data.deviceGroups, isEqual);
    const failedAddRequests: string[] = [];
    const failedRemoveRequests: string[] = [];

    if (deviceGroupsAdded.length > 0 || deviceGroupsRemoved.length > 0) {
      // NOTE the spreading dots `...` used in the promises to get them running in parallel.
      // we want to continue with executing the queries as many as possible. Even if one fails, the other can continue
      // running
      await Promise.allSettled([
        ...deviceGroupsAdded.map(async (deviceGroupAdded) => {
          await createLink({
            variables: {
              deviceGroupId: deviceGroupAdded.deviceGroupId,
              userId: data.userId,
              userRole: deviceGroupAdded.userRole,
              validUntil: deviceGroupAdded.validUntil
            },
            onCompleted: () => {
              const addedDeviceGroup = dataDeviceGroups?.deviceGroups.find(
                (deviceGroup) => deviceGroup.id === deviceGroupAdded.deviceGroupId
              );
              sendMessageToSnackbar(
                t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupLinked', {
                  deviceGroupName: addedDeviceGroup!.name
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const failedDeviceGroupLink = dataDeviceGroups?.deviceGroups.find(
                (deviceGroup) => deviceGroup.id === deviceGroupAdded.deviceGroupId
              );
              failedAddRequests.push(failedDeviceGroupLink!.id);
              sendMessageToSnackbar(
                t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupLinkFailed', {
                  deviceGroupName: failedDeviceGroupLink!.name
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        }),
        ...deviceGroupsRemoved.map(async (deviceGroupRemoved) => {
          await unlink({
            variables: { deviceGroupId: deviceGroupRemoved.deviceGroupId, userId: data.userId },
            onCompleted: () => {
              const removedDeviceGroup = dataDeviceGroups?.deviceGroups.find(
                (deviceGroup) => deviceGroup.id === deviceGroupRemoved.deviceGroupId
              );
              sendMessageToSnackbar(
                t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupUnlinked', {
                  deviceGroupName: removedDeviceGroup!.name
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const removeFailedDeviceGroup = dataDeviceGroups?.deviceGroups.find(
                (item) => item.id === deviceGroupRemoved.deviceGroupId
              );
              failedRemoveRequests.push(deviceGroupRemoved.deviceGroupId);
              sendMessageToSnackbar(
                t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupUnlinkFailed', {
                  deviceGroupName: removeFailedDeviceGroup!.name
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        })
      ]).finally(() => {
        // 1. Device groups which were added but failed shall be excluded from the list
        // 2. Device groups which were removed but failed shall be kept in the list
        const deviceGroupsListExcludeAddFailed = data.deviceGroups.filter(
          (requestDeviceGroup) => !includes(failedAddRequests, requestDeviceGroup.deviceGroupId)
        );
        const deviceGroupsListRemoveFailed = defaultValues.deviceGroups.filter((defaultDeviceGroup) =>
          includes(failedRemoveRequests, defaultDeviceGroup.deviceGroupId)
        );
        reset({ ...data, deviceGroups: [...deviceGroupsListExcludeAddFailed, ...deviceGroupsListRemoveFailed] });
        refetchUserDetails();
        setShowConfirmationModal(false);
        setOpenManageUserDeviceGroupsDrawer(false);
      });
    }
  };

  // Purpose: Re-set the selected validity and device group role when the drawer is closed.
  useEffect(() => {
    if (!props.open) {
      setSelectedUserValidity(DeviceGroupUserValidityDuration.SevenDays);
      setSelectedDeviceGroupRole(UserDeviceGroupRole.DeviceObserver);
    }
  }, [props.open]);

  if (errorDeviceGroups) {
    return (
      <RSDrawer {...props} className="manage-user-device-groups-drawer">
        <ModalDrawerHeader title={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.title', { userName })} />
        <div className="manage-user-device-groups-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorDeviceGroups.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingDeviceGroups) {
    return (
      <RSDrawer {...props} className="manage-user-device-groups-drawer">
        <ModalDrawerHeader title={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.title', { userName })} />
        <div className="manage-user-device-groups-drawer__form">
          <div className="manage-user-device-groups-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="manage-user-device-groups-drawer">
      <ModalDrawerHeader title={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.title', { userName })} />
      {dataDeviceGroups && (
        <>
          <form
            className="manage-user-device-groups-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="manage-user-device-groups-drawer-form"
          >
            <div className="manage-user-device-groups-drawer__form-contents">
              <div className="manage-user-device-groups-drawer__link-select">
                <h3>{t('deviceGroupDetailsPage.manageUserToDeviceGroupLinkDrawer.linkUser')}</h3>
                <div className="manage-user-device-groups-drawer__link-input">
                  <RSSelect
                    inputLabel={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.name')}
                    inputLabelTooltip={t('tooltips.deviceGroup.deviceGroupName')}
                    formControlProps={{ className: 'manage-user-device-groups-drawer__name-select' }}
                    menuItems={deviceGroupOptions}
                    onChange={(event) => setSelectedDeviceGroupId(event.target.value as string)}
                    value={selectedDeviceGroupId}
                    disabled={isEmpty(deviceGroupOptions)}
                    data-testid="manage-user-device-groups-drawer-name-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <RSSelect
                    inputLabel={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupRole')}
                    inputLabelTooltip={t('tooltips.deviceGroup.deviceGroupRole')}
                    menuItems={deviceGroupRoleSelectOptions}
                    formControlProps={{ className: 'manage-user-device-groups-drawer__device-group-role-select' }}
                    onChange={(event) => setSelectedDeviceGroupRole(event.target.value as UserDeviceGroupRole)}
                    value={selectedDeviceGroupRole}
                    data-testid="manage-user-device-groups-drawer-device-group-role-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <RSSelect
                    inputLabel={t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.validFor')}
                    inputLabelTooltip={t('tooltips.deviceGroup.validFor')}
                    menuItems={userDeviceGroupValiditySelectOptions}
                    formControlProps={{
                      className: 'manage-user-device-groups-drawer__valid-for-select'
                    }}
                    onChange={(event) => setSelectedUserValidity(event.target.value as DeviceGroupUserValidityDuration)}
                    value={selectedUserValidity}
                    data-testid="manage-user-device-groups-drawer-valid-for-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <div className="manage-user-device-groups-drawer__link-button-wrapper">
                    <RSButton
                      color="success"
                      variant="contained"
                      onClick={() => {
                        if (selectedDeviceGroupId !== '') {
                          setValue(
                            'deviceGroups',
                            uniqBy(
                              [
                                ...getValues('deviceGroups'),
                                {
                                  deviceGroupId: selectedDeviceGroupId,
                                  userRole: selectedDeviceGroupRole,
                                  validUntil: mapValidityDurationToString(selectedUserValidity, userTimezone)
                                }
                              ],
                              (item) => item.deviceGroupId
                            ),
                            { shouldDirty: true }
                          );
                          setSelectedDeviceGroupId('');
                        }
                      }}
                      disabled={selectedDeviceGroupId === ''}
                      data-testid="manage-user-device-groups-link-link-button"
                    >
                      {t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.link')}
                    </RSButton>
                  </div>
                </div>
              </div>
              <div className="manage-user-device-groups-drawer__device-groups-overview">
                <h3>{t('userAdminDetailsPage.manageUserDeviceGroupsDrawer.deviceGroupOverview')}</h3>
                <DisplayTable
                  className="manage-user-device-groups-drawer__device-groups-overview-table"
                  columnHeader={{ headerCells: userDeviceGroupsTableHeaderCells }}
                  data-testid="manage-user-device-groups-drawer-device-groups-overview-table"
                  rows={sortBy(
                    watch('deviceGroups').map((deviceGroup) => {
                      const matchedDataDeviceGroup = dataDeviceGroups.deviceGroups.find(
                        (dataDeviceGroup) => dataDeviceGroup.id === deviceGroup.deviceGroupId
                      );
                      return {
                        key: deviceGroup.deviceGroupId,
                        cells: userDeviceGroupsTableRowCells(
                          deviceGroup.deviceGroupId,
                          matchedDataDeviceGroup!.name,
                          matchedDataDeviceGroup!.customer.company.name,
                          deviceGroup.userRole,
                          () =>
                            setValue(
                              'deviceGroups',
                              getValues('deviceGroups').filter(
                                (item) => item.deviceGroupId !== deviceGroup.deviceGroupId
                              ),
                              { shouldDirty: true }
                            ),
                          // Have to parse the function in this way to make sure the number of hooks keep the same
                          // during different rendering
                          formatWithDefaultTimezone,
                          deviceGroup.validUntil
                        )
                      };
                    }),
                    // The first item in the cell is the name of the user, and we sort by it
                    (row) => row.cells[0].children
                  )}
                />
              </div>
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loadingCreateLink || loadingUnlink}
              isCancelDisabled={loadingCreateLink || loadingUnlink}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loadingCreateLink || loadingUnlink}
            disableCancelButton={loadingCreateLink || loadingUnlink}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
        </>
      )}
    </RSDrawer>
  );
};
