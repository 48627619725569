import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CUSTOMER_DETAILS = gql(`
  query getCustomerDetails($companyId: uuid!) {
    customerByPK(companyId: $companyId) {
      companyId
      company {
        id
        name
        createdAt
        updatedAt
      }
      documentCenterUrl
      customerCurrentPerformance {
        customerId
        fullyCompletedLastPeriod
        partiallyCompletedLastPeriod
        unexpectedBehaviorLastPeriod
        fullyCompletedPrevPeriod
        partiallyCompletedPrevPeriod
        unexpectedBehaviorPrevPeriod
        totalLastPeriod
        totalPrevPeriod
      }
      programs(orderBy: [{ name: ASC }]) {
        id
        name
        devicesAggregate {
          aggregate {
            count
          }
        }
        programCurrentPerformance {
          programId
          fullyCompletedLastPeriod
          partiallyCompletedLastPeriod
          unexpectedBehaviorLastPeriod
          fullyCompletedPrevPeriod
          partiallyCompletedPrevPeriod
          unexpectedBehaviorPrevPeriod
          totalLastPeriod
          totalPrevPeriod
        }
      }
      devicesAggregate(where: { deactivated: { _eq: false } }) {
        aggregate {
          count
        }
      }
      sitesAggregate {
        aggregate {
          count
        }
      }
      sites {
        id
        name
        location {
          id
          formattedAddress
          latitude
          longitude
        }
        devices(distinctOn: programId) {
          program {
            id
            name
          }
        }
        devicesAggregate(where: { deactivated: { _eq: false } }) {
          aggregate {
            count
          }
        }
        siteCurrentPerformance {
          siteId
          fullyCompletedLastPeriod
          partiallyCompletedLastPeriod
          unexpectedBehaviorLastPeriod
          fullyCompletedPrevPeriod
          partiallyCompletedPrevPeriod
          unexpectedBehaviorPrevPeriod
          totalLastPeriod
          totalPrevPeriod
        }
      }
    }
  }
`);
