import { useAuth0 } from '@auth0/auth0-react';
import { JSX, PropsWithChildren } from 'react';

import { AuthenticationLoadingErrorContainer } from '../../4-features/authentication-loading-error-container/authentication-loading-error-container';
import { LoginRedirect } from '../../4-features/login-redirect/login-redirect';

interface AuthenticatedOnlyWrapperProps extends PropsWithChildren {
  loginRedirectUri: string;
}

export const AuthenticatedOnlyWrapper = ({
  children,
  loginRedirectUri
}: AuthenticatedOnlyWrapperProps): JSX.Element => {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error || isLoading || isAuthenticated) {
    return (
      <AuthenticationLoadingErrorContainer isLoading={isLoading} error={error}>
        {children}
      </AuthenticationLoadingErrorContainer>
    );
  }
  return <LoginRedirect redirectUri={loginRedirectUri} />;
};
