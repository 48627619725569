import { gql } from '../../../../__generated__/gql';

export const MUTATION_MODIFY_SITE = gql(`
  mutation modifySite(
    $id: String!
    $name: String!
    $description: String
    $timezone: String
    $location: LocationInput!
  ) {
    modifySite(id: $id, name: $name, description: $description, timezone: $timezone, location: $location) {
      id
      site {
        id
        name
        description
        updatedAt
        timezone {
          geoName
          name
        }
        location {
          id
          latitude
          longitude
          formattedAddress
        }
      }
    }
  }
`);
