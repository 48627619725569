import { Skeleton } from '@mui/material';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { RSPagination, RSPaginationProps } from '../../5-elements/rs-pagination/rs-pagination';

interface PaginationBarProps extends RSPaginationProps {
  isLoading: boolean;
  startRow: number;
  endRow: number;
  pageCount: number;
  rowCount?: number;
}

export const PaginationBar = ({
  isLoading,
  startRow,
  endRow,
  pageCount,
  rowCount,
  ...props
}: PaginationBarProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="pagination-bar" data-testid="pagination-bar">
      {isLoading ? (
        <Skeleton variant="text" className="pagination-bar__loading" data-testid="pagination-bar-loading" />
      ) : (
        <p className="pagination-bar__displayed-rows">
          {t('paginationBar.rowsCountsAndRowsTotal', {
            startRow: startRow,
            endRow: endRow,
            rowCount: rowCount
          })}
        </p>
      )}
      <RSPagination {...props} count={pageCount} />
    </div>
  );
};
