import { ApolloQueryResult, useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationChangeHistory } from './annotation-change-history/annotation-change-history';
import { MainOperation } from './main-operation/main-operation';
import { OperationLogModal } from './operation-log-modal/operation-log-modal';
import { SubOperation } from './sub-operation/sub-operation';
import { GetAllDeviceOperationsPerformanceQuery, GetDeviceDetailsQuery } from '../../../__generated__/graphql';
import LogIcon from '../../../assets/icons/log.svg?react';
import { appConfig } from '../../../configs/configs';
import { DEFAULT_REFRESH_INTERVAL } from '../../../constants/constants';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { QUERY_GET_OPERATION_DETAILS } from '../../../services/queries/operations/get-operation-details';
import {
  generateOperationNumber,
  generateSequenceNumberString
} from '../../../utilities/generate-operation-number/generate-operation-number';
import { ErrorPage } from '../../1-pages/error-page/error-page';
import { CustomerSiteProgramSubtitle } from '../../4-features/customer-site-program-subtitle/customer-site-program-subtitle';
import { ModalDrawerHeader } from '../../4-features/modal-drawer-header/modal-drawer-header';
import { RSLinkTimezone } from '../../4-features/rs-link-timezone/rs-link-timezone';
import { Loading } from '../../5-elements/loading/loading';
import { RSActionButton } from '../../5-elements/rs-action-button/rs-action-button';
import { AutoRefreshContext } from '../../contexts/auto-refresh-context';
import { RSDrawer, RSDrawerProps } from '../rs-drawer/rs-drawer';

interface OperationDetailsProps extends RSDrawerProps {
  operationId?: string;
  handleOperationDetailsClose: () => void;
  source: 'device-details' | 'operation-overview';
  refetch: () =>
    | Promise<ApolloQueryResult<GetAllDeviceOperationsPerformanceQuery>>
    | Promise<ApolloQueryResult<GetDeviceDetailsQuery>>;
}

export const OperationDetails = ({
  operationId,
  handleOperationDetailsClose,
  source,
  refetch,
  ...props
}: OperationDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const { autoRefresh } = useContext(AutoRefreshContext);
  const [openAnnotationHistory, setOpenAnnotationHistory] = useState<boolean>(false);
  const [openMainOperationLog, setOpenMainOperationLog] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { loading, data, error, startPolling, stopPolling } = useQuery(QUERY_GET_OPERATION_DETAILS, {
    variables: { operationId: operationId || '' },
    skip: !operationId || !props.open
  });
  const deviceId = data?.deviceOperationByPK?.device.id;
  const operationName = data?.deviceOperationByPK?.operationName;
  const { result: userCanEditOperation, loading: loadingUserCanEditOperation } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: {
      type: 'DeviceOperation',
      deviceId: deviceId || '',
      operationName: operationName || ''
    },
    skip: !deviceId || !operationName || !props.open
  });

  if (autoRefresh) {
    startPolling(DEFAULT_REFRESH_INTERVAL);
  } else {
    stopPolling();
  }

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');
    return (
      <RSDrawer {...props} className="operation-details" onClose={handleOperationDetailsClose}>
        <ModalDrawerHeader handleClose={handleOperationDetailsClose} />
        {isUUIDError ? (
          <ErrorPage
            titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
            title={t('operationsPage.operationDetails.notFound.errorTitle')}
            message={t('pageNotFoundErrorPage.notFoundInfo')}
            hideButtonLink={true}
          />
        ) : (
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={error.message}
            hideButtonLink={true}
          />
        )}
      </RSDrawer>
    );
  }

  const isLoading = loading || loadingUserCanEditOperation;

  if (isLoading) {
    return (
      <RSDrawer {...props} className="operation-details" onClose={handleOperationDetailsClose}>
        <ModalDrawerHeader
          title={<Skeleton variant="text" className="operation-details__serial-number-loading" />}
          subtitle={<Skeleton variant="text" className="operation-details__subtitle-loading" />}
          handleClose={handleOperationDetailsClose}
          iconButtonProps={{ disabled: editMode }}
        />
        <div className="operation-details__loading">
          <Loading />
        </div>
      </RSDrawer>
    );
  }

  const deviceOperation = data?.deviceOperationByPK;

  // If not loading, no error, no data: data cannot be found (valid uuid, but the item does not exist), return ErrorPage
  if (!deviceOperation) {
    return (
      <RSDrawer {...props} className="operation-details" onClose={handleOperationDetailsClose}>
        <ModalDrawerHeader handleClose={handleOperationDetailsClose} />
        {props.open && (
          <ErrorPage
            titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
            title={t('operationsPage.operationDetails.notFound.errorTitle')}
            message={t('pageNotFoundErrorPage.notFoundInfo')}
            hideButtonLink={true}
          />
        )}
      </RSDrawer>
    );
  }

  const logAvailable = deviceOperation.deviceOperationLog;
  const sequenceNumberURL =
    source === 'operation-overview'
      ? `${appConfig.basePath}/operations?operationId=${deviceOperation.id}`
      : `${appConfig.basePath}/devices/${deviceOperation.device.id}?operationId=${deviceOperation.id}`;

  return (
    <RSDrawer {...props} className="operation-details" onClose={editMode ? undefined : handleOperationDetailsClose}>
      <ModalDrawerHeader
        title={
          <>
            <RSLinkTimezone
              data-testid="operation-details-device-link"
              to={`${appConfig.basePath}/devices/${deviceOperation.device.id}`}
              className="operation-details__title-link"
            >
              {/* If there is no serialNumber, show "ROC" so that user has a string to click */}
              {deviceOperation.device.serialNumber ||
                t('operationsPage.operationDetails.titles.serialNumberPlaceholder')}
            </RSLinkTimezone>
            {' - '}
            <RSLinkTimezone
              to={sequenceNumberURL}
              className="operation-details__title-link"
              data-testid="operation-details-sequence-number-link"
            >
              {generateSequenceNumberString(deviceOperation.sequence)}
            </RSLinkTimezone>
          </>
        }
        subtitle={
          <CustomerSiteProgramSubtitle
            customerName={deviceOperation.device.customer.company.name}
            siteName={deviceOperation.device.site.name}
            programNumber={deviceOperation.device.program?.name}
            customerId={deviceOperation.device.customer.companyId}
          />
        }
        actions={
          logAvailable && (
            <RSActionButton
              text={t('operationsPage.operationDetails.actions.log')}
              startIcon={<LogIcon />}
              data-testid="operation-details-header-log"
              onClick={() => setOpenMainOperationLog(true)}
            />
          )
        }
        handleClose={handleOperationDetailsClose}
        iconButtonProps={{ disabled: editMode }}
      />

      <div className="operation-details__contents" data-testid="operation-details-contents">
        <MainOperation
          deviceOperation={deviceOperation}
          handleAnnotationChangeLinkClick={() => setOpenAnnotationHistory(true)}
          canUserEditOperation={userCanEditOperation}
          editMode={editMode}
          setEditMode={setEditMode}
          refetch={refetch}
        />
        {deviceOperation.deviceOperations &&
          deviceOperation.deviceOperations.map((subOperation) => (
            <SubOperation
              key={subOperation.id}
              mainOperationId={deviceOperation.id}
              subOperation={subOperation}
              deviceId={deviceOperation.device.id}
              serialNumber={generateOperationNumber(
                deviceOperation.device.serialNumber || '',
                deviceOperation.sequence || 0
              )}
            />
          ))}
        <AnnotationChangeHistory
          open={openAnnotationHistory}
          onClose={() => setOpenAnnotationHistory(false)}
          annotationAuditHistory={deviceOperation.deviceOperationAnnotationAudits}
          originalResult={deviceOperation.deviceOperationResult}
          originalOperationalLifeCycle={deviceOperation.initialOperationalLifeCycle}
          serialNumber={deviceOperation.device.serialNumber || ''}
          deviceOperationStartAt={deviceOperation.startAt}
        />
        {deviceOperation.deviceOperationLog && (
          <OperationLogModal
            open={openMainOperationLog}
            logTitle={generateOperationNumber(deviceOperation.device.serialNumber || '', deviceOperation.sequence || 0)}
            operationId={deviceOperation.id}
            deviceId={deviceOperation.device.id}
            setOpenOperationLog={setOpenMainOperationLog}
            log={deviceOperation.deviceOperationLog}
          />
        )}
      </div>
    </RSDrawer>
  );
};
