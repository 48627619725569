import { DeviceOrderBy, OrderBy } from '../../../../__generated__/graphql';
import { DEFAULT_GET_ADMIN_DEVICES_SORT_BY } from '../../../../constants/constants';

export enum DevicesAdminSortOptions {
  SerialNumberAsc = 'serialNumber',
  SerialNumberDesc = '-serialNumber',
  CompanyAsc = 'company',
  CompanyDesc = '-company',
  SiteAsc = 'site',
  SiteDesc = '-site',
  ProgramAsc = 'program',
  ProgramDesc = '-program',
  TypeAsc = 'type',
  TypeDesc = '-type',
  ConnectorHolderTypeAsc = 'connectorHolderType',
  ConnectorHolderTypeDesc = '-connectorHolderType',
  CreatedAtAsc = 'createdAt',
  CreatedAtDesc = '-createdAt',
  UpdatedAtAsc = 'updatedAt',
  UpdatedAtDesc = '-updatedAt'
}

export const generateSortQuery = (sortOption?: DevicesAdminSortOptions): DeviceOrderBy[] => {
  switch (sortOption) {
    case DevicesAdminSortOptions.TypeAsc:
      return [{ deviceType: { modelNumber: OrderBy.Asc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.TypeDesc:
      return [{ deviceType: { modelNumber: OrderBy.Desc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.CompanyAsc:
      // First `true`, then `false`, finally `null`
      return [{ customer: { company: { name: OrderBy.Asc } } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.CompanyDesc:
      // First `null`, then `false`, finally `true`
      return [{ customer: { company: { name: OrderBy.Desc } } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.CreatedAtAsc:
      return [{ createdAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.CreatedAtDesc:
      return [{ createdAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.SiteAsc:
      return [{ site: { name: OrderBy.Asc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.SiteDesc:
      return [{ site: { name: OrderBy.Desc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.ProgramAsc:
      return [{ program: { name: OrderBy.AscNullsLast } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.ProgramDesc:
      return [{ program: { name: OrderBy.DescNullsFirst } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.UpdatedAtAsc:
      return [{ updatedAt: OrderBy.Asc }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.UpdatedAtDesc:
      return [{ updatedAt: OrderBy.Desc }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.SerialNumberDesc:
      return [{ serialNumber: OrderBy.Desc }];
    case DevicesAdminSortOptions.ConnectorHolderTypeAsc:
      return [{ connectorHolderType: { name: OrderBy.Asc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.ConnectorHolderTypeDesc:
      return [{ connectorHolderType: { name: OrderBy.Desc } }, ...DEFAULT_GET_ADMIN_DEVICES_SORT_BY];
    case DevicesAdminSortOptions.SerialNumberAsc:
    default:
      return DEFAULT_GET_ADMIN_DEVICES_SORT_BY;
  }
};
