import { JSX } from 'react';

import { DataTestId } from '../../../types/rs-input';

interface CountBlockProps extends DataTestId {
  title: string;
  count: number;
}

export const CountBlock = ({ title, count, 'data-testid': dataTestId }: CountBlockProps): JSX.Element => {
  return (
    <div className="count-block" data-testid={dataTestId}>
      <h4 className="count-block__title">{title}</h4>
      <p className="count-block__count-amount">{count}</p>
    </div>
  );
};
