import { Dispatch, SetStateAction, createContext } from 'react';

import { browserTimezone } from '../../utilities/get-browser-timezone/get-browser-timezone';

export interface UserTimezoneContextInterface {
  userTimezone: string;
  setUserTimezone: Dispatch<SetStateAction<string>>;
}

export const UserTimezoneContext = createContext<UserTimezoneContextInterface>({
  userTimezone: browserTimezone,
  setUserTimezone: () => {}
});
