import { useMutation, useQuery } from '@apollo/client';
import { GridEventListener, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { devicesAdminDataGridColumns } from './data-grid-configurations/devices-admin-data-grid-columns';
import { DevicesAdminRow, generateDevicesAdminRows } from './data-grid-configurations/generate-devices-admin-rows';
import { DevicesAdminFilterPanel } from './devices-admin-filter-panel/devices-admin-filter-panel';
import {
  DevicesAdminSearchParameters,
  devicesAdminStatesSchema
} from './devices-admin-states-schema/devices-admin-states-schema';
import { devicesAdminFilterFields, generateFilterQuery } from './generate-queries/generate-filter-query';
import { generateSortQuery } from './generate-queries/generate-sort-query';
import { ImportDeviceDrawer } from './import-device-drawer/import-device-drawer';
import { DeviceBoolExp, DeviceOrderBy } from '../../../__generated__/graphql';
import ReloadIcon from '../../../assets/icons/reload.svg?react';
import { appConfig, graphqlApiConfig } from '../../../configs/configs';
import { DEFAULT_GET_ADMIN_DEVICES_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants/constants';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { MUTATION_REQUEST_SYNC_DEVICES } from '../../../services/mutations/admin/devices/request-sync-devices';
import { QUERY_GET_ADMIN_DEVICES } from '../../../services/queries/admin/devices/get-admin-devices';
import { QUERY_GET_CONNECTOR_HOLDER_TYPES } from '../../../services/queries/commons/get-connector-holder-types';
import { QUERY_GET_CUSTOMER_NAMES } from '../../../services/queries/commons/get-customer-names';
import { QUERY_GET_DEVICE_TYPES } from '../../../services/queries/commons/get-device-types';
import { QUERY_GET_PROGRAM_NAMES } from '../../../services/queries/commons/get-program-names';
import { QUERY_GET_SERIAL_NUMBERS } from '../../../services/queries/commons/get-serial-numbers';
import { QUERY_GET_SITE_NAMES } from '../../../services/queries/commons/get-site-names';
import { calculatePaginationEndRow } from '../../../utilities/data-grid/calculate-pagination-end-row/calculate-pagination-end-row';
import { calculatePaginationPageCount } from '../../../utilities/data-grid/calculate-pagination-page-count/calculate-pagination-page-count';
import { calculatePaginationStartRow } from '../../../utilities/data-grid/calculate-pagination-start-row/calculate-pagination-start-row';
import { filterValidUrlFields } from '../../../utilities/filter-valid-url-fields/filter-valid-url-fields';
import { AdminOverviewMainSectionWrapper } from '../../2-templates/main-sections/admin-overview-main-section-wrapper/admin-overview-main-section-wrapper';
import { AdminAddActionButton } from '../../4-features/admin/admin-add-action-button/admin-add-action-button';
import { AdminPageTitle } from '../../4-features/admin/admin-page-title/admin-page-title';
import { PaginationBar } from '../../4-features/pagination-bar/pagination-bar';
import { RSDataGrid } from '../../4-features/rs-data-grid/rs-data-grid';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { RSTooltip } from '../../5-elements/rs-tooltip/rs-tooltip';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';
import { useEnqueueSnackbar } from '../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../error-page/error-page';

export const DevicesAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openImportDevice, setOpenImportDevice] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const [sortingOptions, setSortingOptions] = useState<DeviceOrderBy[]>(DEFAULT_GET_ADMIN_DEVICES_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<DeviceBoolExp>({});
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_DEVICES, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    }
  });
  const {
    loading: loadingSerialNumbers,
    data: dataSerialNumbers,
    error: errorSerialNumbers
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_CUSTOMER_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingSiteNames,
    data: dataSiteNames,
    error: errorSiteNames
  } = useQuery(QUERY_GET_SITE_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingProgramNames,
    data: dataProgramNames,
    error: errorProgramNames
  } = useQuery(QUERY_GET_PROGRAM_NAMES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingRocTypeNames,
    data: dataRocTypeNames,
    error: errorRocTypeNames
  } = useQuery(QUERY_GET_DEVICE_TYPES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingConnectorHolderTypeNames,
    data: dataConnectorHolderTypeNames,
    error: errorConnectorHolderTypeNames
  } = useQuery(QUERY_GET_CONNECTOR_HOLDER_TYPES, { fetchPolicy: 'network-only' });
  const [requestSyncDevices, { loading: loadingRequestSyncDevices }] = useMutation(MUTATION_REQUEST_SYNC_DEVICES, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    variables: { serialNumbers: [] },
    onError: (error) => {
      if (error.message.includes('in progress')) {
        sendMessageToSnackbar(
          t('devicesAdminPage.syncPrograms.snackbarTitle'),
          undefined,
          t('devicesAdminPage.syncPrograms.syncAlreadyInProgress'),
          'warning'
        );
      } else {
        sendMessageToSnackbar(
          t('devicesAdminPage.syncPrograms.error'),
          undefined,
          error.message || error.name,
          'error'
        );
      }
    },
    onCompleted: (data) => {
      const status = data.syncDevices?.status;
      if (status === 'IN_PROGRESS') {
        sendMessageToSnackbar(
          t('devicesAdminPage.syncPrograms.snackbarTitle'),
          '',
          t('devicesAdminPage.syncPrograms.syncInProgress')
        );
      } else {
        sendMessageToSnackbar(
          t('devicesAdminPage.syncPrograms.error'),
          undefined,
          t('devicesAdminPage.syncPrograms.unknownStatus', { status }),
          'error'
        );
      }
    }
  });
  const { result: canUserCreateRoc, loading: loadingCanUserCreateRoc } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: {
      type: 'Device',
      deviceId: ''
    },
    skip: false
  });
  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<DevicesAdminSearchParameters>(searchParameters, devicesAdminStatesSchema);

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DevicesAdminRow>): void => {
    navigate(`${appConfig.basePath}/admin/devices/${params.id}`);
  };

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, devicesAdminFilterFields);
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }
  }, [routerLocation.search]);

  const groupedError =
    error ||
    errorSerialNumbers ||
    errorCustomerNames ||
    errorSiteNames ||
    errorProgramNames ||
    errorRocTypeNames ||
    errorConnectorHolderTypeNames;
  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const devicesCount = data?.devicesAggregate.aggregate?.count;
  const isLoading =
    loading ||
    loadingSerialNumbers ||
    loadingCustomerNames ||
    loadingSiteNames ||
    loadingProgramNames ||
    loadingRocTypeNames ||
    loadingConnectorHolderTypeNames;

  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName={t('devicesAdminPage.title')}
          actions={
            <>
              {/* shortcut: we assume that users that can create rocs , can also sync them - which is not true (you need to be superuser) */}
              <RSTooltip title={t('tooltips.device.syncPrograms')} useHtml={true}>
                <div>
                  <RSButton
                    extraClassNames={['devices-admin-page__sync-program']}
                    disabled={!canUserCreateRoc || loadingCanUserCreateRoc || loadingRequestSyncDevices}
                    data-testid="devices-admin-page-sync-programs"
                    color="success"
                    onClick={(event) => {
                      event.preventDefault();
                      requestSyncDevices();
                    }}
                  >
                    <ReloadIcon />
                    {t('devicesAdminPage.syncPrograms.button')}
                  </RSButton>
                </div>
              </RSTooltip>
              <AdminAddActionButton
                titleName={t('devicesAdminPage.importDevice')}
                onClick={() => setOpenImportDevice(true)}
                disabled={!canUserCreateRoc || loadingCanUserCreateRoc}
                data-testid="devices-admin-page-add-device"
              />
            </>
          }
        />
      }
      filter={
        <DevicesAdminFilterPanel
          isLoading={isLoading}
          serialNumbers={dataSerialNumbers}
          customerNames={dataCustomerNames}
          siteNames={dataSiteNames}
          programNames={dataProgramNames}
          rocTypeNames={dataRocTypeNames}
          connectionHolderTypeNames={dataConnectorHolderTypeNames}
          defaultValues={{
            serialNumber: validUrlFields.serialNumber,
            company: validUrlFields.company,
            site: validUrlFields.site,
            program: validUrlFields.program,
            type: validUrlFields.type,
            connectorHolderType: validUrlFields.connectorHolderType,
            deactivated: validUrlFields.deactivated
          }}
        />
      }
      data-testid="devices-admin-page-wrapper"
    >
      <section className="devices-admin-page" data-testid="devices-admin-page">
        <div className="devices-admin-page__data-grid-container">
          <RSDataGrid
            columns={devicesAdminDataGridColumns}
            rows={generateDevicesAdminRows(data?.devices, userTimezone)}
            loading={isLoading}
            onRowClick={handleRowClick}
            data-testid="devices-admin-page-data-grid"
          />
        </div>
        <div className="devices-admin-page__pagination">
          <PaginationBar
            isLoading={isLoading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(paginationModel.page, DEFAULT_PAGE_SIZE, devicesCount)}
            rowCount={devicesCount}
            pageCount={calculatePaginationPageCount(devicesCount)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
      <ImportDeviceDrawer open={openImportDevice} setOpenImportDevice={setOpenImportDevice} refetchDevices={refetch} />
    </AdminOverviewMainSectionWrapper>
  );
};
