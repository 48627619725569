import { CustomerPrograms } from '../../../../../types/customer-programs';
import { FlattenFunction } from '../../../../../types/flatten';
import { getPerformanceMetricsValues } from '../../../../../utilities/get-performance-metrics-values/get-performance-metrics-values';

export type CustomerProgramRow = FlattenFunction<typeof generateCustomerProgramRows>;

export const generateCustomerProgramRows = (programs?: CustomerPrograms) => {
  if (!programs) {
    return [];
  }

  return programs.map((program) => ({
    id: program.id,
    name: program.name,
    performance: getPerformanceMetricsValues(
      program.programCurrentPerformance?.fullyCompletedLastPeriod,
      program.programCurrentPerformance?.partiallyCompletedLastPeriod,
      program.programCurrentPerformance?.unexpectedBehaviorLastPeriod,
      program.programCurrentPerformance?.fullyCompletedPrevPeriod,
      program.programCurrentPerformance?.partiallyCompletedPrevPeriod,
      program.programCurrentPerformance?.unexpectedBehaviorLastPeriod
    ),
    rocs: program.devicesAggregate.aggregate?.count || 0
  }));
};
