import { gql } from '../../../../__generated__/gql';

export const MUTATION_MODIFY_CUSTOMER = gql(`
  mutation modifyCustomer(
    $id: String!
    $name: String!
    $allowImport: Boolean!
    $maxDeviceGroupRole: UserDeviceGroupUserRole!
    $rocsysApiIpAcceptlist: String
    $documentCenterUrl: String
  ) {
    modifyCustomer(
      id: $id
      name: $name
      allowImport: $allowImport
      maxDeviceGroupRole: $maxDeviceGroupRole
      companySettings: { rocsysApiIpAcceptlist: $rocsysApiIpAcceptlist }
      documentCenterUrl: $documentCenterUrl
    ) {
      id
      customer {
        companyId
        allowImport
        documentCenterUrl
        updatedAt
        company {
          name
          id
          maxDeviceGroupRole
          companySettings {
            id
            rocsysApiIpAcceptlist
          }
        }
      }
    }
  }
`);
