import { isEmpty } from 'lodash';

import { GetAdminDeviceGroupDetailsQuery, UserDeviceGroupRole } from '../../../../__generated__/graphql';
import { CompanyType } from '../../../../types/company-type';
import { Flatten } from '../../../../types/flatten';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';

export const generateUserRows = (
  users: NonNullable<GetAdminDeviceGroupDetailsQuery['deviceGroupByPK']>['userDeviceGroups'],
  userTimezone?: string
) => {
  if (isEmpty(users)) {
    return [];
  }

  return users.map((user) => ({
    id: user.user.id,
    name: `${user.user.firstName} ${user.user.lastName}`,
    companyName: user.user.company.name,
    companyType: mapCompanyType(user.user.company.companyType as CompanyType),
    deviceGroupRole: mapUserDeviceGroupRole(user.userRole as UserDeviceGroupRole),
    validUntil: user.validUntil
      ? formatTimestamp(user.validUntil, userTimezone)
      : getNonNullishDisplayValue(user.validUntil),
    createdAt: formatTimestamp(user.createdAt, userTimezone),
    updatedAt: formatTimestamp(user.updatedAt, userTimezone)
  }));
};

export type DeviceGroupUserRow = Flatten<ReturnType<typeof generateUserRows>>;
