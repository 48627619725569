import { GetOperationResultsQuery } from '../../../../__generated__/graphql';
import { RSAutocompleteValue } from '../../../../types/rs-autocomplete';
import { OperationResultOption } from '../operation-result-option/operation-result-option';

// A higher-order function, which returns a function to generate RSAutocomplete options.
export const getOperationResultDisplayOption = (operationResults?: GetOperationResultsQuery, useId?: boolean) => {
  const operationResultDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    const resultObject = useId
      ? operationResults?.deviceOperationResults.find((item) => item.id === option)
      : operationResults?.deviceOperationResults.find((item) => item.code === option);
    return <OperationResultOption resultObject={resultObject} />;
  };

  return operationResultDisplayOption;
};
