import { Chip, ChipProps } from '@mui/material';
import { JSX } from 'react';

import CrossIcon from '../../../assets/icons/cross.svg?react';

type RSFilterChipProps = ChipProps;

export const RSFilterChip = (props: RSFilterChipProps): JSX.Element => {
  return (
    <Chip
      {...props}
      variant="outlined"
      className="rs-filter-chip"
      deleteIcon={
        <div className="rs-filter-chip__delete-icon-wrapper">
          <CrossIcon data-testid="rs-filter-chip-delete-button" />
        </div>
      }
    />
  );
};
