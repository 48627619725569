import { startsWith } from 'lodash';
import { JSX, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { PortalHeader } from '../../3-sections/portal-header/portal-header';
import { PortalMain } from '../../3-sections/portal-main/portal-main';
import { AdminMainNavMenu } from '../../4-features/main-nav-menu/admin-main-nav-menu';
import { OperationMainNavMenu } from '../../4-features/main-nav-menu/operation-main-nav-menu';
import { PortalGrid } from '../portal-grid/portal-grid';

type HeaderNavigationWrapperProps = PropsWithChildren;

export const HeaderNavigationWrapper = (props: HeaderNavigationWrapperProps): JSX.Element => {
  const routerLocation = useLocation();
  return (
    <PortalGrid>
      <PortalHeader />
      <PortalMain>
        {startsWith(routerLocation.pathname, '/portal/admin') ? <AdminMainNavMenu /> : <OperationMainNavMenu />}
        {props.children}
      </PortalMain>
    </PortalGrid>
  );
};
