import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../../../../assets/icons/info.svg?react';
import { ExternalLink } from '../../../../5-elements/external-link/external-link';
import { RSTooltip } from '../../../../5-elements/rs-tooltip/rs-tooltip';

interface CustomerExternalLinksProps {
  documentCenterUrl?: string | null;
  customerName?: string;
}

export const CustomerExternalLinks = ({ documentCenterUrl, customerName }: CustomerExternalLinksProps): JSX.Element => {
  const { t } = useTranslation();
  if (!documentCenterUrl) {
    return <></>;
  }

  return (
    <div className="customer-external-links" data-testid="customer-external-links">
      <h3 className="customer-external-links__title">{t('customerDetailsPage.sidePanel.externalLinks.title')}</h3>
      <div className="customer-external-links__links">
        <ExternalLink url={documentCenterUrl}>
          {t('customerDetailsPage.sidePanel.externalLinks.documentCenter', { customerName })}
          <RSTooltip title={t('customerDetailsPage.sidePanel.externalLinks.documentCenterTooltip')}>
            <div className="customer-external-links__info-icon-wrapper">
              <InfoIcon
                data-testid="customer-external-links-info-icon"
                className="customer-external-links__info-icon"
              />
            </div>
          </RSTooltip>
        </ExternalLink>
      </div>
    </div>
  );
};
