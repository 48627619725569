import { Skeleton } from '@mui/material';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricChangeData, MetricData, MetricLoadingError } from '../../../types/performance-metrics';
import { DataTestId } from '../../../types/rs-input';
import { getMetricValue } from '../../../utilities/get-metric-display-value/get-metric-display-value';
import { PerformanceMetricBlockPresentation } from '../../5-elements/performance-metric-block-presentation/performance-metric-block-presentation';
import { MetricChange } from '../metric-change/metric-change';

interface PerformanceMetricBlockProps extends MetricLoadingError, DataTestId {
  metric: MetricData;
  metricChange?: MetricChangeData;
  label: string;
}

type ValuesProps = Omit<PerformanceMetricBlockProps, 'label'>;

type MetricProps = Pick<PerformanceMetricBlockProps, 'metric' | 'loading' | 'hasError'>;

export const PerformanceMetricBlock = ({
  metric,
  metricChange,
  label,
  loading,
  hasError,
  'data-testid': dataTestId
}: PerformanceMetricBlockProps): JSX.Element => {
  const { t } = useTranslation();

  const Metric = ({ metric, hasError }: MetricProps): JSX.Element => {
    if (loading) {
      return (
        <div
          className="performance-metric-block__metric-value"
          data-testid="performance-metric-block__metric-value-loading"
        >
          <Skeleton variant="text" className="performance-metric-block-metric-value-loading" />
        </div>
      );
    }

    if (hasError) {
      return (
        <div
          className="performance-metric-block__metric-value performance-metric-block__metric-value--no-data"
          data-testid="performance-metric-block-metric-value-no-data"
        >
          {t('noData')}
        </div>
      );
    }

    return (
      <p className="performance-metric-block__metric-value" data-testid="performance-metric-block-metric-value">
        {getMetricValue(metric.value, metric.decimalPlaces)}
        {metric.displayUnit}
      </p>
    );
  };

  const Values = ({ metric, metricChange, loading, hasError }: ValuesProps): JSX.Element => {
    return (
      <div className="performance-metric-block__metrics" data-testid="performance-metric-block-metrics">
        <Metric metric={metric} loading={loading} hasError={hasError} />
        {metricChange && (
          <div className="performance-metric-block__metric-change" data-testid="performance-metric-block-metric-change">
            <MetricChange metricChange={metricChange} loading={loading} hasError={hasError} />
          </div>
        )}
      </div>
    );
  };

  return (
    <PerformanceMetricBlockPresentation
      data-testid={dataTestId}
      label={label}
      values={<Values metric={metric} metricChange={metricChange} loading={loading} hasError={hasError} />}
    />
  );
};
