import { ApolloQueryResult, useMutation } from '@apollo/client';
import { first, isEmpty, isNil } from 'lodash';
import { Dispatch, JSX, MouseEventHandler, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MainOperationEdit } from './main-operation-edit';
import {
  GetAllDeviceOperationsPerformanceQuery,
  GetDeviceDetailsQuery,
  GetOperationDetailsQuery,
  OperationalLifeCycle
} from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { MUTATION_MODIFY_DEVICE_OPERATION } from '../../../../services/mutations/operations/modify-device-operation';
import { calculateDuration } from '../../../../utilities/calculate-duration/calculate-duration';
import { generateOperationResultLabel } from '../../../../utilities/generate-operation-result-label/generate-operation-result-label';
import { generateOperationTimestampTooltipTitle } from '../../../../utilities/generate-operation-timestamp-tooltip-title/generate-operation-timestamp-tooltip-title';
import { getAnnotationStatus } from '../../../../utilities/get-annotation-status/get-annotation-status';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';
import { EditButton } from '../../../4-features/edit-button/edit-button';
import { OperationAnnotationStatus } from '../../../4-features/operations/operation-annotation-status/operation-annotation-status';
import { OperationResultChip } from '../../../4-features/operations/operation-result-chip/operation-result-chip';
import { OperationResultTooltipTitle } from '../../../4-features/operations/operation-result-tooltip-title/operation-result-tooltip-title';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { LongTextWithExpansion } from '../../../5-elements/long-text-with-expansion/long-text-with-expansion';
import { RSActionLink } from '../../../5-elements/rs-action-link/rs-action-link';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSTooltip } from '../../../5-elements/rs-tooltip/rs-tooltip';
import { UserTimezoneContext } from '../../../contexts/user-timezone-context';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { formatOperationDate, formatOperationTime } from '../format-operation-date-time/format-operation-date-time';

interface MainOperationProps {
  deviceOperation: NonNullable<GetOperationDetailsQuery['deviceOperationByPK']>;
  handleAnnotationChangeLinkClick: MouseEventHandler<HTMLButtonElement>;
  canUserEditOperation?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  refetch: () =>
    | Promise<ApolloQueryResult<GetDeviceDetailsQuery>>
    | Promise<ApolloQueryResult<GetAllDeviceOperationsPerformanceQuery>>;
}

export const MainOperation = ({
  deviceOperation,
  handleAnnotationChangeLinkClick,
  canUserEditOperation,
  editMode,
  setEditMode,
  refetch
}: MainOperationProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [modifyOperation, { loading }] = useMutation(MUTATION_MODIFY_DEVICE_OPERATION, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });

  const mainOperationAnnotationStatus = getAnnotationStatus(
    deviceOperation.deviceOperationResult?.id,
    deviceOperation.deviceOperationAnnotatedResult?.id
  );

  return (
    <div className="main-operation" data-testid="main-operation">
      <div className="main-operation__edit">
        <EditButton
          disabled={!canUserEditOperation || isNil(deviceOperation.success) || editMode}
          data-testid="main-operation-edit-button"
          onClick={() => setEditMode(true)}
        />
      </div>
      {editMode && (
        <MainOperationEdit
          defaultValues={{
            id: deviceOperation.id,
            operationalLifeCycle: deviceOperation.operationalLifeCycle as OperationalLifeCycle,
            remark: deviceOperation.remark,
            annotatedResultCode: deviceOperation.deviceOperationFinalResult?.code
          }}
          setEditMode={setEditMode}
          refetch={refetch}
        />
      )}
      {!editMode && (
        <>
          <div className="main-operation__operation-result-annotation">
            <div className="main-operation__result">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.result')}
                value={
                  <OperationResultChip
                    title={
                      <OperationResultTooltipTitle
                        annotationStatus={mainOperationAnnotationStatus}
                        annotatedResult={deviceOperation.deviceOperationAnnotatedResult?.name}
                        originalResult={deviceOperation.deviceOperationResult?.name}
                        success={deviceOperation.success ?? undefined}
                        hasEndedAt={Boolean(deviceOperation.endAt)}
                      />
                    }
                    label={generateOperationResultLabel(deviceOperation)}
                    hasEndedAt={Boolean(deviceOperation.endAt)}
                    resultClass={deviceOperation.deviceOperationFinalResult?.resultClass}
                    success={deviceOperation.success ?? undefined}
                  />
                }
              />
              {canUserEditOperation && !deviceOperation.deviceOperationAnnotatedResult && (
                <RSButton
                  // Disabled if the mutation is executing, or the operation is still in progress
                  // isNil(deviceOperation.deviceOperationFinalResult?.code) would be better,
                  // but somehow this value appears to be missing sometimes
                  disabled={loading || isNil(deviceOperation.success)}
                  variant="outlined"
                  extraClassNames={['main-operation__confirm-result-button']}
                  data-testid="main-operation-confirm-annotation-result"
                  onClick={() =>
                    modifyOperation({
                      variables: {
                        deviceOperationInput: {
                          id: deviceOperation.id,
                          annotatedResultCode: deviceOperation.deviceOperationResult?.code
                        }
                      },
                      onCompleted: () => {
                        sendMessageToSnackbar(
                          t('forms.snackbarMessages.successfullySaved'),
                          undefined,
                          undefined,
                          'success'
                        );
                      }
                    })
                  }
                >
                  {t('operationsPage.operationDetails.annotationHistory.confirmResult')}
                </RSButton>
              )}
            </div>
            <div className="main-operation__life-cycle-annotation">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.operationalLifeCycle')}
                value={mapOperationalLifeCycleDisplayLabel(deviceOperation.operationalLifeCycle)}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.annotationStatus')}
                value={
                  <RSTooltip
                    title={generateOperationTimestampTooltipTitle(
                      mainOperationAnnotationStatus,
                      first(deviceOperation.deviceOperationAnnotationAudits)?.annotatedAt &&
                        formatWithDefaultTimezone(first(deviceOperation.deviceOperationAnnotationAudits)!.annotatedAt)
                    )}
                  >
                    <div className="main-operation__annotation-status-result-wrapper">
                      <OperationAnnotationStatus annotationStatus={mainOperationAnnotationStatus} />
                    </div>
                  </RSTooltip>
                }
              />
              {!isEmpty(deviceOperation.deviceOperationAnnotationAudits) && (
                <RSActionLink
                  onClick={handleAnnotationChangeLinkClick}
                  extraClassNames={['main-operation__annotation-history-button']}
                  data-testid="annotation-change-history-link"
                >
                  {t('operationsPage.operationDetails.annotationHistory.actionLink')}
                </RSActionLink>
              )}
            </div>
            <div className="main-operation__notes">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.notes')}
                value={
                  <LongTextWithExpansion
                    amountOfTextLines={3}
                    condensedTextHeight={64}
                    text={getNonNullishDisplayValue(deviceOperation.remark)}
                    moreTextBtnText={t('longText.showMore')}
                    lessTextBtnText={t('longText.showLess')}
                  />
                }
              />
            </div>
          </div>
        </>
      )}
      <div className="main-operation__time" data-testid="operation-details-time">
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.date')}
          value={formatOperationDate(deviceOperation.startAt, deviceOperation.endAt, userTimezone)}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.duration')}
          value={calculateDuration(deviceOperation.startAt, deviceOperation.endAt)}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.startAt')}
          value={formatOperationTime(deviceOperation.startAt, userTimezone)}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.endAt')}
          value={formatOperationTime(deviceOperation.endAt, userTimezone)}
        />
      </div>
    </div>
  );
};
