import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { DateRange, DateRangeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RSDateRangePicker } from '../../../../5-elements/rs-date-range-picker/rs-date-range-picker';
import { OperationsFilters } from '../../operations-overview-states-schema/operations-overview-states-schema';

interface OperationsFilterDatePickerProps {
  errors: FieldErrors<OperationsFilters>;
  onChange:
    | ((value: DateRange<DateTime<boolean>>, context: PickerChangeHandlerContext<DateRangeValidationError>) => void)
    | undefined;
  value: (DateTime<boolean> | null)[] | undefined;
  timezone: string;
}

export const OperationsFilterDatePicker = ({ errors, onChange, value, timezone }: OperationsFilterDatePickerProps) => {
  const { t } = useTranslation();
  const [dateFieldFocused, setDateFieldFocused] = useState<boolean>(false);
  return (
    <FormControl>
      <FormLabel
        className="operations-filter-panel__form-label"
        focused={dateFieldFocused}
        error={Boolean(errors.dateTime)}
      >
        {t('operationsPage.filterPanel.date')}
      </FormLabel>
      <RSDateRangePicker
        timezone={timezone}
        calendars={1}
        disableFuture={true}
        displayWeekNumber={true}
        slotProps={{
          textField: {
            error: Boolean(errors.dateTime),
            onFocus: () => setDateFieldFocused(true),
            onBlur: () => setDateFieldFocused(false)
          },
          actionBar: { actions: ['clear'] }
        }}
        data-testid="operations-date-time-picker"
        onChange={onChange}
        value={(value as [DateTime<boolean>, DateTime<boolean>]) || [null, null]}
      />
      {errors.dateTime && (
        <FormHelperText className="operations-filter-panel__form-helper-text" error={Boolean(errors.dateTime)}>
          {errors.dateTime.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
