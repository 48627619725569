import { toNumber } from 'lodash';
import { z } from 'zod';

import {
  PreprocessArgument,
  preprocessArrayStringInput
} from '../../../../utilities/preprocess-array-string-input/preprocess-array-string-input';
import { CustomersOverviewSortOptions } from '../generate-queries/generate-sort-query';

export const customersOverviewStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(CustomersOverviewSortOptions).optional(),
  // filtering
  name: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional())
});

export type CustomersOverviewSearchParameters = z.infer<typeof customersOverviewStatesSchema>;
