import { toNumber } from 'lodash';
import { z } from 'zod';

import { OperationalLifeCycle } from '../../../../__generated__/graphql';
import { DeviceDeactivated } from '../../../../types/device-deactivated';
import { DeviceState } from '../../../../types/device-state';
import { OnlineStatus } from '../../../../types/online-status';
import {
  PreprocessArgument,
  preprocessArrayStringInput
} from '../../../../utilities/preprocess-array-string-input/preprocess-array-string-input';
import { DevicesOverviewSortOptions } from '../generate-queries/generate-sort-query';

export const devicesOverviewStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(DevicesOverviewSortOptions).optional(),
  // filtering
  serialNumber: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  deviceState: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(DeviceState).array().optional()
  ),
  connectivityState: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(OnlineStatus).array().optional()
  ),
  operationalLifeCycle: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(OperationalLifeCycle).array().optional()
  ),
  rocOS: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  connectorType: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  customer: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  site: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  program: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  deactivated: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(DeviceDeactivated).array().optional()
  )
});

export type DevicesOverviewStatesSchemaType = typeof devicesOverviewStatesSchema;
export type DevicesOverviewSearchParameters = z.infer<typeof devicesOverviewStatesSchema>;
