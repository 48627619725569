import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { difference, includes, isEmpty, isEqual, uniq } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  deviceToDeviceGroupLinkTableHeaderCells,
  deviceToDeviceGroupLinkTableRowCells
} from './display-table-configuration';
import {
  ManageDeviceToDeviceGroupLinkRequest,
  manageDeviceToDeviceGroupLinkSchema
} from './validation-schema/manage-device-to-device-group-link-schema';
import { GetAdminDeviceGroupDetailsQuery } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_DEVICE_TO_DEVICE_GROUP_LINK } from '../../../../services/mutations/admin/companies/create-device-to-device-group-link';
import { MUTATION_UNLINK_DEVICE_FROM_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/unlink-device-from-device-group';
import { QUERY_GET_ADMIN_DEVICE_GROUP_DEVICES } from '../../../../services/queries/admin/companies/get-admin-device-group-devices';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { DisplayTable } from '../../../5-elements/display-table/display-table';
import { Loading } from '../../../5-elements/loading/loading';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../error-page/error-page';

interface ManageDeviceToDeviceGroupLinkDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenManageDeviceToDeviceGroupLinkDrawer: Dispatch<SetStateAction<boolean>>;
  defaultValues: ManageDeviceToDeviceGroupLinkRequest;
  customerId: string;
  deviceGroupName: string;
  refetchDeviceGroups: () => Promise<ApolloQueryResult<GetAdminDeviceGroupDetailsQuery>>;
}

export const ManageDeviceToDeviceGroupLinkDrawer = ({
  setOpenManageDeviceToDeviceGroupLinkDrawer,
  defaultValues,
  customerId,
  deviceGroupName,
  refetchDeviceGroups,
  ...props
}: ManageDeviceToDeviceGroupLinkDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<string>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingDevices,
    data: dataDevices,
    error: errorDevices
  } = useQuery(QUERY_GET_ADMIN_DEVICE_GROUP_DEVICES, {
    fetchPolicy: 'network-only',
    variables: { filters: { customerId: { _eq: customerId } } },
    skip: props.open === false
  });
  const [createLink, { loading: loadingCreateLink }] = useMutation(MUTATION_CREATE_DEVICE_TO_DEVICE_GROUP_LINK, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [unlink, { loading: loadingUnlink }] = useMutation(MUTATION_UNLINK_DEVICE_FROM_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const { handleSubmit, watch, setValue, getValues, reset } = useForm<ManageDeviceToDeviceGroupLinkRequest>({
    resolver: zodResolver(manageDeviceToDeviceGroupLinkSchema),
    defaultValues
  });

  const serialNumbersResponse = dataDevices?.devices || [];
  const serialNumberOptions: RSSelectItemProps[] = serialNumbersResponse
    .map((serialNumber) => ({
      displayName: serialNumber.serialNumber as string,
      menuItemProps: { value: serialNumber.id }
    }))
    .filter((item) => !includes(watch('deviceIds'), item.menuItemProps.value));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenManageDeviceToDeviceGroupLinkDrawer(false);
  };

  const handleCancel = (): void => {
    // We compare values only, regardless of the sequence, therefore `sort()` is introduced
    if (!isEqual(getValues('deviceIds').sort(), defaultValues.deviceIds.sort())) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenManageDeviceToDeviceGroupLinkDrawer(false);
    }
  };

  const onSubmit: SubmitHandler<ManageDeviceToDeviceGroupLinkRequest> = async (data): Promise<void> => {
    // We compare values only, regardless of the sequence, therefore `sort()` is introduced
    if (isEqual(data.deviceIds.sort(), defaultValues.deviceIds.sort())) {
      setOpenManageDeviceToDeviceGroupLinkDrawer(false);
      setShowConfirmationModal(false);
      return;
    }

    const devicesAdded = difference(data.deviceIds, defaultValues.deviceIds);
    const devicesRemoved = difference(defaultValues.deviceIds, data.deviceIds);
    const addFailed: string[] = [];
    const removeFailed: string[] = [];

    if (devicesAdded.length > 0 || devicesRemoved.length > 0) {
      // NOTE the spreading dots `...` used in the promises to get them running in parallel.
      // we want to continue with executing the queries as many as possible. Even if one fails, the other can continue
      // running
      await Promise.allSettled([
        ...devicesAdded.map(async (deviceAdded) => {
          await createLink({
            variables: { deviceGroupId: data.deviceGroupId, deviceId: deviceAdded },
            onCompleted: () => {
              const addedDevice = dataDevices?.devices.find((item) => item.id === deviceAdded);
              const addedDeviceSerialNumber = addedDevice?.serialNumber || '';
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceLinked', {
                  serialNumber: addedDeviceSerialNumber
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const linkFailedDevice = dataDevices?.devices.find((item) => item.id === deviceAdded);
              const linkFailedDeviceSerialNumber = linkFailedDevice?.serialNumber || '';
              addFailed.push(deviceAdded);
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceLinkFailed', {
                  serialNumber: linkFailedDeviceSerialNumber
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        }),
        ...devicesRemoved.map(async (deviceRemoved) => {
          await unlink({
            variables: { deviceGroupId: data.deviceGroupId, deviceId: deviceRemoved },
            onCompleted: () => {
              const removedDevice = dataDevices?.devices.find((item) => item.id === deviceRemoved);
              const removedDeviceSerialNumber = removedDevice?.serialNumber || '';
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceUnlinked', {
                  serialNumber: removedDeviceSerialNumber
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const removeFailedDevice = dataDevices?.devices.find((item) => item.id === deviceRemoved);
              const removeFailedDeviceSerialNumber = removeFailedDevice?.serialNumber || '';
              removeFailed.push(deviceRemoved);
              sendMessageToSnackbar(
                t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceUnlinkFailed', {
                  serialNumber: removeFailedDeviceSerialNumber
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        })
      ]).finally(() => {
        // 1. Devices which were added but failed shall be excluded from the list
        // 2. Devices which were removed but failed shall be kept in the list
        const devicesListExcludeAddFailed = data.deviceIds.filter((deviceId) => !includes(addFailed, deviceId));
        const devicesListRemoveFailed = defaultValues.deviceIds.filter((defaultDeviceId) =>
          includes(removeFailed, defaultDeviceId)
        );
        reset({ ...data, deviceIds: [...devicesListExcludeAddFailed, ...devicesListRemoveFailed] });
        refetchDeviceGroups();
        setShowConfirmationModal(false);
        setOpenManageDeviceToDeviceGroupLinkDrawer(false);
      });
    }
  };

  if (errorDevices) {
    return (
      <RSDrawer {...props} className="manage-device-to-device-group-link-drawer">
        <ModalDrawerHeader
          title={t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.title', { deviceGroupName })}
        />
        <div className="manage-device-to-device-group-link-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorDevices.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingDevices) {
    return (
      <RSDrawer {...props} className="manage-device-to-device-group-link-drawer">
        <ModalDrawerHeader
          title={t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.title', { deviceGroupName })}
        />
        <div className="manage-device-to-device-group-link-drawer__form">
          <div className="manage-device-to-device-group-link-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="manage-device-to-device-group-link-drawer">
      <ModalDrawerHeader
        title={t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.title', { deviceGroupName })}
      />
      {/* Check the existence of the data to prevent MUI from complaining out-of-range values in select components */}
      {dataDevices && (
        <>
          <form
            className="manage-device-to-device-group-link-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="manage-device-to-device-group-link-drawer-form"
          >
            <div className="manage-device-to-device-group-link-drawer__form-contents">
              <div className="manage-device-to-device-group-link-drawer__link-select">
                <h3>{t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.linkDevice')}</h3>
                <div className="manage-device-to-device-group-link-drawer__link-input">
                  <RSSelect
                    inputLabel={t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.serialNumber')}
                    inputLabelTooltip={t('tooltips.deviceGroup.serialNumber')}
                    menuItems={serialNumberOptions}
                    onChange={(event) => setSelectedSerialNumber(event.target.value as string)}
                    value={selectedSerialNumber}
                    disabled={isEmpty(serialNumberOptions)}
                    data-testid="manage-device-to-device-group-link-drawer-serial-number-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <div className="manage-device-to-device-group-link-drawer__link-button-wrapper">
                    <RSButton
                      color="success"
                      variant="contained"
                      onClick={() => {
                        if (selectedSerialNumber !== '') {
                          setValue('deviceIds', uniq([...getValues('deviceIds'), selectedSerialNumber]));
                          setSelectedSerialNumber('');
                        }
                      }}
                      disabled={selectedSerialNumber === ''}
                      data-testid="manage-device-to-device-group-link-link-button"
                    >
                      {t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.link')}
                    </RSButton>
                  </div>
                </div>
              </div>
              <div className="manage-device-to-device-group-link-drawer__device-overview">
                <h3>{t('deviceGroupDetailsPage.manageDeviceToDeviceGroupLinkDrawer.deviceOverview')}</h3>
                <DisplayTable
                  className="manage-device-to-device-group-link-drawer__device-overview-table"
                  columnHeader={{ headerCells: deviceToDeviceGroupLinkTableHeaderCells }}
                  data-testid="manage-device-to-device-group-link-drawer-device-overview-table"
                  rows={
                    dataDevices?.devices
                      .filter((item) => includes(watch('deviceIds'), item.id))
                      .map((device) => ({
                        key: device.id,
                        cells: deviceToDeviceGroupLinkTableRowCells(
                          device.id,
                          device.deviceType.modelNumber,
                          () =>
                            setValue(
                              'deviceIds',
                              getValues('deviceIds').filter((item) => item !== device.id)
                            ),
                          device.serialNumber,
                          device.connectorHolderType?.name
                        )
                      })) || []
                  }
                />
              </div>
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loadingCreateLink || loadingUnlink}
              isCancelDisabled={loadingCreateLink || loadingUnlink}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loadingCreateLink || loadingUnlink}
            disableCancelButton={loadingCreateLink || loadingUnlink}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
        </>
      )}
    </RSDrawer>
  );
};
