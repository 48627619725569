import classNames from 'classnames';

import AnnotatedIcon from '../../../../assets/icons/annotated.svg?react';
import ChangeIcon from '../../../../assets/icons/changed.svg?react';
import { AnnotationStatus, AnnotationStatusInterface } from '../../../../types/operation-annotation';

export const OperationAnnotationIcon = ({ annotationStatus }: AnnotationStatusInterface): JSX.Element => {
  const baseClassName = 'operation-annotation-icon';

  if (annotationStatus === AnnotationStatus.Confirmed) {
    return (
      <AnnotatedIcon
        className={classNames(baseClassName, `${baseClassName}--confirmed`)}
        data-testid="operation-annotation-icon-confirmed"
      />
    );
  }

  if (annotationStatus === AnnotationStatus.Overridden) {
    return (
      <ChangeIcon
        className={classNames(baseClassName, `${baseClassName}--overridden`)}
        data-testid="operation-annotation-icon-overridden"
      />
    );
  }

  return <AnnotatedIcon className={baseClassName} data-testid="operation-annotation-icon-to-be-checked" />;
};
