import classNames from 'classnames';
import { some, startsWith } from 'lodash';
import { JSX } from 'react';
import { useLocation } from 'react-router-dom';

import { RSNavLinkTimezone, RSNavLinkTimezoneProps } from '../rs-navlink-timezone/rs-navlink-timezone';

export interface MainNavMenuButtonProps extends RSNavLinkTimezoneProps {
  label: string;
  icon: JSX.Element;
  isAdmin?: boolean;
  extraMatchPaths?: string[];
}

export const MainNavMenuButton = ({
  label,
  icon,
  isAdmin,
  extraMatchPaths,
  ...props
}: MainNavMenuButtonProps): JSX.Element => {
  const routerLocation = useLocation();
  const menuButtonClassNames = (isActive: boolean, isAdmin?: boolean): string => {
    const extraPathMatch = some((extraMatchPaths || []).map((item) => startsWith(routerLocation.pathname, item)));
    return classNames({
      'main-nav-menu-button': true,
      'main-nav-menu-button--active': isActive === true || extraPathMatch,
      'main-nav-menu-button--admin': isAdmin === true
    });
  };

  return (
    <RSNavLinkTimezone
      data-testid="main-nav-menu-button"
      {...props}
      className={({ isActive }) => menuButtonClassNames(isActive, isAdmin)}
    >
      <div className="main-nav-menu-button__icon-container">{icon}</div>
      <p className="main-nav-menu-button__label">{label}</p>
    </RSNavLinkTimezone>
  );
};
