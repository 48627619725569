import { z } from 'zod';

import { UserPermissionType, UserType } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';
import { CompanyType } from '../../../../../types/company-type';

export const addUserSchema = z
  .object({
    userType: z.nativeEnum(UserType, {
      errorMap: () => ({ message: i18n.t('forms.inputValidation.emptyInput') })
    }),
    companyId: z.string().uuid({ message: i18n.t('forms.inputValidation.emptyInput') }),
    companyType: z.nativeEnum(CompanyType).nullish(),
    firstName: z
      .string({
        required_error: i18n.t('forms.inputValidation.emptyInput'),
        invalid_type_error: i18n.t('forms.inputValidation.emptyInput')
      })
      .trim()
      .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
    lastName: z
      .string({
        required_error: i18n.t('forms.inputValidation.emptyInput'),
        invalid_type_error: i18n.t('forms.inputValidation.emptyInput')
      })
      .trim()
      .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
    email: z.string().email({ message: i18n.t('forms.inputValidation.mustBeEmail') }),
    isSuperUser: z.boolean(),
    permissions: z.array(z.nativeEnum(UserPermissionType))
  })
  .refine(
    (schema) => {
      const res =
        schema.companyType === CompanyType.Customer ||
        (schema.companyType === CompanyType.ServiceProvider && schema.userType === UserType.PortalUser);
      return res;
    },
    { message: i18n.t('usersAdminPage.addUserDrawer.serviceProviderCanNotHaveApiUsers'), path: ['userType'] }
  );

export type AddUserRequest = z.infer<typeof addUserSchema>;
