import i18n from '../../i18n';
import { AnnotationStatus } from '../../types/operation-annotation';

export const generateOperationTimestampTooltipTitle = (
  annotationStatus: AnnotationStatus,
  annotatedTimestamp?: string
): string => {
  switch (annotationStatus) {
    case AnnotationStatus.Confirmed:
      return i18n.t('dataGrid.operationResult.tooltips.resultConfirmedTimestamp', {
        timestamp: annotatedTimestamp
      });
    case AnnotationStatus.Overridden:
      return i18n.t('dataGrid.operationResult.tooltips.resultOverriddenTimestamp', {
        timestamp: annotatedTimestamp
      });
    default:
      return i18n.t('dataGrid.operationResult.tooltips.notAnnotated');
  }
};
