import { toNumber } from 'lodash';
import { z } from 'zod';

import { UserPermissionType, UserType } from '../../../../__generated__/graphql';
import { CompanyType } from '../../../../types/company-type';
import { IsSuperUser } from '../../../../types/is-super-user';
import {
  PreprocessArgument,
  preprocessArrayStringInput
} from '../../../../utilities/preprocess-array-string-input/preprocess-array-string-input';
import { UsersAdminSortOptions } from '../generate-queries/generate-sort-query';

export const usersAdminStatesSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(UsersAdminSortOptions).optional(),
  // filtering
  name: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().uuid().array().optional()
  ),
  companyName: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  companyType: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(CompanyType).array().optional()
  ),
  userType: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(UserType).array().optional()
  ),
  permissions: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(UserPermissionType).array().optional()
  ),
  superUser: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(IsSuperUser).array().optional()
  )
});

export type UsersAdminSearchParameters = z.infer<typeof usersAdminStatesSchema>;
