import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_USER_DEVICE_GROUPS = gql(`
  query getAdminUserDeviceGroups($filters: DeviceGroupBoolExp) {
    deviceGroups(where: $filters) {
      id
      name
      customer {
        companyId
        company {
          id
          name
        }
      }
    }
  }
`);
