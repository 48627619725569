import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModifyDeviceGroupRequest, modifyDeviceGroupSchema } from './validation-schema/modify-device-group-schema';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { MUTATION_DELETE_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/delete-device-group';
import { MUTATION_MODIFY_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/modify-device-group';
import { QUERY_GET_COMPANY_NAMES } from '../../../../services/queries/admin/commons/get-company-names';
import { CompanyType } from '../../../../types/company-type';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { Loading } from '../../../5-elements/loading/loading';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { RSTextInput } from '../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../error-page/error-page';

interface ModifyDeviceGroupDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenModifyDeviceGroup: Dispatch<SetStateAction<boolean>>;
  defaultValues: ModifyDeviceGroupRequest;
  customerId: string;
  createdAt: string;
}

export const ModifyDeviceGroupDrawer = ({
  setOpenModifyDeviceGroup,
  defaultValues,
  customerId,
  createdAt,
  ...props
}: ModifyDeviceGroupDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);
  const { result: userCanDeleteDeviceGroup, loading: loadingUserCanDeleteDeviceGroup } = useAuthCheckerWithSubjectInfo({
    action: 'DELETE',
    subjectInfo: {
      type: 'DeviceGroup',
      customerId: customerId,
      serviceProviderId: defaultValues.serviceProviderId,
      deviceGroupId: defaultValues.id
    },
    skip: props.open === false
  });
  const {
    loading: loadingServiceProviderNames,
    data: dataServiceProviderNames,
    error: errorServiceProviderNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, {
    fetchPolicy: 'network-only',
    skip: !props.open,
    variables: { filters: { companyType: { _eq: CompanyType.ServiceProvider } } }
  });
  const [modifyDeviceGroup, { loading: loadingModifyDeviceGroup }] = useMutation(MUTATION_MODIFY_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error')
  });
  const [deleteDeviceGroup, { loading: loadingDeleteDeviceGroup }] = useMutation(MUTATION_DELETE_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      if (error.message.includes('Foreign key constraint')) {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.errorDelete', { entity: t('entities.deviceGroup') }),
          undefined,
          t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.deviceGroupContainsDevicesOrUsers'),
          'error'
        );
      } else {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.errorDelete', { entity: t('entities.deviceGroup') }),
          undefined,
          error.message || error.name,
          'error'
        );
      }
      setShowDeleteConfirmationModal(false);
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors, isDirty }
  } = useForm<ModifyDeviceGroupRequest>({
    resolver: zodResolver(modifyDeviceGroupSchema),
    defaultValues
  });

  const serviceProviderResponse = dataServiceProviderNames?.companies || [];
  const serviceProviderOptions: RSSelectItemProps[] = serviceProviderResponse.map((serviceProvider) => ({
    displayName: serviceProvider.name,
    menuItemProps: { value: serviceProvider.id }
  }));
  const serviceProviderOptionsWithPlaceholder: RSSelectItemProps[] = [
    {
      displayName: t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.selectServiceProviderPlaceholder'),
      menuItemProps: { value: '' }
    },
    ...serviceProviderOptions
  ];

  const onDelete = (): void => {
    deleteDeviceGroup({
      variables: { id: defaultValues.id },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.successfullyDeleted', { entity: t('entities.deviceGroup') }),
          undefined,
          undefined,
          'success'
        );
        setOpenModifyDeviceGroup(false);
        setShowDeleteConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/companies/${customerId}`);
      }
    });
  };

  const onSubmit: SubmitHandler<ModifyDeviceGroupRequest> = (data): void => {
    if (!isDirty) {
      setOpenModifyDeviceGroup(false);
      setShowLeaveConfirmationModal(false);
      return;
    }

    modifyDeviceGroup({
      variables: data,
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        reset(data);
        setOpenModifyDeviceGroup(false);
        setShowLeaveConfirmationModal(false);
      }
    });
  };

  const handleLeave = (): void => {
    reset();
    setShowLeaveConfirmationModal(false);
    setOpenModifyDeviceGroup(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowLeaveConfirmationModal(true);
    } else {
      reset();
      setOpenModifyDeviceGroup(false);
    }
  };

  if (errorServiceProviderNames) {
    return (
      <RSDrawer {...props} className="modify-device-group-drawer">
        <ModalDrawerHeader title={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.title')} />
        <div className="modify-device-group-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorServiceProviderNames.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingServiceProviderNames || loadingUserCanDeleteDeviceGroup || !dataServiceProviderNames) {
    return (
      <RSDrawer {...props} className="modify-device-group-drawer">
        <ModalDrawerHeader title={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.title')} />
        <div className="modify-device-group-drawer__form">
          <div className="modify-device-group-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="modify-device-group-drawer">
      <ModalDrawerHeader title={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.title')} />
      {dataServiceProviderNames && (
        <>
          <form
            className="modify-device-group-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="modify-device-group-drawer-form"
          >
            <div className="modify-device-group-drawer__input-fields">
              <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value } }) => (
                  <RSTextInput
                    inputLabel={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.name')}
                    required={true}
                    data-testid="modify-device-group-drawer-device-group-name-input"
                    helperText={errors.name?.message}
                    error={Boolean(errors.name)}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {/*
                This is an optional field, we need to make it to an uncontrolled component to allow turing an empty string
                into `undefined` whilst displaying the placeholder
              */}
              <RSSelect
                className="modify-device-group-drawer__service-provider-id-select"
                inputLabel={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.serviceProvider')}
                inputLabelTooltip={t('tooltips.deviceGroup.serviceProvider')}
                displayEmpty={true}
                menuItems={serviceProviderOptionsWithPlaceholder}
                helperText={errors.serviceProviderId?.message}
                error={Boolean(errors.serviceProviderId)}
                data-testid="modify-device-group-drawer-service-provider-select"
                defaultValue={defaultValues.serviceProviderId || ''}
                color="success"
                {...RS_SELECT_ADMIN_MENU_PROPS}
                {...register('serviceProviderId', { setValueAs: (value) => (value === '' ? undefined : value) })}
              />
              <RSTextInput
                inputLabel={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.description')}
                data-testid="modify-device-group-drawer-description-input"
                multiline={true}
                helperText={errors.description?.message}
                error={Boolean(errors.description)}
                defaultValue={defaultValues.description}
                {...register('description', { setValueAs: (value) => (value === '' ? undefined : value) })}
              />
              <RSTextInput
                inputLabel={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.createdAt')}
                data-testid="modify-device-group-drawer-created-at-input"
                helperText={errors.name?.message}
                error={Boolean(errors.name)}
                disabled={true}
                defaultValue={createdAt}
              />
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              handleDelete={() => setShowDeleteConfirmationModal(true)}
              isSaveDisabled={loadingModifyDeviceGroup}
              isCancelDisabled={loadingModifyDeviceGroup}
              isDeleteDisabled={loadingDeleteDeviceGroup || !userCanDeleteDeviceGroup}
              colorVariant="success"
              deleteButtonText={t('deviceGroupDetailsPage.modifyDeviceGroupDrawer.deleteDeviceGroup')}
            />
          </form>
          <ConfirmationModal
            open={showLeaveConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowLeaveConfirmationModal(false)}
            disableConfirmButton={loadingModifyDeviceGroup}
            disableCancelButton={loadingModifyDeviceGroup}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
          <ConfirmationModal
            open={showDeleteConfirmationModal}
            mainTitle={t('forms.deleteModal.title')}
            message={t('forms.deleteModal.message', { entity: t('entities.deviceGroup') })}
            confirmButtonText={
              loadingDeleteDeviceGroup ? t('forms.deleteModal.confirmDeleting') : t('forms.deleteModal.confirm')
            }
            cancelButtonText={t('forms.deleteModal.cancel')}
            handleClickCancelButton={() => setShowDeleteConfirmationModal(false)}
            handleClickConfirmButton={onDelete}
            disableConfirmButton={loadingDeleteDeviceGroup}
            disableCancelButton={loadingDeleteDeviceGroup}
            confirmButtonColor="error"
            cancelButtonColor="error"
          />
        </>
      )}
    </RSDrawer>
  );
};
