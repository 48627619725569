import { DeviceStatusIconVariant } from '../../../../../types/device-state';

// Implemented base on: https://rocsys.atlassian.net/wiki/spaces/CLOUD/pages/430407696/Rocsys+Portal+Monitoring+ROCs

export const mapSafetyTriggeredToStatusIcon = (value?: number | null): DeviceStatusIconVariant => {
  switch (value) {
    case 1:
      return 'critical';
    case 0:
      return 'normal';
    default:
      return 'no-monitoring-active';
  }
};

export const mapConnectivityToStatusIcon = (onlineStatusValue?: number | null): DeviceStatusIconVariant => {
  switch (onlineStatusValue) {
    case 1:
    case 2:
      // 1: Heartbeat only; 2: online
      return 'normal';
    case 0:
      return 'offline';
    default:
      return 'no-monitoring-active';
  }
};

export const mapMeasurementStatusToStatusIcon = (status?: string | null): DeviceStatusIconVariant => {
  // Cannot match the value - as we define the error/ok/warning with our own standard.
  switch (status) {
    case 'ERROR':
      return 'critical';
    case 'OK':
      return 'normal';
    case 'WARNING':
      return 'attention-required';
    default:
      return 'no-monitoring-active';
  }
};
