import { gql } from '../../../__generated__/gql';

export const MUTATION_MODIFY_USER = gql(`
  mutation modifyUser(
    $id: String!
    $firstName: String!
    $lastName: String!
    $isSuperUser: Boolean!
    $permissions: [UserPermissionType!]!
  ) {
    modifyUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      isSuperUser: $isSuperUser
      permissions: $permissions
    ) {
      id
      user {
        id
        firstName
        lastName
        email
        isSuperUser
        updatedAt
        company {
          id
          name
          companyType
        }
        userType
        userPermissionLinks {
          id
          userPermission
        }
      }
    }
  }
`);
