import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { MainNavMenuButton, MainNavMenuButtonProps } from './main-nav-menu-button';
import CrossIcon from '../../../assets/icons/cross.svg?react';
import CustomerIcon from '../../../assets/icons/customer.svg?react';
import RocIcon from '../../../assets/icons/roc.svg?react';
import UserIcon from '../../../assets/icons/user.svg?react';
import {
  COMPANIES_ADMIN_PAGE_PATH,
  DEVICES_ADMIN_PAGE_PATH,
  DEVICE_GROUPS_ADMIN_PAGE_PATH,
  SITES_ADMIN_PAGE_PATH,
  USERS_ADMIN_PAGE_PATH
} from '../../../constants/constants';

export const AdminMainNavMenu = (): JSX.Element => {
  const { t } = useTranslation();

  const mainNavMenuConfig: MainNavMenuButtonProps[] = [
    {
      label: t('mainNavMenu.companies'),
      icon: <CustomerIcon />,
      to: COMPANIES_ADMIN_PAGE_PATH,
      extraMatchPaths: [SITES_ADMIN_PAGE_PATH, DEVICE_GROUPS_ADMIN_PAGE_PATH]
    },
    { label: t('mainNavMenu.devices'), icon: <RocIcon />, to: DEVICES_ADMIN_PAGE_PATH },
    { label: t('mainNavMenu.users'), icon: <UserIcon />, to: USERS_ADMIN_PAGE_PATH }
  ];

  return (
    <nav className="admin-main-nav-menu" data-testid="admin-main-nav-menu">
      <div className="admin-main-nav-menu__pages">
        {mainNavMenuConfig.map((menuItem) => (
          <MainNavMenuButton key={menuItem.label} isAdmin={true} {...menuItem} />
        ))}
      </div>
      <div className="admin-main-nav-menu__admin">
        <MainNavMenuButton
          label={t('mainNavMenu.closeAdmin')}
          icon={<CrossIcon />}
          to="/portal/customers"
          isAdmin={true}
        />
      </div>
    </nav>
  );
};
