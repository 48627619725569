import { RSLinearProgressProps } from '../../../../5-elements/rs-linear-progress/rs-linear-progress';

export const mapUsageStatusToProgressBarColor = (status?: string | null): RSLinearProgressProps['color'] => {
  switch (status) {
    case 'ERROR':
      return 'error';
    case 'OK':
      return 'success';
    case 'WARNING':
      return 'warning';
    default:
      return 'primary';
  }
};
