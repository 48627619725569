export const generateBalenaLink = (balenaUid: string): string => {
  return `https://dashboard.balena-cloud.com/devices/${balenaUid}`;
};

export const generateGoogleMapsUrl = (latitude: number, longitude: number): string => {
  // Example: https://www.google.com/maps/place/52.03442338509859,4.346252384904962
  return `https://www.google.com/maps/place/${latitude},${longitude}`;
};

export const generateJiraLink = (serialNumber: string, showServicePortal?: boolean): string => {
  if (showServicePortal) {
    return `https://rocsys.atlassian.net/issues/?jql=%22ROC%20Serial%20number%5BShort%20text%5D%22%20~%20${serialNumber}%20order%20by%20created%20DESC`;
  }
  return `https://rocsys.atlassian.net/servicedesk/customer/user/requests?filter=${serialNumber}&page=1&reporter=all`;
};
