import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_MEASUREMENT_VALUES = gql(`
  query getAggregatedMeasurements($deviceId: uuid!, $from: timestamptz!, $to: timestamptz!, $name: String!, $aggregationType: AggregationType) {
    requestAggregatedMeasurements(deviceId: $deviceId, from: $from, to: $to, name: $name, aggregationType: $aggregationType ) {
    data {
      dateTime
      maxValue
      minValue
      avgValue
    }
    aggregationType
  }
  }
`);
