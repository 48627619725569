import { useQuery } from '@apollo/client';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import classNames from 'classnames';
import { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AddDeviceGroupDrawer } from './add-device-group-drawer/add-device-group-drawer';
import { AddSiteDrawer } from './add-site-drawer/add-site-drawer';
import { AlertConfigurationDrawer } from './alert-configuration-drawer/alert-configuration-drawer';
import { AlertConfigurationDrawerMode } from './alert-configuration-drawer/alert-configuration-drawer-mode';
import { CompanyDetailsAdminPanel } from './company-details-admin-panel/company-details-admin-panel';
import { alertConfigurationsDataGridColumns } from './data-grid-configurations/company-alert-configuration-data-grid-columns';
import { companyDeviceGroupsDataGridColumns } from './data-grid-configurations/company-device-groups-data-grid-columns';
import { companySitesDataGridColumns } from './data-grid-configurations/company-sites-data-grid-columns';
import {
  CompanyAlertConfigurationRow,
  generateAlertConfigurationRows
} from './data-grid-configurations/generate-alert-configuration-rows';
import {
  CompanyDeviceGroupRow,
  generateDeviceGroupRows
} from './data-grid-configurations/generate-customer-device-group-rows';
import { CompanySiteRow, generateSiteRows } from './data-grid-configurations/generate-site-rows';
import { UserTimezoneContext } from '../../../components/contexts/user-timezone-context';
import { appConfig } from '../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker/authz-checker.hooks';
import { QUERY_GET_ADMIN_COMPANY_DETAILS } from '../../../services/queries/admin/companies/get-admin-company-details';
import { CompanyType } from '../../../types/company-type';
import { AdminDetailsMainSectionWrapper } from '../../2-templates/main-sections/admin-details-main-section-wrapper/admin-details-main-section-wrapper';
import { AdminAddActionButton } from '../../4-features/admin/admin-add-action-button/admin-add-action-button';
import { AdminDetailsDataGrid } from '../../4-features/admin/admin-details-data-grid/admin-details-data-grid';
import { ErrorPage } from '../error-page/error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page/loading-page';

export const CompanyDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [openAddSite, setOpenAddSite] = useState<boolean>(false);
  const [openAddDeviceGroup, setOpenAddDeviceGroup] = useState<boolean>(false);
  const [alertConfigurationId, setAlertConfigurationId] = useState<string>('');
  const [drawerMode, setDrawerMode] = useState<AlertConfigurationDrawerMode>('view');
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_COMPANY_DETAILS, {
    variables: { companyId: companyId! },
    fetchPolicy: 'network-only'
  });
  const { result: canUserCreateSite, loading: loadingCanUserCreateSite } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'Site', customerId: companyId! },
    skip: false
  });
  const { result: canUserCreateDeviceGroup, loading: loadingCanUserCreateDeviceGroup } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'DeviceGroup', customerId: companyId! },
    skip: false
  });
  const { result: canUserCreateRocAlertConfiguration, loading: loadingCanUserCreateRocAlertConfiguration } =
    useAuthCheckerWithSubjectInfo({
      action: 'CREATE',
      subjectInfo: { type: 'RocAlertConfiguration', companyId: companyId! },
      skip: false
    });

  const handleAlertConfigurationRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams<CompanyAlertConfigurationRow>
  ): void => {
    setDrawerMode('view');
    setAlertConfigurationId(params.id as string);
  };

  const handleSiteRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<CompanySiteRow>): void => {
    navigate(`${appConfig.basePath}/admin/sites/${params.id}`);
  };

  const handleDeviceGroupRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams<CompanyDeviceGroupRow>
  ): void => {
    navigate(`${appConfig.basePath}/admin/device-groups/${params.id}`);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const companyDetails = data?.companyByPK;
  if (!companyDetails) {
    return <PageNotFoundErrorPage />;
  }

  const isCustomer = companyDetails.companyType === CompanyType.Customer;
  const deviceGroups = companyDetails.customer?.deviceGroups || companyDetails.serviceProvider?.deviceGroups;
  const deviceGroupDevicesTotal = isCustomer
    ? companyDetails.customer?.devicesAggregate.aggregate?.count || 0
    : deviceGroups!.reduce((sum, item) => sum + (item.deviceGroupDevicesAggregate.aggregate?.count || 0), 0);
  const alertRowBaseClassName = 'company-details-admin-page__alert-configuration-row';

  return (
    <AdminDetailsMainSectionWrapper panel={<CompanyDetailsAdminPanel companyDetails={companyDetails} />}>
      <div className="company-details-admin-page" data-testid="company-details-admin-page">
        {isCustomer && (
          <div className="company-details-admin-page__sites" data-testid="company-details-admin-page-sites">
            <div className="company-details-admin-page__title-bar">
              <h2>{t('companyAdminDetailsPage.sitesDataGrid.title')}</h2>
              <AdminAddActionButton
                titleName={t('companyAdminDetailsPage.sitesDataGrid.add')}
                disabled={!canUserCreateSite || loadingCanUserCreateSite}
                data-testid="company-details-admin-page-add-site"
                onClick={() => setOpenAddSite(true)}
              />
            </div>
            <div className="company-details-admin-page__data-grid">
              <AdminDetailsDataGrid
                columns={companySitesDataGridColumns}
                loading={loading}
                onRowClick={handleSiteRowClick}
                rows={generateSiteRows(companyDetails.customer!.sites, userTimezone)}
                data-testid="company-details-admin-page-sites-data-grid"
              />
            </div>
          </div>
        )}
        <div className="company-details-admin-page__device-groups">
          <div className="company-details-admin-page__title-bar">
            <h2>{t('companyAdminDetailsPage.deviceGroupsDataGrid.title')}</h2>
            {/* only customers own device groups */}
            {companyDetails.companyType === CompanyType.Customer && (
              <AdminAddActionButton
                titleName={t('companyAdminDetailsPage.deviceGroupsDataGrid.add')}
                disabled={!canUserCreateDeviceGroup || loadingCanUserCreateDeviceGroup}
                data-testid="company-details-admin-page-add-device-group"
                onClick={() => setOpenAddDeviceGroup(true)}
              />
            )}
          </div>
          <div className="company-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={companyDeviceGroupsDataGridColumns(companyDetails.companyType as CompanyType)}
              loading={loading}
              onRowClick={handleDeviceGroupRowClick}
              rows={generateDeviceGroupRows(companyDetails.companyType as CompanyType, deviceGroups!, userTimezone)}
              data-testid="company-details-admin-page-customer-device-groups-data-grid"
            />
          </div>
        </div>
        <div className="company-details-admin-page__alert-configurations">
          <div className="company-details-admin-page__title-bar">
            <h2>{t('companyAdminDetailsPage.alertConfigurationsDataGrid.title')}</h2>
            <AdminAddActionButton
              titleName={t('companyAdminDetailsPage.alertConfigurationsDataGrid.add')}
              disabled={
                loadingCanUserCreateRocAlertConfiguration ||
                !canUserCreateRocAlertConfiguration ||
                // do not show add button in acc and prod environments
                ['prod', 'acc'].includes(appConfig.environmentType as string)
              }
              data-testid="company-details-admin-page-add-alert-configuration"
              onClick={() => {
                setAlertConfigurationId('new');
                setDrawerMode('create');
              }}
            />
          </div>
          <div className="company-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={alertConfigurationsDataGridColumns}
              loading={loading}
              rows={generateAlertConfigurationRows(
                companyDetails.rocAlertConfigurations,
                deviceGroupDevicesTotal,
                userTimezone
              )}
              onRowClick={handleAlertConfigurationRowClick}
              data-testid="company-details-admin-page-customer-alert-configurations-data-grid"
              getRowClassName={(params) =>
                params.row.status === t('companyAdminDetailsPage.alertConfigurationsDataGrid.alertEnabled')
                  ? classNames(alertRowBaseClassName, `${alertRowBaseClassName}--enabled`)
                  : classNames(alertRowBaseClassName, `${alertRowBaseClassName}--disabled`)
              }
            />
          </div>
        </div>
      </div>
      {isCustomer && (
        <AddSiteDrawer
          customerName={companyDetails.name}
          customerId={companyDetails.id}
          open={openAddSite}
          setOpenAddSite={setOpenAddSite}
          refetchCompany={refetch}
        />
      )}
      <AddDeviceGroupDrawer
        customerName={companyDetails.name}
        customerId={companyDetails.id}
        open={openAddDeviceGroup}
        setOpenAddDeviceGroup={setOpenAddDeviceGroup}
        refetchCompany={refetch}
      />
      <AlertConfigurationDrawer
        open={Boolean(alertConfigurationId)}
        id={alertConfigurationId}
        companyId={companyDetails.id}
        companyType={companyDetails.companyType as CompanyType}
        drawerMode={drawerMode}
        setAlertConfigurationId={setAlertConfigurationId}
        setDrawerMode={setDrawerMode}
        onClose={drawerMode !== 'view' ? undefined : () => setAlertConfigurationId('')}
        refetchCompany={refetch}
      />
    </AdminDetailsMainSectionWrapper>
  );
};
