import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { capitalize, filter, flatMap, includes, uniq } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertConfigurationCreateRequest, alertConfigurationCreateSchema } from './alert-configuration-schema';
import {
  CreateRocAlertConfigurationMutationVariables,
  DeviceBoolExp,
  GetAdminCompanyDetailsQuery,
  RocAlertChannelParameterValueInput,
  RocAlertStatusType,
  SelectionType
} from '../../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../../constants/constants';
import { MUTATION_CREATE_ROC_ALERT_CONFIGURATION } from '../../../../../services/mutations/admin/companies/create-roc-alert-configuration';
import { QUERY_GET_ROC_ALERT_CHANNELS } from '../../../../../services/queries/admin/companies/get-roc-alert-channels';
import { QUERY_GET_SERIAL_NUMBERS } from '../../../../../services/queries/commons/get-serial-numbers';
import { QUERY_GET_OPERATION_RESULTS } from '../../../../../services/queries/operations/get-operation-results';
import { CompanyType } from '../../../../../types/company-type';
import { RocAlertChannelType } from '../../../../../types/roc-alert-configuration';
import { RSAutocompleteValue } from '../../../../../types/rs-autocomplete';
import { ConfirmationModal } from '../../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { getOperationResultDisplayOption } from '../../../../4-features/operations/operation-result-display-option/operation-result-display-option';
import { Loading } from '../../../../5-elements/loading/loading';
import { RSAutocomplete } from '../../../../5-elements/rs-autocomplete/rs-autocomplete';
import { RSAutocompleteDefaultMenuOption } from '../../../../5-elements/rs-autocomplete/rs-autocomplete-default-menu-option/rs-autocomplete-default-menu-option';
import { RSCheckbox } from '../../../../5-elements/rs-checkbox-group/rs-checkbox';
import { RSCheckboxGroup } from '../../../../5-elements/rs-checkbox-group/rs-checkbox-group';
import { RSRadio } from '../../../../5-elements/rs-radio-group/rs-radio';
import { RSRadioGroup } from '../../../../5-elements/rs-radio-group/rs-radio-group';
import { RSSelect, RSSelectItemProps } from '../../../../5-elements/rs-select/rs-select';
import { RSSwitch } from '../../../../5-elements/rs-switch/rs-switch';
import { RSTextInput } from '../../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../../error-page/error-page';
import { AlertConfigurationDrawerMode } from '../alert-configuration-drawer-mode';

interface AlertConfigurationFormProps {
  drawerMode: AlertConfigurationDrawerMode;
  setDrawerMode: Dispatch<SetStateAction<AlertConfigurationDrawerMode>>;
  setAlertConfigurationId: Dispatch<SetStateAction<string>>;
  companyId: string;
  companyType: CompanyType;
  refetchCompany: () => Promise<ApolloQueryResult<GetAdminCompanyDetailsQuery>>;
}

export const AlertConfigurationForm = ({
  drawerMode,
  setDrawerMode,
  setAlertConfigurationId,
  companyId,
  companyType,
  refetchCompany
}: AlertConfigurationFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const deviceSerialNumberFilters: DeviceBoolExp =
    companyType === CompanyType.Customer
      ? { customerId: { _eq: companyId } }
      : { deviceGroupDevices: { deviceGroup: { serviceProviderId: { _eq: companyId } } } };
  const {
    loading: loadingOperationResults,
    data: dataOperationResults,
    error: errorOperationResults
  } = useQuery(QUERY_GET_OPERATION_RESULTS, { fetchPolicy: 'network-only', skip: drawerMode === 'view' });
  const {
    loading: loadingRocAlertChannels,
    data: dataRocAlertChannels,
    error: errorRocAlertChannels
  } = useQuery(QUERY_GET_ROC_ALERT_CHANNELS, { fetchPolicy: 'network-only', skip: drawerMode === 'view' });
  const {
    loading: loadingSerialNumbers,
    data: dataSerialNumbers,
    error: errorSerialNumbers
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, {
    fetchPolicy: 'network-only',
    skip: drawerMode === 'view',
    variables: { filters: deviceSerialNumberFilters }
  });
  const [createRocAlertConfiguration, { loading: loadingCreateRocAlertConfiguration }] = useMutation(
    MUTATION_CREATE_ROC_ALERT_CONFIGURATION,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) =>
        sendMessageToSnackbar(
          t('companyAdminDetailsPage.alertConfigurationDrawer.createRocAlertConfigurationFailed'),
          undefined,
          error.message || error.name,
          'error'
        )
    }
  );
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors, isDirty }
  } = useForm<AlertConfigurationCreateRequest>({
    resolver: zodResolver(alertConfigurationCreateSchema),
    defaultValues: {
      companyId,
      enabled: true,
      enableStatusAlerts: true,
      enableStatusAlertRecovery: false,
      enableAcdCycleAlerts: true,
      deviceSelectionType: SelectionType.All,
      deviceOperationResultSelectionType: SelectionType.All,
      statusAlertTypes: [
        RocAlertStatusType.Online,
        RocAlertStatusType.SafetyTriggered,
        RocAlertStatusType.DeviceState,
        RocAlertStatusType.StorageUsage,
        RocAlertStatusType.MemoryUsage,
        RocAlertStatusType.QueueLength,
        RocAlertStatusType.ZOffset
      ],
      deviceIds: [],
      deviceOperationResultIds: []
    }
  });
  const operationResultResponse = dataOperationResults?.deviceOperationResults || [];
  const operationResultOptions = operationResultResponse.map((operationResult) => operationResult.id);
  const operationResultDisplayTag = (option: RSAutocompleteValue): string => {
    const resultObject = dataOperationResults?.deviceOperationResults.find((item) => item.id === option);
    return resultObject?.name || '';
  };
  const rocAlertChannelsResponse = dataRocAlertChannels?.rocAlertChannel || [];
  const rocAlertChannelOptions: RSSelectItemProps[] = rocAlertChannelsResponse.map((alertChannel) => ({
    displayName: capitalize(alertChannel.code),
    menuItemProps: { value: alertChannel.id }
  }));
  const serialNumbersResponse = dataSerialNumbers?.devices || [];
  const serialNumberOptions = serialNumbersResponse.map((device) => device.id);
  const serialNumberMenuDisplayOption = (option: RSAutocompleteValue) => {
    const serialNumberObject = serialNumbersResponse.find((item) => item.id === option);
    return <RSAutocompleteDefaultMenuOption option={serialNumberObject?.serialNumber || ''} />;
  };
  const serialNumberTagDisplayOption = (option: RSAutocompleteValue): string => {
    const serialNumberObject = serialNumbersResponse.find((item) => item.id === option);
    return serialNumberObject?.serialNumber || '';
  };

  const handleLeave = (): void => {
    reset();
    if (drawerMode === 'create') {
      setAlertConfigurationId('');
    }
    setDrawerMode('view');
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      if (drawerMode === 'create') {
        setAlertConfigurationId('');
      }
      setDrawerMode('view');
    }
  };

  const getRocAlertChannelParameter = (
    rocAlertChannelCode?: RocAlertChannelType | null,
    teamsUrl?: string | null,
    email?: string | null
  ): RocAlertChannelParameterValueInput[] => {
    const parameters = flatMap(rocAlertChannelsResponse, 'rocAlertChannelParameters');
    if (rocAlertChannelCode === RocAlertChannelType.Teams) {
      return [
        { rocAlertChannelParameterId: parameters.find((item) => item.name === 'webhook_url')?.id, value: teamsUrl! }
      ];
    }
    if (rocAlertChannelCode === RocAlertChannelType.Email) {
      return [{ rocAlertChannelParameterId: parameters.find((item) => item.name === 'email')?.id, value: email! }];
    }
    return [];
  };

  const onSubmit: SubmitHandler<AlertConfigurationCreateRequest> = (data): void => {
    if (!isDirty) {
      if (drawerMode === 'create') {
        setAlertConfigurationId('');
      }
      setDrawerMode('view');
      setShowConfirmationModal(false);
      return;
    }

    const dataToSubmit: CreateRocAlertConfigurationMutationVariables = {
      name: data.name,
      companyId: data.companyId,
      enabled: data.enabled,
      description: data.description ? data.description : undefined, // avoid empty string
      deviceSelectionType: data.deviceSelectionType,
      enableStatusAlerts: data.enableStatusAlerts,
      statusAlertTypes: data.statusAlertTypes,
      enableStatusAlertRecovery: data.enableStatusAlertRecovery,
      enableAcdCycleAlerts: data.enableAcdCycleAlerts,
      deviceOperationResultSelectionType: data.deviceOperationResultSelectionType,
      rocAlertChannelId: data.rocAlertChannelId,
      rocAlertChannelParameterValues: getRocAlertChannelParameter(
        data.rocAlertChannelCode,
        data.alertTeamsUrl,
        data.alertEmails
      ),
      deviceIds: data.deviceSelectionType === SelectionType.All ? [] : data.deviceIds,
      deviceOperationResultIds:
        data.deviceOperationResultSelectionType === SelectionType.All ? [] : data.deviceOperationResultIds
    };

    createRocAlertConfiguration({
      variables: dataToSubmit,
      onCompleted: (response) => {
        sendMessageToSnackbar(
          t('companyAdminDetailsPage.alertConfigurationDrawer.createRocAlertConfigurationSuccess'),
          undefined,
          undefined,
          'success'
        );
        refetchCompany();
        reset();
        if (drawerMode === 'create') {
          setAlertConfigurationId(response.createRocAlertConfiguration!.id);
        }
        setDrawerMode('view');
        setShowConfirmationModal(false);
      }
    });
  };

  const handleCheckboxSelect = (rocAlertStatusType: RocAlertStatusType, newValue?: boolean | null): void => {
    if (newValue === true) {
      setValue('statusAlertTypes', uniq([...getValues('statusAlertTypes'), rocAlertStatusType]));
      return;
    }
    if (!newValue) {
      setValue(
        'statusAlertTypes',
        filter(getValues('statusAlertTypes'), (item) => item !== rocAlertStatusType)
      );
      return;
    }
  };

  const getAlertChannelCode = (alertChannelId: string): RocAlertChannelType | undefined => {
    const alertChannel = rocAlertChannelsResponse.find((channel) => channel.id === alertChannelId);
    return alertChannel?.code as RocAlertChannelType | undefined;
  };

  const groupedError = errorOperationResults || errorRocAlertChannels || errorSerialNumbers;
  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  if (loadingOperationResults || loadingRocAlertChannels || loadingSerialNumbers) {
    return (
      <div className="alert-configuration-form">
        <div className="alert-configuration-form__loading">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <form className="alert-configuration-form" data-testid="alert-configuration-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="alert-configuration-form__input-fields">
        <RSTextInput
          inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.name')}
          required={true}
          data-testid="alert-configuration-form-name-input"
          helperText={errors.name?.message}
          error={Boolean(errors.name)}
          {...register('name')}
        />
        <RSTextInput
          inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.prefix')}
          data-testid="alert-configuration-form-prefix-input"
          helperText={errors.prefix?.message}
          error={Boolean(errors.prefix)}
          {...register('prefix')}
        />
        <RSTextInput
          inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.description')}
          data-testid="alert-configuration-form-description-input"
          multiline={true}
          helperText={errors.description?.message}
          error={Boolean(errors.description)}
          {...register('description')}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onBlur, onChange, value } }) => (
            <RSSwitch
              formLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.alertStatus')}
              data-testid="alert-configuration-form-alert-status-switch"
              checked={Boolean(value)}
              required={true}
              onBlur={onBlur}
              onChange={onChange}
              falseValueLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.disabled')}
              trueValueLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.enabled')}
            />
          )}
        />
        <Controller
          control={control}
          name="deviceSelectionType"
          render={({ field: { onBlur, onChange, value, name } }) => (
            <RSRadioGroup
              formLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.rocs')}
              formLabelProps={{ id: 'deviceSelectionType', required: true }}
              aria-labelledby="deviceSelectionType"
              data-testid="alert-configuration-form-rocs-radio-group"
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              color="success"
            >
              <RSRadio
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.all')}
                value={SelectionType.All}
                data-testid="alert-configuration-form-rocs-selection-type-all"
              />
              <RSRadio
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.allExcept')}
                value={SelectionType.AllExcept}
                data-testid="alert-configuration-form-rocs-selection-type-all-except"
              />
              <RSRadio
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.only')}
                value={SelectionType.Specific}
                data-testid="alert-configuration-form-rocs-selection-type-specific"
              />
            </RSRadioGroup>
          )}
        />
        {watch('deviceSelectionType') !== SelectionType.All && (
          <Controller
            control={control}
            name="deviceIds"
            render={({ field: { onBlur, onChange, value } }) => (
              <RSAutocomplete
                className="alert-configuration-form__devices-ids-autocomplete"
                inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.rocs')}
                data-testid="alert-configuration-form-devices-ids-autocomplete"
                options={serialNumberOptions}
                customMenuOption={serialNumberMenuDisplayOption}
                customTagOption={serialNumberTagDisplayOption}
                value={filter(value, (valueItem) => includes(serialNumberOptions, valueItem))}
                onChange={(_event, values) => onChange(values)}
                onBlur={onBlur}
              />
            )}
          />
        )}
        <div className="alert-configuration-form__notification-channel">
          <div className="alert-configuration-form__notification-channel-title">
            <span className="alert-configuration-form__notification-channel-title-text">
              {t('companyAdminDetailsPage.alertConfigurationDrawer.notificationChannel')}
            </span>
          </div>
          <div className="alert-configuration-form__notification-channel-input-fields">
            <Controller
              name="rocAlertChannelId"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <RSSelect
                  className="alert-configuration-form__alert-channel-select"
                  inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.selectChannel')}
                  required={true}
                  menuItems={rocAlertChannelOptions}
                  helperText={errors.rocAlertChannelId?.message}
                  error={Boolean(errors.rocAlertChannelId)}
                  onBlur={onBlur}
                  onChange={(event) => {
                    onChange(event);
                    const channelCode = getAlertChannelCode(event.target.value as string);
                    setValue('rocAlertChannelCode', channelCode);
                  }}
                  data-testid="alert-configuration-form-alert-channel-select"
                  value={value || ''}
                  {...RS_SELECT_ADMIN_MENU_PROPS}
                />
              )}
            />
            {getAlertChannelCode(watch('rocAlertChannelId')) === RocAlertChannelType.Teams && (
              <RSTextInput
                inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.teamsUrl')}
                inputLabelTooltip={t('companyAdminDetailsPage.alertConfigurationDrawer.tooltips.teamsUrl')}
                required={true}
                data-testid="alert-configuration-form-teams-url-input"
                helperText={errors.alertTeamsUrl?.message}
                error={Boolean(errors.alertTeamsUrl)}
                {...register('alertTeamsUrl')}
              />
            )}
            {getAlertChannelCode(watch('rocAlertChannelId')) === RocAlertChannelType.Email && (
              <RSTextInput
                inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.emails')}
                inputLabelTooltip={t('companyAdminDetailsPage.alertConfigurationDrawer.tooltips.emails')}
                required={true}
                data-testid="alert-configuration-form-emails-input"
                helperText={errors.alertEmails?.message}
                error={Boolean(errors.alertEmails)}
                {...register('alertEmails')}
              />
            )}
          </div>
        </div>
        <div className="alert-configuration-form__status-alerts">
          <div className="alert-configuration-form__status-alerts-title">
            <span className="alert-configuration-form__alert-inclusion-title">
              {t('companyAdminDetailsPage.alertConfigurationDrawer.rocStatusAlerts')}
            </span>
            <Controller
              control={control}
              name="enableStatusAlerts"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  data-testid="alert-configuration-form-roc-status-alerts-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  falseValueLabel={t(
                    'companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.excluded'
                  )}
                  trueValueLabel={t(
                    'companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.included'
                  )}
                />
              )}
            />
          </div>
          <div className="alert-configuration-form__status-alerts-form">
            <Controller
              control={control}
              name="statusAlertTypes"
              render={({ field: { onBlur, value } }) => (
                <RSCheckboxGroup
                  formLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.selectStatusAlertTypes')}
                  formLabelProps={{ id: 'status-alert-types', disabled: watch('enableStatusAlerts') === false }}
                  aria-labelledby="status-alert-types"
                  data-testid="alert-configuration-form-status-alerts-checkbox-group"
                  onBlur={onBlur}
                >
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.deviceState')}
                    checked={includes(value, RocAlertStatusType.DeviceState)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.DeviceState, event.target.checked)}
                    data-testid="alert-configuration-form-device-state-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.safetyTriggered')}
                    checked={includes(value, RocAlertStatusType.SafetyTriggered)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.SafetyTriggered, event.target.checked)}
                    data-testid="alert-configuration-form-safety-triggered-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.connectivityState')}
                    checked={includes(value, RocAlertStatusType.Online)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.Online, event.target.checked)}
                    data-testid="alert-configuration-form-connectivity-state-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.memoryUsage')}
                    checked={includes(value, RocAlertStatusType.MemoryUsage)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.MemoryUsage, event.target.checked)}
                    data-testid="user-permission-user-admin"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.storageUsage')}
                    checked={includes(value, RocAlertStatusType.StorageUsage)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.StorageUsage, event.target.checked)}
                    data-testid="alert-configuration-form-storage-usage-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.queueLength')}
                    checked={includes(value, RocAlertStatusType.QueueLength)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.QueueLength, event.target.checked)}
                    data-testid="alert-configuration-form-queue-length-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                  <RSCheckbox
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.zOffset')}
                    checked={includes(value, RocAlertStatusType.ZOffset)}
                    onChange={(event) => handleCheckboxSelect(RocAlertStatusType.ZOffset, event.target.checked)}
                    data-testid="alert-configuration-form-z-offset-checkbox"
                    color="success"
                    onBlur={onBlur}
                    disabled={watch('enableStatusAlerts') === false}
                  />
                </RSCheckboxGroup>
              )}
            />
            <Controller
              control={control}
              name="enableStatusAlertRecovery"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.includeRecoveryAlerts')}
                  formLabelProps={{ disabled: watch('enableStatusAlerts') === false }}
                  data-testid="alert-configuration-form-include-recovery-alerts-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={watch('enableStatusAlerts') === false}
                  falseValueLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.no')}
                  trueValueLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.yes')}
                />
              )}
            />
          </div>
        </div>
        <div className="alert-configuration-form__acd-cycle-alerts">
          <div className="alert-configuration-form__acd-cycle-alerts-title">
            <span className="alert-configuration-form__alert-inclusion-title">
              {t('companyAdminDetailsPage.alertConfigurationDrawer.acdCycleAlerts')}
            </span>
            <Controller
              control={control}
              name="enableAcdCycleAlerts"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  data-testid="alert-configuration-form-acd-cycle-alerts-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  falseValueLabel={t(
                    'companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.excluded'
                  )}
                  trueValueLabel={t(
                    'companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.included'
                  )}
                />
              )}
            />
          </div>
          <div className="alert-configuration-form__acd-cycle-alerts-form">
            <Controller
              control={control}
              name="deviceOperationResultSelectionType"
              render={({ field: { onBlur, onChange, value, name } }) => (
                <RSRadioGroup
                  formLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.title')}
                  formLabelProps={{
                    id: 'acd-cycle-alerts-selection-type',
                    disabled: watch('enableAcdCycleAlerts') === false
                  }}
                  aria-labelledby="acd-cycle-alerts-selection-type"
                  data-testid="alert-configuration-form-acd-cycle-alerts-selection-type-radio-group"
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  color="success"
                >
                  <RSRadio
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.all')}
                    value={SelectionType.All}
                    data-testid="alert-configuration-form-acd-cycle-alerts-selection-type-all"
                    disabled={watch('enableAcdCycleAlerts') === false}
                  />
                  <RSRadio
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.allExcept')}
                    value={SelectionType.AllExcept}
                    data-testid="alert-configuration-form-acd-cycle-alerts-selection-type-all-except"
                    disabled={watch('enableAcdCycleAlerts') === false}
                  />
                  <RSRadio
                    label={t('companyAdminDetailsPage.alertConfigurationDrawer.selectionType.only')}
                    value={SelectionType.Specific}
                    data-testid="alert-configuration-form-acd-cycle-alerts-selection-type-specific"
                    disabled={watch('enableAcdCycleAlerts') === false}
                  />
                </RSRadioGroup>
              )}
            />
            {watch('deviceOperationResultSelectionType') !== SelectionType.All && (
              <Controller
                control={control}
                name="deviceOperationResultIds"
                render={({ field: { onBlur, onChange, value } }) => (
                  <RSAutocomplete
                    className="alert-configuration-form__acd-cycle-alerts-selection-type-autocomplete"
                    inputLabel={t('companyAdminDetailsPage.alertConfigurationDrawer.selectResults')}
                    data-testid="alert-configuration-form-acd-cycle-selected-results-autocomplete"
                    options={operationResultOptions}
                    customMenuOption={getOperationResultDisplayOption(dataOperationResults, true)}
                    customTagOption={operationResultDisplayTag}
                    value={filter(value, (valueItem) => includes(operationResultOptions, valueItem))}
                    onChange={(_event, values) => onChange(values)}
                    onBlur={onBlur}
                    disabled={watch('enableAcdCycleAlerts') === false}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>
      <DrawerButtonsGroup
        handleCancel={handleCancel}
        handleSave={handleSubmit(onSubmit)}
        isSaveDisabled={loadingCreateRocAlertConfiguration}
        isCancelDisabled={loadingCreateRocAlertConfiguration}
        colorVariant="success"
      />
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loadingCreateRocAlertConfiguration}
        disableCancelButton={loadingCreateRocAlertConfiguration}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </form>
  );
};
