import { ACDCycleOperationResultSuccessRates } from '../../types/acdcycle-operation-result-success-rates';
import { calculateChangePercentage } from '../calculate-change-percentage/calculate-change-percentage';
import { calculateSuccessPercentage } from '../calculate-success-percentage/calculate-success-percentage';
import { execIfBothDefined } from '../exec-if-both-defined/exec-if-both-defined';

const calculateRates = (
  failed: number | null,
  success: number | null,
  failedPrev: number | null,
  successPrev: number | null
): { rate: number | null; prevRate: number | null; rateChange: number | null } => {
  const rate = calculateSuccessPercentage(failed, success);

  const prevRate = calculateSuccessPercentage(failedPrev, successPrev);

  const rateChange = execIfBothDefined(rate, prevRate, (val1, val2) => val1 - val2);
  return {
    rate,
    prevRate,
    rateChange
  };
};

export const calculateACDCycleOperationsResultSuccessRates = (
  acdCycleOperationsFullyCompleted: number | null | undefined,
  acdCycleOperationsPartiallyCompleted: number | null | undefined,
  acdCycleOperationsUnexpectedBehavior: number | null | undefined,
  acdCycleOperationsPrevFullyCompleted: number | null | undefined,
  acdCycleOperationsPrevPartiallyCompleted: number | null | undefined,
  acdCycleOperationsPrevUnexpectedBehavior: number | null | undefined
): ACDCycleOperationResultSuccessRates => {
  const acdCycleOperationsUnexpectedBehaviorAndPartiallyCompleted =
    (acdCycleOperationsUnexpectedBehavior ?? 0) + (acdCycleOperationsPartiallyCompleted ?? 0);

  const acdCycleOperationsPrevUnexpectedBehaviorAndPartiallyCompleted =
    (acdCycleOperationsPrevUnexpectedBehavior ?? 0) + (acdCycleOperationsPrevPartiallyCompleted ?? 0);

  const { rate: acdCycleOperationsFullyCompletedRate, rateChange: acdCycleOperationsFullCompleteRateChange } =
    calculateRates(
      acdCycleOperationsUnexpectedBehaviorAndPartiallyCompleted,
      acdCycleOperationsFullyCompleted ?? null,
      acdCycleOperationsPrevUnexpectedBehaviorAndPartiallyCompleted,
      acdCycleOperationsPrevFullyCompleted ?? null
    );

  const acdCycleOperationsPartiallyCompletedAndFullyCompleted =
    (acdCycleOperationsPartiallyCompleted ?? 0) + (acdCycleOperationsFullyCompleted ?? 0);

  const acdCycleOperationsPrevPartiallyCompletedAndFullyCompleted =
    (acdCycleOperationsPrevPartiallyCompleted ?? 0) + (acdCycleOperationsPrevFullyCompleted ?? 0);

  const { rate: acdCycleOperationsOperationalRate, rateChange: acdCycleOperationsOperationalRateChange } =
    calculateRates(
      acdCycleOperationsUnexpectedBehavior ?? null,
      acdCycleOperationsPartiallyCompletedAndFullyCompleted,
      acdCycleOperationsPrevUnexpectedBehavior ?? null,
      acdCycleOperationsPrevPartiallyCompletedAndFullyCompleted
    );

  const acdCycleOperationsCount =
    (acdCycleOperationsPartiallyCompletedAndFullyCompleted ?? 0) + (acdCycleOperationsUnexpectedBehavior ?? 0);

  const acdCycleOperationsPrevCount =
    (acdCycleOperationsPrevPartiallyCompletedAndFullyCompleted ?? 0) + (acdCycleOperationsPrevUnexpectedBehavior ?? 0);

  const acdCycleOperationsCountChange = calculateChangePercentage(acdCycleOperationsPrevCount, acdCycleOperationsCount);

  return {
    acdCycleOperationsFullyCompletedRate: acdCycleOperationsFullyCompletedRate,
    acdCycleOperationsFullyCompletedRateChange: acdCycleOperationsFullCompleteRateChange,
    acdCycleOperationsOperationalRate: acdCycleOperationsOperationalRate,
    acdCycleOperationsOperationalRateChange: acdCycleOperationsOperationalRateChange,
    acdCycleOperationsCount,
    acdCycleOperationsCountChange
  };
};
