import i18n from '../../i18n';
import { AnnotationResult } from '../../types/operation-annotation';

export const mapAnnotationResultDisplayLabel = (annotationResult: AnnotationResult): string | undefined => {
  switch (annotationResult) {
    case AnnotationResult.Annotated:
      return i18n.t('operationsPage.annotationResult.annotated');
    case AnnotationResult.NotAnnotated:
      return i18n.t('operationsPage.annotationResult.notAnnotated');
    default:
      return undefined;
  }
};
