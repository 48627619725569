import { QueryHookOptions, useQuery } from '@apollo/client';
import { JSX } from 'react';

import { GetDeviceCurrentPerformanceAggregateQuery } from '../../../__generated__/graphql';
import { QUERY_GET_DEVICE_CURRENT_PERFORMANCE_AGGREGATE } from '../../../services/queries/devices/get-device-current-performance-aggregate';
import { calculateACDCycleOperationsResultSuccessRates } from '../../../utilities/calculate-acdcycle-operation-result-success-rates/calculate-acdcycle-operation-result-success-rates';
import { PerformanceMetricsRender } from '../../4-features/performance-metrics-render/performance-metrics-render';

interface PerformanceMetricsDeviceAggregateProps {
  queryHookOptions?: QueryHookOptions<GetDeviceCurrentPerformanceAggregateQuery>;
}

export const PerformanceMetricsDeviceAggregate = ({
  queryHookOptions
}: PerformanceMetricsDeviceAggregateProps): JSX.Element => {
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_CURRENT_PERFORMANCE_AGGREGATE, { ...queryHookOptions });

  const counts = data?.deviceCurrentPerformanceAggregate.aggregate?.sum || {};

  const values = calculateACDCycleOperationsResultSuccessRates(
    counts?.fullyCompletedLastPeriod,
    counts?.partiallyCompletedLastPeriod,
    counts?.unexpectedBehaviorLastPeriod,
    counts.fullyCompletedPrevPeriod,
    counts?.partiallyCompletedPrevPeriod,
    counts?.unexpectedBehaviorPrevPeriod
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsFullyCompletedRate={values.acdCycleOperationsFullyCompletedRate}
      acdCycleOperationsFullyCompletedRateChange={values.acdCycleOperationsFullyCompletedRateChange}
      acdCycleOperationsOperationalRate={values.acdCycleOperationsOperationalRate}
      acdCycleOperationsOperationalRateChange={values.acdCycleOperationsOperationalRateChange}
      acdCycleOperationsCount={values.acdCycleOperationsCount}
      acdCycleOperationsCountChange={values.acdCycleOperationsCountChange}
      loadingTotalAcdCyclesOperationResult={loading}
      errorTotalAcdCyclesOperationResult={Boolean(error)}
    />
  );
};
