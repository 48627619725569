import { z } from 'zod';

import { UserPermissionType } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';

export const modifyUserSchema = z.object({
  id: z.string().uuid(),
  firstName: z
    .string({
      required_error: i18n.t('admin.errors.emptyInput'),
      invalid_type_error: i18n.t('admin.errors.emptyInput')
    })
    .trim()
    .min(1, { message: i18n.t('admin.errors.emptyInput') }),
  lastName: z
    .string({
      required_error: i18n.t('admin.errors.emptyInput'),
      invalid_type_error: i18n.t('admin.errors.emptyInput')
    })
    .trim()
    .min(1, { message: i18n.t('admin.errors.emptyInput') }),
  isSuperUser: z.boolean(),
  permissions: z.array(z.nativeEnum(UserPermissionType))
});

export type ModifyUserRequest = z.infer<typeof modifyUserSchema>;
