import { join } from 'lodash';

import { GetAdminUsersQuery, UserPermissionType, UserType } from '../../../../__generated__/graphql';
import { CompanyType } from '../../../../types/company-type';
import { FlattenFunction } from '../../../../types/flatten';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { mapUserPermissionType } from '../../../../utilities/map-display-labels/map-user-permission-type';
import { mapUserType } from '../../../../utilities/map-display-labels/map-user-type';

export const generateUsersAdminRows = (users?: GetAdminUsersQuery['users'], userTimezone?: string) => {
  if (!users) {
    return [];
  }

  return users.map((user) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      company: user.company.name,
      companyType: mapCompanyType(user.company.companyType as CompanyType),
      userType: mapUserType(user.userType as UserType),
      deviceGroups: user.userDeviceGroupsAggregate.aggregate?.count || 0,
      superUser: user.isSuperUser,
      permissions: join(
        user.userPermissionLinks.map((permission) =>
          mapUserPermissionType(permission.userPermission as UserPermissionType)
        ),
        ', '
      ),
      createdAt: formatTimestamp(user.createdAt, userTimezone),
      updatedAt: formatTimestamp(user.updatedAt, userTimezone)
    };
  });
};

export type UsersAdminRow = FlattenFunction<typeof generateUsersAdminRows>;
