import { gql } from '../../../__generated__/gql';

export const QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE = gql(`
  query getAllDeviceOperationsPerformance(
    $filtersFullyCompleted: DeviceOperationBoolExp
    $filtersPartiallyCompleted: DeviceOperationBoolExp
    $filtersUnexpectedBehavior: DeviceOperationBoolExp
  ) {
    totalACDCycleOperationsFullyCompleted: deviceOperationsAggregate(where: $filtersFullyCompleted) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsPartiallyCompleted: deviceOperationsAggregate(where: $filtersPartiallyCompleted) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsUnexpectedBehavior: deviceOperationsAggregate(where: $filtersUnexpectedBehavior) {
      aggregate {
        count
      }
    }
  }
`);
