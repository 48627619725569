import { gql } from '../../../../__generated__/gql';

export const MUTATION_UNLINK_DEVICE_FROM_DEVICE_GROUP = gql(`
  mutation unlinkDeviceFromDeviceGroup($deviceGroupId: String!, $deviceId: String!) {
    removeDeviceFromDeviceGroupLink(deviceGroupId: $deviceGroupId, deviceId: $deviceId) {
      deviceGroupId
      deviceId
    }
  }
`);
