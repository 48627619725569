import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, isEqual, omit, pick } from 'lodash';
import qs from 'qs';
import { JSX, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  GetCompanyNamesQuery,
  GetUserNamesQuery,
  UserPermissionType,
  UserType
} from '../../../../__generated__/graphql';
import { CompanyType } from '../../../../types/company-type';
import { Flatten } from '../../../../types/flatten';
import { IsSuperUser } from '../../../../types/is-super-user';
import { RSAutocompleteValue } from '../../../../types/rs-autocomplete';
import { filterValidUrlFields } from '../../../../utilities/filter-valid-url-fields/filter-valid-url-fields';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { mapUserPermissionType } from '../../../../utilities/map-display-labels/map-user-permission-type';
import { mapUserType } from '../../../../utilities/map-display-labels/map-user-type';
import { FilterPanelButtonsGroup } from '../../../4-features/filter-panel/filter-panel-buttons-group/filter-panel-buttons-group';
import { RSAutocomplete } from '../../../5-elements/rs-autocomplete/rs-autocomplete';
import { RSAutocompleteDefaultMenuOption } from '../../../5-elements/rs-autocomplete/rs-autocomplete-default-menu-option/rs-autocomplete-default-menu-option';
import { usersAdminFilterFields } from '../generate-queries/generate-filter-query';
import {
  UsersAdminSearchParameters,
  usersAdminStatesSchema
} from '../users-admin-states-schema/users-admin-states-schema';

interface UsersAdminFilterPanelProps {
  userNames?: GetUserNamesQuery;
  companyNames?: GetCompanyNamesQuery;
  isLoading?: boolean;
  defaultValues: UsersAdminSearchParameters;
}

export const UsersAdminFilterPanel = ({
  userNames,
  companyNames,
  isLoading,
  defaultValues
}: UsersAdminFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues
  } = useForm<UsersAdminSearchParameters>({
    resolver: zodResolver(usersAdminStatesSchema),
    defaultValues
  });

  const generateDisplayName = (userNameOption: Flatten<GetUserNamesQuery['users']>): string => {
    return `${userNameOption.firstName} ${userNameOption.lastName}`;
  };

  const getUserByDisplayName = (displayName: string) => {
    return userNames?.users.find((user) => generateDisplayName(user) === displayName);
  };

  const userNameOptions = userNames?.users.map((user) => generateDisplayName(user)) || [];
  const userNameMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={option as string} />;
  };
  const userNameTagDisplayOptions = (option: RSAutocompleteValue) => option as string;

  const onSubmit: SubmitHandler<UsersAdminSearchParameters> = (data): void => {
    const newSearchParams = { ...qs.parse(searchParams.toString()), ...data, page: '1' };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<UsersAdminSearchParameters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter = omit(searchParamsObject, usersAdminFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({ name: [], userType: [], companyType: [], companyName: [], permissions: [], superUser: [] });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());
    const validUrlFields = filterValidUrlFields<UsersAdminSearchParameters>(searchParameters, usersAdminStatesSchema);
    const filterParameters = pick(validUrlFields, usersAdminFilterFields);
    const resetObject: UsersAdminSearchParameters = {
      name: filterParameters.name || [],
      userType: filterParameters.userType || [],
      permissions: filterParameters.permissions || [],
      companyType: filterParameters.companyType || [],
      companyName: filterParameters.companyName || [],
      superUser: filterParameters.superUser || []
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  const companyNameOptions = (companyNames?.companies.map((company) => company.name) as string[]) || [];
  const companyTypeOptions = Object.values(CompanyType);
  const companyTypeMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapCompanyType(option as CompanyType)} />;
  };
  const companyTypeTagDisplayOptions = (option: RSAutocompleteValue) => mapCompanyType(option as CompanyType);
  const userTypeOptions = Object.values(UserType);
  const userTypeMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapUserType(option as UserType)} />;
  };
  const userTypeTagDisplayOptions = (option: RSAutocompleteValue) => mapUserType(option as UserType);
  const permissionOptions = Object.values(UserPermissionType);
  const permissionMenuDisplayOptions = (option: RSAutocompleteValue) => {
    return <RSAutocompleteDefaultMenuOption option={mapUserPermissionType(option as UserPermissionType)} />;
  };
  const permissionTagDisplayOptions = (option: RSAutocompleteValue) =>
    mapUserPermissionType(option as UserPermissionType);
  const superUserOptions = Object.values(IsSuperUser);

  return (
    <aside className="users-admin-filter-panel" data-testid="users-admin-filter-panel">
      <form className="users-admin-filter-panel__form">
        <div className="companies-admin-filter-panel__filters">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.name')}
                  data-testid="users-admin-name-autocomplete"
                  options={userNameOptions}
                  customMenuOption={userNameMenuDisplayOptions}
                  customTagOption={userNameTagDisplayOptions}
                  // We want the search to match the value of `${firstName} ${lastName}`, not the user's uuid.
                  onChange={(_event, values) => {
                    const ids = (values as string[])
                      .map((displayName) => getUserByDisplayName(displayName)?.id)
                      .filter(Boolean);
                    onChange(ids);
                  }}
                  value={
                    value
                      ?.map((id) => {
                        const user = userNames?.users.find((u) => u.id === id);
                        return user ? generateDisplayName(user) : '';
                      })
                      .filter((userName) => userName !== '') || []
                  }
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="companyName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.companyName')}
                  data-testid="users-admin-company-name-autocomplete"
                  options={companyNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(companyNameOptions, valueItem))}
                  onBlur={onBlur}
                  disabled={isLoading}
                />
              );
            }}
          />
          <Controller
            name="companyType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.companyType')}
                  data-testid="users-admin-company-type-autocomplete"
                  options={companyTypeOptions}
                  customMenuOption={companyTypeMenuDisplayOptions}
                  customTagOption={companyTypeTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(companyTypeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="userType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.userType')}
                  data-testid="users-admin-user-type-autocomplete"
                  options={userTypeOptions}
                  customMenuOption={userTypeMenuDisplayOptions}
                  customTagOption={userTypeTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(userTypeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="permissions"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.permissions')}
                  data-testid="users-admin-permissions-autocomplete"
                  options={permissionOptions}
                  customMenuOption={permissionMenuDisplayOptions}
                  customTagOption={permissionTagDisplayOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(permissionOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="superUser"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('usersAdminPage.filterPanel.superUser')}
                  data-testid="users-admin-super-user-autocomplete"
                  options={superUserOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(superUserOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
          variant="admin"
        />
      </form>
    </aside>
  );
};
