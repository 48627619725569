import { useMutation } from '@apollo/client';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { MUTATION_COLLECT_DEVICE_METRICS } from '../../../../services/mutations/devices/collect-device-metrics';
import { getDeviceMeasurementValue } from '../../../../utilities/get-device-measurement-value/get-device-measurement-value';
import { mapDeviceStateToStatusIcon } from '../../../../utilities/map-device-state-to-status-icon/map-device-state-to-status-icon';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { RSActionLink } from '../../../5-elements/rs-action-link/rs-action-link';
import { RSCard } from '../../../5-elements/rs-card/rs-card';
import { RSCardDefaultTitle } from '../../../5-elements/rs-card/rs-card-default-title/rs-card-default-title';
import { RSLinearProgress } from '../../../5-elements/rs-linear-progress/rs-linear-progress';
import { RSTooltip } from '../../../5-elements/rs-tooltip/rs-tooltip';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { DeviceStatus } from '../device-status/device-status';
import {
  calculateUsagePercentage,
  contextualizeConnectivityStatusDisplayValue,
  contextualizeSafetyTriggeredDisplayValue,
  contextualizeZOffsetDisplayValue,
  generateDisplayTimestamp
} from '../device-status/device-status-utilities/contextualize-status-display-value';
import { mapUsageStatusToProgressBarColor } from '../device-status/device-status-utilities/map-value-to-progress-bar-color';
import {
  mapConnectivityToStatusIcon,
  mapMeasurementStatusToStatusIcon,
  mapSafetyTriggeredToStatusIcon
} from '../device-status/device-status-utilities/map-value-to-status-icon-variant';

interface DeviceHealthCardsProps {
  deviceId: string;
  canUserEditDevice?: boolean;
  deviceLastState?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceLastState'];
  deviceMeasurementValues?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceMeasurementValues'];
}

export const DeviceHealthCards = ({
  deviceId,
  canUserEditDevice,
  deviceLastState,
  deviceMeasurementValues = []
}: DeviceHealthCardsProps): JSX.Element => {
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();

  const [collectDeviceMetrics, { loading: loadingCollectDeviceMetrics }] = useMutation(
    MUTATION_COLLECT_DEVICE_METRICS,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.deviceHealth.connectivityState.snackbarMessages.reCheckConnectivityError'),
          undefined,
          error.message || error.name,
          'error'
        );
      },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.deviceHealth.connectivityState.snackbarMessages.reCheckConnectivityOK'),
          undefined,
          undefined,
          'success'
        );
      }
    }
  );

  const handleCollectDeviceMetrics = (): void => {
    collectDeviceMetrics({ variables: { deviceIds: [deviceId] } });
  };

  const safetyTriggeredMeasurement = getDeviceMeasurementValue('safety_triggered', deviceMeasurementValues);
  const connectivityMeasurement = getDeviceMeasurementValue('is_online', deviceMeasurementValues);
  const memoryUsageMeasurement = getDeviceMeasurementValue('memory_usage', deviceMeasurementValues);
  const memoryTotalMeasurement = getDeviceMeasurementValue('memory_total', deviceMeasurementValues);
  const storageUsageMeasurement = getDeviceMeasurementValue('storage_usage', deviceMeasurementValues);
  const storageTotalMeasurement = getDeviceMeasurementValue('storage_total', deviceMeasurementValues);
  const queueLengthMeasurement = getDeviceMeasurementValue('remote_queue_length', deviceMeasurementValues);
  const zOffsetMeasurement = getDeviceMeasurementValue('z_offset', deviceMeasurementValues);

  const safetyTriggeredValue = contextualizeSafetyTriggeredDisplayValue(safetyTriggeredMeasurement?.value);
  const connectivityStateValue = contextualizeConnectivityStatusDisplayValue(connectivityMeasurement?.value);
  const memoryUsageValue = calculateUsagePercentage(memoryUsageMeasurement?.value, memoryTotalMeasurement?.value);
  const storageUsageValue = calculateUsagePercentage(storageUsageMeasurement?.value, storageTotalMeasurement?.value);
  const zOffsetValue = contextualizeZOffsetDisplayValue(zOffsetMeasurement?.value);

  return (
    <div className="device-health-cards">
      <RSCard
        dataTestId="device-health-card-device-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.deviceState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapDeviceStateToStatusIcon(deviceLastState?.deviceStatus)}
              statusValue={deviceLastState?.deviceStatus}
              stateName={deviceLastState?.stateName}
            />
          }
          subValue={generateDisplayTimestamp(deviceLastState?.updatedAt, formatWithDefaultTimezone)}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-safety-triggered"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.safetyTriggered.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapSafetyTriggeredToStatusIcon(safetyTriggeredMeasurement?.value)}
              statusValue={safetyTriggeredValue}
            />
          }
          subValue={generateDisplayTimestamp(safetyTriggeredMeasurement?.updatedAt, formatWithDefaultTimezone)}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-connectivity-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.connectivityState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapConnectivityToStatusIcon(connectivityMeasurement?.value)}
              statusValue={connectivityStateValue}
            />
          }
          subValue={generateDisplayTimestamp(connectivityMeasurement?.updatedAt, formatWithDefaultTimezone)}
        />
        {canUserEditDevice && (
          <InformationBlock
            label=""
            value={
              <span className="device-health-card-connectivity-state__recheck-connectivity">
                <RSActionLink
                  data-testid="device-health-card-connectivity-state-recheck"
                  disabled={loadingCollectDeviceMetrics}
                  onClick={handleCollectDeviceMetrics}
                >
                  {t('deviceDetailsPage.deviceHealth.connectivityState.reCheckConnectivity')}
                </RSActionLink>
              </span>
            }
          />
        )}
      </RSCard>
      <RSCard
        dataTestId="device-health-card-system-health"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.measurements.title')} />}
      >
        <div className="device-health-cards__measurements">
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.memory', {
                    usage: memoryUsageMeasurement?.value,
                    total: memoryTotalMeasurement?.value
                  })}
                </span>
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatWithDefaultTimezone(memoryUsageMeasurement?.updatedAt || '')
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(memoryUsageMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.memory')}
                sizeVariant="small"
              />
              <RSLinearProgress
                data-testid="device-health-memory-usage-progress-bar"
                value={memoryUsageValue}
                labelTextSize="large"
                widthVariant="long"
                color={mapUsageStatusToProgressBarColor(memoryUsageMeasurement?.status)}
              />
            </div>
          </RSTooltip>
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.storage', {
                    usage: storageUsageMeasurement?.value,
                    total: storageTotalMeasurement?.value
                  })}
                </span>
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatWithDefaultTimezone(storageUsageMeasurement?.updatedAt || '')
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(storageUsageMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.storage')}
                sizeVariant="small"
              />
              <RSLinearProgress
                data-testid="device-health-storage-usage-progress-bar"
                value={storageUsageValue}
                labelTextSize="large"
                widthVariant="long"
                color={mapUsageStatusToProgressBarColor(storageUsageMeasurement?.status)}
              />
            </div>
          </RSTooltip>
          <RSTooltip
            title={
              <div className="device-health-cards__measurement-tooltip">
                <span>{t('deviceDetailsPage.deviceHealth.measurements.tooltips.queueLength')}</span>
                <span>
                  {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                    timestamp: formatWithDefaultTimezone(queueLengthMeasurement?.updatedAt || '')
                  })}
                </span>
              </div>
            }
            placement="right"
          >
            <div className="device-health-cards__measurement">
              <DeviceStatus
                statusIconVariant={mapMeasurementStatusToStatusIcon(queueLengthMeasurement?.status)}
                statusValue={t('deviceDetailsPage.deviceHealth.measurements.queueLength')}
                sizeVariant="small"
              />
              <span>{queueLengthMeasurement?.value}</span>
            </div>
          </RSTooltip>
          {zOffsetMeasurement && (
            <RSTooltip
              title={
                <div className="device-health-cards__measurement-tooltip">
                  <span>{t('deviceDetailsPage.deviceHealth.measurements.tooltips.zOffset')}</span>
                  <span>
                    {t('deviceDetailsPage.deviceHealth.measurements.tooltips.since', {
                      timestamp: formatWithDefaultTimezone(zOffsetMeasurement?.updatedAt || '')
                    })}
                  </span>
                </div>
              }
              placement="right"
            >
              <div className="device-health-cards__measurement" data-testid="device-health-card-z-offset">
                <DeviceStatus
                  statusIconVariant={mapMeasurementStatusToStatusIcon(zOffsetMeasurement?.status)}
                  statusValue={t('deviceDetailsPage.deviceHealth.measurements.zOffset')}
                  sizeVariant="small"
                />
                <span>
                  {zOffsetValue} {t('deviceDetailsPage.deviceHealth.measurements.zOffsetUnit')}
                </span>
              </div>
            </RSTooltip>
          )}
        </div>
      </RSCard>
    </div>
  );
};
