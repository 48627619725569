import { gql } from '../../../../__generated__/gql';

// note: empty list will sync all devices
// up to 10 devices can be synced synchronously
export const MUTATION_REQUEST_SYNC_DEVICES = gql(`
  mutation requestDeviceSync($serialNumbers: [String!]!) {
    syncDevices(scopes: PROGRAM, deviceSerialNumbers: $serialNumbers) {
      status
    }
  }
`);
