import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddSiteRequest, addSiteSchema } from './validation-schema/add-site-schema';
import { CreateSiteMutationVariables, GetAdminCompanyDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_SITE } from '../../../../services/mutations/admin/companies/create-site';
import { QUERY_GET_TIME_ZONES } from '../../../../services/queries/commons/get-time-zones';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { Loading } from '../../../5-elements/loading/loading';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { RSTextInput } from '../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../error-page/error-page';

interface AddSiteDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenAddSite: Dispatch<SetStateAction<boolean>>;
  refetchCompany: () => Promise<ApolloQueryResult<GetAdminCompanyDetailsQuery>>;
  customerName: string;
  customerId: string;
}

export const AddSiteDrawer = ({
  setOpenAddSite,
  refetchCompany,
  customerName,
  customerId,
  ...props
}: AddSiteDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingTimezones,
    data: dataTimezones,
    error: errorTimezones
  } = useQuery(QUERY_GET_TIME_ZONES, { fetchPolicy: 'no-cache', skip: props.open === false });
  const [createSite, { loading: loadingCreateSite }] = useMutation(MUTATION_CREATE_SITE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('companyAdminDetailsPage.addSiteDrawer.createSiteFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const TIMEZONE_AUTO_DETECT = { name: t('companyAdminDetailsPage.addSiteDrawer.autoDetect') };
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<AddSiteRequest>({
    resolver: zodResolver(addSiteSchema),
    defaultValues: { id: customerId, timezone: TIMEZONE_AUTO_DETECT.name }
  });

  const customerSelectOption: RSSelectItemProps = { displayName: customerName, menuItemProps: { value: customerId } };
  const timeZonesResponse = dataTimezones?.timezones || [];
  const timeZoneOptions: RSSelectItemProps[] = [TIMEZONE_AUTO_DETECT, ...timeZonesResponse].map((timeZone) => {
    return { displayName: timeZone.name, menuItemProps: { value: timeZone.name } };
  });

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenAddSite(false);
  };

  const handleCancel = (): void => {
    if (!isEmpty(dirtyFields)) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenAddSite(false);
    }
  };

  const onSubmit: SubmitHandler<AddSiteRequest> = (data): void => {
    if (isEmpty(dirtyFields)) {
      setOpenAddSite(false);
      setShowConfirmationModal(false);
      return;
    }

    const dataToSubmit: CreateSiteMutationVariables = {
      ...data,
      id: customerId,
      timezone: data.timezone === TIMEZONE_AUTO_DETECT.name ? undefined : data.timezone
    };

    createSite({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(
          t('companyAdminDetailsPage.addSiteDrawer.createSiteSuccess'),
          undefined,
          undefined,
          'success'
        );
        setOpenAddSite(false);
        setShowConfirmationModal(false);
        reset(data);
        refetchCompany();
        navigate(`${appConfig.basePath}/admin/sites/${responseData.createSite.id}`);
      }
    });
  };

  if (errorTimezones) {
    return (
      <RSDrawer {...props} className="add-site-drawer">
        <ModalDrawerHeader title={t('usersAdminPage.addUserDrawer.title')} />
        <div className="add-site-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorTimezones.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingTimezones) {
    return (
      <RSDrawer {...props} className="modify-site-drawer">
        <ModalDrawerHeader title={t('siteAdminDetailsPage.modifySiteDrawer.title')} />
        <div className="modify-site-drawer__form">
          <div className="modify-site-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="add-site-drawer">
      <ModalDrawerHeader title="Add site" />
      <form className="add-site-drawer__form" onSubmit={handleSubmit(onSubmit)} data-testid="add-site-drawer-form">
        <div className="add-site-drawer__input-fields">
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.siteName')}
            required={true}
            data-testid="add-site-drawer-name-input"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <RSSelect
            className="add-company-drawer__customer-select"
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.customer')}
            required={true}
            disabled={true}
            menuItems={[customerSelectOption]}
            helperText={errors.id?.message}
            error={Boolean(errors.id)}
            data-testid="add-company-drawer-customer-select"
            defaultValue={customerId}
            {...register('id')}
            {...RS_SELECT_ADMIN_MENU_PROPS}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.address')}
            data-testid="add-site-drawer-address-input"
            helperText={errors.location?.formattedAddress?.message}
            error={Boolean(errors.location?.formattedAddress)}
            {...register('location.formattedAddress', { setValueAs: (value) => (value === '' ? undefined : value) })}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.latitude')}
            type="number"
            required={true}
            helperText={errors.location?.latitude?.message}
            error={Boolean(errors.location?.latitude)}
            data-testid="add-site-drawer-latitude-input"
            {...register('location.latitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT.name)
            })}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.longitude')}
            type="number"
            required={true}
            helperText={errors.location?.longitude?.message}
            error={Boolean(errors.location?.longitude)}
            data-testid="add-site-drawer-longitude-input"
            {...register('location.longitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT.name)
            })}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                inputLabel={t('companyAdminDetailsPage.addSiteDrawer.timezone')}
                inputLabelTooltip={t('tooltips.site.timezone')}
                menuItems={timeZoneOptions}
                helperText={errors.timezone?.message}
                error={Boolean(errors.timezone)}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                data-testid="add-site-drawer-timezone-select"
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.addSiteDrawer.description')}
            data-testid="add-site-drawer-description-input"
            multiline={true}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description', { setValueAs: (value) => (value === '' ? undefined : value) })}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingCreateSite}
          isCancelDisabled={loadingCreateSite}
          colorVariant="success"
          saveButtonText={t('companyAdminDetailsPage.addSiteDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loadingCreateSite}
        disableCancelButton={loadingCreateSite}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};
