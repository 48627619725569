import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetAdminCompanyDetailsQuery,
  UserDeviceGroupRole,
  UserDeviceGroupUserRole
} from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { CompanyType } from '../../../../types/company-type';
import { contextualizeBoolean } from '../../../../utilities/contextualize-boolean/contextualize-boolean';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { mapCompanyType } from '../../../../utilities/map-display-labels/map-company-type';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { AdminDetailsPanelTitle } from '../../../4-features/admin/admin-details-panel-title/admin-details-panel-title';
import { ExternalLink } from '../../../5-elements/external-link/external-link';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { ModifyCompanyDrawer } from '../modify-company-drawer/modify-company-drawer';

interface CompanyDetailsAdminPanelProps {
  // This panel will only be shown if the company details are successfully loaded
  companyDetails: NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>;
}

export const CompanyDetailsAdminPanel = ({ companyDetails }: CompanyDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [openModifyCompany, setOpenModifyCompany] = useState<boolean>(false);
  const isCustomer = companyDetails.companyType === CompanyType.Customer;
  const { result: canUserModifyCompany, loading: loadingCanUserUpdateCompany } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: { type: 'Company', companyId: companyDetails.id },
    skip: false
  });

  return (
    <aside className="company-details-admin-panel" data-testid="company-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={mapCompanyType(companyDetails.companyType as CompanyType)}
        objectName={companyDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies`}
        editButtonProps={{
          disabled: !canUserModifyCompany || loadingCanUserUpdateCompany,
          onClick: () => setOpenModifyCompany(true)
        }}
      />
      <div className="company-details-admin-panel__contents">
        {isCustomer && (
          <InformationBlock
            label={t('companyAdminDetailsPage.panel.allowImport')}
            labelTooltip={t('tooltips.company.allowImport')}
            data-testid="company-details-admin-panel-allow-import"
            value={contextualizeBoolean(
              t('companyAdminDetailsPage.panel.allowImportValues.enabled'),
              t('companyAdminDetailsPage.panel.allowImportValues.disabled'),
              companyDetails.customer?.allowImport
            )}
          />
        )}
        <InformationBlock
          label={t('companyAdminDetailsPage.panel.maxDeviceGroupRole')}
          labelTooltip={t('tooltips.company.maxDeviceGroupRole')}
          data-testid="company-details-admin-panel-max-device-group-role"
          value={mapUserDeviceGroupRole(companyDetails.maxDeviceGroupRole as UserDeviceGroupRole)}
        />
        {isCustomer && (
          <InformationBlock
            label={t('companyAdminDetailsPage.panel.ipAcceptList')}
            labelTooltip={t('tooltips.company.rocsysApiIpAcceptList')}
            data-testid="company-details-admin-panel-ip-list"
            value={getNonNullishDisplayValue(companyDetails.companySettings.rocsysApiIpAcceptlist)}
          />
        )}
        {isCustomer && (
          <InformationBlock
            label={t('companyAdminDetailsPage.panel.documentCenterUrl')}
            labelTooltip={t('tooltips.company.documentCenterUrl')}
            data-testid="company-details-admin-panel-document-center-url"
            value={
              companyDetails.customer?.documentCenterUrl ? (
                <ExternalLink url={companyDetails.customer.documentCenterUrl}>
                  <span className="company-details-admin-panel__external-url-link">
                    {companyDetails.customer?.documentCenterUrl}
                  </span>
                </ExternalLink>
              ) : (
                getNonNullishDisplayValue(companyDetails.customer?.documentCenterUrl)
              )
            }
          />
        )}
        <InformationBlock
          label={t('companyAdminDetailsPage.panel.createdAt')}
          data-testid="company-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(companyDetails.createdAt)}
        />
        <InformationBlock
          label={t('companyAdminDetailsPage.panel.updatedAt')}
          data-testid="company-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(companyDetails.updatedAt)}
        />
      </div>
      <ModifyCompanyDrawer
        open={openModifyCompany}
        setOpenModifyCompany={setOpenModifyCompany}
        companyType={companyDetails.companyType as CompanyType}
        createdAt={formatWithDefaultTimezone(companyDetails.createdAt)}
        defaultValues={{
          id: companyDetails.id,
          name: companyDetails.name,
          maxDeviceGroupRole: companyDetails.maxDeviceGroupRole as UserDeviceGroupUserRole,
          allowImport: companyDetails.customer?.allowImport,
          documentCenterUrl: companyDetails.customer?.documentCenterUrl,
          rocsysApiIpAcceptlist: companyDetails.companySettings.rocsysApiIpAcceptlist
        }}
      />
    </aside>
  );
};
