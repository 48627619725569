import { DateTime } from 'luxon';

import { DeviceGroupUserValidityDuration } from '../../types/device-group-user-validity-duration';

export const mapValidityDurationToString = (
  validityDuration: DeviceGroupUserValidityDuration,
  userTimeZone: string
): string | null => {
  const timeNow = DateTime.now().setZone(userTimeZone);

  if (validityDuration === DeviceGroupUserValidityDuration.OneDay) {
    return timeNow.plus({ hours: 24 }).startOf('hour').toISO();
  }
  if (validityDuration === DeviceGroupUserValidityDuration.SevenDays) {
    return timeNow.plus({ hours: 168 }).startOf('hour').toISO(); // 168 hours = 7 days
  }
  if (validityDuration === DeviceGroupUserValidityDuration.ThirtyDays) {
    return timeNow.plus({ hours: 720 }).startOf('hour').toISO(); // 720 hours = 30 days
  }

  return null;
};
