import { isNil } from 'lodash';

import { UserDeviceGroupRole, UserPermissionType } from '../../__generated__/graphql';

export class AuthUser {
  constructor(
    public readonly email: string,
    public readonly companyId: string,
    public readonly isSuperUser: boolean,
    public readonly permissions: UserPermissionType[],
    public readonly userDeviceGroups?: AuthUserDeviceGroup[],
    public readonly id?: string,
    public readonly customerAllowsImport?: boolean
  ) {}
}

export class AuthCompany {
  constructor(
    public readonly id?: string,
    public readonly name?: string
  ) {}
}

export class AuthCustomer {
  constructor(
    public readonly id: string,
    public readonly allowImport?: boolean
  ) {}
}

export class AuthSite {
  constructor(public readonly customer: AuthCustomer) {}
}

export class AuthDeviceGroup {
  constructor(
    public readonly customerId: string,
    public readonly serviceProviderId?: string,
    public readonly id?: string
  ) {}
}

export class AuthDeviceGroupUserLinks {
  constructor(
    public readonly customerId: string,
    public readonly serviceProviderId?: string,
    public readonly id?: string
  ) {}
}

export class AuthDeviceGroupDeviceLinks {
  constructor(
    public readonly customerId: string,
    public readonly serviceProviderId?: string,
    public readonly id?: string
  ) {}
}

export class AuthDeviceOperation {
  constructor(
    public readonly device: AuthDevice,
    public readonly name: string
  ) {}
}

export class AuthDeviceGroupDevice {
  constructor(
    public readonly deviceGroup: AuthDeviceGroup,
    public readonly device: AuthDevice
  ) {}
}

export class AuthDevice {
  constructor(
    public readonly site?: AuthSite,
    public readonly deviceGroups?: AuthDeviceGroup[]
  ) {}
}

export class AuthDeviceSite {
  constructor(public readonly device?: AuthDevice) {}
}

export class AuthDeviceBasicDetails {
  constructor(public readonly device?: AuthDevice) {}
}

export class AuthDeviceCloudSettings {
  constructor(public readonly device?: AuthDevice) {}
}

export class AuthUserDeviceGroup {
  constructor(
    public readonly deviceGroup: AuthDeviceGroup,
    public readonly userRole: UserDeviceGroupRole,
    // optional - to avoid circular references
    public readonly user?: AuthUser
  ) {}
}

export class AuthConnectorHolderType {
  constructor(public readonly name?: string) {}
}

export class AuthDeviceType {
  constructor(
    public readonly name?: string,
    public readonly modelNumber?: string
  ) {}
}

export class AuthRocAlertConfiguration {
  constructor(public readonly companyId: string) {}
}

export type Action = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export type Subject =
  | AuthUser
  | AuthCompany
  | AuthDeviceOperation
  | AuthSite
  | AuthDeviceGroup
  | AuthDeviceGroupUserLinks
  | AuthDeviceGroupDeviceLinks
  | AuthDeviceGroupDevice
  | AuthDevice
  | AuthDeviceBasicDetails
  | AuthDeviceCloudSettings
  | AuthDeviceCloudSettings
  | AuthDeviceType
  | AuthUserDeviceGroup
  | AuthConnectorHolderType
  | AuthRocAlertConfiguration;

type SubjectType =
  | 'User'
  | 'Company'
  | 'DeviceOperation'
  | 'Site'
  | 'DeviceGroup'
  | 'DeviceGroupUserLinks'
  | 'DeviceGroupDeviceLinks'
  | 'DeviceGroupDevice'
  | 'Device'
  | 'DeviceBasicDetails'
  | 'DeviceSite'
  | 'DeviceCloudSettings'
  | 'DeviceType'
  | 'UserDeviceGroup'
  | 'ConnectorHolderType'
  | 'RocAlertConfiguration';

type VerifiedSubject =
  | { entity: AuthUser; type: 'User' }
  | { entity: AuthDeviceOperation; type: 'DeviceOperation' }
  | { entity: AuthCompany; type: 'Company' }
  | { entity: AuthSite; type: 'Site' }
  | { entity: AuthDeviceGroup; type: 'DeviceGroup' }
  | { entity: AuthDeviceGroupDevice; type: 'DeviceGroupDevice' }
  | { entity: AuthDevice; type: 'Device' }
  | { entity: AuthDeviceSite; type: 'DeviceSite' }
  | { entity: AuthDeviceBasicDetails; type: 'DeviceBasicDetails' }
  | { entity: AuthDeviceCloudSettings; type: 'DeviceCloudSettings' }
  | { entity: AuthDeviceType; type: 'DeviceType' }
  | { entity: AuthUserDeviceGroup; type: 'UserDeviceGroup' }
  | { entity: AuthDeviceGroupUserLinks; type: 'DeviceGroupUserLinks' }
  | { entity: AuthDeviceGroupDeviceLinks; type: 'DeviceGroupDeviceLinks' }
  | { entity: AuthUserDeviceGroup; type: 'UserDeviceGroup' }
  | { entity: AuthConnectorHolderType; type: 'ConnectorHolderType' }
  | { entity: AuthRocAlertConfiguration; type: 'RocAlertConfiguration' };

export class AuthzCheckerService {
  constructor() {}

  deriveSubjectType(subject: Subject): SubjectType {
    if (subject instanceof AuthUser) {
      return 'User';
    }
    if (subject instanceof AuthCompany) {
      return 'Company';
    }
    if (subject instanceof AuthDeviceOperation) {
      return 'DeviceOperation';
    }
    if (subject instanceof AuthSite) {
      return 'Site';
    }
    if (subject instanceof AuthDeviceGroup) {
      return 'DeviceGroup';
    }
    if (subject instanceof AuthDeviceGroupUserLinks) {
      return 'DeviceGroupUserLinks';
    }
    if (subject instanceof AuthDeviceGroupDeviceLinks) {
      return 'DeviceGroupDeviceLinks';
    }
    if (subject instanceof AuthDevice) {
      return 'Device';
    }
    if (subject instanceof AuthDeviceBasicDetails) {
      return 'DeviceBasicDetails';
    }
    if (subject instanceof AuthDeviceSite) {
      return 'DeviceSite';
    }
    if (subject instanceof AuthDeviceCloudSettings) {
      return 'DeviceCloudSettings';
    }
    if (subject instanceof AuthDeviceGroupDevice) {
      return 'DeviceGroupDevice';
    }
    if (subject instanceof AuthUserDeviceGroup) {
      return 'UserDeviceGroup';
    }
    if (subject instanceof AuthConnectorHolderType) {
      return 'ConnectorHolderType';
    }
    if (subject instanceof AuthDeviceType) {
      return 'DeviceType';
    }
    if (subject instanceof AuthRocAlertConfiguration) {
      return 'RocAlertConfiguration';
    }
    throw new Error(`Unknown subject type ${JSON.stringify(subject)}`);
  }

  wrappedSubject(subject: Subject): VerifiedSubject {
    switch (this.deriveSubjectType(subject)) {
      case 'User':
        return { entity: subject as AuthUser, type: 'User' };
      case 'Site':
        return { entity: subject as AuthSite, type: 'Site' };
      case 'DeviceOperation':
        return { entity: subject as AuthDeviceOperation, type: 'DeviceOperation' };
      case 'Company':
        return {
          entity: subject as AuthCompany,
          type: 'Company'
        };
      case 'DeviceGroup':
        return {
          entity: subject as AuthDeviceGroup,
          type: 'DeviceGroup'
        };
      case 'DeviceGroupUserLinks':
        return {
          entity: subject as AuthDeviceGroupUserLinks,
          type: 'DeviceGroupUserLinks'
        };
      case 'DeviceGroupDeviceLinks':
        return {
          entity: subject as AuthDeviceGroupDeviceLinks,
          type: 'DeviceGroupDeviceLinks'
        };
      case 'DeviceGroupDevice':
        return {
          entity: subject as AuthDeviceGroupDevice,
          type: 'DeviceGroupDevice'
        };
      case 'Device':
        return {
          entity: subject as AuthDevice,
          type: 'Device'
        };
      case 'DeviceSite':
        return {
          entity: subject as AuthDeviceSite,
          type: 'DeviceSite'
        };
      case 'DeviceBasicDetails':
        return {
          entity: subject as AuthDeviceBasicDetails,
          type: 'DeviceBasicDetails'
        };
      case 'DeviceCloudSettings':
        return {
          entity: subject as AuthDeviceCloudSettings,
          type: 'DeviceCloudSettings'
        };
      case 'DeviceType':
        return {
          entity: subject as AuthDeviceType,
          type: 'DeviceType'
        };
      case 'UserDeviceGroup':
        return {
          entity: subject as AuthUserDeviceGroup,
          type: 'UserDeviceGroup'
        };
      case 'ConnectorHolderType':
        return {
          entity: subject as AuthConnectorHolderType,
          type: 'ConnectorHolderType'
        };
      case 'RocAlertConfiguration':
        return {
          entity: subject as AuthRocAlertConfiguration,
          type: 'RocAlertConfiguration'
        };
      default:
        throw new Error(`Unknown actor type ${subject.constructor.name}`);
    }
  }

  can(actor: AuthUser, action: Action, subject: Subject): boolean {
    return this._can(actor, action, this.wrappedSubject(subject));
  }

  public _can(user: AuthUser, action: Action, subject: VerifiedSubject): boolean {
    switch (subject.type) {
      case 'User':
        return this.canUserPerformActionOnUser(user, action, subject.entity);
      case 'DeviceOperation':
        return this.canUserPerformActionOnDeviceOperation(user, action, subject.entity);
      case 'Company':
        return this.canUserPerformActionOnCompany(user, action, subject.entity);
      case 'Site':
        return this.canUserPerformActionOnSite(user, action, subject.entity);
      case 'DeviceGroup':
        return this.canUserPerformActionOnDeviceGroup(user, action, subject.entity);
      case 'DeviceGroupUserLinks':
        return this.canUserPerformActionOnDeviceGroupUserLinks(user, action, subject.entity);
      case 'DeviceGroupDeviceLinks':
        return this.canUserPerformActionOnDeviceGroupDeviceLinks(user, action, subject.entity);
      case 'DeviceGroupDevice':
        return this.canUserPerformActionOnDeviceGroupDevice(user, action, subject.entity);
      case 'Device':
        return this.canUserPerformActionOnDevice(user, action, subject.entity);
      case 'DeviceSite':
        return this.canUserPerformActionOnDeviceSite(user, action, subject.entity);
      case 'DeviceBasicDetails':
        return this.canUserPerformActionOnDeviceBasicDetails(user, action, subject.entity);
      case 'DeviceCloudSettings':
        return this.canUserPerformActionOnDeviceCloudSettings(user, action, subject.entity);
      case 'DeviceType':
        return this.canUserPerformActionOnDeviceType(user, action);
      case 'UserDeviceGroup':
        return this.canUserPerformActionOnUserDeviceGroup(user, action, subject.entity);
      case 'ConnectorHolderType':
        return this.canUserPerformActionOnConnectorHolderType(user, action);
      case 'RocAlertConfiguration':
        return this.canUserPerformActionOnRocAlertConfiguration(user, action, subject.entity);
      default:
        return false;
    }
  }

  private canUserPerformActionOnDevice(actor: AuthUser, action: Action, device: AuthDevice): boolean {
    const isOwnCompany = actor.companyId ? actor.companyId === device.site?.customer.id : false;
    const isSuperUser = actor.isSuperUser!;
    const isDeviceAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);
    const isCompanyAdmin = actor.permissions.includes(UserPermissionType.CompanyAdmin);
    const isDeviceImporter = actor.permissions.includes(UserPermissionType.DeviceImporter);
    let isDelegatedDeviceAdmin = false;
    let isDelegatedDeviceEngineer = false;
    let hasDelegatedAccess = false;
    // we are refering to an existing device on another company
    // check if we have a link to a device group that links to the device
    //
    // find out highest level of access through device group
    // user -> user_device_group -> device_group -> device_group_device -> device
    if (!isNil(device.deviceGroups) && !isNil(actor.userDeviceGroups)) {
      // device groups or user device groups are not provided, so we can't check access

      const roles = this.userRolesForDevice(actor, device);

      if (roles.length > 0) {
        hasDelegatedAccess = true;
      }
      isDelegatedDeviceAdmin = roles.includes(UserDeviceGroupRole.DeviceAdministrator);
      isDelegatedDeviceEngineer = roles.includes(UserDeviceGroupRole.DeviceEngineer);
    }

    const isOwnCompanyDeviceAdmin = isOwnCompany && isDeviceAdmin;
    const isOwnCompanyAdmin = isOwnCompany && isCompanyAdmin;

    switch (action) {
      case 'READ':
        return isSuperUser || isOwnCompanyDeviceAdmin || isOwnCompanyAdmin || hasDelegatedAccess;
      case 'UPDATE':
        return isSuperUser || isOwnCompanyDeviceAdmin || isDelegatedDeviceAdmin || isDelegatedDeviceEngineer;
      case 'CREATE':
        if (isSuperUser) {
          return true;
        }
        // user should be at least device importer
        if (!isDeviceImporter) {
          return false;
        }
        if (!device.site) {
          // no site provided, so we only check if the user potentially can import devices
          return true;
        }
        // customer is provided, so we check if the user can create devices for it

        // customer can not import devices
        if (!device.site.customer?.allowImport) {
          return false;
        }

        if (isOwnCompany && isDeviceAdmin) {
          // to create devices you should be a device admin
          // (otherwise you can not see you won created devices)
          return true;
        }
        // if the user is not a member of the customer
        // check if user has role deviceAdmin on a deviceGroup of this customer
        if (!isOwnCompany) {
          // check if user has role deviceAdmin on a deviceGroup if this customer
          const roles = this.userRolesForCustomer(actor, device.site.customer?.id);
          if (roles.includes(UserDeviceGroupRole.DeviceAdministrator)) {
            return true;
          }
        }
        return false;
      case 'DELETE':
        return isSuperUser || isOwnCompanyDeviceAdmin;
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceBasicDetails(
    actor: AuthUser,
    action: Action,
    subject: AuthDeviceBasicDetails
  ): boolean {
    const device = subject.device;
    // no device... no access
    if (!device) {
      return false;
    }
    const isSuperUser = actor.isSuperUser!;

    const deviceAccess = this.canUserPerformActionOnDevice(actor, action, subject.device);

    switch (action) {
      case 'READ':
      case 'CREATE':
      case 'DELETE':
        return deviceAccess;
      case 'UPDATE':
        if (isSuperUser) {
          return true;
        }
        break;
      default:
        break;
    }
    // we are refering to an existing device on another company
    // check if we have a link to a device group that links to the device
    //
    // find out highest level of access through device group
    // user -> user_device_group -> device_group -> device_group_device -> device
    let isOperator = false;
    if (!isNil(device.deviceGroups) && !isNil(actor.userDeviceGroups)) {
      // device groups or user device groups not provided, so can check access
      const roles = this.userRolesForDevice(actor, device);
      isOperator = roles.includes(UserDeviceGroupRole.DeviceOperator);
    }
    // other roles are already convered by deviceAccess

    switch (action) {
      case 'UPDATE':
        return deviceAccess || isOperator;
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceSite(actor: AuthUser, action: Action, subject: AuthDeviceSite): boolean {
    const device = subject.device;
    // no device... no access
    if (!device) {
      return false;
    }
    if (action === 'DELETE') {
      // can never be deleted
      return false;
    }
    const isSuperUser = actor.isSuperUser || false;
    const deviceAccess = this.canUserPerformActionOnDevice(actor, action, subject.device);

    switch (action) {
      case 'UPDATE':
        return isSuperUser;
      case 'CREATE':
      case 'READ':
        return deviceAccess;
      default:
        break;
    }
    return false;
  }

  private canUserPerformActionOnDeviceCloudSettings(
    actor: AuthUser,
    action: Action,
    deviceCloudSettings: AuthDeviceCloudSettings
  ): boolean {
    if (action === 'CREATE' || action === 'DELETE') {
      return false;
    }
    // device is not loaded yet
    if (!deviceCloudSettings.device) {
      return false;
    }
    const isOwnCompany = actor.companyId ? actor.companyId === deviceCloudSettings.device?.site?.customer.id : false;
    const isSuperUser = actor.isSuperUser!;
    // companies can not view/edit/create or delete device cloud settings
    if (isOwnCompany && !isSuperUser) {
      return false;
    }
    // delegate to device for now
    // this will change later
    return this.canUserPerformActionOnDevice(actor, action, deviceCloudSettings.device!);
  }

  private canUserPerformActionOnDeviceType(actor: AuthUser, action: Action): boolean {
    const isSuperUser = actor.isSuperUser;

    switch (action) {
      case 'CREATE':
      case 'UPDATE':
      case 'DELETE':
        return isSuperUser;
      case 'READ':
        return true;
      default:
        return false;
    }
  }

  private canUserPerformActionOnUserDeviceGroup(
    actor: AuthUser,
    action: Action,
    subject: AuthUserDeviceGroup
  ): boolean {
    const deviceGroup = subject.deviceGroup;
    const user = subject.user;

    if (!deviceGroup || !user) {
      return false;
    }
    if (!this.canUserPerformActionOnDeviceGroup(actor, 'READ', deviceGroup)) {
      return false;
    }

    if (!this.canUserPerformActionOnUser(actor, 'READ', user)) {
      return false;
    }
    const isSuperUser = actor.isSuperUser;
    const isUserDeviceGroupAdmin = actor.permissions.includes(UserPermissionType.UserDeviceGroupAdmin);

    switch (action) {
      case 'READ':
        return true;
      case 'UPDATE':
      case 'DELETE':
      case 'CREATE':
        return isUserDeviceGroupAdmin || isSuperUser;
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceGroupDevice(
    actor: AuthUser,
    action: Action,
    subject: AuthDeviceGroupDevice
  ): boolean {
    const deviceGroup = subject.deviceGroup;
    const device = subject.device;
    // should not happen...
    if (!deviceGroup || !device) {
      return false;
    }

    if (!this.canUserPerformActionOnDeviceGroup(actor, 'READ', deviceGroup)) {
      return false;
    }
    if (!this.canUserPerformActionOnDevice(actor, 'READ', device)) {
      return false;
    }
    // is admin of device group or super user
    const isSuperUser = actor.isSuperUser;
    const isAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);
    switch (action) {
      case 'READ':
        return true;
      case 'UPDATE':
      case 'DELETE':
      case 'CREATE':
        return isAdmin || isSuperUser;
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceGroup(actor: AuthUser, action: Action, subject: AuthDeviceGroup): boolean {
    const isSuperUser = actor.isSuperUser;
    const isOwnDeviceGroup = actor.companyId === subject.customerId;
    const isLinkedToOwnServiceProvider = subject.serviceProviderId === actor.companyId;

    const isCompanyAdmin = actor.permissions.includes(UserPermissionType.CompanyAdmin);
    const isDeviceAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);
    const isUserDeviceGroupAdmin = actor.permissions.includes(UserPermissionType.UserDeviceGroupAdmin);
    const isUserAdmin = actor.permissions.includes(UserPermissionType.UserAdmin);
    const isMemberOfGroup =
      actor.userDeviceGroups?.some((userDeviceGroup) => userDeviceGroup.deviceGroup.id === subject.id) || false;
    switch (action) {
      case 'UPDATE':
      case 'CREATE':
        return isSuperUser || (isCompanyAdmin && isOwnDeviceGroup);
      case 'READ':
        return (
          isSuperUser ||
          // customers
          (isOwnDeviceGroup && (isCompanyAdmin || isDeviceAdmin || isUserDeviceGroupAdmin)) ||
          // service providers
          (isLinkedToOwnServiceProvider && (isUserAdmin || isUserDeviceGroupAdmin)) ||
          isMemberOfGroup
        );
      case 'DELETE':
        return isSuperUser || (isCompanyAdmin && isOwnDeviceGroup);
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceGroupUserLinks(
    actor: AuthUser,
    action: Action,
    subject: AuthDeviceGroupUserLinks
  ): boolean {
    const isSuperUser = actor.isSuperUser;
    const isOwnDeviceGroup = actor.companyId === subject.customerId;
    const isLinkedToOwnServiceProvider = subject.serviceProviderId === actor.companyId;
    const isUserDeviceGroupAdmin = actor.permissions.includes(UserPermissionType.UserDeviceGroupAdmin);
    const isDeviceAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);
    const isUserAdmin = actor.permissions.includes(UserPermissionType.UserAdmin);
    const isMemberOfGroup =
      actor.userDeviceGroups?.some((userDeviceGroup) => userDeviceGroup.deviceGroup.id === subject.id) || false;

    switch (action) {
      case 'UPDATE':
      case 'CREATE':
      case 'DELETE':
        return (
          isSuperUser ||
          (isUserDeviceGroupAdmin && isOwnDeviceGroup) ||
          (isLinkedToOwnServiceProvider && isUserDeviceGroupAdmin)
        );
      case 'READ':
        return (
          isSuperUser ||
          (isOwnDeviceGroup && (isUserDeviceGroupAdmin || isDeviceAdmin || isUserAdmin)) ||
          (isLinkedToOwnServiceProvider && (isUserDeviceGroupAdmin || isUserAdmin)) ||
          isMemberOfGroup
        );
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceGroupDeviceLinks(
    actor: AuthUser,
    action: Action,
    subject: AuthDeviceGroupDeviceLinks
  ): boolean {
    const isSuperUser = actor.isSuperUser;
    const isOwnDeviceGroup = actor.companyId === subject.customerId;

    const isLinkedToOwnServiceProvider = subject.serviceProviderId === actor.companyId;
    const isUserDeviceGroupAdmin = actor.permissions.includes(UserPermissionType.UserDeviceGroupAdmin);
    const isDeviceAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);
    const isUserAdmin = actor.permissions.includes(UserPermissionType.UserAdmin);
    const isMemberOfGroup =
      actor.userDeviceGroups?.some((userDeviceGroup) => userDeviceGroup.deviceGroup.id === subject.id) || false;
    switch (action) {
      case 'UPDATE':
      case 'CREATE':
      case 'DELETE':
        return isSuperUser || (isDeviceAdmin && isOwnDeviceGroup);
      case 'READ':
        return (
          isSuperUser ||
          (isOwnDeviceGroup && (isUserDeviceGroupAdmin || isDeviceAdmin || isUserAdmin)) ||
          (isLinkedToOwnServiceProvider && (isUserDeviceGroupAdmin || isUserAdmin)) ||
          isMemberOfGroup
        );
      default:
        return false;
    }
  }

  private canUserPerformActionOnUser(actor: AuthUser, action: Action, user: AuthUser): boolean {
    const isSuperUser = actor.isSuperUser;
    // user no company is set, we asume the user want to create use for its own company..
    const isOwnCompany = actor.companyId === user.companyId || !user.companyId;
    const isUserAdmin = actor.permissions.includes(UserPermissionType.UserAdmin);
    const isOwnUser = actor.email === user.email;
    switch (action) {
      case 'UPDATE':
        // note: the backend will not allow to change the superuser flag by non super-users
        if (isOwnUser && isSuperUser && !user.isSuperUser) {
          // super users can not drop their own superuser flag
          return false;
        }
        // Q: should a 'member' user be able to update some fields of its own user?
        if ((isUserAdmin && isOwnCompany) || isSuperUser) {
          if (user.isSuperUser && !this.superUserAllowed(user.email)) {
            // non rocsys user can not become superuser
            return false;
          }
          return true;
        }
        return false;
      case 'CREATE':
        // TD: this is more subtle: a 'member' user should be able to update some fields of its own user
        if ((isUserAdmin && isOwnCompany) || isSuperUser) {
          if (user.isSuperUser && !this.superUserAllowed(user.email)) {
            // non rocsys user can not become superuser
            return false;
          }
          if (!isSuperUser && user.isSuperUser) {
            // only superusers can create superusers
            return false;
          }
          return true;
        }
        return false;
      case 'READ':
        return isOwnCompany || isSuperUser;
      case 'DELETE':
        return Boolean(user.id) && !isOwnUser && ((isUserAdmin && isOwnCompany) || isSuperUser);
      default:
        return false;
    }
  }

  private canUserPerformActionOnSite(user: AuthUser, action: Action, site: AuthSite): boolean {
    const isSuperUser = user.isSuperUser;
    const isOwnCompany = user.companyId === site.customer.id;
    const isAdmin = user.permissions.includes(UserPermissionType.CompanyAdmin);
    const superUserOrAdmin = (isOwnCompany && isAdmin) || isSuperUser;
    switch (action) {
      case 'CREATE':
        return superUserOrAdmin;
      case 'READ':
        return isOwnCompany || isSuperUser;
      case 'UPDATE':
        return superUserOrAdmin;
      case 'DELETE':
        return superUserOrAdmin;
      default:
        return false;
    }
  }

  private canUserPerformActionOnCompany(user: AuthUser, action: Action, company: AuthCompany): boolean {
    const isSuperUser = user.isSuperUser;
    const isOwnCompany = user.companyId === company.id;
    const companyHasId = company.id !== undefined;
    switch (action) {
      case 'CREATE':
        return isSuperUser;
      case 'READ':
        return isOwnCompany || isSuperUser;
      case 'UPDATE':
        return isSuperUser;
      case 'DELETE':
        return companyHasId && !isOwnCompany && isSuperUser;
      default:
        return false;
    }
  }

  private canUserPerformActionOnDeviceOperation(
    actor: AuthUser,
    action: Action,
    deviceOperation: AuthDeviceOperation
  ): boolean {
    // users can never create, delete or manage device operations
    if (action === 'CREATE' || action === 'DELETE') {
      return false;
    }

    if (action === 'UPDATE' && deviceOperation.name !== 'ACDCycle') {
      // only top level operations can be updated
      return false;
    }

    if (actor.isSuperUser) {
      return true;
    }
    const isOwnDevice = deviceOperation.device.site?.customer.id === actor.companyId;
    const isDeviceAdmin = actor.permissions.includes(UserPermissionType.DeviceAdmin);

    const isOwnCompanyDeviceAdmin = isOwnDevice && isDeviceAdmin;

    const roles = this.userRolesForDevice(actor, deviceOperation.device);

    const hasDelegatedAccess = roles.length > 0;
    switch (action) {
      case 'READ':
        return isOwnCompanyDeviceAdmin || hasDelegatedAccess;
      case 'UPDATE':
        return (
          isOwnCompanyDeviceAdmin ||
          roles.includes(UserDeviceGroupRole.DeviceAdministrator) ||
          roles.includes(UserDeviceGroupRole.DeviceEngineer)
        );
      default:
        return false;
    }
  }

  private canUserPerformActionOnConnectorHolderType(actor: AuthUser, action: Action): boolean {
    const isSuperUser = actor.isSuperUser;

    switch (action) {
      case 'CREATE':
      case 'UPDATE':
      case 'DELETE':
        return isSuperUser;
      case 'READ':
        return true;
      default:
        return false;
    }
  }

  private canUserPerformActionOnRocAlertConfiguration(
    actor: AuthUser,
    action: Action,
    rocAlertConfiguration: AuthRocAlertConfiguration
  ): boolean {
    const isSuperUser = actor.isSuperUser;
    const isCompanyAdmin = actor.permissions.includes(UserPermissionType.CompanyAdmin);
    const isOwnCompany = actor.companyId === rocAlertConfiguration.companyId;

    // super-users can do anything
    if (isSuperUser) {
      return true;
    }
    // owners can do anything
    if (isOwnCompany && isCompanyAdmin) {
      return true;
    }
    // read only for all others of company
    if (isOwnCompany && action === 'READ') {
      return true;
    }
    return false;
  }

  private superUserAllowed(email: string) {
    return email.toLocaleLowerCase().endsWith('@rocsys.com');
  }

  private userRolesForDevice(user: AuthUser, device: AuthDevice): UserDeviceGroupRole[] {
    if (!user.userDeviceGroups || !device.deviceGroups) {
      return [];
    }
    const linkedUserDeviceGroups = user.userDeviceGroups.filter((userDeviceGroup) =>
      device.deviceGroups!.some((deviceGroup) => deviceGroup.id === userDeviceGroup.deviceGroup.id)
    );

    return linkedUserDeviceGroups.map((link) => link.userRole);
  }

  private userRolesForCustomer(user: AuthUser, customerId: string): UserDeviceGroupRole[] {
    if (!user.userDeviceGroups) {
      return [];
    }
    const linkedUserDeviceGroups = user.userDeviceGroups.filter(
      (userDeviceGroup) => userDeviceGroup.deviceGroup.customerId === customerId
    );

    return linkedUserDeviceGroups.map((link) => link.userRole);
  }
}
