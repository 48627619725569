import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModifyCompanyRequest, modifyCompanySchema } from './validation-schema/modify-company-schema';
import { UserDeviceGroupUserRole } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { MUTATION_DELETE_CUSTOMER } from '../../../../services/mutations/admin/companies/delete-customer';
import { MUTATION_DELETE_SERVICE_PROVIDER } from '../../../../services/mutations/admin/companies/delete-service-provider';
import { MUTATION_MODIFY_CUSTOMER } from '../../../../services/mutations/admin/companies/modify-customer';
import { MUTATION_MODIFY_SERVICE_PROVIDER } from '../../../../services/mutations/admin/companies/modify-service-provider';
import { CompanyType } from '../../../../types/company-type';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { RSSwitch } from '../../../5-elements/rs-switch/rs-switch';
import { RSTextInput } from '../../../5-elements/rs-text-input/rs-text-input';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';

interface ModifyCompanyDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenModifyCompany: Dispatch<SetStateAction<boolean>>;
  defaultValues: ModifyCompanyRequest;
  companyType: CompanyType;
  createdAt: string;
}

export const ModifyCompanyDrawer = ({
  setOpenModifyCompany,
  defaultValues,
  companyType,
  createdAt,
  ...props
}: ModifyCompanyDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const { result: userCanDeleteCompany, loading: loadingUserCanDeleteCompany } = useAuthCheckerWithSubjectInfo({
    action: 'DELETE',
    subjectInfo: { type: 'Company', companyId: defaultValues.id },
    skip: props.open === false
  });
  const [modifyCustomer, { loading: loadingModifyCustomer }] = useMutation(MUTATION_MODIFY_CUSTOMER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      let customMessage: string | undefined = get(error, 'graphQLErrors[0].extensions[0].message', undefined);
      if (error.message === 'Validation failed' && customMessage) {
        customMessage = `${error.message}: ${customMessage}`;
      }
      sendMessageToSnackbar(
        t('forms.snackbarMessages.errorSave'),
        undefined,
        customMessage || error.message || error.name,
        'error'
      );
    }
  });
  const [modifyServiceProvider, { loading: loadingModifyServiceProvider }] = useMutation(
    MUTATION_MODIFY_SERVICE_PROVIDER,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) =>
        sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error')
    }
  );
  const [deleteCustomer, { loading: loadingDeleteCustomer }] = useMutation(MUTATION_DELETE_CUSTOMER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('forms.snackbarMessages.errorDelete', { entity: t('entities.customer') }),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const [deleteServiceProvider, { loading: loadingDeleteServiceProvider }] = useMutation(
    MUTATION_DELETE_SERVICE_PROVIDER,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) =>
        sendMessageToSnackbar(
          t('forms.snackbarMessages.errorDelete', { entity: t('entities.serviceProvider') }),
          undefined,
          error.message || error.name,
          'error'
        )
    }
  );
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<ModifyCompanyRequest>({
    resolver: zodResolver(modifyCompanySchema),
    defaultValues
  });

  const maxDeviceGroupRoleValues = Object.values(UserDeviceGroupUserRole);
  const maxDeviceGroupRoleSelectOptions: RSSelectItemProps[] = maxDeviceGroupRoleValues.map((item) => ({
    displayName: mapUserDeviceGroupRole(item),
    menuItemProps: { value: item }
  }));

  const handleLeave = (): void => {
    reset();
    setShowLeaveConfirmationModal(false);
    setOpenModifyCompany(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowLeaveConfirmationModal(true);
    } else {
      reset();
      setOpenModifyCompany(false);
    }
  };

  const onDelete = (): void => {
    if (companyType === CompanyType.Customer) {
      deleteCustomer({
        variables: { id: defaultValues.id },
        onCompleted: () => {
          sendMessageToSnackbar(
            t('forms.snackbarMessages.successfullyDeleted', { entity: t('entities.customer') }),
            undefined,
            undefined,
            'success'
          );
          setOpenModifyCompany(false);
          setShowDeleteConfirmationModal(false);
          navigate(`${appConfig.basePath}/admin/companies`);
        }
      });
      return;
    }

    if (companyType === CompanyType.ServiceProvider) {
      deleteServiceProvider({
        variables: { id: defaultValues.id },
        onCompleted: () => {
          sendMessageToSnackbar(
            t('forms.snackbarMessages.successfullyDeleted', { entity: t('entities.customer') }),
            undefined,
            undefined,
            'success'
          );
          setOpenModifyCompany(false);
          setShowDeleteConfirmationModal(false);
          navigate(`${appConfig.basePath}/admin/companies`);
        }
      });
      return;
    }
  };

  const onSubmit: SubmitHandler<ModifyCompanyRequest> = (data): void => {
    if (!isDirty) {
      setOpenModifyCompany(false);
      setShowLeaveConfirmationModal(false);
      return;
    }

    if (companyType === CompanyType.ServiceProvider) {
      modifyServiceProvider({
        variables: { id: data.id, name: data.name, maxDeviceGroupRole: data.maxDeviceGroupRole },
        onCompleted: () => {
          sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
          setOpenModifyCompany(false);
          setShowLeaveConfirmationModal(false);
          reset(data);
        }
      });
    }

    if (companyType === CompanyType.Customer) {
      modifyCustomer({
        variables: {
          id: data.id,
          name: data.name,
          maxDeviceGroupRole: data.maxDeviceGroupRole,
          allowImport: Boolean(data.allowImport),
          documentCenterUrl: data.documentCenterUrl,
          rocsysApiIpAcceptlist: data.rocsysApiIpAcceptlist
        },
        onCompleted: () => {
          sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
          setOpenModifyCompany(false);
          setShowLeaveConfirmationModal(false);
          reset(data);
        }
      });
    }
  };

  return (
    <RSDrawer {...props} className="modify-company-drawer">
      <ModalDrawerHeader
        title={
          companyType === CompanyType.Customer
            ? t('companyAdminDetailsPage.modifyCompanyDrawer.titleCustomer')
            : t('companyAdminDetailsPage.modifyCompanyDrawer.titleServiceProvider')
        }
      />
      <form
        className="modify-company-drawer__form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="modify-company-drawer-form"
      >
        <div className="modify-company-drawer__input-fields">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSTextInput
                inputLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.name')}
                required={true}
                data-testid="modify-company-drawer-name-input"
                helperText={errors.name?.message}
                error={Boolean(errors.name)}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          {companyType === CompanyType.Customer && (
            <Controller
              control={control}
              name="allowImport"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.allowImport')}
                  formLabelTooltip={t('tooltips.company.allowImport')}
                  data-testid="modify-company-drawer-allow-import-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  falseValueLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.allowImportDisabled')}
                  trueValueLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.allowImportEnabled')}
                />
              )}
            />
          )}
          <Controller
            name="maxDeviceGroupRole"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="modify-company-drawer__max-device-group-role-select"
                inputLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.maxDeviceGroupRole')}
                inputLabelTooltip={t('tooltips.company.maxDeviceGroupRole')}
                required={true}
                menuItems={maxDeviceGroupRoleSelectOptions}
                helperText={errors.maxDeviceGroupRole?.message}
                error={Boolean(errors.maxDeviceGroupRole)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="modify-company-drawer-max-device-group-role-select"
                value={value}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          {companyType === CompanyType.Customer && (
            <RSTextInput
              inputLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.documentCenterUrl')}
              inputLabelTooltip={t('tooltips.company.documentCenterUrl')}
              data-testid="modify-company-drawer-document-center-url-input"
              helperText={errors.documentCenterUrl?.message}
              error={Boolean(errors.documentCenterUrl)}
              {...register('documentCenterUrl', { setValueAs: (value) => (value === '' ? undefined : value) })}
            />
          )}
          {companyType === CompanyType.Customer && (
            <RSTextInput
              inputLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.rocsysApiIpAcceptList')}
              data-testid="modify-company-drawer-api-accept-list-input"
              inputLabelTooltip={t('tooltips.company.rocsysApiIpAcceptList')}
              helperText={errors.rocsysApiIpAcceptlist?.message}
              error={Boolean(errors.rocsysApiIpAcceptlist)}
              {...register('rocsysApiIpAcceptlist', { setValueAs: (value) => (value === '' ? undefined : value) })}
            />
          )}
          <RSTextInput
            inputLabel={t('companyAdminDetailsPage.modifyCompanyDrawer.createdAt')}
            data-testid="modify-company-drawer-created-at-input"
            disabled={true}
            defaultValue={createdAt}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={() => setShowDeleteConfirmationModal(true)}
          isSaveDisabled={loadingModifyCustomer || loadingModifyServiceProvider}
          isCancelDisabled={loadingModifyCustomer || loadingModifyServiceProvider}
          isDeleteDisabled={loadingUserCanDeleteCompany || !userCanDeleteCompany}
          colorVariant="success"
          deleteButtonText={t('companyAdminDetailsPage.modifyCompanyDrawer.delete')}
        />
      </form>
      <ConfirmationModal
        open={showLeaveConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowLeaveConfirmationModal(false)}
        disableConfirmButton={loadingModifyCustomer || loadingModifyServiceProvider}
        disableCancelButton={loadingModifyCustomer || loadingModifyServiceProvider}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
      <ConfirmationModal
        open={showDeleteConfirmationModal}
        mainTitle={t('forms.deleteModal.title')}
        message={t('forms.deleteModal.message', {
          entity: companyType === CompanyType.Customer ? t('entities.customer') : t('entities.serviceProvider')
        })}
        confirmButtonText={
          loadingDeleteCustomer || loadingDeleteServiceProvider
            ? t('forms.deleteModal.confirmDeleting')
            : t('forms.deleteModal.confirm')
        }
        cancelButtonText={t('forms.deleteModal.cancel')}
        handleClickCancelButton={() => setShowDeleteConfirmationModal(false)}
        handleClickConfirmButton={onDelete}
        disableConfirmButton={loadingDeleteCustomer || loadingDeleteServiceProvider}
        disableCancelButton={loadingDeleteCustomer || loadingDeleteServiceProvider}
        confirmButtonColor="error"
        cancelButtonColor="error"
      />
    </RSDrawer>
  );
};
