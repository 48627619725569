import { Modal, ModalProps } from '@mui/material';
import { JSX } from 'react';

import { BlobModalLoading } from './blob-modal-loading/blob-modal-loading';

export interface BlobModalBaseProps extends ModalProps {
  isLoading?: boolean;
}

export const BlobModalBase = ({ children, isLoading, ...props }: BlobModalBaseProps): JSX.Element => {
  return (
    <Modal {...props} className="blob-modal-base">
      <div>{isLoading ? <BlobModalLoading /> : <div className="blob-modal-base__box">{children}</div>}</div>
    </Modal>
  );
};
