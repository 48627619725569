import { GetCustomersQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types/flatten';

export type CustomerRow = FlattenFunction<typeof generateCustomerRows>;

export const generateCustomerRows = (customers?: GetCustomersQuery['customers']) => {
  if (!customers) {
    return [];
  }

  return customers.map((customer) => ({
    id: customer.companyId,
    name: customer.company.name,
    devicesCount: customer.devicesAggregate.aggregate?.count || 0,
    sitesCount: customer.sitesAggregate.aggregate?.count || 0,
    operationalPerformance: {
      acdCycleOperationsOperationalRate:
        customer.customerCurrentPerformance?.operationalPerformanceLastPeriodPct ?? null,
      acdCycleOperationsOperationalRateChange: customer.customerCurrentPerformance?.operationalPerformanceDiff ?? null,
      acdCycleOperationsCount: customer.customerCurrentPerformance?.totalLastPeriod ?? 0,
      acdCycleOperationsCountChange: customer.customerCurrentPerformance?.totalDiffPct ?? 0
    },
    basePerformance: {
      acdCycleOperationsFullyCompletedRate: customer.customerCurrentPerformance?.performanceLastPeriodPct ?? null,
      acdCycleOperationsFullyCompletedRateChange: customer.customerCurrentPerformance?.performanceDiff ?? null,
      acdCycleOperationsCount: customer.customerCurrentPerformance?.totalLastPeriod ?? 0,
      acdCycleOperationsCountChange: customer.customerCurrentPerformance?.totalDiffPct ?? 0
    },
    programsCount: customer.programsAggregate.aggregate?.count || 0
  }));
};
