import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { difference, includes, isEmpty, isEqual, uniq } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { deviceDeviceGroupsTableHeaderCells, deviceDeviceGroupsTableRowCells } from './display-table-configuration';
import {
  ManageDeviceDeviceGroupsRequest,
  manageDeviceDeviceGroupsSchema
} from './validation-schema/manage-device-device-groups-schema';
import { GetAdminDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs/configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants/constants';
import { MUTATION_CREATE_DEVICE_TO_DEVICE_GROUP_LINK } from '../../../../services/mutations/admin/companies/create-device-to-device-group-link';
import { MUTATION_UNLINK_DEVICE_FROM_DEVICE_GROUP } from '../../../../services/mutations/admin/companies/unlink-device-from-device-group';
import { QUERY_GET_ADMIN_DEVICE_DEVICE_GROUPS } from '../../../../services/queries/admin/devices/get-admin-device-device-groups';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { ConfirmationModal } from '../../../4-features/confirmation-modal/confirmation-modal';
import { DrawerButtonsGroup } from '../../../4-features/drawer-buttons-group/drawer-buttons-group';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { DisplayTable } from '../../../5-elements/display-table/display-table';
import { Loading } from '../../../5-elements/loading/loading';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSSelect, RSSelectItemProps } from '../../../5-elements/rs-select/rs-select';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { ErrorPage } from '../../error-page/error-page';

interface ManageDeviceDeviceGroupDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenManageDeviceDeviceGroupsDrawer: Dispatch<SetStateAction<boolean>>;
  refetchDeviceDetails: () => Promise<ApolloQueryResult<GetAdminDeviceDetailsQuery>>;
  serialNumber: string;
  defaultValues: ManageDeviceDeviceGroupsRequest;
  customerId: string;
}

export const ManageDeviceDeviceGroupDrawer = ({
  setOpenManageDeviceDeviceGroupsDrawer,
  refetchDeviceDetails,
  customerId,
  serialNumber,
  defaultValues,
  ...props
}: ManageDeviceDeviceGroupDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [selectedDeviceGroupId, setSelectedDeviceGroupId] = useState<string>('');
  const {
    loading: loadingDeviceGroups,
    data: dataDeviceGroups,
    error: errorDeviceGroups
  } = useQuery(QUERY_GET_ADMIN_DEVICE_DEVICE_GROUPS, {
    fetchPolicy: 'network-only',
    variables: { customerId },
    skip: props.open === false
  });
  const [createLink, { loading: loadingCreateLink }] = useMutation(MUTATION_CREATE_DEVICE_TO_DEVICE_GROUP_LINK, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [unlink, { loading: loadingUnlink }] = useMutation(MUTATION_UNLINK_DEVICE_FROM_DEVICE_GROUP, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const { handleSubmit, watch, setValue, getValues, reset } = useForm<ManageDeviceDeviceGroupsRequest>({
    resolver: zodResolver(manageDeviceDeviceGroupsSchema),
    defaultValues
  });

  const deviceGroupsResponse = dataDeviceGroups?.deviceGroups || [];
  const deviceGroupOptions: RSSelectItemProps[] = deviceGroupsResponse
    .map((deviceGroup) => ({ displayName: deviceGroup.name, menuItemProps: { value: deviceGroup.id } }))
    .filter((item) => !includes(watch('deviceGroupIds'), item.menuItemProps.value));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenManageDeviceDeviceGroupsDrawer(false);
  };

  const handleCancel = (): void => {
    // We compare values only, regardless of the sequence, therefore `sort()` is introduced
    if (!isEqual(getValues('deviceGroupIds').sort(), defaultValues.deviceGroupIds.sort())) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenManageDeviceDeviceGroupsDrawer(false);
    }
  };

  const onSubmit: SubmitHandler<ManageDeviceDeviceGroupsRequest> = async (data): Promise<void> => {
    // We compare values only, regardless of the sequence, therefore `sort()` is introduced
    if (isEqual(data.deviceGroupIds.sort(), defaultValues.deviceGroupIds.sort())) {
      setOpenManageDeviceDeviceGroupsDrawer(false);
      setShowConfirmationModal(false);
      return;
    }

    const deviceGroupsAdded = difference(data.deviceGroupIds, defaultValues.deviceGroupIds);
    const deviceGroupsRemoved = difference(defaultValues.deviceGroupIds, data.deviceGroupIds);
    const addFailed: string[] = [];
    const removeFailed: string[] = [];

    if (deviceGroupsAdded.length > 0 || deviceGroupsRemoved.length > 0) {
      // NOTE the spreading dots `...` used in the promises to get them running in parallel.
      // we want to continue with executing the queries as many as possible. Even if one fails, the other can continue
      // running
      await Promise.allSettled([
        ...deviceGroupsAdded.map(async (deviceGroupAdded) => {
          await createLink({
            variables: { deviceId: data.deviceId, deviceGroupId: deviceGroupAdded },
            onCompleted: () => {
              const addedDeviceGroup = dataDeviceGroups?.deviceGroups.find((item) => item.id === deviceGroupAdded);
              const addedDeviceGroupName = addedDeviceGroup?.name || '';
              sendMessageToSnackbar(
                t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroupLinked', {
                  deviceGroupName: addedDeviceGroupName
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const linkFailedDeviceGroup = dataDeviceGroups?.deviceGroups.find((item) => item.id === deviceGroupAdded);
              const linkFailedDeviceGroupName = linkFailedDeviceGroup?.name || '';
              addFailed.push(deviceGroupAdded);
              sendMessageToSnackbar(
                t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroupLinkFailed', {
                  deviceGroupName: linkFailedDeviceGroupName
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        }),
        ...deviceGroupsRemoved.map(async (deviceGroupRemoved) => {
          await unlink({
            variables: { deviceId: data.deviceId, deviceGroupId: deviceGroupRemoved },
            onCompleted: () => {
              const removedDeviceGroup = dataDeviceGroups?.deviceGroups.find((item) => item.id === deviceGroupRemoved);
              const removedDeviceGroupName = removedDeviceGroup?.name || '';
              sendMessageToSnackbar(
                t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroupUnlinked', {
                  deviceGroupName: removedDeviceGroupName
                }),
                undefined,
                undefined,
                'success'
              );
            },
            onError: (error) => {
              const removeFailedDeviceGroup = dataDeviceGroups?.deviceGroups.find(
                (item) => item.id === deviceGroupRemoved
              );
              const removeFailedDeviceGroupName = removeFailedDeviceGroup?.name || '';
              removeFailed.push(deviceGroupRemoved);
              sendMessageToSnackbar(
                t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroupUnlinkFailed', {
                  deviceGroupName: removeFailedDeviceGroupName
                }),
                undefined,
                error.message || error.name,
                'error'
              );
            }
          });
        })
      ]).finally(() => {
        // 1. Device groups which were added but failed shall be excluded from the list
        // 2. Device groups which were removed but failed shall be kept in the list
        const deviceGroupsListExcludeAddFailed = data.deviceGroupIds.filter(
          (deviceGroupId) => !includes(addFailed, deviceGroupId)
        );
        const deviceGroupsListRemoveFailed = defaultValues.deviceGroupIds.filter((defaultDeviceGroupId) =>
          includes(removeFailed, defaultDeviceGroupId)
        );
        reset({ ...data, deviceGroupIds: [...deviceGroupsListExcludeAddFailed, ...deviceGroupsListRemoveFailed] });
        refetchDeviceDetails();
        setShowConfirmationModal(false);
        setOpenManageDeviceDeviceGroupsDrawer(false);
      });
    }
  };

  if (errorDeviceGroups) {
    return (
      <RSDrawer {...props} className="manage-device-device-groups-drawer">
        <ModalDrawerHeader title={t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.title', { serialNumber })} />
        <div className="manage-device-device-groups-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorDeviceGroups.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingDeviceGroups) {
    return (
      <RSDrawer {...props} className="manage-device-device-groups-drawer">
        <ModalDrawerHeader title={t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.title', { serialNumber })} />
        <div className="manage-device-device-groups-drawer__form">
          <div className="manage-device-device-groups-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="manage-device-device-groups-drawer">
      <ModalDrawerHeader title={t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.title', { serialNumber })} />
      {dataDeviceGroups && (
        <>
          <form
            className="manage-device-device-groups-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="manage-device-device-groups-drawer-form"
          >
            <div className="manage-device-device-groups-drawer__form-contents">
              <div className="manage-device-device-groups-drawer__link-select">
                <h3>Link device groups</h3>
                <div className="manage-device-device-groups-drawer__link-input">
                  <RSSelect
                    inputLabel={t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroup')}
                    menuItems={deviceGroupOptions}
                    onChange={(event) => setSelectedDeviceGroupId(event.target.value as string)}
                    value={selectedDeviceGroupId}
                    disabled={isEmpty(deviceGroupOptions)}
                    data-testid="manage-device-device-groups-drawer-device-group-select"
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                  <div className="manage-device-device-groups-drawer__link-button-wrapper">
                    <RSButton
                      color="success"
                      variant="contained"
                      onClick={() => {
                        if (selectedDeviceGroupId !== '') {
                          setValue('deviceGroupIds', uniq([...getValues('deviceGroupIds'), selectedDeviceGroupId]));
                          setSelectedDeviceGroupId('');
                        }
                      }}
                      disabled={selectedDeviceGroupId === ''}
                      data-testid="manage-device-device-groups-drawer-link-button"
                    >
                      {t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.link')}
                    </RSButton>
                  </div>
                </div>
              </div>
              <div className="manage-device-device-groups-drawer__device-group-overview">
                <h3>{t('deviceAdminDetailsPage.deviceDeviceGroupsDrawer.deviceGroupOverview')}</h3>
                <DisplayTable
                  className="manage-device-device-groups-drawer__device-group-overview-table"
                  columnHeader={{ headerCells: deviceDeviceGroupsTableHeaderCells }}
                  data-testid="manage-device-device-groups-drawer-device-group-overview-table"
                  rows={
                    dataDeviceGroups?.deviceGroups
                      .filter((item) => includes(watch('deviceGroupIds'), item.id))
                      .map((deviceGroup) => ({
                        key: deviceGroup.id,
                        cells: deviceDeviceGroupsTableRowCells(
                          deviceGroup.id,
                          () =>
                            setValue(
                              'deviceGroupIds',
                              getValues('deviceGroupIds').filter((item) => item !== deviceGroup.id)
                            ),
                          deviceGroup.name
                        )
                      })) || []
                  }
                />
              </div>
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loadingCreateLink || loadingUnlink}
              isCancelDisabled={loadingCreateLink || loadingUnlink}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loadingCreateLink || loadingUnlink}
            disableCancelButton={loadingCreateLink || loadingUnlink}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
        </>
      )}
    </RSDrawer>
  );
};
