import React, { JSX, ReactNode } from 'react';

import InfoIcon from '../../../assets/icons/info.svg?react';
import { RSTooltip } from '../../5-elements/rs-tooltip/rs-tooltip';

interface InfoIconTooltipProps {
  infoIconTooltip: ReactNode;
}

export const InfoIconTooltip = ({ infoIconTooltip }: InfoIconTooltipProps): JSX.Element => {
  const title = (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: infoIconTooltip ?? '' }}></div>
    </React.Fragment>
  );
  return (
    <RSTooltip title={title} placement="top" className="info-icon-tooltip__title">
      <span className="info-icon-tooltip" data-testid="info-icon-tooltip">
        <InfoIcon className="info-icon-tooltip__icon" />
      </span>
    </RSTooltip>
  );
};
