export enum RocAlertParameterType {
  Email = 'EMAIL',
  Url = 'URL',
  String = 'STRING'
}

export enum RocAlertChannelType {
  Teams = 'TEAMS',
  Email = 'EMAIL'
}
