import { SnackbarProviderProps } from 'notistack';

import { RSSnackbarCloseButton } from './rs-snackbar-close-button/rs-snackbar-close-button';
import CheckmarkIcon from '../../../assets/icons/check-mark.svg?react';
import CrossIcon from '../../../assets/icons/cross.svg?react';
import InfoIcon from '../../../assets/icons/info.svg?react';

export const snackbarProviderConfiguration: SnackbarProviderProps = {
  classes: { root: 'rs-snackbar' },
  maxSnack: 5,
  action: RSSnackbarCloseButton,
  iconVariant: {
    info: <InfoIcon className="rs-snackbar__variant-icon" />,
    success: <CheckmarkIcon className="rs-snackbar__variant-icon" />,
    error: <CrossIcon className="rs-snackbar__variant-icon" />
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  }
};
