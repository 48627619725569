import { Skeleton } from '@mui/material';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricChangeData, MetricData } from '../../../types/performance-metrics';
import { getMetricValue } from '../../../utilities/get-metric-display-value/get-metric-display-value';
import { MetricChange } from '../metric-change/metric-change';

interface SiteCardMetricProps {
  metric: MetricData;
  metricChange: MetricChangeData;
  loading?: boolean;
  hasError?: boolean;
}

interface SiteCardMetricValueProps extends MetricData {
  loading?: boolean;
  hasError?: boolean;
}

const SiteCardMetricValue = (props: SiteCardMetricValueProps): JSX.Element => {
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <Skeleton
        variant="text"
        className="site-card__metric-value-loading"
        data-testid="site-card-metric-value-loading"
      />
    );
  }

  if (props.hasError) {
    return <h3 className="site-card__metric-value site-card__metric-value--no-value">{t('noData')}</h3>;
  }

  return (
    <h3 className="site-card__metric-value">
      {getMetricValue(props.value, props.decimalPlaces)}
      {props.displayUnit}
    </h3>
  );
};

export const SiteCardMetric = (props: SiteCardMetricProps): JSX.Element => {
  return (
    <div className="site-card__metric" data-testid="site-card-metric">
      <SiteCardMetricValue {...props.metric} hasError={props.hasError} loading={props.loading} />
      <div className="site-card__metric-value-change">
        <MetricChange
          metricChange={props.metricChange}
          loading={Boolean(props.loading)}
          hasError={Boolean(props.hasError)}
        />
      </div>
    </div>
  );
};
