import { useSnackbar } from 'notistack';

import { SnackbarMessageType } from '../../types/snackbar';
import { constructSnackbarMessage } from '../../utilities/construct-snackbar-message/construct-snackbar-message';

interface UseEnqueueSnackbarHook {
  sendMessageToSnackbar: (
    title?: string | undefined,
    name?: string | undefined,
    message?: string | undefined,
    variant?: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined
  ) => void;
}

export const useEnqueueSnackbar = (): UseEnqueueSnackbarHook => {
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  return { sendMessageToSnackbar };
};
