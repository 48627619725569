import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationStatus, AnnotationStatusInterface } from '../../../../types/operation-annotation';

interface OperationResultTooltipTitleProps extends AnnotationStatusInterface {
  annotatedResult?: string;
  originalResult?: string;
  success?: boolean;
  hasEndedAt?: boolean;
}

export const OperationResultTooltipTitle = ({
  annotationStatus,
  annotatedResult,
  originalResult,
  success,
  hasEndedAt
}: OperationResultTooltipTitleProps): ReactNode => {
  const { t } = useTranslation();

  const fallBackResult = (hasEndedAt?: boolean, success?: boolean) => {
    if (!hasEndedAt) return t('operationsPage.results.inProgress');
    if (success === true) return t('operationsPage.results.fullyCompleted');
    // has endedAt and success is false or undefined ... assume failed
    return t('operationsPage.results.failed');
  };

  switch (annotationStatus) {
    case AnnotationStatus.Confirmed:
      return t('dataGrid.operationResult.tooltips.confirmedResult', { result: annotatedResult });
    case AnnotationStatus.Overridden:
      return (
        <div className="data-grid-operation-result-cell__result-tooltip">
          <span>{t('dataGrid.operationResult.tooltips.overriddenResult', { result: annotatedResult })}</span>
          <span>
            {t('dataGrid.operationResult.tooltips.originalResult', {
              result: originalResult || fallBackResult(hasEndedAt, success)
            })}
          </span>
        </div>
      );
    default:
      return t('dataGrid.operationResult.tooltips.originalResult', {
        result: originalResult || fallBackResult(hasEndedAt, success)
      });
  }
};
