import qs from 'qs';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteCardInformationBlock } from './site-card-information-block';
import { SiteCardMetric } from './site-card-metric';
import { SiteCardPrograms } from './site-card-programs';
import { SiteCardTitle } from './site-card-title';
import { appConfig } from '../../../configs/configs';
import { ACDCycleOperationResultSuccessRates } from '../../../types/acdcycle-operation-result-success-rates';
import { InternalLink } from '../../5-elements/internal-link/internal-link';
import { RSCard } from '../../5-elements/rs-card/rs-card';
import { RSTooltip } from '../../5-elements/rs-tooltip/rs-tooltip';

export interface SiteCardProps {
  id: string;
  name: string;
  address: string | undefined | null;
  latitude: number;
  longitude: number;
  devicesCount?: number;
  programs?: string[];
  performance: ACDCycleOperationResultSuccessRates;
  loading?: boolean;
  hasError?: boolean;
}

export const SiteCard = (props: SiteCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RSCard
      extraClassNames={['site-card']}
      title={
        <SiteCardTitle
          address={props.address}
          name={props.name}
          latitude={props.latitude}
          longitude={props.longitude}
        />
      }
      dataTestId="site-card"
    >
      <div className="site-card__contents">
        <RSTooltip title={t('performanceMetricsBlocks.tooltips.operationalPerformance')}>
          <div>
            <SiteCardInformationBlock
              title={t('siteCard.title.operationalPerformance')}
              value={
                <SiteCardMetric
                  loading={props.loading}
                  hasError={props.hasError}
                  metric={{
                    value: props.performance.acdCycleOperationsOperationalRate ?? null,
                    displayUnit: t('units.percentage')
                  }}
                  metricChange={{
                    value: props.performance.acdCycleOperationsOperationalRateChange,
                    displayUnit: t('units.percentage')
                  }}
                />
              }
            />
          </div>
        </RSTooltip>
        <SiteCardInformationBlock title={t('siteCard.title.devices')} value={props.devicesCount || 0} />
        <RSTooltip title={t('performanceMetricsBlocks.tooltips.totalCount')}>
          <div>
            <SiteCardInformationBlock
              title={t('siteCard.title.acdCycles')}
              value={
                <SiteCardMetric
                  loading={props.loading}
                  hasError={props.hasError}
                  metric={{ value: props.performance.acdCycleOperationsCount || 0, decimalPlaces: 0 }}
                  metricChange={{
                    value: props.performance.acdCycleOperationsCountChange,
                    displayUnit: t('units.percentage')
                  }}
                />
              }
            />
          </div>
        </RSTooltip>
        <SiteCardInformationBlock
          title={t('siteCard.title.programs')}
          value={<SiteCardPrograms programs={props.programs} />}
        />
      </div>
      <div className="site-card__devices">
        <InternalLink
          text={t('siteCard.viewDevices')}
          to={`${appConfig.basePath}/devices?${qs.stringify({ site: [props.id] }, { arrayFormat: 'brackets' })}`}
          disabled={!props.devicesCount}
        />
      </div>
    </RSCard>
  );
};
