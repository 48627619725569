import classNames from 'classnames';
import { isString } from 'lodash';
import { JSX, PropsWithChildren } from 'react';

import { DataTestId } from '../../../types/rs-input';

export interface DisplayTableColumnHeaderCell extends PropsWithChildren, DataTestId {
  key: string;
  className?: string;
}

interface DisplayTableColumnHeader extends DataTestId {
  className?: string;
  headerRowClassName?: string;
  headerCells: DisplayTableColumnHeaderCell[];
}

export interface DisplayTableCell extends PropsWithChildren, DataTestId {
  className?: string;
  key: string;
}

interface DisplayTableRow extends DataTestId {
  cells: DisplayTableCell[];
  key: string;
  className?: string;
}

interface DisplayTableProps extends DataTestId {
  columnHeader: DisplayTableColumnHeader;
  rows: DisplayTableRow[];
  className?: string;
  tableBodyClassName?: string;
}

export const DisplayTable = ({
  columnHeader,
  rows,
  className,
  tableBodyClassName,
  'data-testid': dataTestId
}: DisplayTableProps): JSX.Element => {
  return (
    <table className={classNames('display-table', className)} data-testid={dataTestId}>
      <thead className={classNames('display-table__header', columnHeader.className)}>
        <tr className={classNames('display-table__header-row', columnHeader.headerRowClassName)}>
          {columnHeader.headerCells.map((headerCell) => (
            <th
              className={classNames('display-table__header-cell', headerCell.className)}
              scope="col"
              key={headerCell.key}
              title={isString(headerCell.children) ? headerCell.children : undefined}
            >
              {headerCell.children}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={classNames('display-table__body', tableBodyClassName)}>
        {rows.map((row) => (
          <tr key={row.key} className={classNames('display-table__row', row.className)}>
            {row.cells.map((cell) => (
              <td
                className={classNames('display-table__cell', cell.className)}
                key={cell.key}
                title={isString(cell.children) ? cell.children : undefined}
              >
                {cell.children}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
