import { GridColDef } from '@mui/x-data-grid';

import { SiteDeviceRow } from './generate-device-rows';
import { appConfig } from '../../../../configs/configs';
import i18n from '../../../../i18n';
import { DataGridLink } from '../../../5-elements/data-grid/data-grid-link/data-grid-link';

export const siteDevicesDataGridColumns: GridColDef<SiteDeviceRow>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: true,
    flex: 1.7,
    minWidth: 170,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.serialNumber'),
    renderCell: ({ id, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/admin/devices/${id}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'deviceGroup',
    hideable: false,
    sortable: true,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.deviceGroup')
  },
  {
    field: 'program',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.program')
  },
  {
    field: 'type',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.type')
  },
  {
    field: 'connectorHolderType',
    hideable: false,
    sortable: true,
    flex: 2.2,
    minWidth: 220,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.connectorHolderType')
  },
  {
    field: 'deactivated',
    hideable: false,
    sortable: true,
    flex: 1.4,
    minWidth: 140,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.deactivated')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('siteAdminDetailsPage.deviceDataGrid.updatedAt')
  }
];
