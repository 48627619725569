import { GetDevicesQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types/flatten';
import { getDeviceMeasurementValue } from '../../../../utilities/get-device-measurement-value/get-device-measurement-value';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';

export type DeviceRowDevice = FlattenFunction<typeof generateDeviceRows>;

export const generateDeviceRows = (devices?: GetDevicesQuery['devices']) => {
  if (!devices) {
    return [];
  }

  return devices.map((device) => ({
    id: device.id,
    serialNumber: {
      serialNumber: device.serialNumber,
      deviceLastState: device.deviceLastState,
      deviceOnlineStatus: getDeviceMeasurementValue('is_online', device.deviceMeasurementValues)
    },
    operationalPerformance: {
      acdCycleOperationsOperationalRate: device.deviceCurrentPerformance?.operationalPerformanceLastPeriodPct ?? null,
      acdCycleOperationsOperationalRateChange: device.deviceCurrentPerformance?.operationalPerformanceDiff ?? null,
      acdCycleOperationsCount: device.deviceCurrentPerformance?.totalLastPeriod ?? null,
      acdCycleOperationsCountChange: device.deviceCurrentPerformance?.totalDiffPct ?? null
    },
    basePerformance: {
      acdCycleOperationsFullyCompletedRate: device.deviceCurrentPerformance?.performanceLastPeriodPct ?? null,
      acdCycleOperationsFullyCompletedRateChange: device.deviceCurrentPerformance?.performanceDiff ?? null,
      acdCycleOperationsCount: device.deviceCurrentPerformance?.totalLastPeriod ?? null,
      acdCycleOperationsCountChange: device.deviceCurrentPerformance?.totalDiffPct ?? null
    },
    operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(device.operationalLifeCycle),
    rocOS: device.deviceSoftwareConfigurationActive?.rocosComposedVersion,
    connectorType: device.connectorHolderType?.connectorType,
    customer: device.site.customer.company.name,
    site: device.site.name,
    program: device.program?.name,
    customerId: device.site.customer.company.id
  }));
};
