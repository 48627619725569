import { capitalize, lowerCase } from 'lodash';

import i18n from '../../i18n';
import { ObjectUploadStatus } from '../../types/object-upload-status';
import { OnlineStatus } from '../../types/online-status';

export type ObjectUploadStatusMessage = {
  title: string;
  main: string;
  detail: string | null;
};

export const mapObjectUploadStatusDescription = (
  uploadStatus: ObjectUploadStatus,
  deviceOnlineStatus: OnlineStatus,
  type: 'image' | 'log'
): ObjectUploadStatusMessage => {
  const prefix = `operationsPage.operationDetails.${type}Modal`;
  let mainMessage = '';
  let detailMessage = '';
  let title = i18n.t(`${prefix}.titleInProgress`);

  switch (uploadStatus) {
    case ObjectUploadStatus.Draft:
      mainMessage = i18n.t('objectUploadStatus.draft');
      title = i18n.t(`${prefix}.titleNotCollected`);
      switch (deviceOnlineStatus) {
        case OnlineStatus.Offline:
          detailMessage = i18n.t('objectUploadStatusDetail.draftOffline');
          break;
        case OnlineStatus.HeartbeatOnly:
          detailMessage = i18n.t('objectUploadStatusDetail.draftHeartbeatOnly');
          break;
      }
      break;
    case ObjectUploadStatus.UploadFailed:
      mainMessage = i18n.t('objectUploadStatus.uploadFailed');
      title = i18n.t(`${prefix}.titleNotAvailable`);
      break;
    case ObjectUploadStatus.UploadFailedNotFound:
      mainMessage = i18n.t('objectUploadStatus.uploadFailedNotFound');
      title = i18n.t(`${prefix}.titleNotAvailable`);
      break;
    case ObjectUploadStatus.UploadRequestFailed:
      mainMessage = i18n.t('objectUploadStatus.uploadRequestFailed');
      title = i18n.t(`${prefix}.titleNotCollected`);
      break;
    case ObjectUploadStatus.UploadRequestQueued:
      mainMessage = i18n.t('objectUploadStatus.uploadRequestQueued');
      title = i18n.t(`${prefix}.titleCollectionInProgress`);
      switch (deviceOnlineStatus) {
        case OnlineStatus.Offline:
          detailMessage = i18n.t('objectUploadStatusDetail.uploadRequestQueuedOffline');
          break;
        case OnlineStatus.HeartbeatOnly:
          detailMessage = i18n.t('objectUploadStatusDetail.uploadRequestQueuedHeartbeatOnly');
          break;
      }
      break;
    case ObjectUploadStatus.UploadRequested:
      mainMessage = i18n.t('objectUploadStatus.uploadRequested');
      title = i18n.t(`${prefix}.titleCollectionInProgress`);
      break;
    default:
      title = i18n.t(`${prefix}.titleNotCollected`);
      mainMessage = i18n.t('objectUploadStatus.unknown');
      break;
  }
  return { main: mainMessage, detail: detailMessage || null, title };
};

export const mapObjectUploadStatus = (uploadStatus: ObjectUploadStatus): string => {
  return capitalize(lowerCase(uploadStatus));
};
