import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_COMPANY_DETAILS = gql(`
  query getAdminCompanyDetails($companyId: uuid!) {
    companyByPK(id: $companyId) {
      id
      companyType
      name
      maxDeviceGroupRole
      createdAt
      updatedAt
      customer {
        companyId
        allowImport
        documentCenterUrl
        sites(orderBy: {name: ASC}) {
          id
          name
          timezoneName
          createdAt
          updatedAt
          location {
            id
            formattedAddress
          }
        }
        devicesAggregate {
          aggregate {
            count
          }
        }
        deviceGroups(orderBy: {name: ASC}) {
          id
          name
          createdAt
          updatedAt
          serviceProvider {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
      }
      serviceProvider {
        companyId
        createdAt
        updatedAt
        deviceGroups(orderBy: {name: ASC}) {
          id
          name
          createdAt
          updatedAt
          customer {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
      }
      companySettings {
        id
        rocsysApiIpAcceptlist
      }
      rocAlertConfigurations(orderBy: {name: ASC}) {
        id
        name
        enabled
        createdAt
        updatedAt
        deviceSelectionType
        rocAlertDevicesAggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`);
