import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPage, ErrorPageProps } from '../error-page';

type PageNotFoundErrorPageProps = Pick<ErrorPageProps, 'hideButtonLink'>;

export const PageNotFoundErrorPage = ({ hideButtonLink }: PageNotFoundErrorPageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
      title={t('pageNotFoundErrorPage.errorTitle')}
      message={t('pageNotFoundErrorPage.notFoundInfo')}
      hideButtonLink={hideButtonLink}
    />
  );
};
