import { isEmpty } from 'lodash';

import { GetAdminUserDetailsQuery, UserDeviceGroupRole } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types/flatten';
import { formatTimestamp } from '../../../../utilities/format-timestamp/format-timestamp';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { mapUserDeviceGroupRole } from '../../../../utilities/map-display-labels/map-user-device-group-role';

export const generateDeviceGroupRows = (
  deviceGroups: NonNullable<GetAdminUserDetailsQuery['userByPK']>['userDeviceGroups'],
  userTimezone?: string
) => {
  if (isEmpty(deviceGroups)) {
    return [];
  }

  return deviceGroups.map((deviceGroup) => ({
    id: deviceGroup.deviceGroup.id,
    name: deviceGroup.deviceGroup.name,
    customer: deviceGroup.deviceGroup.customer.company.name,
    role: mapUserDeviceGroupRole(deviceGroup.userRole as UserDeviceGroupRole),
    validUntil: deviceGroup.validUntil
      ? formatTimestamp(deviceGroup.validUntil, userTimezone)
      : getNonNullishDisplayValue(undefined),
    devicesCount: deviceGroup.deviceGroup.deviceGroupDevicesAggregate.aggregate?.count || 0,
    usersCount: deviceGroup.deviceGroup.userDeviceGroupsAggregate.aggregate?.count || 0,
    createdAt: formatTimestamp(deviceGroup.createdAt, userTimezone),
    updatedAt: formatTimestamp(deviceGroup.updatedAt, userTimezone)
  }));
};

export type UserDeviceGroupRow = Flatten<ReturnType<typeof generateDeviceGroupRows>>;
