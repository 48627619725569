import { GridColDef } from '@mui/x-data-grid';
import { toString } from 'lodash';

import { CompanyAlertConfigurationRow } from './generate-alert-configuration-rows';
import i18n from '../../../../i18n';

export const alertConfigurationsDataGridColumns: GridColDef<CompanyAlertConfigurationRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: true,
    flex: 3,
    minWidth: 300,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.name')
  },
  {
    field: 'devicesCount',
    hideable: false,
    sortable: true,
    flex: 1.6,
    minWidth: 160,
    headerName: i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.devicesCount'),
    renderCell: ({ value }) => {
      return (
        <span
          data-testid="company-details-admin-page-customer-alert-configurations-data-grid-devices-count"
          title={toString(value)}
        >
          {value}
        </span>
      );
    }
  },
  {
    field: 'status',
    hideable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    headerName: i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.status')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('companyAdminDetailsPage.alertConfigurationsDataGrid.updatedAt')
  }
];
