import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { deviceLatestOperationColumns } from './device-latest-operation-columns';
import { DeviceLatestOperationRow, generateDeviceLatestOperationRows } from './generate-device-latest-operation-rows';
import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { RSDataGrid } from '../../../4-features/rs-data-grid/rs-data-grid';
import { UserTimezoneContext } from '../../../contexts/user-timezone-context';

interface DeviceLatestOperationsProps {
  operations: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceOperations'];
  deviceId: string;
  serialNumber?: string | null;
}

export const DeviceLatestOperations = ({
  operations,
  deviceId,
  serialNumber
}: DeviceLatestOperationsProps): JSX.Element => {
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DeviceLatestOperationRow>) => {
    searchParams.set('operationId', params.row.id);
    setSearchParams(searchParams);
  };

  return (
    <RSDataGrid
      columns={deviceLatestOperationColumns}
      rows={generateDeviceLatestOperationRows(operations, deviceId, serialNumber, userTimezone)}
      data-testid="device-latest-operations"
      onRowClick={handleRowClick}
      autoHeight={true}
    />
  );
};
