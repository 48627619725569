import { GetUserPermissionRightsQuery } from '../../__generated__/graphql';
import { CompanyType } from '../../types/company-type';

export const getAllowedUserPermissions = (
  permissionRights?: GetUserPermissionRightsQuery,
  companyType?: CompanyType | null
): string[] => {
  const customerPermissionRights =
    permissionRights?.userPermissionRight.filter((permission) => permission.forCustomer) || [];
  const customerPermissions = customerPermissionRights?.map((right) => right.permission);
  const serviceProviderPermissionRights =
    permissionRights?.userPermissionRight.filter((permission) => permission.forServiceProvider) || [];
  const serviceProviderPermissions = serviceProviderPermissionRights?.map((right) => right.permission);

  if (companyType === CompanyType.Customer) {
    return customerPermissions;
  }
  if (companyType === CompanyType.ServiceProvider) {
    return serviceProviderPermissions;
  }
  return [];
};
