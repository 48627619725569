import { JSX } from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import { ChartTestPage } from './1-pages/chart-test-page/chart-test-page';
import { CompaniesAdminPage } from './1-pages/companies-admin-page/companies-admin-page';
import { CompanyDetailsAdminPage } from './1-pages/company-details-admin-page/company-details-admin-page';
import { CustomerDetailsPage } from './1-pages/customer-details-page/customer-details-page';
import { CustomersPage } from './1-pages/customers-page/customers-page';
import { DeviceDetailsAdminPage } from './1-pages/device-details-admin-page/device-details-admin-page';
import { DeviceDetailsPage } from './1-pages/device-details-page/device-details-page';
import { DeviceGroupDetailsAdminPage } from './1-pages/device-group-details-admin-page/device-group-details-admin-page';
import { DevicesAdminPage } from './1-pages/devices-admin-page/devices-admin-page';
import { DevicesPage } from './1-pages/devices-page/devices-page';
import { PageNotFoundErrorPage } from './1-pages/error-page/page-not-found-error-page/page-not-found-error-page';
import { OperationsPage } from './1-pages/operations-page/operations-page';
import { SiteDetailsAdminPage } from './1-pages/site-details-admin-page/site-details-admin-page';
import { TestPage } from './1-pages/test-page/test-page';
import { TokenPage } from './1-pages/token-page/token-page';
import { UserDetailsAdminPage } from './1-pages/user-details-admin-page/user-details-admin-page';
import { UsersAdminPage } from './1-pages/users-admin-page/users-admin-page';
import { PageWrapper } from './2-templates/page-wrapper/page-wrapper';
import { getRedirectFullPath } from '../utilities/get-redirect-full-path/get-redirect-full-path';

const RedirectToDevicePage = (): JSX.Element => {
  const { deviceId } = useParams();
  return <Navigate to={`/portal/devices/${deviceId}`} replace={true} />;
};

export const ApplicationRoutes = (): JSX.Element => {
  const routerLocation = useLocation();
  const fullPath = getRedirectFullPath(routerLocation);

  // Note: The <PageWrapper> MUST be in this level (not to be in every page component).
  // Putting <PageWrapper> in each page component triggers Auth0 to re-fetch the user avatar (make remote request)
  return (
    <Routes>
      <Route path="/portal" element={<Navigate to="customers" replace={true} />} />
      <Route
        path="/portal/customers"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CustomersPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/customers/:customerId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CustomerDetailsPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/devices"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DevicesPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/devices/:deviceId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DeviceDetailsPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/operations"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <OperationsPage />
          </PageWrapper>
        }
      />
      <Route path="/portal/admin" element={<Navigate to="/portal/admin/companies" replace={true} />} />
      <Route
        path="/portal/admin/companies"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CompaniesAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/companies/:companyId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CompanyDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/sites/:siteId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <SiteDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/device-groups/:deviceGroupId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DeviceGroupDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/users"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <UsersAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/devices/:deviceId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DeviceDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/users/:userId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <UserDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/devices"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DevicesAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/components-demo"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <TestPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/charts-demo"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <ChartTestPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/token"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <TokenPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/callback"
        element={<PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={false} />}
      />
      {/* 
        redirect from old to new device page (used in teams notifications)
        portal/rocs/detail-screen/item/<device-uuid>
        portal/devices/<device-uuid> */}
      <Route path="/portal/rocs/detail-screen/item/:deviceId" element={<RedirectToDevicePage />} />
      <Route
        path="*"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={false}>
            <PageNotFoundErrorPage />
          </PageWrapper>
        }
      />
    </Routes>
  );
};
