import { z } from 'zod';

import i18n from '../../../../../i18n';

export const addSiteSchema = z.object({
  id: z.string().uuid(),
  name: z
    .string({ message: i18n.t('forms.inputValidation.emptyInput') })
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  timezone: z.string().optional(),
  location: z.object({
    latitude: z
      .number({
        required_error: i18n.t('forms.inputValidation.emptyInput'),
        invalid_type_error: i18n.t('forms.inputValidation.noCharsAllowed')
      })
      .gte(-90, { message: i18n.t('forms.inputValidation.outOfRange.latitude') })
      .lte(90, { message: i18n.t('forms.inputValidation.outOfRange.latitude') }),
    longitude: z
      .number({
        required_error: i18n.t('forms.inputValidation.emptyInput'),
        invalid_type_error: i18n.t('forms.inputValidation.noCharsAllowed')
      })
      .gte(-180, { message: i18n.t('forms.inputValidation.outOfRange.longitude') })
      .lte(180, { message: i18n.t('forms.inputValidation.outOfRange.longitude') }),
    formattedAddress: z.string().nullish()
  }),
  description: z.string().nullish()
});

export type AddSiteRequest = z.infer<typeof addSiteSchema>;
