import { Autocomplete, AutocompleteProps, Checkbox, TextField } from '@mui/material';
import classNames from 'classnames';
import { omit } from 'lodash';
import { JSX, ReactNode } from 'react';

import { RSAutocompleteDefaultMenuOption } from './rs-autocomplete-default-menu-option/rs-autocomplete-default-menu-option';
import AngleBracketDownIcon from '../../../assets/icons/angle-brackets-down.svg?react';
import CheckBoxCheckedIcon from '../../../assets/icons/check-box-checked.svg?react';
import CheckBoxOutlinedBlankIcon from '../../../assets/icons/check-box-outlined-blank.svg?react';
import CrossIcon from '../../../assets/icons/cross.svg?react';
import { RSAutocompleteValue } from '../../../types/rs-autocomplete';
import { InfoIconTooltip } from '../../4-features/info-icon-tooltip/info-icon-tooltip';
import { RSFilterChip } from '../rs-filter-chip/rs-filter-chip';

interface RSAutocompleteProps
  extends Omit<AutocompleteProps<RSAutocompleteValue, true, undefined, undefined>, 'renderInput'> {
  customMenuOption?: (option: RSAutocompleteValue) => ReactNode;
  customTagOption?: (option: RSAutocompleteValue) => ReactNode;
  inputLabel: ReactNode;
  inputLabelTooltip?: ReactNode;
}

/**
 * This component renders a RSAutocomplete component. It is based on the MUI Autocomplete component.
 * The API is the same as the MUI Autocomplete component: https://mui.com/material-ui/api/autocomplete/
 * Only commonly used properties are listed below.
 *
 * @param {(string | number | boolean)[]} options An array of options.
 * @param {React.ReactNode} inputLabel The label for the autocomplete field.
 * @returns {React.JSX.Element} A React element that renders a RSAutocomplete component.
 */
export const RSAutocomplete = ({
  customMenuOption,
  customTagOption,
  inputLabel,
  inputLabelTooltip,
  ...props
}: RSAutocompleteProps): JSX.Element => {
  const autocompleteClassNames = classNames('rs-autocomplete', props.className);
  // Properties above `{...props}` can be override by `props`; properties below it cannot.
  return (
    <Autocomplete
      limitTags={3}
      {...props}
      multiple
      disableCloseOnSelect
      disablePortal
      className={autocompleteClassNames}
      renderInput={(params) => (
        <TextField
          {...params}
          className="rs-autocomplete__text-field"
          data-testid="rs-autocomplete-text-input"
          label={
            inputLabelTooltip ? (
              <>
                {inputLabel}&nbsp;
                <InfoIconTooltip infoIconTooltip={inputLabelTooltip} />
              </>
            ) : (
              inputLabel
            )
          }
          slotProps={{ inputLabel: { className: 'rs-autocomplete__input-label', shrink: true } }}
        />
      )}
      renderOption={(optionProps, option, { selected }) => (
        // Cannot use spread {...optionProps}: React complains `key` shall be declared explicitly
        <li key={optionProps.id} {...omit(optionProps, ['key'])}>
          <Checkbox
            className="rs-autocomplete__checkbox"
            data-testid={`rs-autocomplete-option-checkbox-${option}`}
            icon={<CheckBoxOutlinedBlankIcon className="rs-autocomplete__checkbox-outlined" />}
            checkedIcon={<CheckBoxCheckedIcon className="rs-autocomplete__checked-icon" />}
            checked={selected}
            disableRipple
          />
          {customMenuOption ? customMenuOption(option) : <RSAutocompleteDefaultMenuOption option={option} />}
        </li>
      )}
      clearIcon={<CrossIcon />}
      popupIcon={<AngleBracketDownIcon />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <RSFilterChip
            label={customTagOption ? customTagOption(option) : option}
            data-testid={`rs-autocomplete-filter-chip-${option}`}
            {...getTagProps({ index })}
            // React complaints `key` shall be declared explicitly
            key={getTagProps({ index }).key}
          />
        ))
      }
      slotProps={{ popper: { className: 'rs-autocomplete__popper' } }}
    />
  );
};
