import { AnnotationStatus } from '../../types/operation-annotation';

export const getAnnotationStatus = (deviceResultId?: string, deviceAnnotatedResultId?: string): AnnotationStatus => {
  if (deviceResultId && deviceAnnotatedResultId) {
    if (deviceResultId === deviceAnnotatedResultId) {
      return AnnotationStatus.Confirmed;
    }
    return AnnotationStatus.Overridden;
  }
  return AnnotationStatus.NotAnnotated;
};
