import { useMutation } from '@apollo/client';
import { JSX, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs/configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { MUTATION_REQUEST_SYNC_DEVICES } from '../../../../services/mutations/admin/devices/request-sync-devices';
import { contextualizeBoolean } from '../../../../utilities/contextualize-boolean/contextualize-boolean';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { AdminDetailsPanelTitle } from '../../../4-features/admin/admin-details-panel-title/admin-details-panel-title';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { RSButton } from '../../../5-elements/rs-button/rs-button';
import { RSTooltip } from '../../../5-elements/rs-tooltip/rs-tooltip';
import { useEnqueueSnackbar } from '../../../hooks/use-enqueue-snackbar';
import { useFormatTimezone } from '../../../hooks/use-format-timezone';
import { ModifyDeviceDrawer } from '../modify-device-drawer/modify-device-drawer';

interface DeviceDetailsAdminPanelProps {
  // This panel will only be shown if the device details are successfully loaded
  deviceDetails: NonNullable<GetAdminDeviceDetailsQuery['deviceByPK']>;
}

export const DeviceDetailsAdminPanel = ({ deviceDetails }: DeviceDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [openModifyDevice, setOpenModifyDevice] = useState<boolean>(false);
  const { result: userCanEditDevice, loading: loadingUserCanEditDevice } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: { type: 'Device', deviceId: deviceDetails.id },
    skip: false
  });
  const [requestSyncDevice, { loading: loadingRequestSyncDevice }] = useMutation(MUTATION_REQUEST_SYNC_DEVICES, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(
        t('deviceAdminDetailsPage.panel.syncProgram.error'),
        undefined,
        error.message || error.name,
        'error'
      );
    },
    onCompleted: () => {
      sendMessageToSnackbar(
        t('deviceAdminDetailsPage.panel.syncProgram.success'),
        '',
        t('deviceAdminDetailsPage.panel.syncProgram.syncDone')
      );
    }
  });

  const handleRequestProgramSync = (event: MouseEvent): void => {
    event.preventDefault();
    sendMessageToSnackbar(
      t('deviceAdminDetailsPage.panel.syncProgram.started'),
      '',
      t('deviceAdminDetailsPage.panel.syncProgram.syncStarted')
    );
    requestSyncDevice({ variables: { serialNumbers: [deviceDetails.serialNumber || ''] } });
  };

  return (
    <aside className="device-details-admin-panel" data-testid="device-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('deviceAdminDetailsPage.panel.type')}
        objectName={getNonNullishDisplayValue(deviceDetails.serialNumber)}
        goBackUrl={`${appConfig.basePath}/admin/devices`}
        editButtonProps={{
          onClick: () => setOpenModifyDevice(true),
          disabled: loadingUserCanEditDevice || !userCanEditDevice
        }}
      />
      <div className="device-details-admin-panel__contents">
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.customer')}
          data-testid="device-details-admin-panel-customer"
          value={getNonNullishDisplayValue(deviceDetails.site.customer.company?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.site')}
          data-testid="device-details-admin-panel-site"
          value={getNonNullishDisplayValue(deviceDetails.site.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.program')}
          labelTooltip={t('tooltips.device.programNumber')}
          data-testid="device-details-admin-panel-program"
          value={getNonNullishDisplayValue(deviceDetails.program?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.deviceType')}
          data-testid="device-details-admin-panel-device-type"
          value={getNonNullishDisplayValue(deviceDetails.deviceType?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.connectorHolderType')}
          data-testid="device-details-admin-panel-connector-holder-type"
          value={getNonNullishDisplayValue(deviceDetails.connectorHolderType?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.latitude')}
          data-testid="device-details-admin-panel-latitude"
          value={getNonNullishDisplayValue(deviceDetails.location?.latitude)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.longitude')}
          data-testid="device-details-admin-panel-longitude"
          value={getNonNullishDisplayValue(deviceDetails.location?.longitude)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.description')}
          data-testid="device-details-admin-panel-description"
          value={getNonNullishDisplayValue(deviceDetails.description)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.deActivated')}
          labelTooltip={t('tooltips.device.deactivated')}
          data-testid="device-details-admin-panel-deactivated"
          value={contextualizeBoolean(
            t('deviceAdminDetailsPage.panel.deActivatedTrue'),
            t('deviceAdminDetailsPage.panel.deActivatedFalse'),
            deviceDetails.deactivated
          )}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.createdAt')}
          data-testid="device-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(deviceDetails.createdAt)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.updatedAt')}
          data-testid="device-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(deviceDetails.updatedAt)}
        />
        {/* not displaying the button is inconsistent, but it reduces a lot of noise */}
        {userCanEditDevice && !loadingUserCanEditDevice && (
          <InformationBlock
            label={t('deviceAdminDetailsPage.panel.requestProgramSync')}
            data-testid="device-details-admin-panel-request-program-sync"
            value={
              <div className="device-details-admin-panel__request-program-sync">
                <span>{t('deviceAdminDetailsPage.panel.requestProgramSyncDescription')}</span>
                <RSTooltip title={t('tooltips.device.syncProgram')} useHtml={true}>
                  <div>
                    <RSButton
                      color="success"
                      variant="outlined"
                      extraClassNames={['device-details-admin-panel__request-program-sync-button']}
                      onClick={handleRequestProgramSync}
                      disabled={loadingRequestSyncDevice}
                      data-testid="device-details-admin-panel-request-program-sync-button"
                    >
                      {t('deviceAdminDetailsPage.panel.requestProgramSync')}
                    </RSButton>
                  </div>
                </RSTooltip>
              </div>
            }
          />
        )}
      </div>
      <ModifyDeviceDrawer
        open={openModifyDevice}
        setOpenModifyDevice={setOpenModifyDevice}
        defaultValues={{
          id: deviceDetails.id,
          customerId: deviceDetails.site.customer.companyId,
          siteId: deviceDetails.site.id,
          deactivated: deviceDetails.deactivated,
          deviceTypeId: deviceDetails.deviceType.id,
          connectorHolderTypeId: deviceDetails.connectorHolderType?.id,
          location: deviceDetails.location,
          description: deviceDetails.description,
          keepDataAtPreviousSite: true
        }}
        createdAt={formatWithDefaultTimezone(deviceDetails.createdAt)}
        program={deviceDetails.program?.name}
      />
    </aside>
  );
};
