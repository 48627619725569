import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_USER_TO_DEVICE_GROUP_LINK = gql(`
  mutation createUserToDeviceGroupLink(
    $deviceGroupId: String!
    $userId: String!
    $userRole: UserDeviceGroupRole!
    $validUntil: String
  ) {
    createUserToDeviceGroupLink(
      deviceGroupId: $deviceGroupId
      userId: $userId
      userRole: $userRole
      validUntil: $validUntil
    ) {
      deviceGroupId
      userId
    }
  }
`);
