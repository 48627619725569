import { Modal, ModalProps } from '@mui/material';
import classNames from 'classnames';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangeHistoryEntry,
  generateAnnotationChangeHistory
} from './generate-annotation-change-history/generate-annotation-change-history';
import { OperationalLifeCycle } from '../../../../__generated__/graphql';
import {
  AnnotationAuditHistory,
  InitialOperationalLifeCycle,
  OriginalOperationResult
} from '../../../../types/operation-annotation';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities/map-display-labels/map-operational-life-cycle-display-label';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { UserTimezoneContext } from '../../../contexts/user-timezone-context';

interface AnnotationChangeHistoryProps {
  open: ModalProps['open'];
  onClose: () => void;
  annotationAuditHistory: AnnotationAuditHistory;
  originalResult?: OriginalOperationResult;
  originalOperationalLifeCycle: InitialOperationalLifeCycle;
  serialNumber: string;
  deviceOperationStartAt: string;
}

interface AnnotationChangeHistoryEntryProps {
  entry: ChangeHistoryEntry;
  highlight?: boolean;
}

const AnnotationChangeHistoryEntry = ({ entry, highlight }: AnnotationChangeHistoryEntryProps): JSX.Element => {
  const { t } = useTranslation();

  const changeValueClassNames = classNames('annotation-change-history-entry__value', {
    'annotation-change-history-entry__value--highlight': highlight
  });
  return (
    <div className="annotation-change-history-entry" data-testid="annotation-change-history-entry">
      <div className="annotation-change-history-entry__annotated-at">
        <span>{entry.timestamp}</span>
      </div>
      <div className="annotation-change-history-entry__details">
        {entry.result && (
          <div className="annotation-change-history-entry__result">
            <span>{t('operationsPage.operationDetails.annotationHistory.result')}</span>
            <span className={changeValueClassNames}>{entry.result}</span>
          </div>
        )}
        {entry.operationalLifeCycle && (
          <div className="annotation-change-history-entry__operational-life-cycle">
            <span>{t('operationsPage.operationDetails.annotationHistory.operationalLifeCycle')}</span>
            <span className={changeValueClassNames}>
              {mapOperationalLifeCycleDisplayLabel(entry.operationalLifeCycle as OperationalLifeCycle)}
            </span>
          </div>
        )}
        {entry.remark && (
          <div className="annotation-change-history-entry__notes">
            <span>{t('operationsPage.operationDetails.annotationHistory.remark')}</span>
            <span className={changeValueClassNames}>{entry.remark}</span>
          </div>
        )}
        <div className="annotation-change-history-entry__annotated-by">
          <span>{entry.annotatedBy}</span>
        </div>
      </div>
    </div>
  );
};

export const AnnotationChangeHistory = ({
  open,
  onClose,
  annotationAuditHistory,
  originalOperationalLifeCycle,
  originalResult,
  serialNumber,
  deviceOperationStartAt
}: AnnotationChangeHistoryProps): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const annotationHistoryEntries = generateAnnotationChangeHistory(
    serialNumber,
    deviceOperationStartAt,
    annotationAuditHistory,
    originalResult,
    originalOperationalLifeCycle,
    userTimezone
  );
  return (
    <Modal open={open} onClose={onClose} className="annotation-change-history">
      <div className="annotation-change-history__box">
        <ModalDrawerHeader
          title={t('operationsPage.operationDetails.annotationHistory.actionLink')}
          handleClose={onClose}
        />
        <div className="annotation-change-history__contents">
          <div className="annotation-change-history__changes">
            {annotationHistoryEntries.slice(1).map((item) => (
              <AnnotationChangeHistoryEntry key={item.id} entry={item} highlight={true} />
            ))}
          </div>
          <div className="annotation-change-history__original-result">
            <AnnotationChangeHistoryEntry entry={annotationHistoryEntries[0]} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
