import { JSX } from 'react';

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import { DataTestId } from '../../../types/rs-input';
import { RSLinkTimezone } from '../../4-features/rs-link-timezone/rs-link-timezone';

interface CountBlockWithInternalLinkProps extends DataTestId {
  title: string;
  count: number;
  to: string;
}

export const CountBlockWithInternalLink = ({
  title,
  count,
  to,
  'data-testid': dataTestId
}: CountBlockWithInternalLinkProps): JSX.Element => {
  return (
    <RSLinkTimezone
      to={to}
      className="count-block-with-internal-link"
      data-testid={dataTestId}
      // Remove textDecoration from the style prop to be able to overwrite with a custom textDecoration
      style={{ textDecoration: 'inherit' }}
    >
      <h4 className="count-block-with-internal-link__title">{title}</h4>
      <div className="count-block-with-internal-link__count">
        <p className="count-block-with-internal-link__count-amount">{count}</p>
        <ArrowRightIcon className="count-block-with-internal-link__arrow" />
      </div>
    </RSLinkTimezone>
  );
};
