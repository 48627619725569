import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';

import { DataTestId } from '../../../types/rs-input';

interface RSActionButtonProps extends ButtonProps, DataTestId {
  text: string;
  iconContainerClassName?: string;
  activated?: boolean;
}

export const RSActionButton = ({
  text,
  iconContainerClassName,
  startIcon,
  activated,
  'data-testid': dataTestId,
  ...props
}: RSActionButtonProps) => {
  const buttonClassNames = classNames('rs-action-button', { 'rs-action-button--activated': activated === true });
  return (
    <Button
      {...props}
      className={buttonClassNames}
      variant="text"
      startIcon={
        <div
          className={classNames('rs-action-button__icon-container', iconContainerClassName)}
          data-testid="rs-action-button-icon"
        >
          {startIcon}
        </div>
      }
      data-testid={dataTestId || 'rs-action-button'}
    >
      <p className="rs-action-button__text" data-testid="rs-action-button-text">
        {text}
      </p>
    </Button>
  );
};
